import { MouseEventHandler, useCallback } from "react"

import { useTranslation } from "react-i18next"
import styled from "styled-components"

import TabButton from "@/components/groups/TabGroup/TabButton"
import { ITabGroupProps } from "@/components/groups/types"

export default function TabGroup({
  tabs,
  activeTab,
  setActiveTab,
}: ITabGroupProps) {
  const { t } = useTranslation()

  const handleTabClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    event => {
      const tab = Number(event.currentTarget.dataset.tab)
      setActiveTab(tab)
    },
    [setActiveTab]
  )

  return (
    <Wrapper>
      {tabs.map(({ name, tab }) => (
        <TabButton
          key={tab}
          data-tab={tab}
          onClick={handleTabClick}
          data-active={tab === activeTab}
        >
          {t(name)}
        </TabButton>
      ))}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 8px;
`

import baseApi from "@/store/api/base"
import { withQueryParams } from "@/store/api/helpers"

import { Contest, GetContestByIdParams } from "./types"

const contestApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getContestById: builder.query<Contest, GetContestByIdParams>({
      query: ({ id, code }) =>
        withQueryParams({
          url: "/contests/getByID",
          params: { id, code },
        }),
      // queryFn: ({ id }) => ({
      //   data: {
      //     id: Number(id),
      //     name: "Sort Me Round №8",
      //     description:
      //       "What an interesting contest it will be, you can't imagine!",
      //     organizer: "Sort Me Team",

      //     timings: {
      //       now: Date.now() / 1000 + 100,
      //       start: Date.now() / 1000 + 100,
      //       end: Date.now() / 1000 + 200,
      //       registration_start: Date.now() / 1000 + 50,
      //       registration_end: Date.now() / 1000 + 100,
      //     },

      //     features: {
      //       practice: false,
      //       table_visible: true,
      //       jury_chat: false,
      //     },

      //     rules: "icpc",

      //     participants_count: 541,
      //     task_count: 1,

      //     registered: true,

      //     problems: undefined,

      //     languages: [
      //       {
      //         name: "Python 3.11",
      //         highlight: "python",
      //         api: "python",
      //         ext: "py",
      //       },
      //       {
      //         name: "PyPy 3.9",
      //         highlight: "python",
      //         api: "pypy",
      //         ext: "py",
      //       },
      //       {
      //         name: "GNU C++20",
      //         highlight: "cpp",
      //         api: "c++",
      //         ext: "cpp:cc:C",
      //       },
      //     ],
      //   },
      // }),
      providesTags: contest =>
        contest
          ? ["Localized", { type: "Contest", id: contest.id }]
          : ["Localized"],
    }),
    refetchContestById: builder.mutation<void, string>({
      queryFn: () => ({ data: void null }),
      invalidatesTags: (_result, _error, id) => [{ type: "Contest", id }],
    }),
  }),
})

export default contestApi
export const { useGetContestByIdQuery, useRefetchContestByIdMutation } =
  contestApi

import { useCallback, useMemo } from "react"

import {
  useGetAdminsQuery,
  useSetAdminMutation,
} from "@/store/api/problems/edit/admins"

export default function useAdminList(problemId: string) {
  const { admins } = useGetAdminsQuery(problemId, {
    selectFromResult: ({ data }) => ({
      admins: data?.admins ?? [],
    }),
  })

  const adminIds = useMemo(
    () => new Set(admins.map(({ user: { id } }) => id)),
    [admins]
  )

  const [setAdminTrigger] = useSetAdminMutation()

  const setAdmin = useCallback(
    async ({ id, level }: { id: number; level: number }) => {
      const result = await setAdminTrigger({
        id: Number(problemId),
        user_id: id,
        level,
      })
      return result
    },
    [problemId, setAdminTrigger]
  )

  const removeAdmin = useCallback(
    async ({ id }: { id: number }) => {
      const result = await setAdminTrigger({
        id: Number(problemId),
        user_id: id,
        level: 0,
      })
      return result
    },
    [problemId, setAdminTrigger]
  )

  return {
    admins,
    adminIds,
    setAdmin,
    removeAdmin,
  }
}

import React, { useEffect, useRef } from "react"

interface IInfiniteScrollerProps {
  /** Number of child that will trigger new request, starting from the last */
  seamless?: number
  fireOnce?: boolean
  enabled?: boolean

  fetchMore: () => void

  children: React.ReactElement[]
}

export default function InfiniteScroller({
  children,
  fetchMore,
  fireOnce = true,
  enabled = true,
  seamless = 1,
}: IInfiniteScrollerProps): React.ReactElement {
  const target = useRef<HTMLElement>(null)

  useEffect(() => {
    if (!enabled) return
    if (!target.current)
      throw new Error("[InfiniteScroller] Children were not rendered!")

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (!entry.isIntersecting) return
        if (fireOnce) observer.unobserve(entry.target)

        fetchMore()
      },
      { threshold: 0 }
    )

    observer.observe(target.current)

    return () => observer.disconnect()
  }, [children, enabled, fetchMore, fireOnce, target])

  const count = React.Children.count(children)
  const contents = React.Children.map(children, (child, idx) => {
    if (idx + seamless !== count) return child

    return React.cloneElement(child, { ref: target })
  })

  return <>{contents}</>
}

import React from "react"

import { langs } from "@uiw/codemirror-extensions-langs"
import { bbedit } from "@uiw/codemirror-theme-bbedit"
import { dracula } from "@uiw/codemirror-theme-dracula"
import ReactCodeMirror, {
  ReactCodeMirrorProps,
  ReactCodeMirrorRef,
} from "@uiw/react-codemirror"
import styled from "styled-components"

import { useTheme } from "@/store/hooks"
import { code12, code14 } from "@/utils/fonts"
import mediaQueryFor from "@/utils/mediaQuery"

const StyledCodeMirror = React.forwardRef<
  ReactCodeMirrorRef,
  Omit<ReactCodeMirrorProps, "theme">
>((props, ref) => {
  const theme = useTheme()

  return (
    <RawCodeMirror
      ref={ref}
      theme={theme === "dark" ? dracula : bbedit}
      {...props}
    />
  )
})

export const RawCodeMirror = styled(ReactCodeMirror)`
  width: 100%;

  border: 1px solid var(--color-input-stroke);
  border-radius: 4px;

  background-color: var(--color-background);

  transition: background-color var(--transition-duration)
      var(--transition-function),
    border-color var(--transition-duration) var(--transition-function);

  min-height: 200px;

  ${code14};
  // CUSTOM

  ${mediaQueryFor.mobile} {
    ${code12};
  }

  &[data-has-value="false"] .cm-editor .cm-scroller .cm-activeLineGutter {
    background-color: transparent !important;
  }

  & .cm-editor {
    background-color: var(--color-background);
    color: var(--color-text);

    transition: background-color var(--transition-duration)
      var(--transition-function);

    max-height: 635px; // 30 lines + padding + borders

    border-radius: 4px; // Remove sharp edges on corners
    padding-bottom: 1px;

    .cm-selectionMatch {
      background-color: rgba(var(--color-code-editor-background), 0.2);
    }

    .cm-gutters {
      border: none;
      background-color: var(--color-background);
      color: var(--color-text);

      transition: background-color var(--transition-duration)
          var(--transition-function),
        color var(--transition-duration) var(--transition-function);
    }

    .cm-cursor,
    .cm-dropCursor {
      border-left-color: var(--color-text);
    }

    .cm-content {
      filter: brightness(85%);
      caret-color: var(--color-text);

      ${mediaQueryFor.mobile} {
        white-space: pre-wrap;
        word-break: break-word;
        word-wrap: break-word;

        overflow-wrap: anywhere;
        flex-shrink: 1;
      }
    }

    .cm-lineNumbers > .cm-gutterElement {
      padding-left: 16px;
      padding-right: 6px;

      color: var(--color-text-4c);
    }

    .cm-scroller {
      // SCROLLBAR
      scrollbar-width: none;

      &::-webkit-scrollbar {
        width: 0px;
      }
      // end SCROLLBAR

      padding: 8px 0px;

      ${code14};
      letter-spacing: 0.01em;

      ${mediaQueryFor.mobile} {
        ${code12};
      }

      .cm-activeLine {
        background-color: transparent;
      }

      .cm-activeLineGutter {
        background-color: rgba(var(--color-code-editor-background), 0.1);
      }

      .cm-line {
        padding-left: 10px;
        transition: color var(--transition-duration) var(--transition-function);

        .cm-foldPlaceholder {
          background-color: var(--color-bubble);
          border: none;
          color: var(--color-text);
          border-radius: 2px;
        }
      }
    }
  }

  .cm-editor.cm-focused .cm-selectionBackground .cm-selectionBackground,
  .cm-editor .cm-selectionLayer .cm-selectionBackground,
  .cm-editor ::selection {
    background-color: rgba(var(--color-code-editor-background), 0.1);
  }

  & .cm-editor.cm-focused {
    outline: none;
  }
`

export const getLinter = (lang: string | null) => {
  switch (lang) {
    case "python":
    case "pypy":
      return langs.python()
    case "c_cpp":
    case "c++":
    case "cpp":
      return langs.cpp()
    case "golang":
      return langs.go()
    case "haskell":
      return langs.haskell()
    case "java":
      return langs.java()
    case "rust":
      return langs.rust()
    case "c":
      return langs.c()
    case "csharp":
      return langs.csharp()
    default:
      return null
  }
}

export default StyledCodeMirror

import styled from "styled-components"

interface ILineProps {
  top?: number
  bottom?: number
}

const Line = styled.hr<ILineProps>`
  grid-area: line;
  width: 100%;
  height: 1px;

  transition: background-color var(--transition-duration)
    var(--transition-function);

  background-color: var(--color-separator);

  margin-top: ${({ top }) => top ?? 0}px;
  margin-bottom: ${({ bottom }) => bottom ?? 0}px;

  margin-left: 0px;
  margin-right: 0px;

  border: none;
`

export default Line

import { Link } from "react-router-dom"
import styled from "styled-components"

import {
  IManagedSidebarState,
  ProblemSidebarSource,
} from "@/features/task/current/ProblemSidebar"
import ProblemListItemWrapper from "@/features/task/list/ProblemListItemWrapper"
import { IManagedProblem } from "@/features/task/list/types"
import mediaQueryFor from "@/utils/mediaQuery"

import CreatorColumn from "./CreatorColumn"
import IncludedInContestsColumn from "./IncludedInContestsColumn"
import NameColumn from "./NameColumn"
import TagsColumn from "./TagsColumn"

interface IProblemListItemProps extends IManagedProblem {
  linkState: Omit<IManagedSidebarState, "source">
}

export default function ProblemListItem({
  id,
  names,
  marker,
  creator,
  tags,
  includedInContests,
  linkState,
}: IProblemListItemProps) {
  return (
    <StyledLink
      to={`/problems/${id}`}
      state={{
        ...linkState,
        source: ProblemSidebarSource.managed,
      }}
    >
      <Wrapper>
        <NameColumn names={names} marker={marker} />
        <CreatorColumn creator={creator} />
        <TagsColumn tags={tags} />
        <IncludedInContestsColumn includedInContests={includedInContests} />
      </Wrapper>
    </StyledLink>
  )
}

const StyledLink = styled(Link)`
  text-decoration: none;

  margin-top: 8px;
`

const Wrapper = styled(ProblemListItemWrapper)`
  grid-template-columns: 1.5fr 185px 1fr 78px;
  padding: 0 11px 0 14px;

  ${mediaQueryFor.mobile} {
    grid-template-columns: 1fr 140px;
  }
`

import styled from "styled-components"

import { text17Medium } from "@/utils/fonts"
import mediaQueryFor from "@/utils/mediaQuery"

export const SidebarHolder = styled.aside`
  grid-area: sidebar;

  color: var(--color-text);

  padding: 20px;
  border-right: 2px solid var(--color-g-background-stroke);

  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;

  gap: 10px;
  // TODO: fix here
  height: calc(var(--window-h) - 100px);

  /* This is the magic bit for Firefox */
  scrollbar-width: none;

  &::-webkit-scrollbar {
    /* This is the magic bit for WebKit */
    display: none;
  }

  overflow-y: scroll;

  ${mediaQueryFor.mobile} {
    display: none;
  }
`

export const SidebarItem = styled.span`
  ${text17Medium};
  color: var(--color-text);

  transition: color var(--transition-duration) var(--transition-function);

  cursor: pointer;
`

import { ProblemDifficulty } from "@/store/api/problems/types"

import Cat from "~/assets/images/difficulty/Cat"
import Coffin from "~/assets/images/difficulty/Coffin"
import Puzzle from "~/assets/images/difficulty/Puzzle"
import Sloth from "~/assets/images/difficulty/Sloth"
import Swear from "~/assets/images/difficulty/Swear"

import getDifficultyByPercent from "@/utils/getDifficultyByPercent"

interface IDifficultyIconProps {
  percent?: number
  rank?: ProblemDifficulty
  width?: number
  height?: number
}

export default function DifficultyIcon({
  percent = undefined,
  rank = undefined,
}: IDifficultyIconProps) {
  const difficulty = percent ? getDifficultyByPercent(percent) : rank

  if (difficulty) {
    switch (difficulty) {
      case ProblemDifficulty.easy:
        return <Sloth />
      case ProblemDifficulty.medium:
        return <Puzzle />
      case ProblemDifficulty.hard:
        return <Cat />
      case ProblemDifficulty.difficult:
        return <Swear />
      case ProblemDifficulty.impossible:
        return <Coffin />
    }
  }

  return null
}

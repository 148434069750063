import { ChangeEventHandler } from "react"

import styled from "styled-components"

interface ISwitchInputProps {
  toggled: boolean
  onChange: ChangeEventHandler<HTMLInputElement>
  value?: string
  className?: string
  children?: React.ReactNode
}

export default function SwitchInput({
  toggled,
  onChange,
  value = "",
  className = "",
  children = null,
}: ISwitchInputProps) {
  return (
    <Label className={className}>
      <input
        type="checkbox"
        checked={toggled}
        onChange={onChange}
        value={value}
      />
      <Switch />
      {children}
    </Label>
  )
}

const Label = styled.label`
  > input {
    visibility: hidden;
    opacity: 0;
    position: absolute;
  }
`

const Switch = styled.span`
  border-radius: 12.5px;
  border: 0.833px solid var(--color-card-stroke);
  background-color: var(--color-bubble);
  transition: border var(--transition-duration) var(--transition-function),
    background-color var(--transition-duration) var(--transition-function);

  width: 40px;
  height: 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;

  &::after {
    border-radius: 50%;
    border: 1px solid var(--color-card-stroke);
    background-color: #ffffff;
    transform: translateX(0);
    transition: transform 0.3s ease-in-out,
      background-color var(--transition-duration) var(--transition-function),
      border var(--transition-duration) var(--transition-function);

    content: "";
    width: 15px;
    height: 15px;
    box-sizing: border-box;

    position: relative;
    left: 2.5px;
  }

  input:checked + & {
    background-color: var(--color-primary);
    border-color: var(--color-primary);

    &::after {
      border-color: #ffffff;
      transform: translateX(20px);
    }
  }
`

import { useCallback, useState } from "react"

import { ManagedProblemMarker } from "@/store/api/problems/types"

export default function useStateFilter(
  initialStates: ManagedProblemMarker[] = []
) {
  const [states, setStates] = useState<ManagedProblemMarker[]>(initialStates)

  const toggleState = useCallback((state: ManagedProblemMarker) => {
    setStates(prev => {
      if (prev.includes(state)) return prev.filter(d => d !== state)

      return [...prev, state]
    })
  }, [])

  return {
    states,
    toggleState,
  }
}

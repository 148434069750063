import { MouseEventHandler, useCallback, useState } from "react"

import { AdminLevel } from "@/store/api/problems/types"

interface IUserDetails {
  id: number
  handle: string
  name: string
  level: number
  avatar: string
}

export default function useAdminModal(isEditable: boolean) {
  const [modalUser, setModalUser] = useState<IUserDetails | null>(null)

  const showModal = useCallback<MouseEventHandler<HTMLDivElement>>(
    event => {
      const id = Number(event.currentTarget.dataset.id)
      const handle = event.currentTarget.dataset.handle
      const name = event.currentTarget.dataset.name
      const avatar = event.currentTarget.dataset.avatar
      const level = Number(
        event.currentTarget.dataset.level ?? AdminLevel.JustLook
      )

      if (
        isEditable &&
        handle &&
        name &&
        avatar &&
        level !== AdminLevel.Creator
      )
        setModalUser({
          id,
          handle,
          level,
          avatar,
          name,
        })
    },
    [isEditable]
  )

  const closeModal = useCallback(() => {
    setModalUser(null)
  }, [])

  return {
    modalUser,
    showModal,
    closeModal,
  }
}

import {
  ChangeEventHandler,
  DragEventHandler,
  useCallback,
  useEffect,
  useReducer,
} from "react"

import produce from "immer"

import { useAppSelector } from "@/store"

export type FileUploaderStateT =
  | "idle"
  | "defunct"
  | "hover"
  | "loading"
  | "errored"

interface IUseFileUploader {
  dragHandlers: {
    onDragEnter: DragEventHandler
    onDragLeave: DragEventHandler
    onDragOver: DragEventHandler
    onDrop: DragEventHandler
  }
  clickHandlers: {
    onChange: ChangeEventHandler<HTMLInputElement>
  }
  state: FileUploaderStateT
  description?: string
}

interface IUseFileUploaderArguments {
  uploadFile: (file: File) => Promise<void>
}

export default function useFileUploader({
  uploadFile,
}: IUseFileUploaderArguments): IUseFileUploader {
  const isMobileDevice = useAppSelector(
    ({ common }) => common.media === "mobile"
  )

  const [{ state, description }, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    dispatch({ type: isMobileDevice ? "mobile" : "desktop" })
  }, [isMobileDevice])

  const onFileEnter: DragEventHandler<HTMLDivElement> = useCallback(ev => {
    ev.preventDefault()
    ev.stopPropagation()

    dispatch({ type: "in" })
  }, [])

  const onFileLeave: DragEventHandler = useCallback(ev => {
    ev.preventDefault()
    ev.stopPropagation()

    dispatch({ type: "out" })
  }, [])

  const onFileDrop: DragEventHandler = useCallback(
    async event => {
      event.preventDefault()
      event.stopPropagation()

      if (event.dataTransfer.items.length < 1) return

      dispatch({ type: "drop" })

      const item = event.dataTransfer.items[0]

      if (item.kind !== "file") return dispatch({ type: "error" })

      const file = item.getAsFile() as File

      try {
        await uploadFile(file)
        dispatch({ type: "reset" })
      } catch (error) {
        dispatch({
          type: "error",
          payload: error instanceof Error ? error.message : undefined,
        })
      }
    },
    [uploadFile]
  )

  const onInputChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    async ({ target }) => {
      if (!target.files || target.files.length < 1) return

      dispatch({ type: "drop" })

      const file = target.files[0]

      try {
        await uploadFile(file)
        dispatch({ type: "reset" })
      } catch (error) {
        dispatch({
          type: "error",
          payload: error instanceof Error ? error.message : undefined,
        })
      }
    },
    [uploadFile]
  )

  const preventDefault: DragEventHandler = useCallback(event => {
    event.preventDefault()
    event.stopPropagation()
  }, [])

  return {
    dragHandlers: {
      onDragEnter: onFileEnter,
      onDragLeave: onFileLeave,
      onDragOver: preventDefault,
      onDrop: onFileDrop,
    },
    clickHandlers: {
      onChange: onInputChange,
    },
    state,
    description,
  }
}

interface IReducerState {
  level: number
  state: FileUploaderStateT
  base: FileUploaderStateT
  description?: string
}

interface IReducerAction {
  type: "in" | "out" | "drop" | "error" | "reset" | "mobile" | "desktop"
  payload?: string
}

const initialState: IReducerState = {
  level: 0,
  state: "idle",
  base: "idle",
}

function reducer(state: IReducerState, action: IReducerAction): IReducerState {
  /* eslint-disable no-param-reassign */

  switch (action.type) {
    case "in":
      return produce(state, draft => {
        draft.level += 1
        draft.state = "hover"
      })
    case "out":
      return produce(state, draft => {
        draft.level -= 1
        draft.state = draft.level > 0 ? "hover" : state.base
      })
    case "drop":
      return produce(state, draft => {
        draft.level = 0
        draft.state = "loading"
        draft.description = undefined
      })
    case "error":
      return produce(state, draft => {
        draft.level = 0
        draft.state = "errored"
        draft.description = action.payload
      })
    case "reset":
      return produce(state, draft => {
        draft.level = 0
        draft.state = state.base
        draft.description = undefined
      })
    case "mobile":
      return produce(state, draft => {
        if (draft.state === "idle") draft.state = "defunct"
        draft.base = "defunct"
      })
    case "desktop":
      return produce(state, draft => {
        if (draft.state === "defunct") draft.state = "idle"
        draft.base = "idle"
      })
  }

  /* eslint-enable no-param-reassign */
}

export const getExtension = (fname: string): string =>
  (parts => (parts.length > 1 ? parts[parts.length - 1] : ""))(fname.split("."))

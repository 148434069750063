import { ChangeEventHandler, useCallback } from "react"

import { useTranslation } from "react-i18next"
import styled from "styled-components"

import { createQuerySelector } from "@/store/api/helpers"
import { useGetManagedProblemsQuery } from "@/store/api/problems/create"
import { IUserBrief } from "@/store/api/users/types"

import Avatar from "@/components/Avatar"
import CheckboxInput from "@/components/CheckboxInput"
import Loader from "@/components/Loader"
import { text16Medium, text14Medium } from "@/utils/fonts"

interface ICreatorsFilterSelectorProps {
  selected: number[]
  toggleCreator: (creatorId: number) => void
}

export default function CreatorsFilterSelector({
  selected,
  toggleCreator,
}: ICreatorsFilterSelectorProps) {
  const { t } = useTranslation()

  const i18n = {
    header: t("pages.task.list.tabs.managed.creators.header"),
  }

  const { creators, isFetching } = useGetManagedProblemsQuery(undefined, {
    selectFromResult: selectCreators,
  })

  const handleCreatorClick = useCallback<ChangeEventHandler<HTMLInputElement>>(
    event => {
      const id = Number(event.target.value)
      toggleCreator(id)
    },
    [toggleCreator]
  )

  return (
    <Loader size="small" loading={isFetching}>
      <Wrapper>
        <p>{i18n.header}</p>
        {creators.map(({ id, handle, avatar }) => (
          <CustomCheckboxInput
            key={id}
            value={id.toString()}
            checked={selected.includes(id)}
            onChange={handleCreatorClick}
          >
            <Avatar src={avatar} width={20} height={20} />
            {handle}
          </CustomCheckboxInput>
        ))}
      </Wrapper>
    </Loader>
  )
}

const selectCreators = createQuerySelector(
  useGetManagedProblemsQuery,
  ({ data, isFetching }) => {
    const existingIds = new Set()
    const creators =
      data?.problems.reduce((acc, { creator }) => {
        if (!existingIds.has(creator.id)) {
          existingIds.add(creator.id)
          acc.push(creator)
        }
        return acc
      }, [] as IUserBrief[]) ?? []

    return {
      creators,
      isFetching,
    }
  }
)

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  > p {
    margin: 0 0 2px 0;

    ${text16Medium};
  }
`

const CustomCheckboxInput = styled(CheckboxInput)`
  display: flex;
  align-items: start;
  justify-content: flex-start;
  gap: 8px;

  ${text14Medium};

  > picture {
    margin-left: 4px;
  }
`

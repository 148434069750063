import React from "react"

import styled, { css } from "styled-components"

import Navbar from "@/components/navbar"
import { useAppSelector } from "@/store"
import mediaQueryFor from "@/utils/mediaQuery"

export default function Layout({ children }: { children: React.ReactNode }) {
  const layoutMode = useAppSelector(({ common }) => common.layout)

  return (
    <>
      <Navbar />

      <PageWrapper layout={layoutMode} id="page-wrapper">
        {children}
      </PageWrapper>
    </>
  )
}

const wraps = {
  own: css`
    padding: 0px;
    --shift-x: 22px;
    --shift-y: 60px;

    overflow-y: hidden;

    ${mediaQueryFor.mobile} {
      --shift-x: 0;

      padding-bottom: 0px;
      padding-bottom: env(safe-area-inset-bottom);
    }
  `,
  normal: css`
    --shift-x: 22px;
    --shift-y: 60px;

    padding: 57px 52px 60px 52px;
    overflow-y: scroll;

    ${mediaQueryFor.mobile} {
      --shift-x: 0;

      padding: 42px 20px 45px 20px;

      padding-bottom: 20px;
      padding-bottom: calc(env(safe-area-inset-bottom) + 20px);
    }
  `,
}

export type LayoutMode = keyof typeof wraps

const PageWrapper = styled.main<{ layout: LayoutMode }>`
  ${({ layout }) => wraps[layout]};

  box-sizing: border-box;

  width: calc(100vw - 2 * var(--shift-x));
  max-width: calc(1440px - 2 * var(--shift-x));

  height: calc(var(--window-h) - var(--shift-y));

  /* This is the magic bit for Firefox */
  scrollbar-width: none;

  &::-webkit-scrollbar {
    /* This is the magic bit for WebKit */
    display: none;
  }

  margin: 0px auto;

  background: var(--layout-color);
  border: 2px solid var(--layout-border);
  border-bottom: none;
  border-radius: 10px 10px 0px 0px;

  color: var(--color-text);

  transition: background-color var(--transition-duration)
      var(--transition-function),
    border-color var(--transition-duration) var(--transition-function),
    color var(--transition-duration) var(--transition-function),
    border-width var(--transition-duration) var(--transition-function);

  ${mediaQueryFor.mobile} {
    border-left-width: 0px;
    border-right-width: 0px;
    border-top: 2px solid var(--layout-border);
    border-radius: 0px;
  }
`

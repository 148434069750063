import { DateTime } from "luxon"
import { Trans, useTranslation } from "react-i18next"
import styled from "styled-components"

import Hourglass from "~/assets/icons/Hourglass"

import Timer from "@/components/Timer"
import { text14Medium } from "@/utils/fonts"

interface ITimingProps {
  end: DateTime
  now: DateTime
  onExpire: () => void
}

export default function Running({ end, now, onExpire }: ITimingProps) {
  const { t } = useTranslation()

  return (
    <TimingCountdownContainer>
      <Hourglass />
      <Trans
        t={t}
        i18nKey="pages.contests.details.ends_in"
        parent="p"
        components={{
          timer: (
            <Timer
              expiryTimestamp={end.toMillis() - now.toMillis() + Date.now()}
              onExpire={onExpire}
            />
          ),
        }}
      />
    </TimingCountdownContainer>
  )
}

const TimingCountdownContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 4px;
  height: 17px;

  margin-bottom: 6px;

  > svg {
    height: 12px;

    path {
      fill: var(--color-red);
      transition: fill var(--transition-duration) var(--transition-function);
    }
  }

  > p {
    margin: 0;
    ${text14Medium};
    color: var(--color-red);
    transition: color var(--transition-duration) var(--transition-function);
  }
`

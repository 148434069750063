import React from "react"

import { Helmet } from "react-helmet-async"
import { createGlobalStyle } from "styled-components"

import { ThemeT } from "@/store/slices/common/types"

import { useTheme } from "@/store/hooks"

export default function GlobalStyleManager() {
  const theme = useTheme()

  const ThemeStyle = Themes[theme]

  return (
    <>
      <Helmet>
        <meta name="theme-color" content={StatusBar[theme]} />
      </Helmet>

      <Common />
      <ThemeStyle />
    </>
  )
}

const StatusBar: Record<ThemeT, string> = {
  dark: "#141414",
  light: "#f8f8f8",
}

const StyleLight = createGlobalStyle`
  :root {
    --theme: "light";
    --navbar-primary: #222222;

    --button-primary: #1771f1;

    --loader-invert: invert(0);
    --code-cell-invert: invert(0);

    --navbar-accent: #0000000d;

    --layout-color: #ffffff;
    --layout-border: #0000000d;

    --shadow: #00000008;
    --links-accent: #0000001a;
    --links-border-width: 1px;

    --links-contest: #0b7ce5;
    --links-tasks: #e50b0b;
    --links-algorithms: #ffa96b;

    --logins-background: #ffffff;

    --difficulty: #22222280;

    --body-color: #f8f8f8;

    // ===

    --color-code-editor-background: 0, 0, 0;
    --blink-target: 93%;

    // === colors ===

    --color-background-invert: #000000;
    --color-text: #222222;
    --color-text-4c: #2222224c;
    --color-text-33: #22222233;
    --color-background: #f8f8f8;
    --color-g-background: #ffffff;
    --color-g-background-stroke: rgba(0, 0, 0, 0.05);
    --color-card-stroke: rgba(0, 0, 0, 0.1);
    --color-tab-selection: rgba(0, 0, 0, 0.05);
    --color-input-stroke: #e5e5e5;
    --color-green: #2e8a48;
    --color-red: #d72a2a;
    --color-primary: #1771f1;
    --color-primary-transparent: #1771f10d;
    --color-primary-inactive: #b4d3ff;
    --color-separator: #ebebeb;
    --color-bubble: #fbfbfb;
    --color-left-bar-selection: #3680c4;
    --color-table-even-row: #f2f2f2;
    --color-bubble-hover: #f4f4f4;
    --color-primary-hover: #428ffe;
    --color-orange: #f88c29;
    --color-additional: #1771f11a;
  }
`

const StyleDark = createGlobalStyle`
  :root {
    --theme: "dark";
    --button-primary: #2f80f2;

    --navbar-primary: #dddddd;

    --loader-invert: invert(0.909804);
    --code-cell-invert: invert(0.9475806);

    --navbar-accent: #ffffff1a;

    --layout-color: #171717;
    --layout-border: #ffffff0d;

    --shadow: #ffffff08;
    --links-accent: #36383d;
    --links-border-width: 1.5px;

    --links-contest: #4ca2f3;
    --links-tasks: #f96161;
    --links-algorithms: #ffa96b;

    --logins-background: #141414;

    --difficulty: #dddddd80;

    --body-color: #141414;

    // ===

    --color-code-editor-background: 255, 255, 255;
    --blink-target: 130%;

    // === colors ===

    --color-background-invert: #ffffff;
    --color-text: #dddddd;
    --color-text-4c: #dddddd4c;
    --color-text-33: #dddddd33;
    --color-background: #141414;
    --color-g-background: #171717;
    --color-g-background-stroke: rgba(255, 255, 255, 0.05);
    --color-card-stroke: rgba(255, 255, 255, 0.1);
    --color-tab-selection: rgba(255, 255, 255, 0.1);
    --color-input-stroke: #36383d;
    --color-green: #4bb462;
    --color-red: #f96161;
    --color-primary: #2f80f2;
    --color-primary-transparent: #2f80f20d;
    --color-primary-inactive: #2c3b50;
    --color-separator: #373737;
    --color-bubble: #202020;
    --color-left-bar-selection: #478ccc;
    --color-table-even-row: #272727;
    --color-bubble-hover: #2d2d2d;
    --color-primary-hover: #428ffe;
    --color-orange: #f88c29;
    --color-additional: #5099fe1a;
  }
`

const Common = createGlobalStyle`
  :root {
    --transition-duration: 0.2s;
    --transition-function: ease-in-out;

    --telegram-link-color: #69a8e5;
    --link-color: #1771f1;

    color: var(--color-text);
    
    --window-h: 100vh; // if no dynamic height, use default

    @supports (height: 100svh) {
      --window-h: 100svh; // if smallest possible is supported, use it
    }

    @supports (height: 100dvh) {
      --window-h: 100dvh; // If dynamic is supported, use it
    }
  }

  .lottie-loader {
    filter: var(--loader-invert);
    transition: filter var(--transition-duration) var(--transition-function);
  }

  :root, body, html {
    background-color: var(--color-background);
    width: 100vw;
    height: var(--window-h);
    overflow: hidden;

    transition: color var(--transition-duration) var(--transition-function),
      background-color var(--transition-duration) var(--transition-function);

    margin: 0;
    padding: 0;
    box-sizing: border-box;

    overflow: hidden;
  }

  .preload, .preload * {
    transition: none !important; // override transition while loading
  }
`

const Themes: Record<ThemeT, React.ElementType> = {
  dark: StyleDark,
  light: StyleLight,
}

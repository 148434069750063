// Я не знаю как это рабоает
function a2b(a) {
  let b
  let c
  let d
  let e = {}
  let f = 0
  let g = 0
  let h = ""
  let i = String.fromCharCode
  let j = a.length
  for (b = 0; 64 > b; b++) {
    e[
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/".charAt(
        b,
      )
    ] = b
  }
  for (c = 0; j > c; c++) {
    for (b = e[a.charAt(c)], f = (f << 6) + b, g += 6; g >= 8; ) {
      ;((d = 255 & (f >>> (g -= 8))) || j - 2 > c) && (h += i(d))
    }
  }
  return h
}

export default function parseToken(token) {
  const base64Url = token.split(".")[1]
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/")
  const jsonPayload = decodeURIComponent(
    a2b(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join(""),
  )

  return JSON.parse(jsonPayload)
}

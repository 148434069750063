import { useTranslation } from "react-i18next"
import styled from "styled-components"

import { SupportedLanguagesT } from "~/translations/utils"

import StatementRenderer from "@/components/latex/StatementRenderer"
import Line from "@/components/Line"
import { text15Medium } from "@/utils/fonts"

interface ISubtaskSeparatorItemProps {
  id: number
  description: Record<SupportedLanguagesT, string>
}

export default function SubtaskSeparatorItem({
  id,
  description,
}: ISubtaskSeparatorItemProps) {
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation()

  const i18n = {
    header: t("pages.task.edit.stages.tests.subtask_header", {
      index: id,
    }),
  }

  const translatedDescription =
    description[resolvedLanguage as SupportedLanguagesT]

  return (
    <Wrapper>
      <Line />
      <span>
        {i18n.header}{" "}
        {translatedDescription && (
          <>
            {"("}
            <Description>
              {description[resolvedLanguage as SupportedLanguagesT]}
            </Description>
            {")"}
          </>
        )}
      </span>
      <Line />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;

  > span {
    min-width: max-content;
    ${text15Medium};
    opacity: 0.5;

    margin: 0 12px;

    display: flex;
    align-items: center;
    white-space: nowrap;
  }
`

const Description = styled(StatementRenderer)`
  font-size: 15px;
  line-height: 19px;
`

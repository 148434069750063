import baseApi from "@/store/api/base"

import { ILanguage, IRandomTip } from "./types"

const miscApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getLanguages: builder.query<ILanguage[], void>({
      query: () => "/submissions/getAvailableLanguages",
    }),
    getTip: builder.query<IRandomTip, void>({
      query: () => "/getTip",
      providesTags: ["Localized"],
    }),
  }),
})

export default miscApi
export const { useGetLanguagesQuery, useGetTipQuery } = miscApi

import React from "react"

import ReactMarkdown from "react-markdown"
import RehypeKatexPlugin from "rehype-katex"
import RemarkMathPlugin from "remark-math"
import styled from "styled-components"

import {
  code12,
  code14,
  text13,
  text13Medium,
  text14,
  text14Medium,
  text15,
  text15Medium,
  text16,
  text16Medium,
} from "@/utils/fonts"
import GithubTables from "@/utils/latex/GithubTables"
import mediaQueryFor from "@/utils/mediaQuery"

import "katex/dist/katex.min.css"

interface IStatementRenderer {
  children: string
  className?: string
}

function StatementRenderer({ children, className = "" }: IStatementRenderer) {
  return (
    <StyledReactMarkdown
      remarkPlugins={[RemarkMathPlugin]}
      rehypePlugins={[RehypeKatexPlugin, GithubTables]}
      className={className}
    >
      {children}
    </StyledReactMarkdown>
  )
}

const StyledReactMarkdown = styled(ReactMarkdown)`
  width: 100%;

  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-content: start;

  gap: 32px;

  overflow-x: auto;

  ${text16};

  h1,
  h2,
  h3 {
    ${text16Medium}
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  h1:not(:last-child),
  h2:not(:last-child),
  h3:not(:last-child),
  h4:not(:last-child),
  h5:not(:last-child),
  h6:not(:last-child) {
    margin-bottom: -20px;
  }

  table {
    width: 100%;

    border-collapse: separate;
    border-spacing: 0;

    thead th {
      ${text15Medium};
      line-height: 130%;

      background-color: var(--color-background);
    }

    td,
    th {
      border-top: 1px solid var(--color-card-stroke);
      border-right: 1px solid var(--color-card-stroke);

      padding: 10px 12px;
    }

    td {
      ${text15};
      line-height: 130%;
    }

    th:first-child {
      border-top-left-radius: 4px;
    }

    td:first-child,
    th:first-child {
      border-left: 1px solid var(--color-card-stroke);
    }

    th:last-child {
      border-top-right-radius: 4px;
    }

    tr:last-child td {
      border-bottom: 1px solid var(--color-card-stroke);
    }

    tr:last-child td:first-child {
      border-bottom-left-radius: 4px;
    }

    tr:last-child td:last-child {
      border-bottom-right-radius: 4px;
    }
  }

  pre {
    margin: 0;

    > code {
      display: block;

      padding: 4px 4px 4px 8px;
      border-radius: 6px;

      background-color: var(--color-background);
    }
  }

  code {
    ${code14};

    background-color: var(--color-bubble);
    padding: 1px 2px;
    border-radius: 3px;

    transition: background-color var(--transition-duration)
      var(--transition-function);
  }

  p {
    margin: 0;
  }

  p + p,
  pre + p {
    margin-top: -12px;
  }

  p + pre {
    margin-top: -20px;
  }

  // ---

  ${mediaQueryFor.mobile} {
    ${text14};

    gap: 24px;
    overflow: hidden;

    h1,
    h2,
    h3 {
      ${text14Medium};
    }

    table {
      thead th {
        ${text13Medium};
      }

      td {
        ${text13};
      }

      td,
      th {
        padding: 8px 10px;
      }
    }

    code {
      ${code12};
    }

    p + p,
    pre + p {
      margin-top: -8px;
    }

    p + pre {
      margin-top: -12px;
    }
  }
`

export default React.memo(StatementRenderer)

import { ChangeEventHandler, useCallback } from "react"

import { useTranslation } from "react-i18next"
import styled from "styled-components"

import RadioInput from "@/components/RadioInput"
import SwitchInput from "@/components/SwitchInput"
import { IReducerAction } from "@/features/task/list/Public/hooks/useSortOrder"
import { SortOrder } from "@/features/task/list/types"
import { text14Medium, text16Medium } from "@/utils/fonts"

const radios: Array<{
  value: SortOrder
  label: string
}> = [
  {
    value: SortOrder.ByInterest,
    label: "pages.task.list.tabs.public.sort_order.by_interest",
  },
  {
    value: SortOrder.ByDifficulty,
    label: "pages.task.list.tabs.public.sort_order.by_difficulty",
  },
  {
    value: SortOrder.BySolvedAmount,
    label: "pages.task.list.tabs.public.sort_order.by_solved_amount",
  },
]

interface ISortOrderSelectorProps {
  order: SortOrder
  isReversed: boolean
  dispatch: (action: IReducerAction) => void
}

export default function SortOrderSelector({
  order,
  isReversed,
  dispatch,
}: ISortOrderSelectorProps) {
  const { t } = useTranslation()

  const i18n = {
    header: t("pages.task.list.tabs.public.sort_order.header"),
    reverse: t("pages.task.list.tabs.public.sort_order.reverse"),
  }

  const handleOrderChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    event => {
      dispatch({
        type: "setSortOrder",
        payload: Number(event.target.value),
      })
    },
    [dispatch]
  )

  const handleReverseChange = useCallback(() => {
    dispatch({ type: "toggleReversed" })
  }, [dispatch])

  return (
    <Wrapper>
      <p>{i18n.header}</p>
      {radios.map(({ label, value }) => (
        <CustomRadioInput
          key={value}
          value={value.toString()}
          checked={order === value}
          onChange={handleOrderChange}
        >
          {t(label)}
        </CustomRadioInput>
      ))}
      <CustomSwitchInput toggled={isReversed} onChange={handleReverseChange}>
        {i18n.reverse}
      </CustomSwitchInput>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  > p {
    margin-top: 0;
    margin-bottom: 2px;

    ${text16Medium};
  }
`

const CustomRadioInput = styled(RadioInput)`
  display: flex;
  align-items: start;
  justify-content: flex-start;
  gap: 12px;

  ${text14Medium};
`

const CustomSwitchInput = styled(SwitchInput)`
  display: flex;
  align-items: start;
  justify-content: flex-start;
  gap: 12px;

  ${text14Medium};

  margin-top: 16px;
`

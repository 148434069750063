import { useCallback, useState } from "react"

import contestsApi, { useGetManagedQuery } from "@/store/api/contests"

import { useAppDispatch } from "@/store"

export default function useManagedPagination() {
  const dispatch = useAppDispatch()

  const [offset, setOffset] = useState<number | undefined>(undefined)

  const {
    data: {
      contests = [],
      remaining_quota: remainingQuota = 0,
      received_at: receivedAt = null,
      count = 0,
    } = {},
    isFetching,
    isLoading,
  } = useGetManagedQuery({ offset })

  const refetch = useCallback(() => {
    dispatch(contestsApi.util.invalidateTags(["ManagedContest"]))
  }, [dispatch])

  const fetchMore = useCallback(() => {
    setOffset(contests.at(-1)?.id)
  }, [contests])

  const isEmpty = contests.length === 0 || receivedAt === null
  const isExhausted = contests.length === count
  const isFetchingMore = isFetching && !isEmpty

  const isRefetching = isLoading || (isFetching && isEmpty)
  const hasQuota = remainingQuota !== 0

  return {
    data: {
      contests,
      receivedAt,
      remainingQuota,
      hasQuota,
    },

    isLoading,
    isEmpty,
    isExhausted,
    isFetchingMore,
    isRefetching,

    fetchMore,
    refetch,
  }
}

import { useParams } from "react-router-dom"

import { AdminLevel } from "@/store/api/problems/types"

import useTranslatedProblem from "@/features/task/hooks/useTranslatedProblem"

export default function useIsEditable(
  minimalLevel: AdminLevel = AdminLevel.Editor
) {
  const { problemId = "" } = useParams()

  const { selected, isFetching } = useTranslatedProblem(Number(problemId), null)

  const isEditable = (selected?.admin_level ?? AdminLevel.None) >= minimalLevel

  return { isEditable, isFetching }
}

import { MouseEventHandler } from "react"

import { useTranslation } from "react-i18next"
import styled from "styled-components"

import { IUserBrief } from "@/store/api/users/types"

import Avatar from "@/components/Avatar"
import { text16Medium } from "@/utils/fonts"

import UserDetails from "./UserDetails"

interface IUserListProps {
  users: IUserBrief[]
  existingAdminIds: Set<number>
  isFetching: boolean
  isVisible: boolean
  onClick: MouseEventHandler<HTMLDivElement>
}

export default function UserList({
  users,
  existingAdminIds,
  isFetching,
  isVisible,
  onClick,
}: IUserListProps) {
  const { t } = useTranslation()

  const i18n = {
    loading: t("pages.task.edit.stages.access.loading"),
    notFound: t("pages.task.edit.stages.access.not_found"),
  }

  const isEmpty = !users.length && !isFetching

  return (
    <Wrapper data-visible={isVisible}>
      {isFetching ? (
        <Status>{i18n.loading}</Status>
      ) : isEmpty ? (
        <Status>{i18n.notFound}</Status>
      ) : (
        users.map(({ id, handle, name, avatar }) => (
          <UserItem
            key={id}
            onClick={existingAdminIds.has(id) ? undefined : onClick}
            data-disabled={existingAdminIds.has(id)}
            data-id={id}
            data-handle={handle}
            data-avatar={avatar}
            data-name={name}
          >
            <Avatar src={avatar} width={40} height={40} />
            <p>{handle}</p>
            <span>{name}</span>
          </UserItem>
        ))
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  border-radius: 10px;
  border: 1px solid var(--color-g-background-);
  background-color: var(--color-g-background);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
  transition: background-color var(--transition-duration)
    var(--transition-function);

  display: flex;
  flex-direction: column;
  padding: 32px 0 10px 0;

  position: relative;
  top: -23px;
  z-index: 1;

  &[data-visible="false"] {
    display: none;
  }
`

const UserItem = styled(UserDetails)`
  transition: background-color var(--transition-duration)
    var(--transition-function);

  padding: 10px 20px;
  box-sizing: border-box;

  &[data-disabled="true"] {
    opacity: 0.3;
  }

  &[data-disabled="false"]:hover {
    background-color: var(--color-bubble-hover);
    cursor: pointer;
  }
`

const Status = styled.span`
  ${text16Medium};
  text-align: center;
`

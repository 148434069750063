import styled from "styled-components"

import Checkmark from "~/assets/icons/Checkmark"

import { SidebarItem } from "@/components/Sidebar"
import useProblemName from "@/utils/hooks/useProblemName"

interface IProblemSidebarItemProps {
  onClick: () => void
  highlighted: boolean
  names: Record<string, string>
  solved: boolean
}

export default function ProblemSidebarItem({
  onClick,
  highlighted,
  names,
  solved,
}: IProblemSidebarItemProps) {
  const name = useProblemName(names)

  return (
    <Item onClick={onClick} data-highlighted={highlighted}>
      {solved && <Checkmark />}
      {name}
    </Item>
  )
}

const Item = styled(SidebarItem)`
  display: inline;
  position: relative;

  transition: opacity var(--transition-duration) var(--transition-function),
    color var(--transition-duration) var(--transition-function);

  color: var(--color-text);
  opacity: 0.5;
  transition: color var(--transition-duration) var(--transition-function),
    opacity var(--transition-duration) var(--transition-function);

  svg {
    flex: 0 0 20px;

    min-height: 20px;
    min-width: 20px;

    margin-right: 8px;

    position: relative;
    top: 4px;

    fill: var(--color-text);
    transition: fill var(--transition-duration) var(--transition-function);
  }

  &[data-highlighted="true"] {
    color: var(--color-left-bar-selection);
    opacity: 1;

    svg {
      fill: var(--color-left-bar-selection);
    }
  }
`

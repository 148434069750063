import { MouseEventHandler, useCallback } from "react"

import { useTranslation } from "react-i18next"
import styled from "styled-components"

import { ProblemDetails } from "@/store/api/problems/types"

import CopyIcon from "~/assets/icons/CopyIcon"

import ScrollableCodeCell from "@/components/ScrollableCodeCell"
import StatementTitle from "@/features/task/current/statement/StatementTitle"
import { text12Medium, text15Medium } from "@/utils/fonts"
import mediaQueryFor from "@/utils/mediaQuery"

interface ITestSamplesProps {
  samples: ProblemDetails["samples"]
}

export default function TestSamples({ samples }: ITestSamplesProps) {
  const { t } = useTranslation()

  const i18n = {
    title: t("pages.task.statement.examples"),
    stdin: t("pages.task.statement.stdin"),
    stdout: t("pages.task.statement.stdout"),
  }

  const copyHandler = useCallback<MouseEventHandler<SVGSVGElement>>(ev => {
    const icon = ev.currentTarget
    const cell = icon.parentNode as HTMLDivElement

    if (cell.textContent) {
      window.navigator.clipboard.writeText(cell.textContent)
      cell.classList.add("copied")

      setTimeout(() => {
        if (cell?.isConnected) {
          cell.classList.remove("copied")
        }
      }, 300)
    }
  }, [])

  return (
    <Wrapper>
      <HeaderCell>{i18n.stdin}</HeaderCell>
      <HeaderCell>{i18n.stdout}</HeaderCell>

      <MobileOnlyStatementTitle>{i18n.title}</MobileOnlyStatementTitle>

      {samples.map(({ stdin, stdout }) => (
        <SampleWrapper key={stdin + stdout}>
          <ScrollableCodeCell>
            <pre>{stdin}</pre>
            <CopyIcon onClick={copyHandler} />
          </ScrollableCodeCell>
          <ScrollableCodeCell>
            <pre>{stdout}</pre>
            <CopyIcon onClick={copyHandler} />
          </ScrollableCodeCell>
        </SampleWrapper>
      ))}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: max-content;
  grid-auto-flow: row;

  width: 100%;

  gap: 10px;

  ${mediaQueryFor.mobile} {
    grid-template-columns: 100%;
  }
`

const SampleWrapper = styled.div`
  display: contents;

  ${mediaQueryFor.mobile} {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: start;

    padding: 15px;
    gap: 10px;

    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 5px;
  }
`

const HeaderCell = styled.div`
  ${text12Medium};
  text-transform: uppercase;
  color: var(--color-text);
  opacity: 0.3;

  transition: color var(--transition-duration) var(--transition-function);

  ${mediaQueryFor.mobile} {
    display: none;
  }
`

const MobileOnlyStatementTitle = styled(StatementTitle)`
  display: none;

  ${mediaQueryFor.mobile} {
    display: inline;

    ${text15Medium};
  }
`

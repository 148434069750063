import { useMemo } from "react"
import React from "react"

import isEqual from "lodash/isEqual"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

import { ProblemDetails } from "@/store/api/problems/types"

import StatementRenderer from "@/components/latex/StatementRenderer"
import TaskTable, { WithTableDivider } from "@/components/table/TaskTable"
import StatementTitle from "@/features/task/current/statement/StatementTitle"
import { useAppSelector } from "@/store"
import {
  text10Medium,
  text12,
  text12Medium,
  text15,
  text15Medium,
} from "@/utils/fonts"
import mediaQueryFor from "@/utils/mediaQuery"

interface SubtasksTableProps {
  subtasks: ProblemDetails["subtasks"]
}

function SubtasksTable({ subtasks }: SubtasksTableProps) {
  const { t } = useTranslation()

  const isMobile = useAppSelector(({ common }) => common.media === "mobile")

  const i18n = {
    title: t("pages.task.statement.subtasks"),
    index: t("pages.task.subtasks.index"),
    points: t("pages.task.subtasks.points"),
    requirements: t("pages.task.subtasks.requirements"),
    description: t("pages.task.subtasks.description"),
  }

  const data = useMemo(
    () => subtasks.map((data, index) => ({ ...data, id: index })),
    [subtasks]
  )

  return (
    <Subtasks>
      <CustomStatementTitle>{i18n.title}</CustomStatementTitle>

      <TaskTable
        setWidth={true}
        data={data}
        header={{
          id: i18n.index,
          points: i18n.points,
          depends: i18n.requirements,
          description: i18n.description,
        }}
        dedicated={isMobile ? ["description"] : null}
        mapper={{
          id: SubtaskNumber,
          points: SubtaskPoints,
          depends: SubtaskDependencies,
          description: SubtaskDescription,
        }}
      />
    </Subtasks>
  )
}

function SubtaskNumber(n: number) {
  return <SubtaskIdentifier>{n + 1}</SubtaskIdentifier>
}

function SubtaskPoints(points: number) {
  return <WithTableDivider>{points}</WithTableDivider>
}

function SubtaskDependencies(deps: number[] | null) {
  return (
    <WithTableDivider>{deps?.length ? deps.join(", ") : "-"}</WithTableDivider>
  )
}

function SubtaskDescription(descr: string) {
  return (
    <WithTableDivider>
      <StatementRenderer>{descr}</StatementRenderer>
    </WithTableDivider>
  )
}

const Subtasks = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;

  align-self: stretch;

  gap: 12px;

  & > table {
    ${text15};

    th {
      ${text12Medium};
    }

    p {
      ${text15};

      .katex {
        font-size: 16px;
      }
    }

    .column-id {
      width: 5%;
    }

    .column-points {
      width: 5%;
      min-width: 55px;
    }

    .column-depends {
      width: 10%;
      min-width: 130px;
    }

    .column-description {
      width: 80%;
    }
  }

  ${mediaQueryFor.mobile} {
    & > table {
      ${text12};

      border-spacing: 0px;

      th {
        ${text10Medium};

        padding-bottom: 6px;
      }

      .column-id {
        width: 12%;
      }

      .column-points {
        width: 17%;
        min-width: 55px;
      }

      .column-depends {
        width: 70%;
        min-width: 130px;
      }

      tr:nth-child(2n + 1) > td {
        &:first-child > div {
          border-top-left-radius: 5px;
          border-bottom-left-radius: 0px;
        }

        &:last-child > div {
          border-top-right-radius: 5px;
          border-bottom-right-radius: 0px;
        }
      }

      tr:nth-child(2n) > td {
        padding-bottom: 6px;

        div[data-divider] {
          display: none;
        }

        &:first-child > div {
          border-top-left-radius: 0px;
          border-bottom-left-radius: 5px;
        }

        &:last-child > div {
          border-top-right-radius: 0px;
          border-bottom-right-radius: 5px;
        }
      }
    }
  }

  ${mediaQueryFor.desktop} {
    & > table tr:hover > td > div {
      background-color: var(--color-bubble);
    }
  }
`

const CustomStatementTitle = styled(StatementTitle)`
  ${mediaQueryFor.mobile} {
    ${text15Medium};
  }
`

const SubtaskIdentifier = styled.span`
  opacity: 0.25;

  ${text15Medium};
`

export default React.memo(
  SubtasksTable,
  ({ subtasks: prev }, { subtasks: next }) => isEqual(prev, next)
)

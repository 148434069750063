import { Fragment, useCallback, useMemo } from "react"

import { TFunction } from "i18next"
import produce from "immer"
import { DateTime } from "luxon"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate } from "react-router-dom"
import styled from "styled-components"

import { createQuerySelector } from "@/store/api/helpers"
import { COMPILATION, useGetSubmissionsQuery } from "@/store/api/submissions"
import { ISubmission } from "@/store/api/submissions/types"

import TaskTable, { WithTableDivider } from "@/components/table/TaskTable"
import StatementTitle from "@/features/task/current/statement/StatementTitle"
import { useAppSelector } from "@/store"
import {
  text10Medium,
  text12,
  text12Medium,
  text14,
  text14Medium,
  text15Medium,
  text16Medium,
} from "@/utils/fonts"
import mediaQueryFor from "@/utils/mediaQuery"

import DateToTitle, { TODAY, YESTERDAY } from "./DateToTitle"

interface ISubmissionsListProps {
  taskId: string
}

interface ISubmissionsByDate {
  [date: string]: ISubmission[]
}

type SubmissionStatus = "red" | "green" | "original"

export default function SubmissionsList({ taskId }: ISubmissionsListProps) {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const { state } = useLocation()

  const userId = useAppSelector(({ user }) => user.uid)

  const { submissions } = useGetSubmissionsQuery(
    { user_id: userId?.toString(), task_id: taskId },
    {
      selectFromResult: selectFromSubmissions,
      skip: !userId,
    }
  )

  const groups = useMemo(() => {
    return submissions && submissions.length
      ? submissions.reduce<ISubmissionsByDate>(
          (acc, item) => addToGroup(acc, item),
          {}
        )
      : null
  }, [submissions])

  const openSubmissionModal = useCallback(
    (id: number) =>
      id > 0 && navigate(`s/${id}`, { state: { ...state, popup: true } }),
    [navigate, state]
  )

  const i18n = {
    title: t("pages.task.submissions.title"),
    points: t("pages.task.submissions.points"),
    verdict: t("pages.task.submissions.verdict"),
    test: t("pages.task.submissions.test"),
  }

  const mapper = useCallback(
    (data: ISubmission) => transformSubmissionData(t, data),
    [t]
  )

  if (!userId) return null

  return (
    <Wrapper>
      {groups && <CustomStatementTitle>{i18n.title}</CustomStatementTitle>}

      {groups &&
        Object.entries(groups).map(([key, items], groupIndex) => (
          <Fragment key={key}>
            <DateToTitle groupName={key} />

            <SubmissionSegment key={`submission-group-${key}`}>
              <TaskTable
                showHeader={groupIndex === 0}
                setWidth={true}
                data={items.map(mapper)}
                onRowClick={openSubmissionModal}
                header={{
                  id: null,
                  score: i18n.points,
                  result: i18n.verdict,
                  status: null,
                  test: i18n.test,
                }}
                mapper={{
                  score: (arg, { status }) => (
                    <ScoreWrapper status={status}>{arg}</ScoreWrapper>
                  ),
                  result: (arg, { status }) => (
                    <WithTableDivider>
                      <ScoreWrapper status={status}>{arg}</ScoreWrapper>
                    </WithTableDivider>
                  ),
                  test: (arg, { status }) => (
                    <WithTableDivider>
                      <ScoreWrapper status={status}>{arg}</ScoreWrapper>
                    </WithTableDivider>
                  ),
                }}
              />
            </SubmissionSegment>
          </Fragment>
        ))}
    </Wrapper>
  )
}

const selectFromSubmissions = createQuerySelector(
  useGetSubmissionsQuery,
  ({ data: { submissions } = {} }) => ({ submissions })
)

const addToGroup = produce((draft: ISubmissionsByDate, item: ISubmission) => {
  /* eslint-disable no-param-reassign */
  const date = DateTime.fromSeconds(item.submitted_at)
  const now = DateTime.now()
  const key = now.hasSame(date, "day")
    ? TODAY
    : now.minus({ day: 1 }).hasSame(date, "day")
    ? YESTERDAY
    : date.toISODate()

  if (key in draft) {
    draft[key].push(item)
  } else {
    draft[key] = [item]
  }
  /* eslint-enable no-param-reassign */
})

const Wrapper = styled.aside`
  grid-area: submissions;

  color: var(--color-text);

  padding: 52px 36px 60px 40px;

  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;

  gap: 16px;

  height: max-content;
  min-width: 325px;

  ${mediaQueryFor.mobile} {
    width: calc(100% - 20px * 2);

    padding: 16px 20px 50px 20px;
  }
`

const SubmissionSegment = styled.div`
  width: 100%;

  & table {
    ${text14};
    text-overflow: ellipsis;
    text-transform: uppercase;

    th {
      ${text12Medium};
    }

    tr {
      > td:nth-child(1) > div {
        ${text16Medium};
        min-width: 30px;
      }

      > td:nth-child(2) > div {
        ${text14Medium};
      }

      > td:nth-child(3) > div {
        ${text16Medium};
        min-width: 30px;
      }
    }

    .column-score {
      width: 12%;
    }

    .column-result {
      width: calc(100% - 12% * 2);
    }

    .column-test {
      width: 12%;
    }
  }

  ${mediaQueryFor.mobile} {
    & table {
      ${text12};

      th {
        ${text10Medium};
      }

      tr {
        > td:nth-child(1) > div {
          ${text14Medium};
        }

        > td:nth-child(2) > div {
          ${text12Medium};
        }

        > td:nth-child(3) > div {
          ${text14Medium};
        }
      }
    }
  }
`

const Verdicts = {
  queue: "shared.judging.in_queue",
  compilation: "shared.judging.compilation",
  judging: "shared.judging.judging",
}

function transformSubmissionData(t: TFunction, item: ISubmission) {
  const {
    id,
    verdict_text: result,
    verdict: resultKey,
    points: score,
    test,
  } = item

  const status: SubmissionStatus =
    resultKey === 1
      ? "green"
      : ![0, 3].includes(resultKey) && score === 0
      ? "red"
      : "original"

  return {
    id,
    score: [0, 5].includes(resultKey) ? null : score,
    result: resultKey !== 0 ? result : t(getVerdict(test ?? 0)),
    status,
    test: test === 0 ? null : test,
  }
}

function getVerdict(test: number): string {
  switch (test) {
    case 0:
      return Verdicts.queue
    case COMPILATION:
      return Verdicts.compilation
    default:
      return Verdicts.judging
  }
}

const ScoreWrapper = styled.span<{ status: "green" | "red" | "original" }>`
  color: ${({ status }) =>
    status === "green"
      ? "var(--color-green)"
      : status === "red"
      ? "var(--color-red)"
      : "inherit"};
`

const CustomStatementTitle = styled(StatementTitle)`
  ${mediaQueryFor.mobile} {
    ${text15Medium};
  }
`

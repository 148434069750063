import { MouseEventHandler, useCallback, useState } from "react"

import { useTranslation } from "react-i18next"
import styled from "styled-components"

import DropdownIcon from "~/assets/icons/DropdownIcon"

import { ICategoryDetails } from "@/features/task/list/types"
import { text11Medium, text14Medium } from "@/utils/fonts"
import mediaQueryFor from "@/utils/mediaQuery"

import ShowAllButton from "./ShowAllButton"

interface ICategoryFilterProps {
  selected: number[]
  toggleCategory: (categoryId: number) => void
  categories: ICategoryDetails[]
}

export default function CategoryFilter({
  selected,
  toggleCategory,
  categories,
}: ICategoryFilterProps) {
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation()

  const [isOpen, setIsOpen] = useState(false)

  const handleOpenClick = useCallback(() => setIsOpen(prev => !prev), [])

  const handleCategoryClick = useCallback<MouseEventHandler<HTMLDivElement>>(
    ({ currentTarget }) => {
      const id = Number(currentTarget.dataset.id)
      toggleCategory(id)
    },
    [toggleCategory]
  )

  const i18n = {
    showAll: t("pages.task.list.show_all"),
    hide: t("pages.task.list.hide"),
  }

  return (
    <Wrapper data-open={isOpen}>
      <div>
        {categories.map(({ id, names, problemAmount }) => (
          <Category
            key={id}
            data-id={id}
            data-selected={selected.includes(id)}
            onClick={handleCategoryClick}
          >
            {names[resolvedLanguage]}
            <span>{problemAmount}</span>
          </Category>
        ))}
      </div>
      <ShowAllButton data-open={isOpen} onClick={handleOpenClick}>
        <span>{isOpen ? i18n.hide : i18n.showAll}</span>
        <DropdownIcon />
      </ShowAllButton>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  grid-area: "categories";

  border-bottom: solid var(--color-separator) 1px;
  transition: border-color var(--transition-duration) var(--transition-function);

  box-sizing: border-box;
  height: min-content;
  width: 100%;

  padding-bottom: 12px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  > div {
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    gap: 4px;

    height: 25px;
  }

  &[data-open="true"] {
    padding-bottom: 20px;

    > div {
      height: max-content;
    }
  }

  ${mediaQueryFor.mobile} {
    border: none;
    padding-bottom: 0;

    &[data-open="true"] {
      padding-bottom: 0;
    }

    > div {
      height: 18px;
    }
  }
`

const Category = styled.div`
  cursor: pointer;
  border-radius: 6px;
  transition: color var(--transition-duration) var(--transition-function),
    background-color var(--transition-duration) var(--transition-function);

  display: flex;
  width: max-content;
  gap: 8px;
  padding: 4px 8px;

  flex-basis: max-content;

  ${text14Medium};
  color: var(--color-text);

  > span {
    opacity: 0.5;
  }

  &:hover {
    background-color: var(--color-bubble-hover);
  }

  &[data-selected="true"] {
    background-color: var(--color-primary);
    color: #ffffff;
  }

  ${mediaQueryFor.mobile} {
    border-radius: 4px;
    padding: 2px 4px;
    ${text11Medium};
  }
`

import { ChangeEventHandler, useCallback } from "react"

import { useTranslation } from "react-i18next"
import styled from "styled-components"

import { ManagedProblemMarker } from "@/store/api/problems/create/types"

import Checkmark from "~/assets/icons/Checkmark"
import FileEditIcon from "~/assets/icons/FIleEditIcon"
import WarningIcon from "~/assets/icons/WarningIcon"

import CheckboxInput from "@/components/CheckboxInput"
import { text15Medium, text16Medium } from "@/utils/fonts"

const checkboxes: Array<{
  value: ManagedProblemMarker
  Icon: React.FC<{ width?: number; height?: number }>
  label: string
}> = [
  {
    value: ManagedProblemMarker.verified,
    Icon: Checkmark,
    label: "pages.task.list.tabs.managed.states.verified",
  },
  {
    value: ManagedProblemMarker.ready,
    Icon: Checkmark,
    label: "pages.task.list.tabs.managed.states.ready",
  },
  {
    value: ManagedProblemMarker.unfinished,
    Icon: WarningIcon,
    label: "pages.task.list.tabs.managed.states.unfinished",
  },
  {
    value: ManagedProblemMarker.draft,
    Icon: FileEditIcon,
    label: "pages.task.list.tabs.managed.states.draft",
  },
]

interface IStateFilterProps {
  states: ManagedProblemMarker[]
  toggleState: (state: ManagedProblemMarker) => void
}

export default function StateFilterSelector({
  states,
  toggleState,
}: IStateFilterProps) {
  const { t } = useTranslation()

  const i18n = {
    header: t("pages.task.list.tabs.managed.states.header"),
  }

  const handleStateChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    event => toggleState(event.target.value as ManagedProblemMarker),
    [toggleState]
  )

  return (
    <Wrapper>
      <p>{i18n.header}</p>
      {checkboxes.map(({ value, Icon, label }) => (
        <CustomCheckboxInput
          key={value}
          value={value}
          checked={states.includes(value)}
          onChange={handleStateChange}
        >
          <Icon />
          {t(label)}
        </CustomCheckboxInput>
      ))}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  > p {
    margin: 0 0 2px 0;

    ${text16Medium};
  }
`

const CustomCheckboxInput = styled(CheckboxInput)`
  display: flex;
  align-items: start;
  justify-content: flex-start;
  gap: 8px;

  ${text15Medium};

  > svg {
    margin-left: 4px;
    width: 20px;
    height: 20px;

    padding: 1px;
    box-sizing: border-box;

    path {
      transition: fill var(--transition-duration) var(--transition-function);
    }
  }

  input[value=${ManagedProblemMarker.verified}] ~ svg {
    path {
      fill: var(--color-green);
    }
  }

  input[value=${ManagedProblemMarker.ready}] ~ svg {
    path {
      fill: var(--color-text);
    }
  }

  input[value=${ManagedProblemMarker.unfinished}] ~ svg {
    path {
      fill: var(--color-orange);
    }
  }

  input[value=${ManagedProblemMarker.draft}] ~ svg {
    opacity: 0.5;

    path {
      fill: var(--color-text);
    }
  }
`

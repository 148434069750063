import uniqBy from "lodash/uniqBy"

import baseApi from "@/store/api/base"
import { withQueryParams } from "@/store/api/helpers"

import { IArchiveContestsList, IGetAvailableArchivesParams } from "./types"

const archivesApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getAvailableArchives: builder.query<
      IArchiveContestsList,
      IGetAvailableArchivesParams
    >({
      query: ({ query, offset }) =>
        withQueryParams({
          url: "/archives/getAvailable",
          params: { query, offset },
        }),

      providesTags: ["Localized", { type: "Archive", id: "LIST" }],

      serializeQueryArgs: ({ queryArgs: { query } }) => ({ query }),
      merge: (current, next) => {
        return {
          count: next.count,
          archives: uniqBy([...current.archives, ...next.archives], "id"),
        }
      },
      forceRefetch: ({ previousArg, currentArg }) =>
        previousArg?.offset !== currentArg?.offset ||
        previousArg?.query !== currentArg?.query,

      keepUnusedDataFor: 600,
    }),
  }),
})

export default archivesApi
export const { useGetAvailableArchivesQuery } = archivesApi

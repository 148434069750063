import {
  ChangeEventHandler,
  MouseEventHandler,
  useCallback,
  useEffect,
  useState,
} from "react"

import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import styled from "styled-components"
import { match } from "ts-pattern"

import { useSetSamplesCountMutation } from "@/store/api/problems/edit/tests"

import BaseInput from "@/components/BaseInput"
import useIsEditable from "@/features/task/hooks/useIsEditable"
import useTranslatedProblem from "@/features/task/hooks/useTranslatedProblem"
import { text14Medium } from "@/utils/fonts"

export default function ShownTestsInput() {
  const { problemId = "" } = useParams()

  const { t } = useTranslation()

  const { selected } = useTranslatedProblem(Number(problemId), null)

  const [shownTests, setShownTests] = useState(
    selected?.samples.length.toString() ?? ""
  )

  const [hasError, setHasError] = useState(false)

  useEffect(
    () => setShownTests(selected?.samples.length.toString() ?? ""),
    [selected]
  )

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    event => {
      const value = event.target.value
      if (Number(value) >= 0 || value === "")
        setShownTests(String(Number(event.target.value))) // removes leading zeros
    },
    [setShownTests]
  )

  const [setSamplesCount, { isLoading }] = useSetSamplesCountMutation()

  const handleSaveClick = useCallback<
    MouseEventHandler<HTMLButtonElement>
  >(async () => {
    try {
      const result = await setSamplesCount({
        problem_id: Number(problemId),
        samples_count: Number(shownTests),
      }).unwrap()
      if (result.status === "ok") setHasError(false)
    } catch (error) {
      setHasError(true)
    }
  }, [problemId, setSamplesCount, shownTests])

  const { isEditable } = useIsEditable()

  const i18n = {
    shownTests: t("pages.task.edit.stages.tests.shown_tests.header"),
    save: t("pages.task.edit.stages.tests.shown_tests.save"),
    loading: t("pages.task.edit.stages.tests.shown_tests.loading"),
    error: t("pages.task.edit.stages.tests.shown_tests.error"),
  }

  const buttonText = match({ isLoading, hasError })
    .with({ isLoading: true }, () => i18n.loading)
    .with({ hasError: true }, () => i18n.error)
    .otherwise(() => i18n.save)

  return (
    <Wrapper data-editable={isEditable}>
      <span>{i18n.shownTests}</span>
      <StyledInput
        value={shownTests}
        onChange={handleChange}
        disabled={!isEditable}
      />
      <SaveButton onClick={handleSaveClick} disabled={isLoading || !isEditable}>
        {buttonText}
      </SaveButton>
    </Wrapper>
  )
}

const StyledInput = styled(BaseInput)`
  appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  max-width: 112px;
  height: 41px;
`

const SaveButton = styled.button`
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 4px 6px;

  ${text14Medium};
  color: var(--color-primary);
  transition: color var(--transition-duration) var(--transition-function);
`

const Wrapper = styled.div`
  grid-column: main;

  display: flex;
  align-items: center;
  gap: 12px;

  margin-bottom: 32px;

  > span {
    max-width: 235px;

    ${text14Medium};
    opacity: 0.5;
  }

  &[data-editable="false"] {
    > ${SaveButton} {
      display: none;
    }
  }
`

import { Helmet } from "react-helmet-async"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"
import styled from "styled-components"

import Line from "@/components/Line"
import SearchBar from "@/components/SearchBar"
import CategoryFilter from "@/features/task/list/CategoryFilter"
import NotAllowed from "@/features/task/list/Managed/NotAllowed"
import ProblemFilter from "@/features/task/list/ProblemFilter"
import ScrollToTopButton from "@/features/task/list/ScrollToTopButton"
import { IManagedFilterProps } from "@/features/task/list/types"
import useCategoryFilter from "@/features/task/list/utils/hooks/useCategoryFilter"
import useSearchFilter from "@/features/task/list/utils/hooks/useSearchFilter"
import { useAppSelector } from "@/store"
import mediaQueryFor from "@/utils/mediaQuery"

import useCategoriesCount from "./hooks/useCategoriesCount"
import useContestsUsageFilter from "./hooks/useContestsUsageFilter"
import useCreatorsFilter from "./hooks/useCreatorsFilter"
import useStateFilter from "./hooks/useStateFilter"
import ProblemList from "./ProblemList"
import ContestsUsageFilterSelector from "./selectors/ContestsUsageFilterSelector"
import CreatorsFilterSelector from "./selectors/CreatorsFilterSelector"
import StateFilterSelector from "./selectors/StateFilterSelector"

interface IManagedListState {
  filters: IManagedFilterProps
}

export default function ProblemsManaged() {
  const { state }: { state?: IManagedListState } = useLocation()

  const filters = state?.filters

  const { t } = useTranslation()

  const i18n = {
    title: t("pages.task.list.tabs.managed.title"),
    searchPlaceholder: t("pages.task.list.tabs.managed.search_placeholder"),
  }

  const isLoggedIn = useAppSelector(({ user }) => user.token !== null)

  const {
    search,
    handleChange: handleSearchChange,
    debouncedSearch,
  } = useSearchFilter(filters?.search)

  const { states, toggleState } = useStateFilter(filters?.states)

  const { isUsedInContests, toggleIsUsedInContests } = useContestsUsageFilter(
    filters?.isUsedInContests
  )

  const { creators, toggleCreator } = useCreatorsFilter(filters?.creators)

  const { selectedCategories, toggleCategory } = useCategoryFilter(
    filters?.categories
  )

  const categories = useCategoriesCount(
    {
      search: debouncedSearch,
      states,
      isUsedInContests,
      creatorIds: creators,
    },
    selectedCategories
  )

  return (
    <>
      <Helmet>
        <title>{i18n.title}</title>
        <link rel="canonical" href="https://sort-me.org/problems/managed" />
      </Helmet>

      {isLoggedIn ? (
        <Wrapper>
          <Sidebar>
            <StyledSearchBar
              value={search}
              onChange={handleSearchChange}
              placeholder={i18n.searchPlaceholder}
            />
            <ProblemFilter>
              <StateFilterSelector states={states} toggleState={toggleState} />
              <Line />
              <ContestsUsageFilterSelector
                isUsedInContests={isUsedInContests}
                toggleIsUsedInContests={toggleIsUsedInContests}
              />
              <Line />
              <CreatorsFilterSelector
                selected={creators}
                toggleCreator={toggleCreator}
              />
            </ProblemFilter>
          </Sidebar>
          <Main>
            <CategoryFilter
              selected={selectedCategories}
              toggleCategory={toggleCategory}
              categories={categories}
            />
            <ProblemList
              filterProps={{
                search: debouncedSearch,
                states,
                isUsedInContests,
                creators,
                categories: selectedCategories,
              }}
            />
          </Main>
          <ScrollToTopButton />
        </Wrapper>
      ) : (
        <NotAllowed />
      )}
    </>
  )
}

const Wrapper = styled.div`
  position: relative;

  display: grid;
  gap: 30px;
  grid-template-columns: 258px 1fr;

  ${mediaQueryFor.mobile} {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
`

const Sidebar = styled.aside`
  display: flex;
  flex-direction: column;
  gap: 32px;

  ${mediaQueryFor.mobile} {
    flex-direction: row;
    gap: 20px;
    justify-content: space-between;
    align-items: center;
  }
`

const StyledSearchBar = styled(SearchBar)`
  ${mediaQueryFor.mobile} {
    height: 38px;
    padding: 11px 20px 10px 20px;
    width: 100%;
  }
`

const Main = styled.main`
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${mediaQueryFor.mobile} {
    gap: 18px;
  }
`

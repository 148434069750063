import {
  useCallback,
  MouseEventHandler,
  useRef,
  useEffect,
  useState,
} from "react"

import { BasicSetupOptions, ReactCodeMirrorRef } from "@uiw/react-codemirror"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import styled from "styled-components"

import {
  useDropTestMutation,
  useGetTestQuery,
  useSetTestMutation,
} from "@/store/api/problems/edit/tests"

import CustomButton from "@/components/CustomButton"
import Loader from "@/components/Loader"
import { text12Medium, text14Medium, text18Medium } from "@/utils/fonts"

import LoaderBlock from "./LoaderBlock"
import StyledCodeMirror from "./StyledCodeMirror"

const codeSettings: BasicSetupOptions = {
  foldGutter: true,
  highlightSpecialChars: false,
  dropCursor: false,
  foldKeymap: false,
  rectangularSelection: false,
  crosshairCursor: false,
  lintKeymap: false,
  autocompletion: false,
  searchKeymap: false,
  completionKeymap: false,
}

interface ITestDetailsProps {
  id: string
  closeModal: () => void
}

export default function TestDetails({ id, closeModal }: ITestDetailsProps) {
  const { problemId = "" } = useParams()
  const { t } = useTranslation()

  const stdinEditor = useRef<ReactCodeMirrorRef>(null)
  const stdoutEditor = useRef<ReactCodeMirrorRef>(null)

  const isValidTestId = !isNaN(Number(id))
  const isNewTest = id === "new"

  const { data, isFetching } = useGetTestQuery(
    {
      problem_id: Number(problemId),
      test_id: Number(id),
    },
    { skip: !isValidTestId }
  )

  const stdin = useRef<string>(data?.stdin ?? "")
  const stdout = useRef<string>(data?.stdout ?? "")

  const [hasStdinCode, setHasStdinCode] = useState(
    data ? data.stdin.length > 0 : false
  )
  const [hasStdoutCode, setHasStdoutCode] = useState(
    data ? data.stdout.length > 0 : false
  )

  useEffect(() => {
    if (data) {
      stdin.current = data.stdin
      setHasStdinCode(data.stdin.length > 0)

      stdout.current = data.stdout
      setHasStdoutCode(data.stdout.length > 0)
    }
  }, [data])

  const handleStdinChange = useCallback((value: string) => {
    stdin.current = value
    setHasStdinCode(value.length > 0)
  }, [])

  const handleStdoutChange = useCallback((value: string) => {
    stdout.current = value
    setHasStdoutCode(value.length > 0)
  }, [])

  const [saveTest, { isLoading: isSaving, isSuccess: isSaved }] =
    useSetTestMutation()

  const handleSaveClick = useCallback<
    MouseEventHandler<HTMLButtonElement>
  >(async () => {
    try {
      if (isValidTestId)
        await saveTest({
          problem_id: Number(problemId),
          test_id: Number(id),
          stdin: stdin.current,
          stdout: stdout.current,
        }).unwrap()
      else if (isNewTest)
        await saveTest({
          problem_id: Number(problemId),
          stdin: stdin.current,
          stdout: stdout.current,
        }).unwrap()
      setTimeout(closeModal, 1500) // show success message
    } catch {}
  }, [closeModal, id, isNewTest, isValidTestId, problemId, saveTest])

  const [deleteTest, { isLoading: isDeleting }] = useDropTestMutation()

  const handleDeleteClick = useCallback<
    MouseEventHandler<HTMLButtonElement>
  >(async () => {
    try {
      if (isValidTestId) {
        const result = await deleteTest({
          problem_id: Number(problemId),
          test_id: Number(id),
        }).unwrap()
        if (result.status === "ok") closeModal()
      }
    } catch {}
  }, [closeModal, deleteTest, id, isValidTestId, problemId])

  const i18n = {
    header: t("pages.task.edit.stages.tests.edit_modal.header", { id }),
    save: t("pages.task.edit.stages.tests.edit_modal.save"),
    delete: t("pages.task.edit.stages.tests.edit_modal.delete"),
    deleting: t("pages.task.edit.stages.tests.edit_modal.deleting"),

    newHeader: t("pages.task.edit.stages.tests.edit_modal.new.header"),
    newSave: t("pages.task.edit.stages.tests.edit_modal.new.save"),
  }

  const showLoader = isSaving || isSaved
  const isSaveEnabled = isValidTestId || isNewTest

  return (
    <Loader loading={isFetching} size="large">
      <HeaderBlock>
        <h2>{isNewTest ? i18n.newHeader : i18n.header}</h2>
        {!showLoader && (
          <CustomButton
            data-type="primary"
            onClick={handleSaveClick}
            disabled={!isSaveEnabled}
          >
            {isNewTest ? i18n.newSave : i18n.save}
          </CustomButton>
        )}
      </HeaderBlock>
      {showLoader ? (
        <LoaderBlock isSaving={isSaving} isNew={isNewTest} />
      ) : (
        <>
          <EditorBlock>
            <span>STDIN</span>
            <span>STDOUT</span>
            <CodeEditor
              basicSetup={codeSettings}
              editable={true}
              ref={stdinEditor}
              data-has-value={hasStdinCode}
              value={stdin.current}
              onChange={handleStdinChange}
            />
            <CodeEditor
              basicSetup={codeSettings}
              editable={true}
              ref={stdoutEditor}
              data-has-value={hasStdoutCode}
              value={stdout.current}
              onChange={handleStdoutChange}
            />
          </EditorBlock>
          {isValidTestId && (
            <DeleteButton onClick={handleDeleteClick}>
              {isDeleting ? i18n.deleting : i18n.delete}
            </DeleteButton>
          )}
        </>
      )}
    </Loader>
  )
}

const HeaderBlock = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  > h2 {
    margin: 0;
    ${text18Medium};
  }
`

const EditorBlock = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: max-content 1fr;
  gap: 16px;
  width: 100%;

  > span {
    ${text12Medium};
    opacity: 0.3;
  }
`

const CodeEditor = styled(StyledCodeMirror)``

const DeleteButton = styled.button`
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 0;

  ${text14Medium};
  color: var(--color-red);
  transition: color var(--transition-duration) var(--transition-function);
`

import styled from "styled-components"

import { PublicProblemMarker } from "@/store/api/problems/types"

import BookmarkIcon from "~/assets/icons/BookmarkIcon"
import MinusMarker from "~/assets/icons/MinusMarker"
import PlusMarker from "~/assets/icons/PlusMarker"

import NameColumnWrapper from "@/features/task/list/NameColumnWrapper"
import useProblemName from "@/utils/hooks/useProblemName"
import mediaQueryFor from "@/utils/mediaQuery"

const colors = {
  original: {
    text: "var(--color-text)",
    icon: "none",
  },
  red: {
    text: "var(--color-text)",
    icon: "var(--color-red)",
  },
  orange: {
    text: "var(--color-text)",
    icon: "var(--color-orange)",
  },
  green: {
    text: "var(--color-green)",
    icon: "var(--color-green)",
  },
  blue: {
    text: "var(--color-text)",
    icon: "var(--color-primary)",
  },
}

interface INameColumnProps {
  names: Record<string, string>
  marker: undefined | PublicProblemMarker
}

export default function NameColumn({ names, marker }: INameColumnProps) {
  const name = useProblemName(names)

  switch (marker) {
    case PublicProblemMarker.solved:
      return (
        <Column $color="green">
          <PlusMarker />
          <span>{name}</span>
        </Column>
      )
    case PublicProblemMarker.unsolved:
      return (
        <Column $color="red">
          <MinusMarker />
          <span>{name}</span>
        </Column>
      )
    case PublicProblemMarker.partial:
      return (
        <Column $color="orange">
          <MinusMarker />
          <span>{name}</span>
        </Column>
      )
    case PublicProblemMarker.bookmark:
      return (
        <Column $color="blue">
          <StyledBookmarkIcon />
          <span>{name}</span>
        </Column>
      )
    default:
      return (
        <Column $color="original">
          <span>{name}</span>
        </Column>
      )
  }
}

const Column = styled(NameColumnWrapper)<{ $color: keyof typeof colors }>`
  color: ${({ $color }) => colors[$color].text};

  > svg {
    path {
      fill: ${({ $color }) => colors[$color].icon};
    }
  }
`

const StyledBookmarkIcon = styled(BookmarkIcon)`
  ${mediaQueryFor.desktop} {
    padding: 2px;
    width: 16px !important;
    height: 16px !important;
  }
`

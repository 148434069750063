import styled from "styled-components"

const Card = styled.div`
  background-color: var(--color-g-background);
  border: 1px solid var(--color-card-stroke);
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03);
  cursor: pointer;
  transition: background-color var(--transition-duration)
      var(--transition-function),
    border var(--transition-duration) var(--transition-function);

  min-width: 368px;
  height: 166px;
  padding: 22px 24px;
  box-sizing: border-box;

  @media (max-width: 408px) {
    min-width: 100%;
  }
`

export default Card

import React, { useEffect } from "react"

import { ErrorBoundary } from "react-error-boundary"
import { Outlet } from "react-router-dom"

import Layout from "@/components/Layout"
import TranslationProvider from "@/components/TranslationProvider"
import BSOD from "@/features/BSOD"
import GlobalStyleManager from "@/utils/globalStyles"

import LoginModal from "./widgets/LoginModal"

export default function App() {
  useEffect(() => {
    // when render is finished, enable animations
    document.body.classList.remove("preload")
  }, [])

  return (
    <React.StrictMode>
      <TranslationProvider>
        <GlobalStyleManager />

        <ErrorBoundary FallbackComponent={BSOD}>
          <Layout>
            <Outlet />
          </Layout>

          <LoginModal />
        </ErrorBoundary>
      </TranslationProvider>
    </React.StrictMode>
  )
}

import { Draft, original } from "@reduxjs/toolkit"

import baseApi from "@/store/api/base"
import { withQueryParams } from "@/store/api/helpers"

import type { RootState } from "@/store/store"

import {
  IGetSubmissionListParams,
  ISubmission,
  ISubmissionDetails,
  ISubmissionsList,
  SubmitCodeParams,
  SubmitCodeResponse,
} from "./types"

const submissionsApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getSubmissions: builder.query<ISubmissionsList, IGetSubmissionListParams>({
      query: ({ problem_id, user_id, contest_id, count, offset }) =>
        withQueryParams({
          url: "/submissions/get",
          params: {
            problem_id,
            user_id,
            contest_id,
            count,
            offset,
          },
        }),
      providesTags: ["Localized", { type: "Submission", id: "LIST" }],
    }),

    getSubmissionById: builder.query<ISubmissionDetails, string>({
      query: id =>
        withQueryParams({
          url: "/submissions/getByID",
          params: { id },
        }),
      providesTags: params =>
        params
          ? ["Localized", { type: "Submission", id: params.id }]
          : ["Localized"],
    }),

    submitCode: builder.mutation<SubmitCodeResponse, SubmitCodeParams>({
      query: params => ({
        url: "/submit",
        method: "POST",
        body: params,
      }),

      async onCacheEntryAdded(
        { task_id: taskId },
        { cacheDataLoaded, getState, dispatch },
      ) {
        const {
          user: { token, uid: userId },
          common: { lang: language },
        } = getState() as RootState

        const {
          data: { id: submissionId },
        } = await cacheDataLoaded

        if (!submissionId) {
          throw new Error("Submission failed.")
        }

        const onMessageReceived = (event: MessageEvent<string>) => {
          const message: number | ISubmission = JSON.parse(event.data)

          switch (typeof message) {
            case "object":
              return void dispatch(
                submissionsApi.util.updateQueryData(
                  "getSubmissions",
                  {
                    problem_id: taskId.toString(),
                    user_id: userId.toString(),
                  },
                  state => {
                    const index = original(state)!.submissions.findIndex(
                      ({ id }) => id === submissionId,
                    )

                    if (index >= 0) state.submissions[index] = message
                    else state.count = state.submissions.unshift(message)
                  },
                ),
              )

            case "number":
              return void dispatch(
                submissionsApi.util.updateQueryData(
                  "getSubmissions",
                  {
                    problem_id: taskId.toString(),
                    user_id: userId.toString(),
                  },
                  state => {
                    const index = findSubmission(state, submissionId)

                    if (index >= 0)
                      state.submissions[index].test =
                        message === 0 ? COMPILATION : message
                  },
                ),
              )
          }
        }

        const url = withQueryParams({
          url: "wss://api.sort-me.org/wss/listenSubmission",
          params: {
            id: submissionId,
            token,
            language,
          },
        })

        const ws = new WebSocket(url)

        ws.addEventListener("message", onMessageReceived)

        ws.addEventListener("error", () =>
          dispatch(submissionsApi.util.invalidateTags(["Submission"])),
        )

        ws.addEventListener("close", () =>
          dispatch(
            submissionsApi.util.invalidateTags([
              { type: "Submission", id: submissionId },
            ]),
          ),
        )
      },
    }),
  }),
})

const DUMMY = -1
export const COMPILATION = -1

const findSubmission = (data: Draft<ISubmissionsList>, target: number) =>
  original(data)!.submissions.findIndex(item =>
    [target, DUMMY].includes(item.id),
  )

export default submissionsApi

export const {
  useGetSubmissionsQuery,
  useGetSubmissionByIdQuery,
  useSubmitCodeMutation,
} = submissionsApi

import { useCallback, useMemo } from "react"

import { useTranslation } from "react-i18next"
import { useLocation, useNavigate } from "react-router-dom"
import styled from "styled-components"

import { PublicProblemMarker } from "@/store/api/problems/types"

import Loader from "@/components/Loader"
import { SidebarHolder, SidebarItem } from "@/components/Sidebar"
import {
  IManagedFilterProps,
  IProblemBrief,
  IPublicFilterProps,
} from "@/features/task/list/types"
import { text17Medium } from "@/utils/fonts"

import ProblemSidebarItem from "./ProblemSidebarItem"

export enum ProblemSidebarSource {
  direct,
  search,
  managed,
}

interface IProblemSidebarProps {
  problemId: string
}

export interface IDirectSidebarState {
  source: ProblemSidebarSource.direct
}

export interface ISearchSidebarState {
  source: ProblemSidebarSource.search
  filters: IPublicFilterProps
  similar: IProblemBrief[]
  listScrollPosition?: {
    index: number
    offset: number
  }
}

export interface IManagedSidebarState {
  source: ProblemSidebarSource.managed
  filters: IManagedFilterProps
  similar: IProblemBrief[]
  listScrollPosition?: {
    index: number
    offset: number
  }
}

type SidebarState =
  | IDirectSidebarState
  | ISearchSidebarState
  | IManagedSidebarState

type PickPartialUnion<T> = Partial<Omit<T, keyof SidebarState>>

type OwnSidebarState = SidebarState &
  PickPartialUnion<IDirectSidebarState> &
  PickPartialUnion<ISearchSidebarState> &
  PickPartialUnion<IManagedSidebarState>

export default function ProblemSidebar({ problemId }: IProblemSidebarProps) {
  const { t } = useTranslation()

  const navigate = useNavigate()
  const { state }: { state?: OwnSidebarState } = useLocation()

  const {
    source = ProblemSidebarSource.direct,
    filters,
    similar,
    listScrollPosition,
  } = state ?? {}

  const i18n = {
    back: t("pages.task.sidebar.back"),
    subtitle:
      source == ProblemSidebarSource.search ||
      source == ProblemSidebarSource.managed
        ? t("pages.task.sidebar.search")
        : source == ProblemSidebarSource.direct
        ? t("pages.task.sidebar.similar")
        : "UNKNOWN",
  }

  const onBackClick = useCallback(() => {
    switch (source) {
      case ProblemSidebarSource.direct:
        return navigate("/problems")
      case ProblemSidebarSource.search:
        return navigate("/problems", {
          state: {
            filters,
            listScrollPosition,
          },
        })
      case ProblemSidebarSource.managed:
        return navigate("/problems/managed", {
          state: {
            filters,
            listScrollPosition,
          },
        })
      default:
        navigate(-1)
    }
  }, [source, navigate, filters, listScrollPosition])

  const onItemClick = (id: number) => () => {
    navigate(`/problems/${id}`, {
      state,
    })
  }

  const list = useMemo(() => {
    switch (source) {
      case ProblemSidebarSource.direct:
        return []
      case ProblemSidebarSource.search:
      case ProblemSidebarSource.managed:
        return similar!
    }
  }, [source, similar])

  return (
    <SidebarHolder>
      <Loader size="small" loading={false}>
        <BackButton onClick={onBackClick}>{i18n.back}</BackButton>
        <Subtitle>{i18n.subtitle}</Subtitle>

        {list.map(({ id, names, marker }) => (
          <ProblemSidebarItem
            onClick={onItemClick(id)}
            key={id}
            highlighted={id === Number(problemId)}
            names={names}
            solved={marker === PublicProblemMarker.solved}
          />
        ))}
      </Loader>
    </SidebarHolder>
  )
}

const Subtitle = styled.span`
  ${text17Medium};
  transition: color var(--transition-duration) var(--transition-function);

  margin-top: 23px;
  margin-bottom: 6px;
`

const BackButton = styled(SidebarItem)`
  display: inline;
  position: relative;

  opacity: 0.5;

  transition: opacity var(--transition-duration) var(--transition-function),
    color var(--transition-duration) var(--transition-function);

  svg {
    flex: 0 0 20px;

    min-height: 20px;
    min-width: 20px;

    margin-right: 8px;

    position: relative;
    top: 4px;

    path {
      fill: var(--color-text);
      transition: fill var(--transition-duration) var(--transition-function);
    }
  }

  &[data-highlighted="true"] {
    color: var(--color-left-bar-selection);
    opacity: 1;

    svg path {
      fill: var(--color-left-bar-selection);
    }
  }
`

import styled from "styled-components"

import Divider from "@/components/Divider"
import { text15Medium } from "@/utils/fonts"
import mediaQueryFor from "@/utils/mediaQuery"

interface IIncludedInContestsColumnProps {
  includedInContests: number
}

export default function IncludedInContestsColumn({
  includedInContests,
}: IIncludedInContestsColumnProps) {
  return (
    <Column data-empty={includedInContests === 0}>
      <Divider data-divider />
      <span>{includedInContests}</span>
    </Column>
  )
}

const Column = styled.div`
  display: flex;
  align-items: center;

  > span {
    ${text15Medium};
    color: var(--color-text);
    transition: color var(--transition-duration) var(--transition-function);
  }

  &[data-empty="true"] {
    > span {
      opacity: 0.5;
    }
  }

  ${Divider} {
    margin-right: 16px;

    height: 20px;
    align-self: center;
  }

  ${mediaQueryFor.mobile} {
    display: none;
  }
`

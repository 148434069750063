import { MouseEventHandler, useCallback, useState } from "react"

import { Trans, useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import { match } from "ts-pattern"

import { useCreateProblemMutation } from "@/store/api/problems/create"

import PlusIcon from "~/assets/icons/PlusIcon"

import { text12Medium, text14Medium, text16Medium } from "@/utils/fonts"
import mediaQueryFor from "@/utils/mediaQuery"

const NOTIFICATION_THRESHOLD = 50

interface ICreateProblemButtonProps {
  remainingQuota: number
}

export default function CreateProblemButton({
  remainingQuota,
}: ICreateProblemButtonProps) {
  const navigate = useNavigate()

  const { t } = useTranslation()

  const [hasError, setHasError] = useState(false)

  const [createProblem, { isLoading }] = useCreateProblemMutation()

  const handleClick = useCallback<
    MouseEventHandler<HTMLButtonElement>
  >(async () => {
    try {
      const result = await createProblem().unwrap()
      if (result.status === "ok") return navigate(`/problems/${result.id}`)
    } catch (e) {
      setHasError(true)
    }
  }, [createProblem, navigate])

  const noQuota = remainingQuota === 0
  const isAboveNotificationThreshold = remainingQuota > NOTIFICATION_THRESHOLD

  const i18n = {
    createProblem: t("pages.task.list.tabs.managed.create_problem.create"),
    quotaExceeded: t(
      "pages.task.list.tabs.managed.create_problem.quota_exceeded"
    ),
    loading: t("pages.task.list.tabs.managed.create_problem.loading"),
    error: t("pages.task.list.tabs.managed.create_problem.error"),
  }

  return (
    <StyledButton
      disabled={noQuota}
      data-hide-notification={isAboveNotificationThreshold}
      onClick={handleClick}
    >
      <PlusIcon />
      <p>
        {match({ isLoading, hasError, noQuota })
          .with({ isLoading: true }, () => i18n.loading)
          .with({ hasError: true }, () => i18n.error)
          .with({ noQuota: true }, () => i18n.quotaExceeded)
          .otherwise(() => i18n.createProblem)}
      </p>
      {!isAboveNotificationThreshold && (
        <Trans
          t={t}
          i18nKey="pages.task.list.tabs.managed.create_problem.quota_notification"
          count={remainingQuota}
          parent="span"
          components={{
            mail: <a href="mailto: guys@sort-me.org" />,
          }}
        />
      )}
    </StyledButton>
  )
}

const StyledButton = styled.button`
  cursor: pointer;
  background-color: var(--color-additional);
  border: 1px solid transparent;
  border-radius: 10px;
  transition: border-color var(--transition-duration) var(--transition-function),
    background-color var(--transition-duration) var(--transition-function),
    color var(--transition-duration) var(--transition-function);

  display: grid;
  grid-template-areas:
    "icon text"
    "icon notification";
  grid-template-columns: 20px 1fr;
  align-items: center;
  align-content: center;
  justify-items: start;
  column-gap: 16px;

  height: 50px;
  box-sizing: border-box;
  padding: 0 15px;

  margin-top: 12px;

  color: var(--color-primary);

  a {
    color: var(--color-primary);
  }

  &:disabled {
    cursor: not-allowed;
    background-color: transparent;
    border-color: var(--color-card-stroke);
    color: var(--color-text);

    > svg path {
      fill: transparent;
    }
  }

  &[data-hide-notification="true"] {
    grid-template-areas: "icon text";
  }

  > p {
    grid-area: text;
    ${text16Medium};
    margin: 0;
  }

  > span {
    grid-area: notification;
    ${text12Medium};
  }

  > svg {
    grid-area: icon;
    width: 20px;
    height: 20px;

    path {
      fill: var(--color-primary);
    }
  }

  ${mediaQueryFor.mobile} {
    grid-template-areas: "icon text";
    grid-template-columns: 16px 1fr;
    margin-top: 0;

    > p {
      ${text14Medium};
    }

    > span {
      display: none;
    }

    > svg {
      width: 16px;
      height: 16px;
    }
  }
`

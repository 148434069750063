import { gfmTableFromMarkdown, gfmTableToMarkdown } from "mdast-util-gfm-table"
import { gfmTable } from "micromark-extension-gfm-table"
import type { Processor, Plugin } from "unified"

function GithubTables(this: Processor) {
  const data = this.data()

  const add = (to: string, value: unknown) => {
    const list = data[to] ? (data[to] as unknown[]) : (data[to] = [])

    list.push(value)
  }

  add("micromarkExtensions", gfmTable)
  add("fromMarkdownExtensions", gfmTableFromMarkdown)
  add("toMarkdownExtensions", gfmTableToMarkdown())
}

export default GithubTables as Plugin

import { useState, useMemo, useEffect } from "react"

import { isEmpty } from "lodash"

import { useNewGetProblemByIdQuery } from "@/store/api/problems"

import {
  getDraft,
  mergeTranslations,
} from "@/features/task/edit/legend/utils/helpers"
import { ISavedTranslations } from "@/features/task/edit/legend/utils/types"
import usePreferredLanguages from "@/utils/hooks/usePreferredLanguages"

export default function useLegendData(problemId: string) {
  const [isLegendLoading, setIsLegendLoading] = useState(false)

  const languagePreference = usePreferredLanguages()

  const [currentTranslation, setCurrentTranslation] = useState<string>()
  const [translations, setTranslations] = useState<ISavedTranslations>({})

  const { data, isFetching } = useNewGetProblemByIdQuery(
    {
      id: problemId,
      translations: currentTranslation
        ? [currentTranslation]
        : languagePreference,
    },
    {
      // skip if translation isn't saved, meaning it has data in a draft
      skip: currentTranslation ? !translations[currentTranslation] : false,
    }
  )

  // `currentTranslation` is undefined after an initial request to prevent refetching,
  // since the same data was fetched with `languagePrefence` params
  // TODO: use hook by @kireevmp to prevent refetching and remove this
  const actualTranslation = useMemo(
    () => currentTranslation ?? data?.matched_translation,
    [currentTranslation, data]
  )

  const serverTranslations = useMemo(
    () => new Set(data?.translations),
    [data?.translations]
  )

  const [title, setTitle] = useState(data?.name ?? "")
  const [legend, setLegend] = useState(data?.legend ?? "")

  useEffect(() => {
    const draft = getDraft(problemId)

    // first request
    if (isEmpty(translations) && data) {
      setTitle(data.name)
      setLegend(data.legend)

      setTranslations(
        mergeTranslations({
          draft,
          recievedTranslations: data.translations,
        })
      )
    } else if (actualTranslation && !translations[actualTranslation]) {
      if (draft[actualTranslation]) {
        setTitle(draft[actualTranslation].title)
        setLegend(draft[actualTranslation].legend)
      } else {
        setTitle("")
        setLegend("")
      }
    } else if (data) {
      setTitle(data.name)
      setLegend(data.legend)
    }

    setIsLegendLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualTranslation, data, problemId])

  useEffect(() => {
    if (isFetching) setIsLegendLoading(true)
  }, [isFetching])

  // translation can be deleted if it's not the only one on the server or if it's not on the server
  const canDelete = useMemo(
    () =>
      (serverTranslations.size > 1 &&
        serverTranslations.has(actualTranslation!)) ||
      !serverTranslations.has(actualTranslation!),
    [actualTranslation, serverTranslations]
  )

  return {
    translations,
    setTranslations,
    serverTranslations,
    currentTranslation: actualTranslation,
    setCurrentTranslation,

    data,
    title,
    setTitle,
    legend,
    setLegend,

    isLoading: isLegendLoading || isFetching,
    canDelete,
  }
}

import React from "react"

import { Helmet } from "react-helmet-async"
import { Trans, useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import styled from "styled-components"

import { header140, header44, text24Medium } from "@/utils/fonts"
import { IPageConfig } from "@/utils/types"

export const config: IPageConfig = {
  routes: { path: "*", Component: NotFound },

  server: {},
  client: {},

  layout: "normal",
}

function NotFound() {
  const { t } = useTranslation()

  const i18n = {
    title: t("pages.not_found.title"),
    header: t("pages.not_found.header"),
    subheader: t("pages.not_found.subheader"),
    description: "pages.not_found.description",
  }

  return (
    <>
      <Helmet>
        <title>{i18n.title}</title>
        <link rel="canonical" href="https://sort-me.org/404" />
      </Helmet>

      <Wrapper>
        <h1>{i18n.header}</h1>
        <h2>{i18n.subheader}</h2>
        <Trans
          i18nKey={i18n.description}
          parent="p"
          components={{
            lnk: <Link to="/" />,
          }}
        />
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: start;

  color: var(--color-text);
  transition: color var(--transition-duration) var(--transition-function);

  h1,
  h2,
  p {
    margin: 0;
    padding: 0;
  }

  & > h1 {
    ${header140};
  }

  & > h2 {
    margin-bottom: 48px;
    ${header44};
  }

  & > p {
    ${text24Medium};
    text-align: center;

    max-width: 800px;
    width: 100%;

    a {
      color: var(--button-primary);
      transition: color var(--transition-duration) var(--transition-function);
      text-decoration: none;
    }
  }
`

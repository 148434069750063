import { useCallback, useState } from "react"

import styled from "styled-components"

import CustomButton from "@/components/CustomButton"

interface IButtonWithLoaderProps {
  onClick: () => Promise<void>
  disabled?: boolean
  children: React.ReactNode
  className?: string
}

export default function ButtonWithLoader({
  onClick,
  disabled = false,
  children,
  className = "",
}: IButtonWithLoaderProps) {
  const [isLoading, setIsLoading] = useState(false)

  const handleClick = useCallback(async () => {
    setIsLoading(true)

    try {
      await onClick()
    } finally {
      setIsLoading(false)
    }
  }, [onClick])

  return (
    <Button
      className={className}
      onClick={handleClick}
      disabled={disabled || isLoading}
      data-type="primary"
      data-loading={isLoading}
    >
      {isLoading ? "..." : children}
    </Button>
  )
}

const Button = styled(CustomButton)`
  &[data-loading="true"],
  &:disabled[data-loading="true"] {
    cursor: wait;
    transform: scale(0.9);
  }
`

import React, { MouseEventHandler, useCallback } from "react"

import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"

import { switchLanguage } from "@/store/slices/common/lang"
import { toggleTheme } from "@/store/slices/common/theme"

import LanguageSwitcher from "~/assets/icons/LanguageSwitcher"
import ThemeSwitcher from "~/assets/icons/ThemeSwitcher"
import SortMeLogo from "~/assets/images/SortMeLogo"

import { useAppDispatch, useAppSelector } from "@/store"
import { text18 } from "@/utils/fonts"
import mediaQueryFor from "@/utils/mediaQuery"

import Dropdown from "./Dropdown"
import NavbarLinks from "./NavbarLinks"
import ProfileBrief from "./ProfileBrief"

export default function Navbar() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const { i18n } = useTranslation()

  const media = useAppSelector(({ common }) => common.media)

  const handleThemeChange: MouseEventHandler<Element> = useCallback(
    event => {
      event.stopPropagation()

      dispatch(toggleTheme())
    },
    [dispatch],
  )

  const handleLanguageChange: MouseEventHandler<Element> = useCallback(
    event => {
      event.stopPropagation()

      dispatch(switchLanguage({ i18n, next: true }))
    },
    [i18n, dispatch],
  )

  const handleLogoClick = useCallback(() => {
    navigate("/")
  }, [navigate])

  if (media === "desktop") {
    return (
      <Wrapper>
        <SortMeLogo onClick={handleLogoClick} />

        <NavbarCategories>
          <NavbarLinks />
        </NavbarCategories>
        <NavbarMenu>
          <ProfileBrief />

          <ThemeSwitcher onClick={handleThemeChange} />

          <LanguageSwitcher onClick={handleLanguageChange} />
        </NavbarMenu>
      </Wrapper>
    )
  }

  if (media === "mobile") {
    return (
      <Wrapper>
        <SortMeLogo onClick={handleLogoClick} />

        <Dropdown>
          <NavbarLinks />

          <MobileProfileWrapper>
            <ProfileBrief />
          </MobileProfileWrapper>

          <NavbarMobileMenu>
            <SwitcherButton onClick={handleThemeChange}>
              <ThemeSwitcher width={18} height={18} />
            </SwitcherButton>

            <SwitcherButton onClick={handleLanguageChange}>
              <LanguageSwitcher width={16} height={16} />
            </SwitcherButton>
          </NavbarMobileMenu>
        </Dropdown>
      </Wrapper>
    )
  }

  return null
}

const Wrapper = styled.div`
  height: 60px;
  box-sizing: border-box;

  width: calc(100vw - 48px);
  max-width: calc(1440px - 48px);

  margin: 0px auto;
  padding: 12px 0px;

  align-content: center;
  justify-content: stretch;

  display: grid;
  grid-template-columns: max-content 1fr max-content;
  grid-template-rows: max-content;
  grid-template-areas: "logo categories menu";

  align-items: center;
  justify-items: start;

  gap: 31px;

  ${mediaQueryFor.mobile} {
    grid-template-columns: max-content max-content;
    grid-template-areas: "logo menu";
    justify-content: space-between;
  }
`

const NavbarCategories = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: start;

  column-gap: 21px;
`

const NavbarMenu = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  gap: 12px;

  user-select: none;

  img {
    height: 36px;
    width: 36px;
    border-radius: 50%;
  }

  picture {
    height: 36px;
    width: 36px;
    margin-right: 14px;

    cursor: pointer;
  }

  > svg {
    cursor: pointer;

    path,
    rect {
      transition: fill var(--transition-duration) var(--transition-function);

      &[data-kind="background"] {
        fill: var(--color-text);
      }

      &[data-kind="foreground"] {
        fill: var(--color-g-background);
      }
    }
  }

  span {
    ${text18};
    font-weight: 500;

    cursor: pointer;

    color: var(--color-text);
    transition: color var(--transition-duration) var(--transition-function);
  }
`

const MobileProfileWrapper = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: end;
  width: 100%;

  margin-top: 14px;

  img {
    height: 36px;
    width: 36px;
    border-radius: 50%;
  }

  > picture {
    height: 36px;
    width: 36px;

    cursor: pointer;
  }

  > span {
    ${text18};
    font-weight: 500;

    cursor: pointer;

    color: var(--color-text);
    transition: color var(--transition-duration) var(--transition-function);
  }
`

const NavbarMobileMenu = styled.div`
  border-top: 2px solid var(--layout-border);
  transition: border var(--transition-duration) var(--transition-function);

  display: flex;
  width: calc(100% + 38px);

  position: relative;
  left: 19px;
  margin-top: 6px;

  svg {
    cursor: pointer;

    path,
    rect {
      transition: fill var(--transition-duration) var(--transition-function);

      &[data-kind="background"] {
        fill: var(--color-text);
      }

      &[data-kind="foreground"] {
        fill: var(--color-g-background);
      }
    }
  }

  > button:first-of-type {
    border-right: 1px solid var(--layout-border);
  }

  > button:last-of-type {
    border-left: 1px solid var(--layout-border);
  }
`

const SwitcherButton = styled.button`
  background: none;
  border: none;
  outline: none;
  transition: border var(--transition-duration) var(--transition-function);

  width: 100%;
  padding: 12px 0;
`

import baseApi from "@/store/api/base"
import { withQueryParams } from "@/store/api/helpers"
import problemsApi from "@/store/api/problems"
import {
  IAdminsList,
  ISetAdminParams,
  ISetAdminResponse,
} from "@/store/api/problems/edit/admins/types"

import { RootState } from "@/store/store"

const editAdminsApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getAdmins: builder.query<IAdminsList, string>({
      query: id =>
        withQueryParams({
          url: "https://beta.sort-me.org/api/problems/getAdmins",
          params: { id },
        }),
    }),
    setAdmin: builder.mutation<ISetAdminResponse, ISetAdminParams>({
      query: params => ({
        url: "https://beta.sort-me.org/api/problems/setAdmin",
        method: "POST",
        body: params,
      }),
      async onQueryStarted(
        { id, user_id: userId },
        { getState, dispatch, queryFulfilled }
      ) {
        try {
          const {
            data: { status, admins },
          } = await queryFulfilled

          if (status === "ok") {
            dispatch(
              editAdminsApi.util.updateQueryData(
                "getAdmins",
                id.toString(),
                draft => Object.assign(draft, { admins })
              )
            )

            const {
              user: { uid: selfId },
            } = getState() as RootState

            if (selfId === userId)
              dispatch(
                problemsApi.util.invalidateTags([{ type: "Problem", id }])
              )
          }
        } catch {}
      },
    }),
  }),
})

export default editAdminsApi
export const { useGetAdminsQuery, useSetAdminMutation } = editAdminsApi

import { useMemo } from "react"

import { DateTime } from "luxon"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

import { IUserRegalStatistics } from "@/store/api/users/types"

import DifficultyRecord from "@/components/regal/DifficultyRecord"
import {
  header18,
  header30,
  text12Medium,
  text13Medium,
  text16,
} from "@/utils/fonts"
import mediaQueryFor from "@/utils/mediaQuery"

interface IRegalStatsProps {
  stats: IUserRegalStatistics
}

export default function RegalStats({
  stats: { total, difficulties, last_accepted: date },
}: IRegalStatsProps) {
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation()

  const lastAccepted = useMemo(
    () =>
      DateTime.fromSeconds(date).toLocaleString(dateFormat, {
        locale: resolvedLanguage,
      }),
    [resolvedLanguage, date]
  )

  const i18n = {
    total: t("pages.profile.regals.stats.total_tasks"),
    lastAccepted: t("pages.profile.regals.stats.last_accepted", {
      lastAccepted,
    }),
  }

  return (
    <Wrapper>
      <Total>
        <span>{total}</span>
        <span>{i18n.total}</span>
      </Total>

      <Difficulties>
        {difficulties.map((solved, rank) => (
          <DifficultyRecord key={rank} solved={solved} difficulty={rank + 1} />
        ))}
      </Difficulties>

      <LastAccepted>{i18n.lastAccepted}</LastAccepted>
    </Wrapper>
  )
}

const dateFormat: Intl.DateTimeFormatOptions = {
  day: "numeric",
  month: "long",
  year: "numeric",
}

const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: center;

  height: max-content;

  padding: 24px 20px;

  gap: 18px;

  color: var(--color-text);

  ${mediaQueryFor.mobile} {
    gap: 10px;

    padding: 16px 16px 12px 16px;
  }
`

const Total = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;

  gap: 8px;

  span:first-child {
    ${header30}
  }
  span:last-child {
    ${text16};
  }

  ${mediaQueryFor.mobile} {
    gap: 4px;

    span:first-child {
      ${header18};
    }
    span:last-child {
      ${text12Medium};
    }
  }
`

const Difficulties = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: flex-start;

  gap: 12px;

  ${mediaQueryFor.mobile} {
    gap: 7.5px;

    > svg {
      width: 12px;
      height: 12px;
    }
  }
`

const LastAccepted = styled.span`
  ${text16};

  opacity: 0.5;

  ${mediaQueryFor.mobile} {
    ${text13Medium};
  }
`

import { Trans, useTranslation } from "react-i18next"
import styled from "styled-components"

import Codeforces from "~/assets/images/task-creation-selector/Codeforces"
import EJudge from "~/assets/images/task-creation-selector/EJudge"
import Rofl from "~/assets/images/task-creation-selector/Rofl"
import Yandex from "~/assets/images/task-creation-selector/Yandex"

import { text18Medium } from "@/utils/fonts"

import SelectorGridItem from "./SelectorGridItem"

const icons = {
  rofl: { as: Rofl, right: -14, top: -14 },
  codeforces: { as: Codeforces, right: -8, top: -8 },
  yandex: { as: Yandex, right: -8, top: -8 },
  ejudge: { as: EJudge, right: -8, top: -8 },
}

const gridItems = [
  {
    to: "/problems/create/new",
    icon: icons.rofl,
    title: "pages.task.create.selector.from_scratch",
  },
  {
    to: "/problems/create/polygon-import",
    icon: icons.codeforces,
    title: "pages.task.create.selector.polygon_import",
  },
  {
    to: null,
    icon: icons.yandex,
    title: "pages.task.create.selector.yandex_import",
  },
  {
    to: null,
    icon: icons.ejudge,
    title: "pages.task.create.selector.ejudge_import",
  },
]

export default function SelectorGrid() {
  const { t } = useTranslation()

  return (
    <Wrapper>
      {gridItems.map(({ to, icon, title }) => (
        <SelectorGridItem key={title} to={to}>
          <TypeIcon {...icon} />
          <Trans
            t={t}
            parent={TypeTitle}
            i18nKey={title}
            components={{ next: <br /> }}
          />
        </SelectorGridItem>
      ))}
    </Wrapper>
  )
}

interface ITypeIconProps {
  right: number
  top: number
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 400px);
  grid-gap: 16px;
`

const TypeTitle = styled.span`
  color: var(--color-text);
  transition: color var(--transition-duration) var(--transition-function);
  ${text18Medium};

  position: relative;
`

const TypeIcon = styled.svg<ITypeIconProps>`
  position: absolute;
  right: ${({ right }) => right}px;
  top: ${({ top }) => top}px;
  transform: rotate(-15deg);
`

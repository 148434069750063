import { useCallback, useState } from "react"

export default function useContestsUsageFilter(
  initialIsUsedInContests: boolean = false
) {
  const [isUsedInContests, setIsUsedInContests] = useState(
    initialIsUsedInContests
  )

  const toggleIsUsedInContests = useCallback(
    () => setIsUsedInContests(prev => !prev),
    []
  )

  return {
    isUsedInContests,
    toggleIsUsedInContests,
  }
}

import React, { Fragment } from "react"

import isEqual from "lodash/isEqual"
import styled from "styled-components"

import Divider from "@/components/Divider"
import mediaQueryFor from "@/utils/mediaQuery"

type TableMapper<I extends Record<string, any>> = {
  [k in keyof I]: (arg: I[k], item: I) => React.ReactNode
}

type NoInfer<T> = [T][T extends any ? 0 : never]

interface ITaskTableProps<
  Item extends Record<string, any> & { id: number },
  Mapper extends TableMapper<Item>
> {
  header: {
    [k in keyof Item]: string | null
  }
  mapper?: Partial<Mapper>
  dedicated?: (keyof NoInfer<Item> & string)[] | null
  data: Item[]
  showHeader?: boolean
  setWidth?: boolean
  onRowClick?: (id: Item["id"]) => void
}

function TaskTable<
  Item extends Record<string, any> & { id: number },
  Mapper extends TableMapper<Item>
>({
  header,
  mapper = {},
  dedicated = [],
  data,
  showHeader = true,
  setWidth = false,
  // eslint-disable-next-line react/require-default-props
  onRowClick,
}: ITaskTableProps<Item, Mapper>) {
  const columns = Object.entries(header)
    .filter(([k, v]) => !dedicated?.includes(k) && v !== null)
    .map(([k]) => k)

  const tableWidth = columns.length

  const map = (item: Item, key: keyof Item & string) =>
    mapper?.[key]?.(item[key], item) ?? `${item[key]}`

  return (
    <Wrapper>
      {setWidth && (
        <colgroup>
          {columns.map(name => (
            <col className={`column-${name}`} key={name} />
          ))}
        </colgroup>
      )}

      {showHeader && (
        <thead>
          <Header>
            {columns.map(key => (
              <th key={key}>{header[key]}</th>
            ))}
          </Header>
        </thead>
      )}

      <tbody data-clickable={Boolean(onRowClick)}>
        {data.map(item => (
          <Fragment key={item.id}>
            <Row
              key={`${item.id}_$core`}
              onClick={() => void onRowClick?.(item.id)}
            >
              {/* TODO: Remove inline function creation, this will cause extra re-rendering work */}
              {columns.map(key => (
                <Cell key={key + item.id}>
                  <div>{map(item, key)}</div>
                </Cell>
              ))}
            </Row>

            {dedicated?.map(key => (
              <Row
                key={`${item.id}_${key}`}
                onClick={() => void onRowClick?.(item.id)}
              >
                <Cell colSpan={tableWidth}>
                  <div>{map(item, key)}</div>
                </Cell>
              </Row>
            ))}
          </Fragment>
        ))}
      </tbody>
    </Wrapper>
  )
}

export function WithTableDivider({ children }: { children: React.ReactNode }) {
  return (
    <>
      <Divider data-divider />
      {children}
    </>
  )
}

const Wrapper = styled.table`
  width: 100%;

  border-spacing: 0px 6px;

  transition: background-color var(--transition-duration)
      var(--transition-function),
    color var(--transition-duration) var(--transition-function);

  td {
    padding: 0px;
  }

  tbody[data-clickable="true"] {
    cursor: pointer;
  }

  ${Divider} {
    margin-right: 10px;
  }
`

const Header = styled.tr`
  & > th {
    text-align: left;
    opacity: 0.3;

    text-transform: uppercase;
    /* padding: 3px 12px 3px 0px; */

    padding-right: 10px;
  }
`

const Row = styled.tr`
  & > td {
    height: 0px;
    // this does not set height for td
    // but provides basis for height: 100% for div inside
  }

  & > td > div {
    --v-pad: 8px;
    min-height: calc(36px - var(--v-pad) * 2);
    height: calc(100% - var(--v-pad) * 2);
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: stretch;

    background-color: var(--color-bubble);
    transition: background-color var(--transition-duration)
      var(--transition-function);

    padding: var(--v-pad) 10px var(--v-pad) 0px;
  }

  & > td:first-child > div {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;

    padding-left: 12px;
  }

  & > td:last-child > div {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;

    padding-right: 12px;
  }

  ${mediaQueryFor.desktop} {
    &:hover > td > div {
      background-color: var(--color-table-even-row);
    }
  }
`

const Cell = styled.td``

export default React.memo(TaskTable, (prev, next) => isEqual(prev, next))

import { useMemo } from "react"

import { BasicSetupOptions } from "@uiw/react-codemirror"
import { DateTime } from "luxon"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

import { useGetTipQuery } from "@/store/api/misc"
import { useGetSubmissionByIdQuery } from "@/store/api/submissions"

import ShareIcon from "~/assets/icons/ShareIcon"

import Loader from "@/components/Loader"
import StyledCodeMirror, {
  getLinter,
} from "@/features/task/current/editor/StyledCodeMirror"
import { useAppSelector } from "@/store"
import {
  text12Medium,
  text16Medium,
  text20Medium,
  text36Medium,
} from "@/utils/fonts"
import mediaQueryFor from "@/utils/mediaQuery"

import SubmissionLoader from "./SubmissionLoader"

interface ISubmissionDetailsProps {
  id: string
}

export default function SubmissionDetails({ id }: ISubmissionDetailsProps) {
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation()

  const isMobile = useAppSelector(({ common }) => common.media === "mobile")

  // TODO: Maybe, enable this in SSR?
  const { data: submission, isLoading: isSubmissionLoading } =
    useGetSubmissionByIdQuery(id)
  const { isLoading: isTipLoading } = useGetTipQuery()
  // TODO: make getTip conditional

  const {
    code = "",
    lang: { name: languageName = null, highlight: languageCode = null } = {},
    submitted_at: submissionTime = 0,
  } = submission ?? {}

  const i18n = {
    header: t("pages.task.submission_modal.header", { id }),
    share: t("pages.task.submission_modal.share"),

    datetimeFormats: {
      differentYear: t("pages.task.submission_modal.datetime.different_year"),
      sameYear: t("pages.task.submission_modal.datetime.same_year"),
    },
  }

  const date = useMemo(
    () =>
      formatHeaderDatetime({
        time: submissionTime,
        locale: resolvedLanguage,
        same: i18n.datetimeFormats.sameYear,
        different: i18n.datetimeFormats.differentYear,
      }),
    [
      i18n.datetimeFormats.differentYear,
      i18n.datetimeFormats.sameYear,
      resolvedLanguage,
      submissionTime,
    ]
  )

  const lint = useMemo(() => getLinter(languageCode), [languageCode])
  const settings = useMemo(() => getCodeSettings({ isMobile }), [isMobile])

  return (
    <Loader loading={isSubmissionLoading || isTipLoading} size="large">
      <HeaderBlock>
        <Header>{i18n.header}</Header>
        <SubHeader>
          {t("pages.task.submission_modal.description", {
            lang: languageName,
            date,
          })}
        </SubHeader>
        <Share>
          <ShareIcon />
          {i18n.share}
        </Share>
      </HeaderBlock>

      <CodeEditor
        extensions={lint ? [lint] : []}
        value={code}
        basicSetup={settings}
        editable={true}
        readOnly={true}
        data-has-value={true}
      />

      {submission && <SubmissionLoader submission={submission} />}
    </Loader>
  )
}

function formatHeaderDatetime({
  time,
  locale,
  same,
  different,
}: {
  time: number
  locale: string
  same: string
  different: string
}): string {
  if (!time) return ""

  const then = DateTime.fromSeconds(time, { locale })
  const now = DateTime.now()

  const isSameYear = now.year === then.year

  return isSameYear ? then.toFormat(same) : then.toFormat(different)
}

const getCodeSettings = ({
  isMobile,
}: {
  isMobile: boolean
}): BasicSetupOptions => ({
  ...CodeSettings,
  foldGutter: !isMobile,
})

const CodeSettings: BasicSetupOptions = {
  foldGutter: true,
  highlightSpecialChars: false,
  dropCursor: false,
  foldKeymap: false,
  rectangularSelection: false,
  crosshairCursor: false,
  lintKeymap: false,
  autocompletion: false,
  searchKeymap: false,
  completionKeymap: false,
}

const HeaderBlock = styled.div`
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-template-rows: max-content max-content;
  grid-template-areas:
    "header share"
    "description description";

  width: 100%;

  gap: 14px;

  ${mediaQueryFor.mobile} {
    grid-template-columns: 100%;
    grid-template-areas:
      "header"
      "description";

    gap: 9px;
  }
`

const Header = styled.h2`
  ${text36Medium};

  grid-area: header;

  color: var(--color-text);
  margin: 0;

  ${mediaQueryFor.mobile} {
    ${text20Medium};
  }
`

const SubHeader = styled.span`
  ${text16Medium};

  grid-area: description;

  color: var(--color-text);
  opacity: 0.5;

  ${mediaQueryFor.mobile} {
    ${text12Medium};
  }
`

const Share = styled.span`
  ${text16Medium};

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  gap: 8px;

  grid-area: share;
  align-self: start;

  cursor: pointer;

  text-align: right;
  color: var(--color-primary);

  svg > path {
    fill: var(--color-primary);
  }

  ${mediaQueryFor.mobile} {
    display: none;
  }
`

const CodeEditor = styled(StyledCodeMirror)`
  height: max-content;

  .cm-editor {
    min-height: 220px;
    height: max-content;

    box-sizing: border-box;

    .cm-scroller {
      .cm-activeLineGutter {
        background-color: transparent;
      }
    }
  }
`

import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { match } from "ts-pattern"

import Loader from "@/components/Loader"
import { text18Medium, text30Medium } from "@/utils/fonts"

interface ILoaderBlockProps {
  isSaving: boolean
  isNew: boolean
}

export default function LoaderBlock({ isSaving, isNew }: ILoaderBlockProps) {
  const { t } = useTranslation()

  const i18n = {
    updating: t("pages.task.edit.stages.tests.edit_modal.updating"),
    waitText: t("pages.task.edit.stages.tests.edit_modal.wait_text"),
    updated: t("pages.task.edit.stages.tests.edit_modal.updated"),
    updatedText: t("pages.task.edit.stages.tests.edit_modal.updated_text"),

    adding: t("pages.task.edit.stages.tests.edit_modal.new.adding"),
    added: t("pages.task.edit.stages.tests.edit_modal.new.added"),
  }

  const mainText = match({ isSaving, isNew })
    .with({ isSaving: true, isNew: false }, () => i18n.updating)
    .with({ isSaving: false, isNew: false }, () => i18n.updated)
    .with({ isSaving: true, isNew: true }, () => i18n.adding)
    .with({ isSaving: false, isNew: true }, () => i18n.added)
    .exhaustive()

  return (
    <Wrapper>
      <Loader loading={isSaving} size="large" />
      <p>{mainText}</p>
      <span>{isSaving ? i18n.waitText : i18n.updatedText}</span>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  padding: 8px 0;
  margin-bottom: 17px;

  > div {
    height: 122px !important;
  }

  > p {
    ${text30Medium};
    margin: 32px 0 22px 0;
  }

  > span {
    ${text18Medium};
    opacity: 0.7;
  }
`

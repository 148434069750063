export enum Format {
  Normal,
  Interactive,
  DoubleStart,
  OpenTests,
  WithGrader,
  Custom,
}

export enum RatingSystem {
  IOI,
  ICPC,
  Custom,
}

export enum Stage {
  Format = "format",
  RatingSystem = "ratingSystem",
}

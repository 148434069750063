import { BasicSetupOptions } from "@uiw/react-codemirror"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

import { ISubmissionDetails } from "@/store/api/submissions/types"

import OkHandIcon from "~/assets/icons/OkHandIcon"

import Line from "@/components/Line"
import { RawCodeMirror } from "@/features/task/current/editor/StyledCodeMirror"
import { text14, text16, text16Medium, text20Medium } from "@/utils/fonts"
import mediaQueryFor from "@/utils/mediaQuery"

import SubmissionSubtasks from "./SubmissionSubtasks"
import SubtasksTotal from "./SubtasksTotal"

export default function SubmissionResult({
  submission: {
    results: { subtasks, points, compiler_log: compilerLog = "" },
  },
}: {
  submission: ISubmissionDetails
}) {
  const { t } = useTranslation()

  const hasCompiledSuccessfully = !compilerLog

  const i18n = {
    compilation: {
      title: t("pages.task.submission_modal.compilation.title"),
      success: t("pages.task.submission_modal.compilation.success"),
      failure: t("pages.task.submission_modal.compilation.failure"),
    },
  }

  return (
    <>
      <CompilationResult>
        <h4>{i18n.compilation.title}</h4>
        <span>
          {hasCompiledSuccessfully ? (
            <>
              <OkHandIcon />
              {i18n.compilation.success}
            </>
          ) : (
            i18n.compilation.failure
          )}
        </span>

        {!hasCompiledSuccessfully && (
          <CodeEditor
            value={compilerLog}
            basicSetup={ErrorSettings}
            editable={false}
            data-has-value={true}
          />
        )}
      </CompilationResult>

      <Line />

      {subtasks && <SubmissionSubtasks subtasks={subtasks} />}

      <SubtasksTotal earned={points} />
    </>
  )
}

const ErrorSettings: BasicSetupOptions = {
  foldGutter: false,
  highlightSpecialChars: false,
  dropCursor: false,
  foldKeymap: false,
  rectangularSelection: false,
  crosshairCursor: false,
  lintKeymap: false,
  autocompletion: false,
  searchKeymap: false,
  completionKeymap: false,
  lineNumbers: false,
}

const CompilationResult = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: center;
  gap: 16px;

  width: 100%;

  > h4 {
    margin: 0;
    ${text20Medium};
  }

  > span {
    ${text16};

    display: flex;
    flex-flow: row nowrap;
    align-items: start;
    justify-content: center;

    gap: 14px;
  }

  ${mediaQueryFor.mobile} {
    > h4 {
      ${text16Medium};
    }

    > span {
      ${text14};
    }
  }
`

const CodeEditor = styled(RawCodeMirror)`
  min-height: max-content;

  .cm-editor {
    color: var(--color-red);
    .cm-scroller {
      .cm-activeLineGutter {
        background-color: transparent;
      }
    }
  }
`

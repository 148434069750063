import { useCallback } from "react"

import styled, { css } from "styled-components"

import loginWithGoogle from "@/store/slices/auth/google"
import { Integration } from "@/store/slices/auth/types"

import GoogleBubble from "~/assets/icons/GoogleBubble"

import { useAppDispatch, useAppSelector } from "@/store"

export default function GoogleLoginBubble() {
  const dispatch = useAppDispatch()

  const isLoading = useAppSelector(
    state => state.auth.loading === Integration.google,
  )

  const hasError = useAppSelector(state =>
    state.auth.lastLoaded === Integration.google ? state.auth.lastError : null,
  )

  const onClick = useCallback(() => {
    if (isLoading) return

    dispatch(loginWithGoogle({ mode: "create" }))
  }, [dispatch, isLoading])

  return (
    <LogInButton
      onClick={onClick}
      $loading={isLoading}
      $errored={hasError !== null}
    />
  )
}

const LoadingAnimation = css`
  animation: googlePulse 1.8s linear infinite;
  border-radius: 6.82px;

  @keyframes googlePulse {
    0% {
      box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.1);
    }
    60% {
      box-shadow: 0 0 0 4.8px rgba(0, 0, 0, 0.1);
    }
    100% {
      box-shadow: 0 0 0 8px rgba(0, 0, 0, 0);
    }
  }
`

const ErrorMarker = css`
  transition: box-shadow 0.3s ease-in-out;

  box-shadow: 0 0 4px 0.1px var(--color-red);
`

const LogInButton = styled(GoogleBubble)<{
  $loading: boolean
  $errored: boolean
}>`
  box-shadow: none;
  border-radius: 6.82px;

  ${({ $loading }) => $loading && LoadingAnimation}
  ${({ $errored }) => $errored && ErrorMarker}
`

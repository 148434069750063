import { Trans, useTranslation } from "react-i18next"
import styled from "styled-components"

import CheckboxInput from "@/components/CheckboxInput"
import { text15Medium } from "@/utils/fonts"

interface IIsUsedInContestsFilterProps {
  isUsedInContests: boolean
  toggleIsUsedInContests: () => void
}

export default function ContestsUsageFilterSelector({
  isUsedInContests,
  toggleIsUsedInContests,
}: IIsUsedInContestsFilterProps) {
  const { t } = useTranslation()

  return (
    <CustomCheckboxInput
      value="usedInContests"
      checked={isUsedInContests}
      onChange={toggleIsUsedInContests}
    >
      <Trans
        t={t}
        i18nKey="pages.task.list.tabs.managed.used_in_contests"
        components={{
          next: <br />,
        }}
      />
    </CustomCheckboxInput>
  )
}

const CustomCheckboxInput = styled(CheckboxInput)`
  display: flex;
  align-items: start;
  justify-content: flex-start;
  gap: 8px;

  ${text15Medium};
`

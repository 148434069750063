import { useTranslation, Trans } from "react-i18next"
import styled from "styled-components"

import InfoIcon from "~/assets/icons/InfoIcon"

import { text14Medium } from "@/utils/fonts"

export default function Tip() {
  const { t } = useTranslation()

  const i18n = {
    tipTests: t("pages.task.edit.stages.legend.tip.tests"),
    datetimeFormat: t("pages.task.edit.datetime.full"),
  }

  return (
    <>
      <TipBox>
        <InfoIcon />
        <Trans
          t={t}
          i18nKey="pages.task.edit.stages.legend.tip.legend"
          components={{
            next: (
              <>
                <br />
                <br />
              </>
            ),
          }}
          parent="p"
        />
      </TipBox>

      <TipBox>
        <InfoIcon />
        <p>{i18n.tipTests}</p>
      </TipBox>
    </>
  )
}

const TipBox = styled.div`
  background-color: var(--color-background);
  border-radius: 8px;
  transition: background-color var(--transition-duration)
    var(--transition-function);

  padding: 18px;
  display: flex;
  gap: 21px;

  > svg {
    flex: none;

    path {
      fill: var(--color-text);
      opacity: 0.5;
      transition: fill var(--transition-duration) var(--transition-function);
    }
  }

  p {
    margin: 0;
    ${text14Medium};

    opacity: 0.5;
    color: var(--color-text);
    transition: color var(--transition-duration) var(--transition-function);
  }
`

import styled from "styled-components"

import { text14Medium, text16Medium } from "@/utils/fonts"
import mediaQueryFor from "@/utils/mediaQuery"

const StatementTitle = styled.span`
  ${text16Medium};
  color: var(--color-text);
  transition: color var(--transition-duration) var(--transition-function);

  ${mediaQueryFor.mobile} {
    ${text14Medium};
  }
`

export default StatementTitle

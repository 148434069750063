import uniqBy from "lodash/uniqBy"

import baseApi from "@/store/api/base"
import { withQueryParams } from "@/store/api/helpers"

import {
  IContest,
  IGetManagedParams,
  IGetParticipatedParams,
  IManagedList,
  IParticipatedList,
  IRawManagedList,
  IRawParticipatedList,
  IUpcomingContestsList,
} from "./types"

const contestsApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getAvailableContests: builder.query<IUpcomingContestsList, void>({
      query: () => "/contests/getAvailable",
      providesTags: ["Localized", { type: "AvailableContest", id: "LIST" }],
    }),

    getParticipated: builder.query<IParticipatedList, IGetParticipatedParams>({
      query: ({ offset }) =>
        withQueryParams({
          url: "/contests/getParticipated",
          params: { offset },
        }),

      transformResponse: (data: IRawParticipatedList) => {
        return { ...data, received_at: extractReceivedAt(data) }
      },

      providesTags: ["Localized", { type: "ParticipatedContest", id: "LIST" }],

      serializeQueryArgs: ({ endpointName }) => endpointName,
      merge: (current, next) => {
        return {
          count: next.count,
          contests: uniqBy([...current.contests, ...next.contests], "id"),
          received_at: { ...current.received_at, ...next.received_at },
        }
      },

      forceRefetch: ({ previousArg, currentArg }) =>
        previousArg?.offset !== currentArg?.offset,

      keepUnusedDataFor: 600,
    }),

    getManaged: builder.query<IManagedList, IGetManagedParams>({
      query: ({ offset }) =>
        withQueryParams({
          url: "/contests/getManaged",
          params: { offset },
        }),

      transformResponse: (data: IRawManagedList) => {
        return { ...data, received_at: extractReceivedAt(data) }
      },

      serializeQueryArgs: ({ endpointName }) => endpointName,
      merge: (current, next) => {
        return {
          remaining_quota: next.remaining_quota,
          count: next.count,
          contests: uniqBy([...current.contests, ...next.contests], "id"),
          received_at: { ...current.received_at, ...next.received_at },
        }
      },
      forceRefetch: ({ previousArg, currentArg }) =>
        previousArg?.offset !== currentArg?.offset,

      providesTags: ["Localized", { type: "ManagedContest", id: "LIST" }],
    }),

    refetchContests: builder.mutation<null, void>({
      queryFn: () => ({ data: null }),
      invalidatesTags: ["AvailableContest", "ManagedContest"],
    }),
  }),
})

const extractReceivedAt = (data: {
  contests: IContest[]
}): Record<number, number> => {
  const now = Date.now()

  return data.contests.reduce(
    // eslint-disable-next-line no-param-reassign
    (acc, { id }) => ((acc[id] = now), acc),
    {} as Record<number, number>
  )
}

export default contestsApi
export const {
  useGetAvailableContestsQuery,
  useGetParticipatedQuery,
  useGetManagedQuery,
  useRefetchContestsMutation,
} = contestsApi

import { useReducer } from "react"

import produce from "immer"

import { SortOrder } from "@/features/task/list/types"

export default function useSortOrder({
  sortOrder: initialSortOrder = SortOrder.ByInterest,
  isReversed: initialIsReserved = false,
}: IReducerState) {
  const [{ sortOrder, isReversed }, dispatch] = useReducer(reducer, {
    sortOrder: initialSortOrder,
    isReversed: initialIsReserved,
  })

  return {
    sortOrder,
    isReversed,
    dispatch,
  }
}

export interface IReducerState {
  sortOrder: SortOrder
  isReversed: boolean
}

export interface IReducerAction {
  type: "setSortOrder" | "toggleReversed"
  payload?: SortOrder
}

function reducer(state: IReducerState, action: IReducerAction): IReducerState {
  /* eslint-disable no-param-reassign */

  switch (action.type) {
    case "setSortOrder":
      return produce(state, draft => {
        draft.sortOrder = action.payload!
      })
    case "toggleReversed":
      return produce(state, draft => {
        draft.isReversed = !state.isReversed
      })
  }

  /* eslint-enable no-param-reassign */
}

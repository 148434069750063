import { ChangeEventHandler } from "react"

import styled from "styled-components"

interface IRadioInputProps {
  checked: boolean
  onChange: ChangeEventHandler<HTMLInputElement>
  value?: string
  className?: string
  children?: React.ReactNode
}

export default function RadioInput({
  checked,
  onChange,
  value = "",
  className = "",
  children = null,
}: IRadioInputProps) {
  return (
    <Label className={className}>
      <input type="radio" checked={checked} onChange={onChange} value={value} />
      <Radio />
      {children}
    </Label>
  )
}

const Label = styled.label`
  > input {
    visibility: hidden;
    opacity: 0;
    position: absolute;
  }
`

const Radio = styled.span`
  cursor: pointer;
  background-color: var(--color-background);
  border: 1px solid var(--color-input-stroke);
  border-radius: 50%;
  transition: border var(--transition-duration) var(--transition-function),
    background-color var(--transition-duration) var(--transition-function);

  display: inline-block;
  width: 20px;
  height: 20px;
  box-sizing: border-box;

  input:checked + & {
    background-color: var(--color-primary);
    border-color: var(--color-primary);

    &::after {
      background-color: #ffffff;
    }
  }

  &::after {
    border-radius: 50%;
    background-color: var(--color-background);
    transition: background-color var(--transition-duration)
      var(--transition-function);

    content: "";
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: block;
    width: 6px;
    height: 6px;
  }
`

import { useMemo } from "react"

import { DateTime, DateTimeFormatOptions } from "luxon"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

import { ThemeT } from "@/store/slices/common/types"

import { IUserAward } from "@/store/api/users/types"

import AwardCup from "~/assets/images/award/Cup"
import AwardGoldMedal from "~/assets/images/award/GoldMedal"
import AwardSilverMedal from "~/assets/images/award/SilverMedal"

import { useTheme } from "@/store/hooks"
import { text10, text12, text12Medium } from "@/utils/fonts"
import mediaQueryFor from "@/utils/mediaQuery"

interface IAwardRecordProps {
  award: IUserAward
}

export default function AwardRecord({
  award: { type, title, date: rawDate },
}: IAwardRecordProps) {
  const theme = useTheme()
  const { i18n } = useTranslation()

  const Icon = useMemo(() => getAwardIcon(type), [type])

  const date = useMemo(
    () =>
      DateTime.fromSeconds(rawDate)
        .setLocale(i18n.resolvedLanguage)
        .toFormat("LLLL yyyy"),
    [rawDate, i18n.resolvedLanguage]
  )

  return (
    <Wrapper $theme={theme} $kind={type}>
      <Icon />
      <Title>{title}</Title>
      <Subtitle>{date}</Subtitle>
    </Wrapper>
  )
}

function getAwardIcon(kind: number) {
  switch (kind) {
    case 2:
      return AwardCup
    case 3:
      return AwardGoldMedal
    case 4:
      return AwardSilverMedal

    default:
      return AwardSilverMedal
  }
}

const AwardBackground = {
  light: ["unset", "unset", "#FFE7C5", "#FFE7C5", "#DAF0FF"],
  dark: ["unset", "unset", "#462C09", "#462C09", "#15242F"],
}

const AwardBorder = (kind: number, theme: ThemeT) =>
  kind !== 2
    ? "unset"
    : theme === "dark"
    ? "1px solid #BE842E"
    : "1px solid #FFB13F"

const Wrapper = styled.div<{ $kind: number; $theme: ThemeT }>`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: max-content;

  padding: 15px 6px;

  gap: 7px;

  border: ${({ $kind, $theme }) => AwardBorder($kind, $theme)};
  background-color: ${({ $kind, $theme }) => AwardBackground[$theme][$kind]};

  box-sizing: border-box;

  border-radius: 10px;

  ${mediaQueryFor.mobile} {
    padding: 16px 6px 16px 16px;

    display: grid;
    grid-template-rows: max-content max-content;
    grid-template-columns: 30px 1fr;
    justify-items: start;

    row-gap: 2px;
    column-gap: 18px;

    > svg {
      width: 30px;
      height: 30px;
      grid-area: 1 / 1 / 3 / 2;
    }
  }
`

const Title = styled.span`
  ${text12};
  text-align: center;

  ${mediaQueryFor.mobile} {
    text-align: start;
    ${text12Medium};
  }
`

const Subtitle = styled.span`
  ${text10};
  text-align: center;

  opacity: 0.5;
  text-transform: capitalize;
`

import { createAsyncThunk } from "@reduxjs/toolkit"
import { i18n } from "i18next"
import Cookies from "js-cookie"

import { setLanguage } from "@/store/slices/common"

import baseApi from "@/store/api/base"

import { supportedLanguages, SupportedLanguagesT } from "~/translations/utils"

import { LOCALE_COOKIE } from "@/utils/i18n"

interface ISwitchLanguageArgs {
  i18n: i18n
  lang?: SupportedLanguagesT
  next?: true
}

// eslint-disable-next-line import/prefer-default-export
export const switchLanguage = createAsyncThunk<void, ISwitchLanguageArgs>(
  "common/setLanguage",
  async ({ i18n, lang: targetLang }, { dispatch }) => {
    const resolved = i18n.resolvedLanguage as SupportedLanguagesT

    const nextLanguage =
      targetLang ??
      supportedLanguages.find(lng => lng != resolved) ??
      supportedLanguages[0]

    i18n.changeLanguage(nextLanguage)

    if (!process.env.SSR) Cookies.set(LOCALE_COOKIE, nextLanguage)

    await dispatch(setLanguage(nextLanguage))
    await dispatch(baseApi.util.invalidateTags(["Localized"]))
  }
)

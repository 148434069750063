import { useCallback, useState } from "react"

import { Helmet } from "react-helmet-async"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import styled from "styled-components"

import { useGetAdminsQuery } from "@/store/api/problems/edit/admins"
import { AdminLevel } from "@/store/api/problems/types"

import Loader from "@/components/Loader"
import useIsEditable from "@/features/task/hooks/useIsEditable"
import { header30, text16Medium } from "@/utils/fonts"

import AdminList from "./AdminList"
import AdminSearchBar from "./AdminSearchBar"
import EditLevelModal from "./EditLevelModal"
import useAdminList from "./hooks/useAdminList"
import useAdminModal from "./hooks/useAdminModal"

export default function Access() {
  const { problemId = "" } = useParams()

  const { t } = useTranslation()

  const i18n = {
    title: t("pages.task.edit.stages.access.title"),
    listHeader: t("pages.task.edit.stages.access.admins"),
  }

  const { isEditable } = useIsEditable(AdminLevel.Admin)

  const { modalUser, showModal, closeModal } = useAdminModal(isEditable)

  const { isFetching } = useGetAdminsQuery(problemId)
  const { admins, adminIds, setAdmin, removeAdmin } = useAdminList(problemId)

  const [query, setQuery] = useState("")

  const saveAdmin = useCallback(
    async (level: AdminLevel) => {
      if (modalUser) {
        const result = await setAdmin({ id: modalUser.id, level })
        if ("data" in result && !adminIds.has(modalUser.id)) setQuery("")
      }
    },
    [modalUser, setAdmin, adminIds]
  )

  const deleteAdmin = useCallback(async () => {
    if (modalUser) {
      const result = await removeAdmin({ id: modalUser.id })
      if ("data" in result && !adminIds.has(modalUser.id)) setQuery("")
    }
  }, [adminIds, modalUser, removeAdmin])

  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href={`https://sort-me.org/problems/${problemId}/edit/access`}
        />
      </Helmet>

      <Loader size="large" loading={isFetching}>
        <Wrapper>
          <Title>{i18n.title}</Title>
          <ListHeader>{i18n.listHeader}</ListHeader>
          <AdminList admins={admins} onItemClick={showModal} />
          {isEditable && (
            <CustomSearchBar
              query={query}
              setQuery={setQuery}
              existingAdminIds={adminIds}
              showModal={showModal}
            />
          )}
        </Wrapper>
      </Loader>

      <EditLevelModal
        close={closeModal}
        user={modalUser}
        save={saveAdmin}
        delete={deleteAdmin}
      />
    </>
  )
}

const Wrapper = styled.div`
  grid-area: main;

  padding: 35px 36px;
  box-sizing: border-box;

  height: calc(var(--window-h) - var(--shift-y));
  max-width: calc(1200px - 2 * var(--shift-x));

  overflow: hidden overlay;
`

const Title = styled.h1`
  margin: 0 0 48px 0;
  ${header30}
`

const ListHeader = styled.p`
  margin: 0 0 20px 0;
  ${text16Medium};
`

const CustomSearchBar = styled(AdminSearchBar)`
  max-width: 400px;
  margin-top: 12px;
`

import { ReactEventHandler, useCallback } from "react"

import styled from "styled-components"

interface IAvatarProps {
  src: string
  width?: number
  height?: number
}

const fallbackAvatar = "/assets/icons/avatar.svg"

export default function Avatar({
  src,
  width = 100,
  height = 100,
}: IAvatarProps) {
  const onImageLoadFailed = useCallback<ReactEventHandler<HTMLImageElement>>(
    event => {
      // eslint-disable-next-line no-param-reassign
      event.currentTarget.src = fallbackAvatar
    },
    []
  )

  return (
    <Picture width={width} height={height}>
      <img src={src} onError={onImageLoadFailed} />
    </Picture>
  )
}

const Picture = styled.picture<{ width: number; height: number }>`
  &,
  & > img {
    width: ${({ width }) => width}px;
    height: ${({ height }) => height}px;
    border-radius: 50%;
  }
`

import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import styled from "styled-components"

import { AdminLevel } from "@/store/api/problems/types"

import KeyIcon from "~/assets/icons/KeyIcon"

import { text14Medium } from "@/utils/fonts"

interface EditorEntrypointProps {
  level: AdminLevel
}

export default function EditorEntrypoint({ level }: EditorEntrypointProps) {
  const { t } = useTranslation()

  const i18n = {
    admin: t(PromptMap[level]),
    edit: t("pages.task.editor_entrypoint.edit"),
    view: t("pages.task.editor_entrypoint.view"),
  }

  return (
    <Wrapper>
      <KeyIcon />
      <Level>{i18n.admin}</Level>
      <StyledLink to="edit">
        {level >= AdminLevel.Editor ? i18n.edit : i18n.view}
      </StyledLink>
    </Wrapper>
  )
}

const PromptMap: Record<AdminLevel, string> = {
  [AdminLevel.None]: "",
  [AdminLevel.JustLook]: "pages.task.editor_entrypoint.prompt.just_look",
  [AdminLevel.Editor]: "pages.task.editor_entrypoint.prompt.editor",
  [AdminLevel.Admin]: "pages.task.editor_entrypoint.prompt.admin",
  [AdminLevel.Creator]: "pages.task.editor_entrypoint.prompt.creator",
}

const Wrapper = styled.div`
  background-color: var(--color-bubble);

  padding: 14px 18px;
  border-radius: 8px;

  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: start;

  gap: 16px;

  ${text14Medium};

  transition: background-color var(--transition-duration)
    var(--transition-function);

  > svg {
    width: 20px;
    height: 20px;
  }
`

const Level = styled.span`
  color: var(--color-text);
  transition: color var(--transition-duration) var(--transition-function);

  flex: 1 0 auto;
`

const StyledLink = styled(Link)`
  color: var(--color-primary);
  text-decoration: none;
`

import { skipToken } from "@reduxjs/toolkit/query"
import { Trans, useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import styled from "styled-components"

import { useGetProblemByIdQuery } from "@/store/api/problems"

import {
  text14,
  text16,
  text16Medium,
  text20,
  text20Medium,
  text24Medium,
} from "@/utils/fonts"
import mediaQueryFor from "@/utils/mediaQuery"

export default function SubtasksTotal({ earned }: { earned: number }) {
  const { t } = useTranslation()
  const { taskId } = useParams()

  const { totalAvailable } = useGetProblemByIdQuery(taskId ?? skipToken, {
    selectFromResult: ({ data }) => ({
      totalAvailable: data?.max_points ?? 0,
    }),
  })

  const i18n = {
    header: t("pages.task.submission_modal.result"),
    score: "pages.task.submission_modal.subtask.points",
  }

  if (!taskId) return null

  return (
    <TotalBlock>
      <h4>{i18n.header}</h4>

      <TotalPoints>
        <Trans
          t={t}
          i18nKey={i18n.score}
          values={{
            earned,
            total: totalAvailable,
          }}
          components={{
            accent: <b />,
          }}
        />
      </TotalPoints>
    </TotalBlock>
  )
}

const TotalBlock = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;

  gap: 17px;

  color: var(--color-text);

  h4 {
    ${text20};

    margin: 0;
  }

  ${mediaQueryFor.mobile} {
    gap: 16px;

    h4 {
      ${text16Medium};
    }
  }
`

const TotalPoints = styled.span`
  ${text16};

  line-height: 24px;

  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
  justify-content: center;

  b {
    ${text24Medium};
  }

  ${mediaQueryFor.mobile} {
    ${text14};

    b {
      ${text20Medium};
    }
  }
`

import { ChangeEventHandler, useCallback, useState } from "react"

import useDebounce from "@/utils/hooks/useDebounce"

const SEARCH_TIMEOUT = 100

export default function useSearchFilter(initialValue: string = "") {
  const [search, setSearch] = useState(initialValue)

  const debouncedSearch = useDebounce(search, SEARCH_TIMEOUT)

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    event => {
      setSearch(event.target.value)
    },
    []
  )

  return { search, handleChange, debouncedSearch }
}

import styled from "styled-components"

import { text12Medium, text10Medium } from "@/utils/fonts"
import mediaQueryFor from "@/utils/mediaQuery"

const ShowAllButton = styled.button`
  cursor: pointer;
  border: none;
  background-color: transparent;

  flex-shrink: 0;
  position: relative;

  display: flex;
  align-items: center;
  gap: 4px;
  padding: 0;
  width: max-content;

  ${text12Medium};
  color: var(--color-text);

  > span {
    opacity: 0.5;
    transition: color var(--transition-duration) var(--transition-function),
      opacity var(--transition-duration) var(--transition-function);
  }

  > svg {
    opacity: 0.5;
    transition: transform 0.2s ease-in-out,
      opacity var(--transition-duration) var(--transition-function);

    width: 16px;
    height: 16px;

    > path {
      fill: var(--color-text);
      transition: fill var(--transition-duration) var(--transition-function);
    }
  }

  &[data-open="true"] {
    color: var(--color-primary);
    align-self: flex-end;

    > span {
      opacity: 1;
    }

    > svg {
      opacity: 1;
      transform: rotateX(180deg);

      > path {
        fill: var(--color-primary);
      }
    }
  }

  ${mediaQueryFor.mobile} {
    ${text10Medium};

    > svg {
      width: 10px;
      height: 10px;
    }
  }
`

export default ShowAllButton

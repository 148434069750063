import { useTranslation } from "react-i18next"
import styled from "styled-components"

import DisappointedEmoji from "~/assets/icons/DisappointedEmoji"

import LoginContainer from "@/components/LoginContainer"
import { useAppSelector } from "@/store"
import { header24, header30, text14Medium, text18Medium } from "@/utils/fonts"
import mediaQueryFor from "@/utils/mediaQuery"

export default function NotAllowed() {
  const { t } = useTranslation()

  const i18n = {
    header: t("pages.task.edit.not_allowed.header"),
    text: t("pages.task.edit.not_allowed.text"),
    unauthorized: t("pages.task.edit.not_allowed.unauthorized"),
    loginTitle: t("pages.task.edit.not_allowed.login_title"),
  }

  const isLoggedIn = useAppSelector(({ user }) => user.uid !== null)

  return (
    <NotAllowedWrapper>
      <DisappointedEmoji />
      <Header>{i18n.header}</Header>
      {isLoggedIn ? (
        <Text>{i18n.text}</Text>
      ) : (
        <>
          <Text>{i18n.unauthorized}</Text>
          <LoginContainer title={i18n.loginTitle} />
        </>
      )}
    </NotAllowedWrapper>
  )
}

const NotAllowedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 20px;

  // TODO: fix here
  height: calc(var(--window-h) - var(--shift-y) - 145px);
  text-align: center;

  ${mediaQueryFor.mobile} {
    height: calc(var(--window-h) - var(--shift-y) - 109px);
  }
`

const Header = styled.h2`
  margin: 10px 0 0 0;
  ${header30};

  ${mediaQueryFor.mobile} {
    max-width: 338px;
    ${header24};
  }
`

const Text = styled.p`
  max-width: 500px;
  margin: 0;
  ${text18Medium};

  > a {
    color: var(--color-primary);
    transition: color var(--transition-duration) var(--transition-function);
    text-decoration: underline;

    &:hover {
      color: #085fdb;
    }
  }

  ${mediaQueryFor.mobile} {
    max-width: 338px;
    ${text14Medium};
  }
`

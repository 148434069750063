import styled from "styled-components"

import { IUserDetails } from "@/store/api/users/types"

import Avatar from "@/components/Avatar"
import { header32, header48, text16Medium, text20Medium } from "@/utils/fonts"
import mediaQueryFor from "@/utils/mediaQuery"

export default function BasicUserInfo({ user }: { user: IUserDetails }) {
  return (
    <Wrapper>
      <Avatar src={user?.avatar} />
      <Login>{user?.handle}</Login>
      <Name>{user?.name}</Name>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  grid-area: basic-info;

  display: grid;
  grid-template-columns: 100px calc(100% - 100px - 32px);
  grid-template-rows: 3fr 2fr;

  margin: 40px 0px 52px 0px;

  color: var(--color-text);

  column-gap: 32px;

  & picture,
  & img {
    grid-row: 1 / 3;
    grid-column: 1 / 2;

    background-color: var(--color-background);
  }

  ${mediaQueryFor.mobile} {
    grid-template-columns: 100%;
    grid-template-rows: 100px repeat(2, max-content);

    justify-items: center;

    margin: 32px 0px;

    & picture,
    & img {
      grid-row: 1 / 2;
      grid-column: 1 / 2;
    }
  }
`

const Login = styled.span`
  grid-row: 1 / 2;
  grid-column: 2 / 3;
  display: inline-block;

  overflow-x: hidden;
  text-overflow: ellipsis;

  ${header48};

  ${mediaQueryFor.mobile} {
    grid-row: 2 / 3;
    grid-column: 1 / 2;

    ${header32};

    margin-top: 8px;
    margin-bottom: 4px;
  }
`

const Name = styled.span`
  grid-row: 2 / 3;
  grid-column: 2 / 3;

  ${text20Medium};

  ${mediaQueryFor.mobile} {
    grid-row: 3 / 4;
    grid-column: 1 / 2;

    ${text16Medium};
  }
`

import { useTranslation } from "react-i18next"
import styled from "styled-components"

import { IContestTimings } from "@/store/api/contests/types"

import LockIcon from "~/assets/icons/LockIcon"
import LongCheckmark from "~/assets/icons/LongCheckmark"

import CustomButton from "@/components/CustomButton"
import useContestStatus, { ContestStatus } from "@/shared/time/useContestStatus"
import useMoscowTime from "@/shared/time/useMoscowTime"
import { text14Medium, text18Bold, text18Medium } from "@/utils/fonts"

interface ParticipateButtonProps {
  timings: IContestTimings
  registered: boolean
}

export default function ParticipateButton({
  timings,
  registered,
}: ParticipateButtonProps) {
  const { t } = useTranslation()
  const status = useContestStatus(timings)

  const isRegistrationOngoing =
    timings.registration_start <= timings.now &&
    timings.now < timings.registration_end

  const registrationStartsAt = useMoscowTime(timings.registration_start)
  const registrationEndsAt = useMoscowTime(timings.registration_end)

  const i18n = {
    apply: t("pages.contest.description.participate_button.apply"),

    status: {
      waiting: t(
        "pages.contest.description.participate_button.registration.waiting",
        { time: registrationStartsAt }
      ),
      // Pending start
      pending: t(
        "pages.contest.description.participate_button.registration.pending",
        { time: registrationEndsAt }
      ),
      successful: t(
        "pages.contest.description.participate_button.registration.successful"
      ),
      unknown: t(
        "pages.contest.description.participate_button.status.unavailable"
      ),
    },

    subscript: {
      [ContestStatus.Registration]: t(
        "pages.contest.description.participate_button.status.pending"
      ),
      [ContestStatus.Pending]: t(
        "pages.contest.description.participate_button.status.pending"
      ),
      [ContestStatus.Running]: t(
        "pages.contest.description.participate_button.status.running"
      ),
      [ContestStatus.Ended]: t(
        "pages.contest.description.participate_button.status.ended"
      ),
    } as Record<ContestStatus, string>,
  }

  if (registered) {
    const subscript =
      i18n.subscript[status] ?? i18n.subscript[ContestStatus.Pending]

    return (
      <RegisteredStatus>
        <LongCheckmark />

        <span>{i18n.status.successful}</span>
        <span>{subscript}</span>
      </RegisteredStatus>
    )
  }

  if (isRegistrationOngoing)
    return (
      <ApplyStatus>
        <CustomButton data-type="primary">{i18n.apply}</CustomButton>
        <span>{i18n.status.pending}</span>
      </ApplyStatus>
    )

  switch (status) {
    case ContestStatus.Waiting:
      return <WaitingStatus>{i18n.status.waiting}</WaitingStatus>
    case ContestStatus.Ended:
      return <WaitingStatus>{i18n.subscript.ended}</WaitingStatus>

    default:
      return (
        <UnknownStatus>
          <LockIcon />
          {i18n.status.unknown}
        </UnknownStatus>
      )
  }
}

const WaitingStatus = styled.span`
  ${text18Medium};
  opacity: 0.5;
`

const UnknownStatus = styled.span`
  ${text14Medium};
  opacity: 0.5;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: start;

  gap: 8px;

  svg {
    width: 20px;
    height: 20px;

    fill: var(--color-text);
  }
`

const ApplyStatus = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: start;
  justify-content: start;

  gap: 12px;

  ${text14Medium};

  ${CustomButton} {
    ${text18Bold};

    width: 418px;
    height: 48px;
  }

  span {
    opacity: 0.5;
    color: var(--color-text);
  }
`

const RegisteredStatus = styled.div`
  ${text14Medium};

  display: grid;
  grid-template-columns: max-content max-content;
  grid-template-rows: 50% 50%;
  align-items: center;
  justify-items: start;

  column-gap: 22px;

  grid-template-areas:
    "checkmark ."
    "checkmark .";
  grid-auto-flow: row;

  color: var(--color-primary);

  > svg {
    grid-area: checkmark;

    width: 19px;
    height: 12.6px;

    stroke: var(--color-primary);
  }
`

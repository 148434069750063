import { ChangeEventHandler, useCallback, useRef } from "react"

import { useTranslation } from "react-i18next"
import styled from "styled-components"

import AddFileIcon from "~/assets/icons/AddFileIcon"

import { text13Medium, text16Medium } from "@/utils/fonts"
import { FileUploaderStateT } from "@/utils/hooks/useFileUploader"
import mediaQueryFor from "@/utils/mediaQuery"

interface IFileUploaderProps {
  state: FileUploaderStateT
  onChange: ChangeEventHandler<HTMLInputElement>
}

export default function FileUploader({ state, onChange }: IFileUploaderProps) {
  const { t } = useTranslation()

  const ref = useRef<HTMLInputElement>(null)

  const triggerUploadModal = useCallback(() => ref.current?.click(), [ref])

  const i18n = {
    idle: t("pages.task.editor.upload.idle"),
    defunct: t("pages.task.editor.upload.defunct"),
    hover: t("pages.task.editor.upload.hover"),
    loading: t("pages.task.editor.upload.loading"),
    errored: t("pages.task.editor.upload.errored"),
  }

  return (
    <Wrapper onClick={triggerUploadModal}>
      <hr />
      <AddFileIcon />
      <span>{i18n[state]}</span>
      <input type="file" ref={ref} onChange={onChange} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: absolute;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  gap: 15px;

  ${text16Medium};

  color: var(--color-text);

  transition: color var(--transition-duration) var(--transition-function);

  bottom: 26px;
  left: 26px;
  right: 26px;

  input {
    display: none;
  }

  & > span {
    max-width: 271px;
    height: 46px;

    opacity: 0.5;
    text-align: center;
  }

  & > hr {
    width: 100%;

    border: none;
    border-top: 1px solid var(--color-separator);

    transition: border-color var(--transition-duration)
      var(--transition-function);

    margin-bottom: 6px;
  }

  & > svg {
    path {
      opacity: 0.5;
      fill: var(--color-text);
      transition: fill var(--transition-duration) var(--transition-function);
    }
  }

  ${mediaQueryFor.mobile} {
    ${text13Medium};

    gap: 11px;
  }
`

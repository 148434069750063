import styled, { css } from "styled-components"

import mediaQueryFor from "@/utils/mediaQuery"

const LoginFlowTransition = css`
  &.switch-enter {
    opacity: 0;

    pointer-events: none;
  }

  &.switch-enter-active {
    transition: opacity 0.2s ease-in;

    opacity: 1;
  }

  &.switch-exit {
    opacity: 1;

    pointer-events: none;
  }

  &.switch-exit-active {
    transition: opacity 0.2s ease-out;

    opacity: 0;
  }
`

const LoginWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: start;
  justify-content: start;

  gap: 24px;

  ${LoginFlowTransition};

  ${mediaQueryFor.mobile} {
    gap: 16px;
  }
`

export default LoginWrapper

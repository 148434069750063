import { useCallback, useState } from "react"

import { useGetParticipatedQuery } from "@/store/api/contests"

export default function useParticipatedPagination() {
  const [offset, setOffset] = useState<number | undefined>(undefined)

  const {
    data: { contests = [], count = 0, received_at: receivedAt = null } = {},
    isFetching,
    isLoading,
  } = useGetParticipatedQuery({ offset })

  const fetchMore = useCallback(() => {
    setOffset(contests.at(-1)?.id)
  }, [contests])

  const isEmpty = contests.length === 0 || receivedAt === null
  const isExhausted = contests.length === count
  const isFetchingMore = isFetching && !isEmpty

  const isRefetching = isLoading || (isFetching && isEmpty)

  return {
    data: { contests, receivedAt },

    isLoading,
    isEmpty,
    isExhausted,
    isFetchingMore,
    isRefetching,

    fetchMore,
  }
}

import { Helmet } from "react-helmet-async"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

import { header30 } from "@/utils/fonts"

import SelectorGrid from "./SelectorGrid"
import SelectorSidebar from "./SelectorSidebar"

export default function TypeSelector() {
  const { t } = useTranslation()

  const i18n = {
    title: t("pages.task.create.selector.title"),
    header: t("pages.task.create.selector.header"),
  }

  return (
    <>
      <Helmet>
        <title>{i18n.title}</title>
        <link rel="canonical" href={"https://sort-me.org/problems/create"} />
      </Helmet>

      <SelectorSidebar />

      <Content>
        <Header>{i18n.header}</Header>
        <SelectorGrid />
      </Content>
    </>
  )
}

const Content = styled.div`
  grid-area: main;
  padding: 35px 36px;
  height: calc(var(--window-h) - var(--shift-y));
  box-sizing: border-box;
  overflow: hidden overlay;
`

const Header = styled.h1`
  margin: 0 0 40px 0;
  ${header30};
`

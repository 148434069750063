import styled from "styled-components"

import { text16Medium } from "@/utils/fonts"

interface IBaseInputProps {
  "data-errored"?: boolean
}

const BaseInput = styled.input<IBaseInputProps>`
  padding: 10px 13px;

  width: 272px;
  height: 41px;
  box-sizing: border-box;

  color: var(--color-text);
  ${text16Medium};

  background: var(--color-bubble);
  border: 1px solid var(--color-card-stroke);
  border-radius: 5px;
  transition: border-color var(--transition-duration) var(--transition-function),
    background-color var(--transition-duration) var(--transition-function),
    color var(--transition-duration) var(--transition-function),
    box-shadow var(--transition-duration) var(--transition-function);

  &[data-errored="true"] {
    background-color: #fb2a2a0d;
    border-color: #fb2a2a80;
  }

  &:focus {
    outline: none;
    box-shadow: 0px 0px 0 1px #c4c4c4;
    border: 1px solid #c4c4c4;
  }
`

export default BaseInput

import { Duration } from "luxon"
import { useTranslation } from "react-i18next"
import { useTimer } from "react-timer-hook"

interface ITimerProps {
  /** Number when the timer expires in miliseconds */
  expiryTimestamp: number
  onExpire?: () => void
}

export default function Timer({
  expiryTimestamp,
  onExpire = () => {},
}: ITimerProps) {
  const { t } = useTranslation()

  const i18n = {
    withDays: t("pages.contests.datetime.timer_with_days"),
  }

  const { seconds, minutes, hours, days } = useTimer({
    expiryTimestamp: new Date(expiryTimestamp),
    onExpire,
  })

  const duration = Duration.fromObject({ seconds, minutes, hours, days })

  return days ? (
    <>{duration.toFormat(i18n.withDays)}</>
  ) : (
    <>{duration.toFormat(hours ? "HH:mm:ss" : "mm:ss")}</>
  )
}

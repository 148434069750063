import React from "react"

import { Helmet } from "react-helmet-async"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import styled from "styled-components"

import usersApi, { useGetUserByIdQuery } from "@/store/api/users"

import Line from "@/components/Line"
import Loader from "@/components/Loader"
import AccountButtons from "@/features/profile/AccountButtons"
import BasicUserInfo from "@/features/profile/BasicUserInfo"
import Bio from "@/features/profile/Bio"
import ProfileActions from "@/features/profile/ProfileActions"
import AccountRegal from "@/features/profile/regal/AccountRegal"
import SignedUpDate from "@/features/profile/SignedUpDate"
import { useAppSelector } from "@/store"
import { text17Medium } from "@/utils/fonts"
import mediaQueryFor from "@/utils/mediaQuery"
import { IPageConfig } from "@/utils/types"

type IProfileProps = Record<"uid", string>

export const config: IPageConfig<IProfileProps> = {
  routes: { path: "/profile/:uid", Component: Profile },

  server: {
    async populate({ store, params: { uid } }) {
      await store.dispatch(usersApi.endpoints.getUserById.initiate(uid))

      await Promise.all(store.dispatch(usersApi.util.getRunningQueriesThunk()))
    },
  },

  client: {},

  layout: "normal",
}

function Profile() {
  const { t } = useTranslation()

  const ownUserId = useAppSelector(({ user }) => user.uid)
  const navigate = useNavigate()

  const { uid } = useParams()

  const { isFetching, data: user } = useGetUserByIdQuery(uid as string)

  const isSelf = user && String(ownUserId) === (uid as string)

  const i18n = {
    title: t("pages.profile.title"),
    back: t("pages.profile.back"),
  }

  return (
    <>
      <Helmet>
        <title>{i18n.title}</title>
        <link rel="canonical" href={`https://sort-me.org/profile/${uid}`} />
      </Helmet>

      <Loader size="large" loading={isFetching}>
        <Wrapper data-is-self={isSelf}>
          <BackButton onClick={() => navigate(-1)}>{i18n.back}</BackButton>

          {user && <BasicUserInfo user={user} />}
          {isSelf && <AccountButtons integrations={user?.integrations} />}

          {isSelf && <Line top={24} bottom={24} />}

          {user && <Bio bio={user.bio} />}
          {user && <SignedUpDate at={user.registered_at ?? 0} />}
          {isSelf && <ProfileActions />}
          {user && <AccountRegal user={user} />}
        </Wrapper>
      </Loader>
    </>
  )
}

const Wrapper = styled.div`
  width: calc(100% - 80px);

  display: grid;
  grid-template-areas:
    "back ."
    "basic-info ranks"
    "connect ranks"
    "line ranks"
    "bio ranks"
    "date ranks"
    "buttons ranks"
    ". ranks";

  grid-template-columns: 50% 50%;
  grid-template-rows: repeat(7, max-content) 1fr;

  column-gap: 80px;

  ${mediaQueryFor.mobile} {
    width: 100%;

    grid-template-columns: 100%;
    grid-template-rows: repeat(7, max-content);

    grid-template-areas:
      "back"
      "basic-info"
      "bio"
      "date"
      "ranks"
      "connect"
      "buttons";

    > hr {
      display: none;
    }
  }
`

const BackButton = styled.span`
  grid-area: back;
  ${text17Medium};

  color: var(--difficulty);

  cursor: pointer;
`

import { Fragment, useCallback } from "react"

import { DragDropContext, OnDragEndResponder } from "react-beautiful-dnd"
import { useParams } from "react-router-dom"
import styled from "styled-components"

import { moveTest } from "@/store/slices/tests"

import { useGetTestsQuery } from "@/store/api/problems/edit/tests"

import Loader from "@/components/Loader"
import StrictModeDroppable from "@/components/StrictModeDroppable"
import useSubtaskDistribution from "@/features/task/edit/tests/hooks/useSubtaskDistribution"
import SaveContainer from "@/features/task/edit/tests/TestsList/SaveContainer"
import { useAppDispatch } from "@/store"

import AddButton from "./AddButton"
import SubtaskSeparatorItem from "./SubtaskSeparatorItem"
import TestItem from "./TestItem"

interface ITestsListProps {
  search: string
}

export default function TestsList({ search }: ITestsListProps) {
  const { problemId = "" } = useParams()
  const dispatch = useAppDispatch()

  const { isFetching } = useGetTestsQuery(Number(problemId))

  const subtaskDistribution = useSubtaskDistribution(search)

  const handleDragEnd = useCallback<OnDragEndResponder>(
    result => {
      if (!result.destination) return
      const { source, destination } = result
      dispatch(
        moveTest({
          source: {
            subtask: Number(source.droppableId),
            index: source.index,
          },
          destination: {
            subtask: Number(destination.droppableId),
            index: destination.index,
          },
        }),
      )
    },
    [dispatch],
  )

  const hasMultipleSubtasks =
    !!subtaskDistribution && subtaskDistribution.length > 1
  const isMovable = !search
  const isLoading = isFetching || !subtaskDistribution

  return (
    <>
      <Wrapper>
        <Loader size="large" loading={isLoading}>
          <DragDropContext onDragEnd={handleDragEnd}>
            {subtaskDistribution?.map(
              ({ id, description, tests }, subtaskIndex) =>
                (!search || tests.length > 0) && (
                  <Fragment key={`header_${id}`}>
                    {hasMultipleSubtasks && (
                      <SubtaskSeparatorItem id={id} description={description} />
                    )}
                    <StrictModeDroppable
                      droppableId={`${subtaskIndex}`}
                      key={id}
                    >
                      {provided => (
                        <Subtask
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {tests.map(test => (
                            <TestItem
                              key={test.id}
                              test={test}
                              index={test.newId - 1}
                              isMovable={isMovable}
                            />
                          ))}
                          {provided.placeholder}
                        </Subtask>
                      )}
                    </StrictModeDroppable>
                  </Fragment>
                ),
            )}

            <AddButton />
          </DragDropContext>
        </Loader>
      </Wrapper>

      <SaveContainer distribution={subtaskDistribution} />
    </>
  )
}

const Wrapper = styled.div`
  grid-column: main;
  position: relative;
`

const Subtask = styled.div`
  width: 100%;
`

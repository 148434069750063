import { ChangeEventHandler, useCallback } from "react"

import { useTranslation } from "react-i18next"
import styled from "styled-components"

import { ProblemDifficulty } from "@/store/api/problems/types"

import CheckboxInput from "@/components/CheckboxInput"
import DifficultyIcon from "@/components/DifficultyIcon"
import { text14Medium, text16Medium } from "@/utils/fonts"

const checkboxes: Array<{
  value: ProblemDifficulty
  label: string
}> = [
  {
    value: ProblemDifficulty.easy,
    label: "pages.task.list.tabs.public.difficulty.easy",
  },
  {
    value: ProblemDifficulty.medium,
    label: "pages.task.list.tabs.public.difficulty.medium",
  },
  {
    value: ProblemDifficulty.hard,
    label: "pages.task.list.tabs.public.difficulty.difficult",
  },
  {
    value: ProblemDifficulty.difficult,
    label: "pages.task.list.tabs.public.difficulty.hard",
  },
  {
    value: ProblemDifficulty.impossible,
    label: "pages.task.list.tabs.public.difficulty.impossible",
  },
]

interface IDifficultyFilterProps {
  difficulties: ProblemDifficulty[]
  toggleDifficulty: (difficulty: ProblemDifficulty) => void
}

export default function DifficultyFilterSelector({
  difficulties,
  toggleDifficulty,
}: IDifficultyFilterProps) {
  const { t } = useTranslation()

  const i18n = {
    header: t("pages.task.list.tabs.public.difficulty.header"),
  }

  const handleDifficultyChange = useCallback<
    ChangeEventHandler<HTMLInputElement>
  >(
    event => {
      toggleDifficulty(Number(event.target.value))
    },
    [toggleDifficulty]
  )

  return (
    <Wrapper>
      <p>{i18n.header}</p>
      {checkboxes.map(({ value, label }) => (
        <CustomCheckboxInput
          key={value}
          value={value.toString()}
          checked={difficulties.includes(value)}
          onChange={handleDifficultyChange}
        >
          <DifficultyIcon rank={value} />
          {t(label)}
        </CustomCheckboxInput>
      ))}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;

  > p {
    margin: 0;

    ${text16Medium};
  }
`

const CustomCheckboxInput = styled(CheckboxInput)`
  display: flex;
  align-items: start;
  justify-content: flex-start;
  gap: 8px;

  ${text14Medium};

  > svg {
    margin-left: 4px;
  }
`

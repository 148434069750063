import { useMemo } from "react"

import { useGetTagsQuery } from "@/store/api/problems"
import { useGetManagedProblemsQuery } from "@/store/api/problems/create"
import { ManagedProblemMarker } from "@/store/api/problems/create/types"

import getProblemName from "@/features/task/list/utils/getProblemName"

export interface ICategoryCountFilterProps {
  search: string
  states: ManagedProblemMarker[]
  isUsedInContests: boolean
  creatorIds: number[]
}

export default function useCategoriesCount(
  filterProps: ICategoryCountFilterProps,
  selected: number[]
) {
  const { data: { problems: problemData } = {} } = useGetManagedProblemsQuery()
  const { data: { tags: categoryData } = {} } = useGetTagsQuery()

  const categories = useMemo(() => {
    if (!categoryData || !problemData) return []

    const { search, states, isUsedInContests, creatorIds } = filterProps

    const count = Object.fromEntries(
      categoryData.map(category => [category.id, 0])
    )

    for (const problem of problemData) {
      const isMatched = search
        ? getProblemName(problem.names)
            .toLowerCase()
            .includes(search.toLowerCase())
        : true

      if (
        isMatched &&
        (states.length === 0 ||
          (problem.marker && states.includes(problem.marker))) &&
        (isUsedInContests || problem.included_in_contests === 0) &&
        (creatorIds.length === 0 || creatorIds.includes(problem.creator.id))
      )
        for (const tag of problem.tags) count[tag]++
    }

    const result = categoryData
      .map(category => ({
        ...category,
        problemAmount: count[category.id],
      }))
      .filter(
        category => category.problemAmount > 0 || selected.includes(category.id)
      )

    result.sort((a, b) => b.problemAmount - a.problemAmount)
    return result
  }, [categoryData, problemData, filterProps, selected])

  return categories
}

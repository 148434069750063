import { useTranslation } from "react-i18next"
import styled from "styled-components"

import { text13Medium } from "@/utils/fonts"

interface IProgressBarProps {
  progress: number
  className?: string
}

export function ProgressBar({ progress, className = "" }: IProgressBarProps) {
  return (
    <BarWrapper className={className}>
      <Progress progress={progress} />
    </BarWrapper>
  )
}

export default function ProgressBarWithLabel({ progress }: IProgressBarProps) {
  const { t } = useTranslation()

  const i18n = {
    percentage: t("pages.contests.public.archive_solved_percentage", {
      solved: progress,
    }),
  }

  return (
    <WithLabelWrapper>
      <Label data-filled={progress === 100}>{i18n.percentage}</Label>
      <ProgressBar progress={progress} />
    </WithLabelWrapper>
  )
}

const Progress = styled.div<{ progress: number }>`
  background-color: var(--color-green);
  border-radius: 4px;
  transition: background-color var(--transition-duration)
    var(--transition-function);

  width: ${({ progress }) => progress}%;
  height: 4px;
`

const BarWrapper = styled.div`
  background-color: var(--color-text-33);
  border-radius: 4px;
  transition: background-color var(--transition-duration)
    var(--transition-function);

  width: 100%;
  height: 4px;
`

const WithLabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 20px;
`

const Label = styled.p`
  opacity: 0.9;
  margin: 0;
  white-space: nowrap;
  ${text13Medium};

  &[data-filled="true"] {
    color: var(--color-green);
    transition: color var(--transition-duration) var(--transition-function);
  }
`

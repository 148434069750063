import { useRef, useState } from "react"

import { Helmet } from "react-helmet-async"
import { useTranslation } from "react-i18next"
import { Outlet, RouteObject, useParams } from "react-router-dom"
import styled from "styled-components"

import contestApi, { useGetContestByIdQuery } from "@/store/api/contest"
import { createQuerySelector } from "@/store/api/helpers"
import problemsApi from "@/store/api/problems"
import submissionsApi from "@/store/api/submissions"

import Loader from "@/components/Loader"
import ContestDescription from "@/features/contest/ContestDescription"
import Statement from "@/features/task/current/statement/Statement"
import SubmissionModal from "@/features/task/current/submission/SubmissionModal"
import SubmissionsList from "@/features/task/current/submission/SubmissionsList"
import useTranslatedProblem from "@/features/task/hooks/useTranslatedProblem"
import mediaQueryFor from "@/utils/mediaQuery"
import { IPageConfig } from "@/utils/types"
import ContestSidebar from "@/widgets/contest/ContestSidebar"

interface IContestProps extends Record<string, string> {
  contestId: string
}

interface IContestTaskProps extends IContestProps {
  problemId: string
}

const SupportedRoutes: RouteObject[] = [
  { index: true, Component: ContestHomePage },
  {
    path: ":problemId",
    Component: ContestTaskPage,
    children: [{ path: "s/:submissionId", Component: SubmissionModal }],
  },
]

export const config: IPageConfig<IContestProps> = {
  routes: {
    path: "/contest/:contestId",
    Component: ContestLayout,
    children: SupportedRoutes,
  },

  server: {
    async populate({ store, params: { problemId, contestId } }) {
      const { user } = store.getState()

      await store.dispatch(
        contestApi.endpoints.getContestById.initiate({ id: contestId }),
      )

      if (problemId) {
        await store.dispatch(
          problemsApi.endpoints.getProblemById.initiate({
            id: Number(problemId),
            lang: "ru",
          }),
        )
      }

      if (user.uid) {
        // If user is authenticated, load submissions
        await store.dispatch(
          submissionsApi.endpoints.getSubmissions.initiate({
            problem_id: problemId,
            user_id: String(user.uid),
          }),
        )
      }

      await Promise.all([
        ...store.dispatch(contestApi.util.getRunningQueriesThunk()),
        ...store.dispatch(problemsApi.util.getRunningQueriesThunk()),
        ...store.dispatch(submissionsApi.util.getRunningQueriesThunk()),
      ])
    },
  },

  client: {},

  layout: "own",
}

function ContestLayout() {
  const { contestId } = useParams<"contestId">()

  return (
    <Wrapper>
      <ContestSidebar contestId={contestId!} />

      <Outlet />
    </Wrapper>
  )
}

function ContestHomePage() {
  const { t } = useTranslation()

  const { contestId } = useParams<IContestProps>()

  const { isFetching, name } = useGetContestByIdQuery(
    { id: contestId! },
    { selectFromResult: selectFromContest },
  )

  const i18n = {
    title: t("pages.contest.description.title", {
      name: name ?? t("pages.contest.description.default_title"),
    }),
  }

  return (
    <>
      <Helmet>
        <title>{i18n.title}</title>
        <link
          rel="canonical"
          href={`https://sort-me.org/contest/${contestId}`}
        />
      </Helmet>

      <Loader size="large" loading={isFetching}>
        {!isFetching && <ContestDescription contestId={contestId!} />}
      </Loader>
    </>
  )
}

function ContestTaskPage() {
  const { t } = useTranslation()
  const { contestId, taskId } = useParams<IContestTaskProps>()

  const [forcedLanguage, forceLanguage] = useState<string | null>(null)

  const contentRef = useRef<HTMLDivElement>(null)

  const { selected, isFetching } = useTranslatedProblem(1, forcedLanguage)

  const i18n = {
    title: t("pages.task.title", {
      name: selected?.name ?? t("pages.task.default_title"),
    }),
  }

  return (
    <>
      <Helmet>
        <title>{i18n.title}</title>
        <link
          rel="canonical"
          href={`https://sort-me.org/contest/${contestId}/${taskId}`}
        />
      </Helmet>

      <Loader loading={isFetching} size="large" suspend={true}>
        {!isFetching && (
          <Content ref={contentRef}>
            <Statement
              scrollRef={contentRef}
              problem={selected}
              setLanguage={forceLanguage}
            />

            <SubmissionsList taskId={"1"} />
          </Content>
        )}
      </Loader>

      <Outlet />
    </>
  )
}

const selectFromContest = createQuerySelector(
  useGetContestByIdQuery,
  ({ isFetching, data }) => ({ isFetching, name: data?.name ?? null }),
)

const Wrapper = styled.div`
  display: grid;
  grid-template-areas: "sidebar main";
  grid-template-rows: max-content;
  grid-template-columns: 238px 1fr;

  padding-bottom: 60px;

  div[aria-label="animation"] {
    grid-area: main;
  }

  ${mediaQueryFor.mobile} {
    grid-template-areas: "main";
    grid-template-columns: 100%;
  }
`

const Content = styled.div`
  grid-area: main;

  display: grid;
  grid-template-areas: "statement submissions";
  grid-template-rows: max-content;
  grid-template-columns: 60% 40%;

  height: calc(var(--window-h) - var(--shift-y));
  max-width: calc(1200px - 2 * var(--shift-x));

  overflow-y: overlay;
  overflow-x: hidden;

  ${mediaQueryFor.mobile} {
    grid-template-areas:
      "statement"
      "submissions";
    grid-template-columns: 100%;
  }
`

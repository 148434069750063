import { createApi } from "@reduxjs/toolkit/query/react"

import { sortMeBaseQuery } from "@/store/api/helpers"

const baseApi = createApi({
  reducerPath: "api",
  baseQuery: sortMeBaseQuery,
  tagTypes: [
    "Localized",
    "Problem",
    "Submission",
    "Contest",
    "AvailableContest",
    "ParticipatedContest",
    "ManagedContest",
    "Archive",
    "Test",
  ],
  endpoints: () => ({}),
})

export default baseApi

import { useCallback, useRef, useState } from "react"

import { usePolygonImportMutation } from "@/store/api/problems/create"

import useFileUploader from "@/utils/hooks/useFileUploader"

const MAX_UPLOAD_SIZE = 100 // MB

const ZIP_MIME_TYPES = [
  "application/zip",
  "application/octet-stream",
  "application/x-zip-compressed",
  "multipart/x-zip",
]

export default function useZipArchiveUploader() {
  const [uploadProgress, setUploadProgress] = useState(0)
  const socketId = useRef<number | null>(null)

  const [triggerUpload] = usePolygonImportMutation()

  const uploadFile = useCallback(
    async (file: File) => {
      setUploadProgress(0)

      if (file.size / 1024 ** 2 > MAX_UPLOAD_SIZE)
        throw new Error(
          "pages.task.create.polygon_import.upload.errors.too_big"
        )

      if (!ZIP_MIME_TYPES.includes(file.type))
        throw new Error(
          "pages.task.create.polygon_import.upload.errors.invalid_type"
        )

      const result = await triggerUpload({
        file,
        callback: progress => setUploadProgress(progress),
      })

      // TODO: remove. For demonstration purposes only
      await new Promise(resolve => setTimeout(resolve, 3000))

      if ("error" in result)
        throw new Error(
          "pages.task.create.polygon_import.upload.errors.unexpected"
        )

      socketId.current = result.data
    },
    [triggerUpload]
  )

  const fileUploader = useFileUploader({ uploadFile })

  return {
    fileUploader,
    uploadProgress,
    socketId: socketId.current,
  }
}

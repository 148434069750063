import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { getClientSavedLocale, SupportedLanguagesT } from "~/translations/utils"

import { MediaT, queryMedia } from "@/utils/mediaQuery"

import { getTheme, saveTheme } from "./theme"
import { ICommonSlice } from "./types"

const initialState: ICommonSlice = {
  theme: getTheme(),
  media: queryMedia(),

  layout: "normal",
  lang: getClientSavedLocale(),

  hydrated: false,
}

const commonSlice = createSlice({
  name: "common",
  initialState: initialState,
  reducers: {
    setMedia(state, { payload }: PayloadAction<MediaT>) {
      state.media = payload
    },
    setLayout(state, { payload }: PayloadAction<ICommonSlice["layout"]>) {
      state.layout = payload
    },
    setLanguage(state, { payload }: PayloadAction<SupportedLanguagesT>) {
      state.lang = payload
    },
  },
  extraReducers: builder => {
    builder.addCase(saveTheme.pending, (state, { meta }) => {
      state.theme = meta.arg
    })
  },
})

export const { setMedia, setLayout, setLanguage } = commonSlice.actions

export default commonSlice.reducer

import { useGetAvailableContestsQuery } from "@/store/api/contests"

import ContestLoader from "@/features/contest/loaders/ContestLoader"
import UpcomingContainer from "@/features/contest/UpcomingContainer"

export default function Upcoming() {
  let { isFetching, contests } = useGetAvailableContestsQuery(undefined, {
    selectFromResult: ({ data, isFetching, fulfilledTimeStamp }) => ({
      isFetching,
      contests: data?.contests.map(contest => ({
        ...contest,
        requestFulfilledAt: fulfilledTimeStamp!,
      })),
    }),
  })

  return (
    <ContestLoader loading={isFetching}>
      <UpcomingContainer contests={contests ?? []} />
    </ContestLoader>
  )
}

import { Outlet } from "react-router-dom"
import styled from "styled-components"

import TypeSelector from "@/features/task/creation/selector/TypeSelector"
import FromScratch from "@/features/task/creation/variations/FromScratch"
import PolygonImport from "@/features/task/creation/variations/PolygonImport"
import mediaQueryFor from "@/utils/mediaQuery"
import { IPageConfig } from "@/utils/types"

export const config: IPageConfig = {
  routes: {
    path: "/problems/create/*",
    Component: ProblemCreation,
    children: [
      { index: true, Component: TypeSelector },
      { path: "new", Component: FromScratch },
      { path: "polygon-import", Component: PolygonImport },
    ],
  },

  server: {},
  client: {},

  layout: "own",
}

function ProblemCreation() {
  return (
    <Wrapper>
      <Outlet />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: grid;
  grid-template-areas: "sidebar main";
  grid-template-rows: max-content;
  grid-template-columns: 238px 1fr;

  ${mediaQueryFor.mobile} {
    grid-template-areas: "main";
    grid-template-columns: 100%;
  }
`

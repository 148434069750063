import { useMemo } from "react"

import { ISubtaskDetails } from "@/features/task/edit/tests/types"

interface IChangesDescription {
  orderChanged: boolean
  subtaskChanged: boolean
  highestMovedTestId: number | null
}

export default function useTestChanges(distribution: ISubtaskDetails[]) {
  return useMemo(() => {
    const defaultResult: IChangesDescription = {
      highestMovedTestId: null,
      orderChanged: false,
      subtaskChanged: false,
    }
    /* eslint-disable no-param-reassign */
    return (
      distribution.reduce((acc, { id, tests }) => {
        tests.forEach(test => {
          if (test.subtask !== test.originalSubtask) acc.subtaskChanged = true
          if (test.id > test.newId) acc.orderChanged = true
          if (
            !acc.highestMovedTestId &&
            (test.originalSubtask !== id || test.id > test.newId)
          )
            acc.highestMovedTestId = test.id
        })
        return acc
      }, defaultResult) ?? defaultResult
    )
    /* eslint-enable no-param-reassign */
  }, [distribution])
}

import styled from "styled-components"

const SelectorGridItem = styled.div`
  cursor: pointer;
  background-color: var(--color-bubble);
  border-radius: 10px;
  transition: transform 0.15s ease-out,
    box-shadow var(--transition-duration) var(--transition-function),
    background-color var(--transition-duration) var(--transition-function);

  width: 400px;
  height: 166px;
  padding: 27px;
  box-sizing: border-box;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 12px;

  &[data-disabled="true"] {
    cursor: auto;

    > * {
      opacity: 0.3;
    }
  }

  &[data-disabled="false"] {
    &:hover {
      box-shadow: inset 0 0 0 1px var(--color-card-stroke),
        0px 4px 20px var(--color-g-background-stroke);
      transform: translate(0, -4px);
    }
  }
`

export default SelectorGridItem

import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react"

import TextareaAutoresize from "react-textarea-autosize"
import styled from "styled-components"

import { text16 } from "@/utils/fonts"

import StatementRenderer from "./StatementRenderer"

interface IMarkdownInputProps {
  value: string
  onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void
  isEditable?: boolean
  className?: string
  children?: React.ReactNode
}

export default function MarkdownInput({
  value,
  onChange,
  isEditable = true,
  children = null,
  className = "",
}: IMarkdownInputProps) {
  const [isFocused, setIsFocused] = useState(false)

  const textareaRef = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    const textarea = textareaRef.current
    if (isFocused) {
      textarea?.setSelectionRange(
        textarea?.value.length,
        textarea?.value.length
      )
      textarea?.focus()
    }
  }, [isFocused])

  const handleClick = useCallback(() => setIsFocused(true), [])

  const handleBlur = useCallback(() => setIsFocused(false), [])

  return (
    <Wrapper className={className} onClick={handleClick} onBlur={handleBlur}>
      {!isFocused && !value ? (
        children
      ) : isFocused && isEditable ? (
        <TextareaAutoresize
          ref={textareaRef}
          value={value}
          onChange={onChange}
          spellCheck={false}
        />
      ) : (
        <StatementRenderer>{value}</StatementRenderer>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  > textarea {
    border: none;
    outline: none;
    background-color: transparent;

    resize: none;
    width: 100%;
    padding: 0;

    color: var(--color-text);
    ${text16};
    text-align: justify;
  }
`

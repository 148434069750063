import { useMemo } from "react"

import { useTranslation } from "react-i18next"
import styled from "styled-components"

import { useGetTipQuery } from "@/store/api/misc"

import Checkmark from "~/assets/icons/Checkmark"
import ReloadIcon from "~/assets/icons/ReloadIcon"

import { ProgressBar } from "@/components/ProgressBar"
import { header36, text14Medium, text18Medium } from "@/utils/fonts"

interface IFileLoaderProps {
  uploadProgress: number
  parsingProgress: number
}

export default function ImportLoader({
  uploadProgress,
  parsingProgress,
}: IFileLoaderProps) {
  const { t } = useTranslation()

  const { data: { tip = "" } = {}, refetch: getNewTip } = useGetTipQuery()

  const i18n = {
    header: t("pages.task.edit.stages.tests.upload.importing.header"),
    getNewTip: t("pages.task.edit.stages.tests.upload.tip.get_new"),
    showTip: t("pages.task.edit.stages.tests.upload.tip.show", { tip }),
  }

  const progressBars = useMemo(
    () => [
      {
        progress: uploadProgress,
        text: "pages.task.edit.stages.tests.upload.importing.server_upload",
      },
      {
        progress: parsingProgress,
        text: "pages.task.edit.stages.tests.upload.importing.parsing",
      },
    ],
    [parsingProgress, uploadProgress]
  )

  return (
    <Wrapper>
      <Header>{i18n.header}</Header>
      <ProgressWrapper>
        {progressBars.map(({ progress, text }) => (
          <div key={text} data-completed={progress === 100}>
            <Checkmark />
            <span data-active={progress !== 0}>{t(text)}</span>
            <StyledProgressBar progress={progress} />
          </div>
        ))}
      </ProgressWrapper>
      <TipWrapper>
        <p>{i18n.showTip}</p>
        <span onClick={getNewTip}>
          <ReloadIcon />
          {i18n.getNewTip}
        </span>
      </TipWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  // TODO: fix here
  height: calc(var(--window-h) - var(--shift-y) - 70px);
  text-align: center;
`

const Header = styled.h1`
  margin: 0 0 32px 0;
  ${header36};
`

const ProgressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  margin-bottom: 64px;

  > div {
    transition: opacity var(--transition-duration) var(--transition-function);

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    position: relative;

    &[data-completed="true"] {
      opacity: 0.5;

      svg {
        display: block;
      }
    }

    > span {
      ${text14Medium};

      &[data-active="true"] {
        color: var(--color-primary);
      }
    }

    > svg {
      display: none;
      position: absolute;
      left: -34px;

      path {
        fill: var(--color-primary);
      }
    }
  }
`

const StyledProgressBar = styled(ProgressBar)`
  width: 246px;

  > div {
    background-color: var(--color-primary);
    transition: width 0.1s linear;
  }
`

const TipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 18px;

  > p {
    opacity: 0.5;
    ${text18Medium};

    display: flex;
    align-items: flex-end;
    min-height: 53px;
    margin: 0;
  }

  > span {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;

    gap: 15px;
    cursor: pointer;

    ${text18Medium};

    color: var(--color-primary);

    svg {
      path {
        fill: var(--color-primary);
      }
    }
  }
`

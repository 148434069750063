import React, {
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"

import { useTranslation } from "react-i18next"
import { Transition } from "react-transition-group"
import styled from "styled-components"

import DropdownIcon from "~/assets/icons/DropdownIcon"
import GlobeIcon from "~/assets/icons/GlobeIcon"
import PlusIcon from "~/assets/icons/PlusIcon"

import Line from "@/components/Line"
import {
  text11Medium,
  text12Medium,
  text14Medium,
  text15Medium,
  text16Medium,
} from "@/utils/fonts"
import { getLanguageTranslations } from "@/utils/languages"
import mediaQueryFor from "@/utils/mediaQuery"

import NewTranslationModal from "./NewTranslationModal"

interface CommonTranslationSelectorProps {
  translations: string[]
  selected: string

  onTranslationSelected: (code: string) => void

  className?: string
}

interface ReadableTranslationSelectorProps {
  isEditable: false
}

interface EditableTranslationSelectorProps {
  isEditable: true
  saved: string[]

  onTranslationAdded: (code: string) => void
}

type TranslationSelectorProps = CommonTranslationSelectorProps &
  (ReadableTranslationSelectorProps | EditableTranslationSelectorProps)

type PickPartialUnion<T> = Partial<Omit<T, keyof TranslationSelectorProps>>

type OwnTranslationSelectorProps = TranslationSelectorProps &
  PickPartialUnion<ReadableTranslationSelectorProps> &
  PickPartialUnion<EditableTranslationSelectorProps>

function TranslationSelector({
  translations,
  selected,
  onTranslationSelected,

  className = undefined,

  isEditable,
  saved = [],
  onTranslationAdded,
}: OwnTranslationSelectorProps) {
  const [isOpen, setIsOpen] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const wrapper = useRef<HTMLDivElement>(null)

  const { t } = useTranslation()

  const i18n = {
    translationHeader: isEditable
      ? t("pages.task.edit.stages.legend.translation.header")
      : t("pages.task.languages.available"),

    add: t("pages.task.edit.stages.legend.translation.add"),
  }

  useEffect(() => {
    if (isOpen) {
      const listener = () => setIsOpen(false)
      document.addEventListener("click", listener)

      return () => document.removeEventListener("click", listener)
    }
  }, [isOpen])

  const closeModal = useCallback(() => {
    setIsModalOpen(false)
  }, [])

  const toggleOpen: MouseEventHandler<HTMLButtonElement> = useCallback(
    event => {
      event.stopPropagation()

      setIsOpen(open => !open)
    },
    []
  )

  const handleSelect = useCallback<MouseEventHandler<HTMLElement>>(
    event => {
      event.stopPropagation()

      const code = event.currentTarget.dataset["code"]!

      onTranslationSelected(code)
      setIsOpen(false)
    },
    [onTranslationSelected]
  )

  const handleAddClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    event => {
      event.stopPropagation()

      setIsModalOpen(true)
    },
    []
  )

  const handleNewTranslation = useCallback(
    (code: string) => {
      onTranslationAdded?.(code)
      setIsOpen(false)
    },
    [onTranslationAdded]
  )

  return (
    <Wrapper className={className}>
      <OpenButton onClick={toggleOpen}>
        <GlobeIcon />
        <span>{getLanguageTranslations(t, selected).name}</span>
      </OpenButton>

      <Transition in={isOpen} timeout={300} nodeRef={wrapper}>
        {state => (
          <ListWrapper data-open={state} ref={wrapper}>
            <TranslationHeader>
              <GlobeIcon />
              {i18n.translationHeader}
            </TranslationHeader>

            {translations.map(code => (
              <TranslationSelectorLanguage
                key={code}
                code={code}
                isSelected={selected === code}
                isSaved={saved.includes(code) || !isEditable}
                onClick={handleSelect}
              />
            ))}

            {isEditable && (
              <>
                <Line />

                <AddButton onClick={handleAddClick}>
                  <PlusIcon />
                  {i18n.add}
                </AddButton>
              </>
            )}
          </ListWrapper>
        )}
      </Transition>

      <NewTranslationModal
        isVisible={isModalOpen}
        close={closeModal}
        translations={new Set(Object.keys(translations))}
        addTranslation={handleNewTranslation}
      />
    </Wrapper>
  )
}

interface TranslationSelectorLanguageProps {
  code: string
  isSelected: boolean
  isSaved: boolean
  onClick: MouseEventHandler<HTMLElement>
}

function TranslationSelectorLanguage({
  code,
  isSelected,
  isSaved,

  onClick,
}: TranslationSelectorLanguageProps) {
  const { t } = useTranslation()

  const { name, native } = useMemo(
    () => getLanguageTranslations(t, code),
    [t, code]
  )

  const i18n = {
    notSaved: t("pages.task.edit.stages.legend.translation.not_saved"),
  }

  return (
    <TranslationItem
      data-code={code}
      data-selected={isSelected}
      onClick={onClick}
    >
      <DropdownIcon />

      <Language>{name}</Language>
      <NativeLanguage>{native}</NativeLanguage>

      {!isSaved && <SaveIndicator>{i18n.notSaved}</SaveIndicator>}
    </TranslationItem>
  )
}

const Wrapper = styled.div`
  position: relative;
`

const OpenButton = styled.button`
  cursor: pointer;
  border: none;
  border-radius: 6px;
  background-color: var(--color-bubble);
  transition: color var(--transition-duration) var(--transition-function),
    background-color var(--transition-duration) var(--transition-function);

  width: max-content;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 16px;

  color: var(--color-text);
  ${text16Medium};

  > svg {
    flex: none;

    path {
      fill: var(--color-text);
      transition: fill var(--transition-duration) var(--transition-function);
    }
  }

  ${mediaQueryFor.mobile} {
    padding: 8px 12px;

    > svg {
      width: 14px;
      height: 14px;
    }

    > span {
      display: none;
    }
  }
`

const ListWrapper = styled.div`
  background-color: var(--layout-color);
  border: 1px solid var(--color-g-background-stroke);
  border-radius: 4px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);

  box-sizing: border-box;
  padding: 16px;
  width: 338px;
  max-width: calc(100vw - 40px);
  display: flex;
  flex-direction: column;
  gap: 18px;

  position: absolute;
  top: 0px;
  right: -163px;
  z-index: 500;

  ${mediaQueryFor.mobile} {
    right: 0;
  }

  &[data-open="entering"] {
    animation: dropdownOpen 0.3s var(--transition-function);
  }

  &[data-open="entered"] {
    opacity: 1;
  }

  &[data-open="exiting"] {
    animation: dropdownOpen 0.3s var(--transition-function) reverse;
  }

  &[data-open="exited"] {
    display: none;
  }

  transition: opacity var(--transition-duration) var(--transition-function),
    background-color var(--transition-duration) var(--transition-function),
    border-color var(--transition-duration) var(--transition-function);

  @keyframes dropdownOpen {
    from {
      opacity: 0;
      pointer-events: none;
    }

    to {
      opacity: 1;
      pointer-events: all;
    }
  }
`

const TranslationHeader = styled.span`
  opacity: 0.5;
  color: var(--color-text);
  transition: color var(--transition-duration) var(--transition-function);
  ${text14Medium};

  display: flex;
  align-items: center;
  gap: 10px;

  > svg {
    path {
      fill: var(--color-text);
      transition: fill var(--transition-duration) var(--transition-function);
    }
  }
`

const TranslationItem = styled.div`
  display: grid;
  grid-template-areas:
    "icon name saved"
    "icon native saved";
  grid-template-columns: 24px 1fr auto;
  justify-content: start;
  align-items: center;
  gap: 4px 6px;

  cursor: pointer;
  color: var(--color-text);
  transition: color var(--transition-duration) var(--transition-function);

  > svg {
    grid-area: icon;
  }

  &[data-selected="true"] {
    color: var(--color-primary);
    cursor: default;

    > svg {
      opacity: 1;

      path {
        fill: var(--color-primary);
      }
    }
  }

  > svg {
    opacity: 0;
    transform: rotate(-90deg);
    transition: opacity var(--transition-duration) var(--transition-function);

    path {
      fill: var(--color-text);
      transition: fill var(--transition-duration) var(--transition-function);
    }
  }

  &[data-selected="false"]:hover {
    > svg {
      opacity: 1;
    }
  }
`

const Language = styled.p`
  grid-area: name;
  margin: 0;
  ${text16Medium};
`

const NativeLanguage = styled.span`
  grid-area: native;
  ${text12Medium};
  opacity: 0.5;
`

const SaveIndicator = styled.span`
  grid-area: saved;
  ${text11Medium};
  opacity: 0.5;
  text-align: right;
`

const AddButton = styled.button`
  cursor: pointer;
  border: none;
  background-color: transparent;
  transition: color var(--transition-duration) var(--transition-function);

  width: fit-content;
  padding: 0;
  display: flex;
  align-items: center;
  gap: 8px;

  color: var(--color-primary);
  ${text15Medium};

  > svg {
    path {
      fill: var(--color-primary);
      transition: fill var(--transition-duration) var(--transition-function);
    }
  }
`

export default TranslationSelector as React.FC<TranslationSelectorProps>

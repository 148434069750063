import { useCallback, useMemo } from "react"

import { useLocation, useSearchParams } from "react-router-dom"

interface LanguageSelector {
  language: string | null
  forceLanguage: (lang: string) => void
}

export default function useLanguageSelector(): LanguageSelector {
  const [search, setSearch] = useSearchParams()
  const { state } = useLocation()

  const language = useMemo(() => search.get("lang"), [search])

  const forceLanguage = useCallback(
    (lang: string) => {
      setSearch(
        prev => {
          prev.set("lang", lang)
          return prev
        },
        { replace: true, state },
      )
    },
    [setSearch, state],
  )

  return {
    language,
    forceLanguage,
  }
}

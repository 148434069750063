import { ManagedProblemMarker } from "@/store/api/problems/create/types"
import { PublicProblemMarker } from "@/store/api/problems/types"

import { IProblemBrief } from "@/features/task/list/types"

interface IProblem {
  id: number
  names: Record<string, string>
  marker?: PublicProblemMarker | ManagedProblemMarker
}

export default function useBriefProblems(
  problems: IProblem[]
): IProblemBrief[] {
  return problems.map(({ id, names, marker }) => ({
    id,
    names,
    marker,
  }))
}

import { Fragment } from "react"

import { useParams } from "react-router-dom"

import { useGetProblemByIdQuery } from "@/store/api/problems"
import { ISubtaskDetails } from "@/store/api/submissions/types"

import Line from "@/components/Line"
import SubtaskResult from "@/features/task/current/submission/SubtaskResult"

export default function SubmissionSubtasks({
  subtasks: submissionSubtasks,
}: {
  subtasks: ISubtaskDetails[]
}) {
  const { taskId } = useParams()

  const { subtasks: problemSubtasks } = useGetProblemByIdQuery(taskId, {
    selectFromResult: ({ data: { subtasks } = {} }) => ({ subtasks }),
    skip: !taskId,
  })

  if (!taskId) return null
  if (!problemSubtasks) return null

  return (
    <>
      {submissionSubtasks.map((subtask, index) => (
        <Fragment key={index}>
          <SubtaskResult
            index={index + 1}
            submissionSubtask={subtask}
            problemSubtask={problemSubtasks[index]}
          />
          <Line />
        </Fragment>
      ))}
    </>
  )
}

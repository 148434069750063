import { ChangeEventHandler, FormEvent, useCallback } from "react"

import styled from "styled-components"

import { EmailAuthField, EmailAuthStatus } from "@/store/api/auth/types"

import EmailIcon from "~/assets/icons/EmailIcon"

import BaseInput from "@/components/BaseInput"
import CustomButton from "@/components/CustomButton"
import Line from "@/components/Line"
import GoogleLoginBubble from "@/features/auth/LoginFlow/components/GoogleLoginBubble"
import LoginFormLabel from "@/features/auth/LoginFlow/components/LoginFormLabel"
import LoginTitle from "@/features/auth/LoginFlow/components/LoginTitle"
import LoginWrapper from "@/features/auth/LoginFlow/components/LoginWrapper"
import TelegramLoginBubble from "@/features/auth/LoginFlow/components/TelegramLoginBubble"
import {
  LoginControls,
  LoginModalStep,
  WithForwardedRef,
} from "@/features/auth/LoginFlow/types"
import { text14Medium, text16Bold, text16Medium } from "@/utils/fonts"
import useScopedTranslation from "@/utils/hooks/useScopedTranslation"
import mediaQueryFor from "@/utils/mediaQuery"

export default function LoginEntry({
  setStep,
  info,
  updateInfo,
  authorize,
  turnstile,
  isLoading,
  forwardedRef,
}: WithForwardedRef<LoginControls, HTMLDivElement>) {
  const t = useScopedTranslation("auth.widget.entry")

  const onChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    ({ target }) => updateInfo({ email: target.value }),
    [updateInfo],
  )

  const onSubmit = useCallback(
    async (ev: FormEvent) => {
      ev.preventDefault()

      const result = await authorize(info)

      if (result.status === EmailAuthStatus.TokenFailure) return // TODO: better error handling

      if (result.status === EmailAuthStatus.Accepted) {
        if (result.excepted_fields.includes(EmailAuthField.Code))
          return setStep(LoginModalStep.RegistrationCode)
        if (result.excepted_fields.includes(EmailAuthField.Password))
          return setStep(LoginModalStep.LoginPassword)
      }
    },
    [authorize, info, setStep],
  )

  const canContinue = !isLoading && info.email.includes("@")

  return (
    <LoginWrapper ref={forwardedRef}>
      <LoginTitle>{t("title")}</LoginTitle>

      <EmailEntry onSubmit={onSubmit}>
        <EmailFieldWrapper>
          <EmailLabel>
            <EmailIcon />
            <LoginFormLabel htmlFor="email">{t("email")}</LoginFormLabel>
          </EmailLabel>

          <BaseInput
            type="email"
            autoComplete="email username"
            name="email"
            onChange={onChange}
            value={info.email}
            disabled={isLoading}
          />
        </EmailFieldWrapper>

        {turnstile}

        <ConfirmEmail data-type="primary" disabled={!canContinue}>
          {isLoading ? "..." : t("log_in")}
        </ConfirmEmail>
      </EmailEntry>

      <Line />

      <QuickLogin>
        <span>{t("log_in_with")}</span>

        <QuickLoginMethods>
          <TelegramLoginBubble />
          <GoogleLoginBubble />
        </QuickLoginMethods>
      </QuickLogin>
    </LoginWrapper>
  )
}

const EmailEntry = styled.form`
  display: flex;
  flex-flow: column nowrap;
  align-items: start;
  justify-content: start;

  gap: 16px;

  width: 100%;
`

const EmailLabel = styled.span`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  gap: 8px;

  svg {
    flex-shrink: 0;

    height: 20px;
    width: 20px;

    opacity: 0.5;

    fill: var(--color-text);
    transition: fill var(--transition-duration) var(--transition-function);
  }

  ${mediaQueryFor.mobile} {
    svg {
      height: 16px;
      width: 16px;
    }
  }
`

const EmailFieldWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: start;

  gap: 24px;

  width: 100%;

  ${mediaQueryFor.mobile} {
    input {
      flex: 1 1 max-content;
    }
  }
`

const ConfirmEmail = styled(CustomButton)`
  ${text16Bold};
  letter-spacing: 0.16px;

  width: 214px;

  ${mediaQueryFor.mobile} {
    width: 100%;
  }
`

const QuickLogin = styled.span`
  ${text16Medium};

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: start;

  color: var(--color-text);
  transition: color var(--transition-duration) var(--transition-function);

  gap: 30px;

  ${mediaQueryFor.mobile} {
    ${text14Medium};

    gap: 12px;
  }
`

const QuickLoginMethods = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: start;

  gap: 12px;

  svg {
    cursor: pointer;

    width: 30px;
    height: 30px;
  }
`

import {
  MouseEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"

import { Transition } from "react-transition-group"
import styled from "styled-components"

import ArrowUpIcon from "~/assets/icons/ArrowUpIcon"

import mediaQueryFor from "@/utils/mediaQuery"

const REVEAL_THRESHOLD = 400

export default function ScrollToTopButton() {
  const [scrollTop, setScrollTop] = useState(
    document.getElementById("page-wrapper")?.scrollTop ?? 0
  )

  const wrapper = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    const layout = document.getElementById("page-wrapper")
    if (layout) {
      const listener = () => setScrollTop(layout.scrollTop)
      layout.addEventListener("scroll", listener)

      return () => layout.removeEventListener("scroll", listener)
    }
  }, [])

  const handleClick = useCallback<MouseEventHandler<HTMLButtonElement>>(() => {
    const layout = document.getElementById("page-wrapper")
    layout?.scrollTo({
      top: 0,
    })
  }, [])

  const isVisible = scrollTop > REVEAL_THRESHOLD

  return (
    <Transition in={isVisible} timeout={200} nodeRef={wrapper} appear>
      {state => (
        <Wrapper onClick={handleClick} data-visible={state} ref={wrapper}>
          <ArrowUpIcon />
        </Wrapper>
      )}
    </Transition>
  )
}

const Wrapper = styled.button`
  cursor: pointer;
  border: none;
  background-color: var(--color-bubble-hover);
  transition: background-color var(--transition-duration)
    var(--transition-function);
  border-radius: 50%;

  padding: 18px;
  width: 60px;
  height: 60px;

  position: fixed;
  bottom: 42px;

  ${mediaQueryFor.mobile} {
    bottom: 24px;
  }

  > svg {
    opacity: 0.5;
    width: 24px;
    height: 24px;

    > path {
      fill: var(--color-text);
      transition: fill var(--transition-duration) var(--transition-function);
    }
  }

  &[data-visible="entering"] {
    animation: slideIn 0.2s cubic-bezier(0, 1, 0, 1);
  }

  &[data-visible="exiting"] {
    animation: slideIn 0.2s cubic-bezier(0, 1, 0, 1) reverse;
  }

  &[data-visible="exited"] {
    display: none;
  }

  @keyframes slideIn {
    from {
      transform: translateY(200px);
      opacity: 0;
    }
    to {
      transform: translateY(0px);
      opacity: 1;
    }
  }
`

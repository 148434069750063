import baseApi from "@/store/api/base"
import {
  ICreateProblemResponse,
  IManagedProblemsList,
  IPolygonImportParams,
} from "@/store/api/problems/create/types"

import { RootState } from "@/store/store"

const problemsCreateApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getManagedProblems: builder.query<IManagedProblemsList, void>({
      query: () => "https://beta.sort-me.org/api/problems/getManaged",
      providesTags: [{ type: "Problem", id: "LIST" }],
    }),

    createProblem: builder.mutation<ICreateProblemResponse, void>({
      query: () => ({
        url: "https://beta.sort-me.org/api/problems/create",
        method: "POST",
      }),
      invalidatesTags: (_, error) =>
        error ? [] : [{ type: "Problem", id: "LIST" }],
    }),

    polygonImport: builder.mutation<number, IPolygonImportParams>({
      queryFn: async ({ file, callback }, api) => {
        const {
          user: { token },
          common: { lang },
        } = api.getState() as RootState

        const formData = new FormData()
        formData.append("file", file)

        const xhr = new XMLHttpRequest()
        const result = await new Promise<void>(resolve => {
          xhr.upload.addEventListener("progress", event => {
            if (event.lengthComputable) {
              const percentCompleted = Math.round(
                (event.loaded * 100) / event.total
              )
              console.log(percentCompleted)

              callback?.(percentCompleted)
            }
          })
          xhr.addEventListener("loadend", () => resolve())

          xhr.open("POST", "http://localhost:3000/bin", true)

          xhr.setRequestHeader("Content-Type", "multipart/form-data")
          xhr.setRequestHeader("X-Language", lang ?? "ru")
          if (token) xhr.setRequestHeader("Authorization", `Bearer ${token}`)

          xhr.send(formData)
        })

        return { data: 123 }
      },
    }),
  }),
})

export default problemsCreateApi
export const {
  useGetManagedProblemsQuery,
  useCreateProblemMutation,
  usePolygonImportMutation,
} = problemsCreateApi

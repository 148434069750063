import styled from "styled-components"

import mediaQueryFor from "@/utils/mediaQuery"

export default function HomepageSpline() {
  return <Animation title="Animation" src="/animation/index.html" />
}

const Animation = styled.iframe`
  /* Visual */
  border: none;
  outline: none;

  /* Content */
  width: 100%;
  height: calc(100vh - 47px);

  grid-row: 1 / 3;
  grid-column: 2 / 3;

  /* Position */
  position: absolute;
  left: 0;
  top: 0;

  ${mediaQueryFor.middleDesktopOrLess} {
    & {
      /* Content */
      display: none;
    }
  }
`

import { Trans, useTranslation } from "react-i18next"
import styled from "styled-components"

import { IContestTimings } from "@/store/api/contests/types"

import Timer from "@/components/Timer"
import useContestStatus, { ContestStatus } from "@/shared/time/useContestStatus"
import { text16Medium } from "@/utils/fonts"

interface ContestSidebarTimerProps {
  timings: IContestTimings | undefined
  onExpire: () => void
}

export default function ContestSidebarTimer({
  timings,
  onExpire,
}: ContestSidebarTimerProps) {
  const { t } = useTranslation()
  const status = useContestStatus(timings)

  switch (status) {
    case ContestStatus.Running: {
      return (
        <Wrapper>
          <Timer expiryTimestamp={timings!.end * 1000} onExpire={onExpire} />
        </Wrapper>
      )
    }

    case ContestStatus.Waiting:
    case ContestStatus.Pending:
    case ContestStatus.Registration: {
      return (
        <Trans
          t={t}
          i18nKey="pages.contest.sidebar.timer.starts_in"
          parent={Wrapper}
          components={{
            timer: (
              <Timer
                expiryTimestamp={timings!.start * 1000}
                onExpire={onExpire}
              />
            ),
          }}
        />
      )
    }

    default: {
      return null
    }
  }
}

const Wrapper = styled.span`
  ${text16Medium};

  opacity: 0.65;
  color: var(--color-text);
`

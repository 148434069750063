import { useMemo } from "react"

import { useParams } from "react-router-dom"

import { useGetTestsQuery } from "@/store/api/problems/edit/tests"
import { ITestWithId } from "@/store/api/problems/edit/tests/types"

import { ISubtaskDetails } from "@/features/task/edit/tests/types"
import { useAppSelector } from "@/store"

export default function useSubtaskDistribution(search: string) {
  const { problemId = "" } = useParams()

  const { data } = useGetTestsQuery(Number(problemId))
  const { tests, subtasks } = useAppSelector(({ tests }) => tests)

  const subtaskDistribution = useMemo(() => {
    if (!subtasks || !data) return undefined

    const result = (tests ?? []).reduce(
      (acc, test, testIndex) => {
        if (matchesSearch(test, search))
          acc[test.subtask - 1].tests.push({
            ...test,
            newId: testIndex + 1,
            originalSubtask: data.tests[test.id - 1]?.subtask ?? test.subtask, // if test is not added yet, use its current subtask
          })
        return acc
      },
      subtasks.map(
        ({ id, description }): ISubtaskDetails => ({
          id,
          description,
          tests: [],
        })
      )
    )

    return result
  }, [data, search, subtasks, tests])

  return subtaskDistribution
}

function matchesSearch(test: ITestWithId, search: string) {
  if (!search.trim()) return true
  if (Number.isInteger(Number(search))) return test.id === Number(search)

  return (
    test.comment.toLowerCase().includes(search.toLowerCase()) ||
    test.stdin_preview.toLocaleLowerCase().includes(search.toLowerCase()) ||
    test.stdout_preview.toLocaleLowerCase().includes(search.toLowerCase())
  )
}

import { Helmet } from "react-helmet-async"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"
import styled from "styled-components"

import Line from "@/components/Line"
import SearchBar from "@/components/SearchBar"
import CategoryFilter from "@/features/task/list/CategoryFilter"
import ProblemFilter from "@/features/task/list/ProblemFilter"
import useCategoriesCount from "@/features/task/list/Public/hooks/useCategoriesCount"
import ProblemList from "@/features/task/list/Public/ProblemList"
import ScrollToTopButton from "@/features/task/list/ScrollToTopButton"
import { IPublicFilterProps } from "@/features/task/list/types"
import useCategoryFilter from "@/features/task/list/utils/hooks/useCategoryFilter"
import useSearchFilter from "@/features/task/list/utils/hooks/useSearchFilter"
import mediaQueryFor from "@/utils/mediaQuery"

import useDifficultyFilter from "./hooks/useDifficultyFilter"
import useSortOrder from "./hooks/useSortOrder"
import DifficultyFilterSelector from "./selectors/DifficultyFilterSelector"
import SortOrderSelector from "./selectors/SortOrderSelector"

interface IPublicListState {
  filters: IPublicFilterProps
}

export default function ProblemsPublic() {
  const { state }: { state: IPublicListState } = useLocation()

  const filters = state?.filters ?? {}

  const { t } = useTranslation()

  const i18n = {
    title: t("pages.task.list.tabs.public.title"),
    searchPlaceholder: t("pages.task.list.tabs.public.search_placeholder"),
  }

  const {
    search,
    handleChange: handleSearchChange,
    debouncedSearch,
  } = useSearchFilter(filters?.search)

  const {
    sortOrder,
    isReversed,
    dispatch: setSortOrder,
  } = useSortOrder({
    sortOrder: filters?.sortOrder,
    isReversed: filters?.isReversed,
  })

  const { difficulties, toggleDifficulty } = useDifficultyFilter(
    filters?.difficulties
  )

  const { selectedCategories, toggleCategory } = useCategoryFilter(
    filters?.categories
  )

  const categories = useCategoriesCount(
    {
      search: debouncedSearch,
      difficulties,
    },
    selectedCategories
  )

  return (
    <>
      <Helmet>
        <title>{i18n.title}</title>
        <link rel="canonical" href="https://sort-me.org/problems" />
      </Helmet>

      <Wrapper>
        <Sidebar>
          <StyledSearchBar
            value={search}
            onChange={handleSearchChange}
            placeholder={i18n.searchPlaceholder}
          />
          <ProblemFilter>
            <SortOrderSelector
              order={sortOrder}
              isReversed={isReversed}
              dispatch={setSortOrder}
            />
            <Line />
            <DifficultyFilterSelector
              difficulties={difficulties}
              toggleDifficulty={toggleDifficulty}
            />
          </ProblemFilter>
        </Sidebar>
        <Main>
          <CategoryFilter
            selected={selectedCategories}
            toggleCategory={toggleCategory}
            categories={categories}
          />
          <ProblemList
            filterProps={{
              search: debouncedSearch,
              sortOrder,
              isReversed,
              difficulties,
              categories: selectedCategories,
            }}
          />
        </Main>
        <ScrollToTopButton />
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div`
  position: relative;

  display: grid;
  gap: 30px;
  grid-template-columns: 258px 1fr;

  ${mediaQueryFor.mobile} {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
`

const Sidebar = styled.aside`
  display: flex;
  flex-direction: column;
  gap: 32px;

  ${mediaQueryFor.mobile} {
    flex-direction: row;
    gap: 20px;
    justify-content: space-between;
    align-items: center;
  }
`

const StyledSearchBar = styled(SearchBar)`
  ${mediaQueryFor.mobile} {
    height: 38px;
    padding: 11px 20px 10px 20px;
    width: 100%;
  }
`

const Main = styled.main`
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${mediaQueryFor.mobile} {
    gap: 18px;
  }
`

import styled from "styled-components"

import Loader from "@/components/Loader"

interface IRectangularLoaderProps {
  loading: boolean
  size?: number
  className?: string
}

export default function RectangularLoader({
  loading = false,
  size = 224,
  className = "",
}: IRectangularLoaderProps) {
  if (!loading) return null

  return (
    <Wrapper className={className} $size={size} $loading={loading}>
      <Loader size="large" loading={loading} />
    </Wrapper>
  )
}

const Wrapper = styled.div<{ $size: number; $loading: boolean }>`
  ${({ $loading }) => !$loading && "display: none;"}

  height: ${({ $size }) => $size}px;

  > div {
    height: 100% !important;
  }
`

import { useTranslation } from "react-i18next"
import styled from "styled-components"

import CustomButton from "@/components/CustomButton"
import { text12, text14Medium } from "@/utils/fonts"

export default function CreateContestBar({
  remainingQuota,
}: {
  remainingQuota: number
}) {
  const { t } = useTranslation()

  const i18n = {
    create_button_text: t("pages.contests.manage.create_button_text"),
    notification: t("pages.contests.manage.quota_notification", {
      count: remainingQuota,
    }),
    quotaIncrease: t("pages.contests.manage.quota_increase"),
  }

  if (remainingQuota >= 100)
    return (
      <ButtonHighQuota data-type="primary">
        {i18n.create_button_text}
      </ButtonHighQuota>
    )

  return (
    <CreateContestBarWrapper>
      <ButtonLowQuota data-type="primary" disabled={remainingQuota === 0}>
        {i18n.create_button_text}
      </ButtonLowQuota>
      <AnnotationContainer>
        <Limit>{i18n.notification}</Limit>
        <Email>
          {i18n.quotaIncrease}{" "}
          <a href="mailto: guys@sort-me.org">guys@sort-me.org</a>
        </Email>
      </AnnotationContainer>
    </CreateContestBarWrapper>
  )
}

const ButtonHighQuota = styled(CustomButton)`
  margin-bottom: 32px;
`

const ButtonLowQuota = styled(CustomButton)`
  width: 214px;

  @media (max-width: 791px) {
    width: 100%;
  }
`

const CreateContestBarWrapper = styled.div`
  border-radius: 8px;
  background-color: var(--color-bubble);
  transition: background-color var(--transition-duration)
    var(--transition-function);

  width: 660px;
  padding: 16px 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  column-gap: 35px;

  margin-bottom: 32px;

  @media (max-width: 791px) {
    width: 100%;
    flex-direction: column;
    padding: 16px 20px 12px 20px;
    gap: 11px;
  }
`

const AnnotationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1px;

  @media (max-width: 791px) {
    align-items: center;
  }
`

const Limit = styled.p`
  margin: 0;
  ${text14Medium};
`

const Email = styled.p`
  opacity: 0.5;
  margin: 0;
  ${text12};

  > a {
    color: var(--color-text);
    transition: color var(--transition-duration) var(--transition-function);

    &:hover {
      color: var(--color-background-invert);
    }
  }
`

import { AdminLevel } from "@/store/api/problems/types"

const levels: Record<number, { name: string; description: string }> = {
  [AdminLevel.JustLook]: {
    name: "pages.task.edit.stages.access.levels.just_look.name",
    description: "pages.task.edit.stages.access.levels.just_look.description",
  },
  [AdminLevel.Editor]: {
    name: "pages.task.edit.stages.access.levels.editor.name",
    description: "pages.task.edit.stages.access.levels.editor.description",
  },
  [AdminLevel.Admin]: {
    name: "pages.task.edit.stages.access.levels.admin.name",
    description: "pages.task.edit.stages.access.levels.admin.description",
  },
  [AdminLevel.Creator]: {
    name: "pages.task.edit.stages.access.levels.creator.name",
    description: "pages.task.edit.stages.access.levels.creator.description",
  },
}

export default levels

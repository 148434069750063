import {
  ChangeEventHandler,
  SyntheticEvent,
  useCallback,
  useState,
} from "react"

import styled from "styled-components"

import { EmailAuthStatus } from "@/store/api/auth/types"

import BaseInput from "@/components/BaseInput"
import CustomButton from "@/components/CustomButton"
import LoginFormLabel from "@/features/auth/LoginFlow/components/LoginFormLabel"
import LoginTitle from "@/features/auth/LoginFlow/components/LoginTitle"
import LoginWrapper from "@/features/auth/LoginFlow/components/LoginWrapper"
import {
  LoginControls,
  LoginModalStep,
  WithForwardedRef,
} from "@/features/auth/LoginFlow/types"
import { text12Medium, text16Bold } from "@/utils/fonts"
import useScopedTranslation from "@/utils/hooks/useScopedTranslation"
import mediaQueryFor from "@/utils/mediaQuery"

export default function LoginPassword({
  setStep,
  info,
  updateInfo,
  authorize,
  isLoading,
  turnstile,
  forwardedRef,
}: WithForwardedRef<LoginControls, HTMLDivElement>) {
  const t = useScopedTranslation("auth.widget.log_in")

  const [hasError, setError] = useState(false)

  const onChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    ({ target }) => {
      setError(false)
      updateInfo({ password: target.value })
    },
    [updateInfo],
  )

  const onContinue = useCallback(
    async (ev: SyntheticEvent) => {
      ev.preventDefault()

      const result = await authorize(info)

      if (result.status === EmailAuthStatus.Error) return setError(true)
      if (result.status === EmailAuthStatus.TokenFailure) return // TODO: better error handling
    },
    [authorize, info],
  )

  const toPasswordReset = useCallback(() => {
    const update = { reset_password: true, password: undefined } as const

    updateInfo(update)
    authorize({ ...info, ...update })

    setStep(LoginModalStep.ResetCode)
  }, [authorize, info, setStep, updateInfo])

  return (
    <LoginWrapper ref={forwardedRef}>
      <LoginTitle>{t("title", { email: info.email })}</LoginTitle>

      <PasswordEntry onSubmit={onContinue}>
        <input
          hidden
          type="text"
          name="email"
          value={info.email}
          readOnly
          autoComplete="username email"
        />

        <PasswordWrapper>
          <LoginFormLabel htmlFor="password">{t`password`}</LoginFormLabel>
          <BaseInput
            type="password"
            autoComplete="current-password"
            name="password"
            onChange={onChange}
            data-errored={hasError}
            disabled={isLoading}
          />
        </PasswordWrapper>

        {turnstile}

        <Actions>
          <ConfirmPassword
            data-type="primary"
            disabled={isLoading || !info.password}
          >
            {t`log_in`}
          </ConfirmPassword>
          <ForgotPassword onClick={toPasswordReset} data-disabled={isLoading}>
            {t`forgot_password`}
          </ForgotPassword>
        </Actions>
      </PasswordEntry>
    </LoginWrapper>
  )
}

const PasswordEntry = styled.form`
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-content: start;

  gap: 16px;

  ${mediaQueryFor.mobile} {
    width: 100%;
  }
`

const PasswordWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: start;

  gap: 24px;

  label {
    flex: 0 0 max-content;
  }
`

const ConfirmPassword = styled(CustomButton)`
  ${text16Bold};
  letter-spacing: 0.16px;
`

const ForgotPassword = styled.span`
  ${text12Medium};
  color: var(--color-primary);

  grid-area: forgot;

  cursor: pointer;

  ${mediaQueryFor.mobile} {
    text-align: center;
  }

  &[data-disabled="true"] {
    pointer-events: none;
  }
`

const Actions = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;

  gap: 24px;

  ${mediaQueryFor.mobile} {
    display: contents;
  }
`

import React, { ForwardedRef } from "react"

import styled from "styled-components"

import { IContest } from "@/store/api/contests/types"

import Card from "@/components/Card"
import Timing from "@/features/contest/Timing"
import { text13Medium, text16Medium } from "@/utils/fonts"

interface IContestEntryProps {
  contest: IContest
  receivedAt: number
  onExpire: () => void
}

function BaseEntry(
  { contest, onExpire, receivedAt }: IContestEntryProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  return (
    <Card ref={ref}>
      <CardHeader>{contest.name}</CardHeader>
      <Timing
        timing={contest.timings}
        requestFulfilledAt={receivedAt}
        onExpire={onExpire}
      />
      <CardOrganizer>{contest.organizer}</CardOrganizer>
    </Card>
  )
}

const CardHeader = styled.h4`
  height: 60px;
  overflow: hidden;
  width: 100%;

  margin: 0 0 19px 0;
  ${text16Medium};
`

const CardOrganizer = styled.span`
  opacity: 0.5;
  ${text13Medium};
`

export default React.forwardRef<HTMLDivElement, IContestEntryProps>(BaseEntry)

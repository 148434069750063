import styled from "styled-components"

import { IUserBrief } from "@/store/api/users/types"

import Avatar from "@/components/Avatar"
import { text13Medium, text15Medium } from "@/utils/fonts"
import mediaQueryFor from "@/utils/mediaQuery"

interface ICreatorColumnProps {
  creator: IUserBrief
}

export default function CreatorColumn({ creator }: ICreatorColumnProps) {
  return (
    <Column>
      <Avatar src={creator.avatar} />
      <span>{creator.handle}</span>
    </Column>
  )
}

const Column = styled.div`
  display: flex;
  align-items: center;

  > span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 15px;

    ${text15Medium};
    color: var(--color-text);
    transition: color var(--transition-duration) var(--transition-function);
  }

  > picture {
    width: 24px;
    height: 24px;

    margin-right: 10px;

    > img {
      width: 24px;
      height: 24px;
    }
  }

  ${mediaQueryFor.mobile} {
    flex-direction: row-reverse;
    > span {
      padding-right: 0;
      ${text13Medium};
      opacity: 0.75;
    }

    > picture {
      width: 22px;
      height: 22px;

      margin-right: 0;
      margin-left: 10px;

      > img {
        width: 22px;
        height: 22px;
      }
    }
  }
`

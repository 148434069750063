import { DateTime } from "luxon"
import { Trans, useTranslation } from "react-i18next"
import styled from "styled-components"

import EditIcon from "~/assets/icons/EditIcon"
import ReloadIcon from "~/assets/icons/ReloadIcon"

import useRestore from "@/features/task/edit/legend/utils/hooks/useRestore"
import { text16Medium } from "@/utils/fonts"

interface IRestoreDraftProps {
  problemId: string
  currentTranslation: string
  serverTranslations: Set<string>
  title: string
  legend: string
  restore: ({ title, legend }: { title: string; legend: string }) => void
}

export default function RestoreDraft({
  problemId,
  currentTranslation,
  serverTranslations,
  title,
  legend,
  restore,
}: IRestoreDraftProps) {
  const {
    t,
    i18n: { resolvedLanguage: lang },
  } = useTranslation()

  const i18n = {
    datetimeFormat: t("pages.task.edit.datetime.full"),
    restore: t("pages.task.edit.stages.legend.tip.restore"),
  }

  const { draft, handleRestore } = useRestore({
    problemId,
    translation: currentTranslation,
    restore,
  })

  const canRestore =
    !!draft[currentTranslation] &&
    serverTranslations.has(currentTranslation) &&
    (title !== draft[currentTranslation].title ||
      legend !== draft[currentTranslation].legend)

  if (canRestore)
    return (
      <RestoreBox>
        <EditIcon />
        <div>
          <Trans
            parent="p"
            i18nKey={"pages.task.edit.stages.legend.tip.draft"}
            components={{
              next: <br />,
            }}
            values={{
              date: DateTime.fromMillis(
                draft[currentTranslation].timestamp
              ).toFormat(i18n.datetimeFormat, { locale: lang }),
            }}
          />
          <button onClick={handleRestore}>
            <ReloadIcon /> {i18n.restore}
          </button>
        </div>
      </RestoreBox>
    )

  return null
}

const RestoreBox = styled.div`
  background-color: var(--color-background);
  border-radius: 8px;
  transition: background-color var(--transition-duration)
    var(--transition-function);

  padding: 18px;
  display: flex;
  gap: 21px;

  svg {
    flex: none;

    path {
      fill: var(--color-text);
      transition: fill var(--transition-duration) var(--transition-function);
    }
  }

  > div {
    max-width: 196px;

    > p {
      margin: 0;
      ${text16Medium};
    }

    > button {
      background-color: transparent;
      border: none;
      cursor: pointer;
      color: var(--color-primary);
      transition: color var(--transition-duration) var(--transition-function);

      display: flex;
      align-items: center;
      gap: 8px;
      padding: 0;

      margin-top: 16px;

      ${text16Medium};

      > svg path {
        fill: var(--color-primary);
      }
    }
  }
`

import { useCallback } from "react"

import styled, { css } from "styled-components"

import loginWithTelegram from "@/store/slices/auth/telegram"
import { Integration } from "@/store/slices/auth/types"

import TelegramBubble from "~/assets/icons/TelegramBubble"

import { useAppDispatch, useAppSelector } from "@/store"

export default function TelegramLoginBubble() {
  const dispatch = useAppDispatch()

  const isLoading = useAppSelector(
    state => state.auth.loading === Integration.telegram,
  )
  const hasError = useAppSelector(state =>
    state.auth.lastLoaded === Integration.telegram
      ? state.auth.lastError
      : null,
  )

  const onClick = useCallback(() => {
    if (isLoading) return

    dispatch(loginWithTelegram({ mode: "create" }))
  }, [dispatch, isLoading])

  return (
    <LogInButton
      onClick={onClick}
      $loading={isLoading}
      $errored={hasError !== null}
    />
  )
}

const LoadingAnimation = css`
  animation: telegramPulse 1.8s linear infinite;
  border-radius: 6.82px;

  @keyframes telegramPulse {
    0% {
      box-shadow: 0 0 0 0px #69a8e544;
    }
    60% {
      box-shadow: 0 0 0 4.8px #69a8e566;
    }
    100% {
      box-shadow: 0 0 0 8px #69a8e500;
    }
  }
`

const ErrorMarker = css`
  transition: box-shadow 0.3s ease-in-out;

  box-shadow: 0 0 4px 0.1px var(--color-red);
`

const LogInButton = styled(TelegramBubble)<{
  $loading: boolean
  $errored: boolean
}>`
  box-shadow: none;
  border-radius: 6.82px;

  ${({ $loading }) => $loading && LoadingAnimation}
  ${({ $errored }) => $errored && ErrorMarker}
`

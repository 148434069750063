import styled from "styled-components"

import DifficultyIcon from "@/components/DifficultyIcon"
import Divider from "@/components/Divider"
import { text12Medium, text15Medium } from "@/utils/fonts"
import mediaQueryFor from "@/utils/mediaQuery"

interface IDifficultyColumnProps {
  difficulty: number
}

export default function DifficultyColumn({
  difficulty,
}: IDifficultyColumnProps) {
  return (
    <Column>
      <Divider data-divider />
      <DifficultyIcon percent={difficulty} /> {difficulty}%
    </Column>
  )
}

const Column = styled.div`
  display: flex;
  align-items: center;

  ${text15Medium};
  color: var(--color-text);
  transition: color var(--transition-duration) var(--transition-function);

  > svg {
    margin-right: 12px;

    width: 16px;
    height: 16px;
  }

  ${Divider} {
    margin-right: 16px;
  }

  ${mediaQueryFor.mobile} {
    ${text12Medium};

    ${Divider} {
      display: none;
    }
  }
`

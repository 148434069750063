import { useCallback } from "react"

import { useTranslation } from "react-i18next"
import styled from "styled-components"

import loginWithGoogle from "@/store/slices/auth/google"
import loginWithTelegram from "@/store/slices/auth/telegram"
import { Integration } from "@/store/slices/auth/types"

import { useAppDispatch, useAppSelector } from "@/store"

interface IConnectButtonProps {
  kind: Integration
  current: string | null
}

export default function ConnectButton({
  kind,
  current = null,
}: IConnectButtonProps) {
  const dispatch = useAppDispatch()
  const login = useCallback(
    () =>
      dispatch(
        kind === Integration.telegram
          ? loginWithTelegram({ mode: "create" })
          : loginWithGoogle({ mode: "create" })
      ),
    [dispatch, kind]
  )

  const { t } = useTranslation()

  const loading = useAppSelector(state => state.auth.loading)
  const error = useAppSelector(state =>
    state.auth.lastLoaded === kind ? state.auth.lastError : null
  )
  const textOptions = [
    { use: !!current, value: current },
    { use: loading === kind, value: t("auth.button.logging_in") },
    { use: !!error, value: t("auth.button.error") },
    { use: true, value: t("auth.button.connect") },
  ]

  const buttonText = textOptions.find(({ use }) => use)!.value

  return (
    <Wrapper onClick={login} data-loading={loading} data-connected={!!current}>
      {buttonText}
    </Wrapper>
  )
}

const Wrapper = styled.a`
  text-decoration: underline;
  color: var(--link-color);

  cursor: pointer;

  &[data-connected="true"] {
    text-decoration: none;
    color: var(--color-text);

    cursor: default;

    transition: color var(--transition-duration) var(--transition-function);
  }

  &[data-loading="true"] {
    pointer-events: none;
  }
`

import {
  MouseEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"

import { Transition } from "react-transition-group"
import styled from "styled-components"

import MenuIcon from "~/assets/icons/MenuIcon"

interface IDropdownProps {
  children?: React.ReactNode
}

export default function Dropdown({ children = null }: IDropdownProps) {
  const [isOpen, setIsOpen] = useState(false)

  const wrapper = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (isOpen) {
      const listener = () => setIsOpen(false)
      document.addEventListener("click", listener)

      return () => document.removeEventListener("click", listener)
    }
  }, [isOpen])

  const toggleOpen: MouseEventHandler<HTMLDivElement> = useCallback(event => {
    event.stopPropagation()

    setIsOpen(open => !open)
  }, [])

  return (
    <Transition in={isOpen} timeout={300} nodeRef={wrapper}>
      {state => (
        <Wrapper onClick={toggleOpen} data-open={state} ref={wrapper}>
          <MenuIcon />

          <ListWrapper data-open={state}>{children}</ListWrapper>
        </Wrapper>
      )}
    </Transition>
  )
}

const Wrapper = styled.div`
  background: none;
  outline: none;
  border: none;
  cursor: pointer;

  box-sizing: border-box;
  padding: 6px;
  height: 36px;

  margin-right: -6px;
  position: relative;

  > svg path {
    transition: fill var(--transition-duration) var(--transition-function);
  }

  &[data-open="entering"] > svg {
    animation: rotationIn 0.3s var(--transition-function);
  }

  &[data-open="entered"] > svg {
    transform: rotate(90deg);
  }

  &[data-open="exiting"] > svg {
    animation: rotationIn 0.3s var(--transition-function) reverse;
  }

  @keyframes rotationIn {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(90deg);
    }
  }
`

const ListWrapper = styled.div`
  background: var(--layout-color);
  border: 2px solid var(--layout-border);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;

  box-sizing: border-box;
  padding: 25px 19px 0 19px;
  display: flex;
  gap: 9px;
  flex-direction: column;
  align-items: flex-end;

  position: absolute;
  right: 13px;
  top: 58px;
  z-index: 600;

  &[data-open="entering"] {
    animation: dropdownOpen 0.3s var(--transition-function);
  }

  &[data-open="entered"] {
    opacity: 1;
  }

  &[data-open="exiting"] {
    animation: dropdownOpen 0.3s var(--transition-function) reverse;
  }

  &[data-open="exited"] {
    display: none;
  }

  transition:
    background-color var(--transition-duration) var(--transition-function),
    border-color var(--transition-duration) var(--transition-function),
    color var(--transition-duration) var(--transition-function),
    opacity var(--transition-duration) var(--transition-function);

  @keyframes dropdownOpen {
    from {
      opacity: 0;
      pointer-events: none;
    }

    to {
      opacity: 1;
      pointer-events: all;
    }
  }
`

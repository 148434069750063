import {
  ChangeEventHandler,
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react"

import { useTranslation } from "react-i18next"
import styled from "styled-components"

import Checkmark from "~/assets/icons/Checkmark"

import Modal from "@/components/Modal"
import SearchBar from "@/components/SearchBar"
import { text16Medium, text18Medium } from "@/utils/fonts"
import { getLanguagesList } from "@/utils/languages"

interface INewLanguageModalProps {
  isVisible: boolean
  close: () => void
  translations: Set<string>
  addTranslation: (translation: string) => void
}

export default function NewTranslationModal({
  isVisible,
  close,
  translations,
  addTranslation,
}: INewLanguageModalProps) {
  const [search, setSearch] = useState("")

  const { t } = useTranslation()

  const [isOpen, setIsOpen] = useState(isVisible)

  useEffect(() => {
    if (isVisible) setIsOpen(true)
  }, [isVisible])

  const languages = useMemo(() => getLanguagesList(t), [t])

  const filteredLanguages = useMemo(() => {
    const result = []
    const existing = []

    for (const language of languages) {
      const lowercaseSearch = search.toLowerCase()

      if (
        !lowercaseSearch ||
        language.code === lowercaseSearch ||
        language.name.toLowerCase().includes(lowercaseSearch) ||
        language.native.toLowerCase().includes(lowercaseSearch)
      ) {
        if (translations.has(language.code)) existing.push(language)
        else result.push(language)
      }
    }

    return result.concat(existing)
  }, [search, languages, translations])

  const handleClose = useCallback(() => {
    setSearch("")
    setIsOpen(false)
  }, [])

  const handleSearchChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    event => setSearch(event.target.value),
    []
  )

  const handleClick = useCallback<MouseEventHandler<HTMLDivElement>>(
    event => {
      const code = event.currentTarget.dataset.code

      if (code) {
        setSearch("")
        setIsOpen(false)
        addTranslation(code)
      }
    },
    [addTranslation]
  )

  const i18n = {
    header: t("pages.task.edit.stages.legend.translation.search_header"),
    placeholder: t(
      "pages.task.edit.stages.legend.translation.search_placeholder"
    ),
    notFound: t("pages.task.edit.stages.legend.translation.not_found"),
  }

  const isEmpty = filteredLanguages.length === 0

  if (!isVisible) return null

  return (
    <ModalWrapper isOpen={isOpen} close={handleClose} onExit={close}>
      <h6>{i18n.header}</h6>
      <StyledSearchBar
        value={search}
        onChange={handleSearchChange}
        placeholder={i18n.placeholder}
      />
      {isEmpty ? (
        <Empty>{i18n.notFound}</Empty>
      ) : (
        <TranslationList>
          {filteredLanguages.map(({ code, name, native }) => {
            const isActive = translations.has(code)

            return (
              <TranslationItem
                key={code}
                data-code={code}
                data-active={isActive}
                onClick={handleClick}
              >
                {isActive && <Checkmark />}
                <p>{t(name)}</p>
                <span>{t(native)}</span>
              </TranslationItem>
            )
          })}
        </TranslationList>
      )}
    </ModalWrapper>
  )
}

const ModalWrapper = styled(Modal)`
  box-sizing: border-box;
  width: 640px;
  padding: 48px 32px 32px 32px;
  display: flex;
  flex-direction: column;
  gap: 32px;

  > h6 {
    margin: 0;
    ${text18Medium};
    color: var(--color-text);
    transition: color var(--transition-duration) var(--transition-function);
  }
`

const StyledSearchBar = styled(SearchBar)`
  width: 100%;
`

const TranslationList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  justify-content: start;
  align-items: center;
  gap: 16px;
`

const TranslationItem = styled.div`
  background-color: var(--color-bubble);
  border-radius: 6px;
  cursor: pointer;

  display: grid;
  grid-template-areas:
    "checkmark name"
    "checkmark nativeName";
  align-items: center;
  row-gap: 3px;
  justify-content: start;

  box-sizing: border-box;
  padding: 10px 16px 9px 16px;
  width: 260px;

  > svg {
    grid-area: checkmark;
    flex: none;
    margin-right: 15px;

    path {
      fill: var(--color-text);
      transition: fill var(--transition-duration) var(--transition-function);
    }
  }

  > p {
    grid-area: name;
    margin: 0;
    ${text16Medium};
    color: var(--color-text);
    transition: color var(--transition-duration) var(--transition-function);
  }

  > span {
    grid-area: nativeName;
    ${text16Medium}
    color: var(--color-text);
    opacity: 0.5;
    transition: color var(--transition-duration) var(--transition-function);
  }

  &[data-active="true"] {
    cursor: default;

    > * {
      opacity: 0.3;
    }
  }
`

const Empty = styled.p`
  ${text16Medium};
  text-align: center;
`

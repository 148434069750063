import { RouteObject, matchRoutes } from "react-router-dom"
import styled from "styled-components"

import problemsApi from "@/store/api/problems"
import problemsCreateApi from "@/store/api/problems/create"

import ProblemsManaged from "@/features/task/list/Managed"
import ProblemsPublic from "@/features/task/list/Public"
import { IPageConfig } from "@/utils/types"
import PageHeader from "@/widgets/header/PageHeader"
import { ILinkDetails } from "@/widgets/header/types"

enum RouteID {
  public = "public",
  managed = "managed",
}

type SupportedProblemRouteT = RouteObject & { id: RouteID; path: string }

const SupportedRoutes: SupportedProblemRouteT[] = [
  {
    id: RouteID.public,
    path: "/problems",
    index: true,
    Component: withProblemsHeader(ProblemsPublic),
  },
  {
    id: RouteID.managed,
    path: "/problems/managed",
    Component: withProblemsHeader(ProblemsManaged),
  },
]

export const config: IPageConfig = {
  routes: SupportedRoutes,

  server: {
    async populate({ req, store }) {
      const routes = matchRoutes(SupportedRoutes, { pathname: req.path }) ?? []

      const {
        user: { uid },
      } = store.getState()

      for (const { route } of routes) {
        switch (route.id) {
          case RouteID.public:
            await store.dispatch(problemsApi.endpoints.getPublic.initiate())
            break
          case RouteID.managed:
            if (uid)
              await store.dispatch(
                problemsCreateApi.endpoints.getManagedProblems.initiate()
              )
            break
        }
      }

      await Promise.all(
        store.dispatch(problemsApi.util.getRunningQueriesThunk())
      )
    },
  },
  client: {},

  layout: "normal",
}

const links: ILinkDetails[] = [
  { href: "/problems", name: "pages.task.list.tabs.public.name" },
  { href: "/problems/managed", name: "pages.task.list.tabs.managed.name" },
]

function withProblemsHeader(Component: React.ComponentType): React.FC {
  return function ProblemsList() {
    return (
      <Wrapper>
        <PageHeader links={links} header="pages.task.list.header" />

        <Component />
      </Wrapper>
    )
  }
}

const Wrapper = styled.div`
  width: 100%;
  height: max-content;
  min-height: 100%;
`

/* eslint-disable import/prefer-default-export */
import { RouteObject, matchRoutes } from "react-router-dom"
import styled from "styled-components"

import archivesApi from "@/store/api/archives"
import contestsApi from "@/store/api/contests"

import ContestHistory from "@/features/contest/subpages/History"
import ContestManage from "@/features/contest/subpages/Manage"
import ContestPublic from "@/features/contest/subpages/Public"
import { AppDispatch } from "@/store/store"
import { IPageConfig } from "@/utils/types"
import PageHeader from "@/widgets/header/PageHeader"
import { ILinkDetails } from "@/widgets/header/types"

enum RouteID {
  public = "public",
  history = "history",
  manage = "manage",
}

type SupportedContestRouteT = RouteObject & { id: RouteID; path: string }

const SupportedRoutes: SupportedContestRouteT[] = [
  {
    id: RouteID.public,
    path: "/contest",
    index: true,
    Component: withContestHeader(ContestPublic),
  },
  {
    id: RouteID.history,
    path: "/contest/history",
    Component: withContestHeader(ContestHistory),
  },
  {
    id: RouteID.manage,
    path: "/contest/manage",
    Component: withContestHeader(ContestManage),
  },
]

const RouteDataLoader: Record<RouteID, (d: AppDispatch) => Promise<unknown>> = {
  [RouteID.public]: dispatch =>
    dispatch(archivesApi.endpoints.getAvailableArchives.initiate({})),
  [RouteID.manage]: dispatch =>
    dispatch(contestsApi.endpoints.getManaged.initiate({})),
  [RouteID.history]: dispatch =>
    dispatch(contestsApi.endpoints.getParticipated.initiate({})),
}

export const config: IPageConfig = {
  routes: SupportedRoutes,

  server: {
    async populate({ req, store }) {
      const routes = matchRoutes(SupportedRoutes, { pathname: req.path }) ?? []

      await Promise.all(
        routes.map(({ route }) => RouteDataLoader[route.id](store.dispatch))
      )

      const {
        user: { uid },
      } = store.getState()

      if (uid) {
        // If user is authenticated, load upcoming contests
        await store.dispatch(
          contestsApi.endpoints.getAvailableContests.initiate()
        )
      }

      await Promise.all([
        ...store.dispatch(contestsApi.util.getRunningQueriesThunk()),
        ...store.dispatch(archivesApi.util.getRunningQueriesThunk()),
      ])
    },
  },

  client: {},

  layout: "normal",
}

const links: ILinkDetails[] = [
  { href: "/contest", name: "pages.contests.links.public" },
  { href: "/contest/history", name: "pages.contests.links.history" },
  { href: "/contest/manage", name: "pages.contests.links.manage" },
]

function withContestHeader(Component: React.ComponentType): React.FC {
  return function ContestList() {
    return (
      <Wrapper>
        <PageHeader links={links} header="pages.contests.header" />

        <Component />
      </Wrapper>
    )
  }
}

const Wrapper = styled.div`
  width: 100%;
  height: max-content;
  min-height: 100%;

  position: relative;
`

import { useCallback, useState } from "react"

import { ProblemDifficulty } from "@/store/api/problems/types"

export default function useDifficultiesFilter(
  initialDifficulties: ProblemDifficulty[] = []
) {
  const [difficulties, setDifficulties] =
    useState<ProblemDifficulty[]>(initialDifficulties)

  const toggleDifficulty = useCallback((difficulty: ProblemDifficulty) => {
    setDifficulties(prev => {
      if (prev.includes(difficulty)) return prev.filter(d => d !== difficulty)

      return [...prev, difficulty]
    })
  }, [])

  return {
    difficulties,
    toggleDifficulty,
  }
}

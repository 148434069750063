import {
  ChangeEventHandler,
  SyntheticEvent,
  useCallback,
  useState,
} from "react"

import styled from "styled-components"

import { EmailAuthStatus } from "@/store/api/auth/types"

import BaseInput from "@/components/BaseInput"
import CustomButton from "@/components/CustomButton"
import LoginFormLabel from "@/features/auth/LoginFlow/components/LoginFormLabel"
import LoginTitle from "@/features/auth/LoginFlow/components/LoginTitle"
import LoginWrapper from "@/features/auth/LoginFlow/components/LoginWrapper"
import {
  LoginControls,
  WithForwardedRef,
} from "@/features/auth/LoginFlow/types"
import { text16Bold } from "@/utils/fonts"
import useScopedTranslation from "@/utils/hooks/useScopedTranslation"
import mediaQueryFor from "@/utils/mediaQuery"

export default function CreatePassword({
  info,
  updateInfo,
  isLoading,
  authorize,
  turnstile,
  forwardedRef,
}: WithForwardedRef<LoginControls, HTMLDivElement>) {
  const t = useScopedTranslation("auth.widget.create_password")

  const [passwordConfirmation, setConfirmation] = useState("")
  const [hasError, setError] = useState(false)

  const onChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    ({ target }) => {
      setError(false)
      updateInfo({ password: target.value })
    },
    [updateInfo],
  )

  const onConfirmationChange = useCallback<
    ChangeEventHandler<HTMLInputElement>
  >(({ target }) => {
    setError(false)
    setConfirmation(target.value)
  }, [])

  const onContinue = useCallback(
    async (ev: SyntheticEvent) => {
      ev.preventDefault()

      if (
        info.password !== passwordConfirmation ||
        !info.password ||
        !passwordConfirmation
      )
        return setError(true)

      const result = await authorize(info)

      if (result.status === EmailAuthStatus.TokenFailure) return // TODO: better error handling
    },
    [authorize, info, passwordConfirmation],
  )

  const isEmpty = !info.password || !passwordConfirmation
  const isRegistration = !info.reset_password

  const i18n = {
    title: isRegistration ? t("last_step") : t("invent_password"),
    confirm: isRegistration ? t("create_account") : t("log_in"),
  }

  return (
    <LoginWrapper onSubmit={onContinue} ref={forwardedRef}>
      <LoginTitle>{i18n.title}</LoginTitle>

      <PasswordEntry action="#">
        <input
          hidden
          type="text"
          name="email"
          value={info.email}
          readOnly
          autoComplete="username email"
        />

        <InputWrapper>
          <LoginFormLabel htmlFor="password">{t("password")}</LoginFormLabel>
          <BaseInput
            type="password"
            autoComplete="new-password"
            name="password"
            disabled={isLoading}
            onChange={onChange}
          />
        </InputWrapper>

        <InputWrapper>
          <LoginFormLabel htmlFor="repeat-password">
            {t("confirm_password")}
          </LoginFormLabel>
          <BaseInput
            type="password"
            autoComplete="new-password"
            name="repeat-password"
            disabled={isLoading}
            onChange={onConfirmationChange}
            data-errored={hasError}
          />
        </InputWrapper>

        {turnstile}

        <ConfirmPassword data-type="primary" disabled={isLoading || isEmpty}>
          {i18n.confirm}
        </ConfirmPassword>
      </PasswordEntry>
    </LoginWrapper>
  )
}

const PasswordEntry = styled.form`
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-content: start;

  gap: 16px;

  ${mediaQueryFor.mobile} {
    width: 100%;
  }
`

const InputWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: start;

  gap: 24px;

  label {
    flex: 0 0 55px;
  }
`

const ConfirmPassword = styled(CustomButton)`
  ${text16Bold};
  letter-spacing: 0.16px;

  width: 214px;

  ${mediaQueryFor.mobile} {
    width: 100%;
  }
`

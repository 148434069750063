import { ForwardedRef, forwardRef } from "react"

import styled from "styled-components"

import { IArchiveContest } from "@/store/api/archives/types"

import Dot from "~/assets/icons/Dot"

import Card from "@/components/Card"
import ProgressBarWithLabel from "@/components/ProgressBar"
import { text13Medium, text16Medium } from "@/utils/fonts"

interface IArchiveEntryProps {
  archive: IArchiveContest
}

function ArchiveEntry(
  { archive }: IArchiveEntryProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  const { name, org, period, stats } = archive

  const solvedPercent = Math.round(
    (stats.solved_problems / stats.total_problems) * 100
  )

  return (
    <StyledCard ref={ref}>
      <CardHeader>{name}</CardHeader>
      <BottomWrapper>
        <CardOrganizer>
          {org && <p>{org}</p>}
          {org && <Dot />}
          <p>{period}</p>
        </CardOrganizer>
        {stats.solved_problems > 0 && (
          <ProgressBarWithLabel progress={solvedPercent} />
        )}
      </BottomWrapper>
    </StyledCard>
  )
}

export default forwardRef<HTMLDivElement, IArchiveEntryProps>(ArchiveEntry)

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const CardHeader = styled.h4`
  height: 60px;
  overflow: hidden;
  width: 100%;

  margin: 0;
  ${text16Medium};
`

const CardOrganizer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 6px;

  > p {
    margin: 0;
    opacity: 0.5;
    ${text13Medium};
  }

  > svg {
    fill: var(--color-text);
    opacity: 0.5;
    transition: fill var(--transition-duration) var(--transition-function);
  }
`

const BottomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

import React from "react"

import { useRefetchContestsMutation } from "@/store/api/contests"

import CardGrid from "@/components/CardGrid"

import { IContestWithRequestFulfilledAt } from "./types"
import UpcomingEntry from "./UpcomingEntry"

interface IUpcomingContainerProps {
  contests: IContestWithRequestFulfilledAt[]
}

function UpcomingContainer({ contests }: IUpcomingContainerProps) {
  const [updateContests] = useRefetchContestsMutation()

  return (
    <CardGrid>
      {contests.map(contest => (
        <UpcomingEntry
          key={contest.id}
          contest={contest}
          onExpire={updateContests}
        />
      ))}
    </CardGrid>
  )
}

export default React.memo(UpcomingContainer)

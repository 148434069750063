import { useMemo } from "react"

import { DateTime } from "luxon"
import { useTranslation } from "react-i18next"

export default function useMoscowTime(seconds: number): string {
  const {
    i18n: { resolvedLanguage },
    t,
  } = useTranslation()

  const i18n = {
    same: t("pages.contests.datetime.same_year"),
    different: t("pages.contests.datetime.different_year"),
  }

  const formatted = useMemo(() => {
    const time = DateTime.fromSeconds(seconds)
      .setLocale(resolvedLanguage)
      .setZone("Europe/Moscow")

    const isSameYear = DateTime.now().hasSame(time, "year")

    return isSameYear ? time.toFormat(i18n.same) : time.toFormat(i18n.different)
  }, [i18n.different, i18n.same, resolvedLanguage, seconds])

  return formatted
}

import { IPageConfig } from "@/utils/types"

export const config: IPageConfig = {
  routes: { path: "/profile/edit", Component: EditProfile },

  server: {},
  client: {},

  layout: "normal",
}

function EditProfile() {
  return <div>Edit Profile</div>
}

import { Helmet } from "react-helmet-async"

interface AlternateTranslationsLinksProps {
  base: string
  langs: string[] | null
}

export default function AlternateTranslationsLinks({
  base,
  langs,
}: AlternateTranslationsLinksProps) {
  return (
    <Helmet>
      {langs?.map(lang => (
        <link
          key={lang}
          rel="alternate"
          href={`${base}?lang=${lang}`}
          hrefLang={lang}
        />
      ))}
    </Helmet>
  )
}

import React from "react"

import { useTranslation } from "react-i18next"
import styled from "styled-components"

import { ErrorMessage } from "@/store/slices/auth/types"

import GoogleLoginButton from "@/components/auth/GoogleLoginButton"
import TelegramLoginButton from "@/components/auth/TelegramLoginButton"
import { useAppSelector } from "@/store"
import {
  text14Medium,
  text15Medium,
  text16Medium,
  text20Medium,
} from "@/utils/fonts"
import mediaQueryFor from "@/utils/mediaQuery"

interface LoginContainerProps {
  className?: string
  title?: string
}

function LoginContainer({
  className = undefined,
  title = undefined,
}: LoginContainerProps) {
  const error = useAppSelector(state => state.auth.lastError)
  const { t } = useTranslation()

  const i18n = {
    title: title ?? t("auth.title"),
    errors: {
      [ErrorMessage.unknown]: t("auth.errors.unknown"),
    },
  }

  return (
    <Wrapper data-has-error={!!error} className={className}>
      <h3>{i18n.title}</h3>

      <TelegramLoginButton type="create" />
      <GoogleLoginButton type="create" />

      {!!error && <h4>{i18n.errors[error]}</h4>}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 580px;
  height: max-content;

  background-color: var(--logins-background);

  transition: color var(--transition-duration) var(--transition-function),
    background-color var(--transition-duration) var(--transition-function),
    border-color var(--transition-duration) var(--transition-function);

  display: grid;
  grid-template-areas:
    "title title"
    "telegram google";
  grid-template-rows: repeat(2, max-content);
  grid-template-columns: 1fr 1fr;

  padding: 24px 20px;
  border: 1px solid var(--links-accent);
  border-radius: 10px;

  gap: 24px;

  &[data-has-error="true"] {
    grid-template-areas:
      "title title"
      "telegram google"
      "error error";

    grid-template-rows: repeat(3, max-content);
  }

  > h3 {
    ${text20Medium};
    margin: 0;
    height: 25px;

    color: var(--color-text);

    grid-area: title;

    transition: color var(--transition-duration) var(--transition-function);
  }

  > h4 {
    ${text16Medium};
    color: var(--color-red);
    margin: 0;

    grid-area: error;
  }

  ${mediaQueryFor.mobile} {
    width: 100%;
    margin-top: 0px;

    grid-template-columns: 100%;
    grid-template-rows: repeat(3, max-content);

    gap: 12px;

    > h3 {
      ${text15Medium};
      text-align: center;

      height: max-content;
      margin-bottom: 8px;
    }

    > h4 {
      ${text14Medium};
    }

    grid-template-areas:
      "title"
      "telegram"
      "google";

    &[data-has-error="true"] {
      grid-template-rows: repeat(4, max-content);

      grid-template-areas:
        "title"
        "telegram"
        "google"
        "error";

      padding-bottom: 12px;
    }
  }
`

export default React.memo(LoginContainer)

import { useTranslation } from "react-i18next"
import styled from "styled-components"

import {
  Format,
  RatingSystem,
  Stage,
} from "@/features/task/creation/variations/FromScratch/types"
import { text16, text18Medium } from "@/utils/fonts"

import SelectorGridItem from "./SelectorGridItem"

const gridItems = {
  [Stage.Format]: [
    { i18nTitle: "normal", selectResult: Format.Normal },
    { i18nTitle: "interactive", selectResult: Format.Interactive },
    { i18nTitle: "double_start", selectResult: Format.DoubleStart },
    { i18nTitle: "open_tests", selectResult: Format.OpenTests },
    { i18nTitle: "grader", selectResult: null },
    { i18nTitle: "custom", selectResult: null },
  ],
  [Stage.RatingSystem]: [
    { i18nTitle: "ioi", selectResult: RatingSystem.IOI },
    { i18nTitle: "icpc", selectResult: RatingSystem.ICPC },
    { i18nTitle: "custom", selectResult: RatingSystem.Custom },
  ],
}

interface ISelectorGridProps {
  stage: Stage
  onClick: (stage: Stage, selectResult: Format | RatingSystem) => void
}

export default function SelectorGrid({ stage, onClick }: ISelectorGridProps) {
  const { t } = useTranslation()

  return (
    <Wrapper>
      {gridItems[stage].map(({ i18nTitle, selectResult }) => {
        const stageAlias = stage === Stage.Format ? "format" : "rating_system"
        const title = `pages.task.create.from_scratch.stages.${stageAlias}.options.${i18nTitle}.title`
        const description = `pages.task.create.from_scratch.stages.${stageAlias}.options.${i18nTitle}.description`

        const handleClick = () => {
          if (selectResult !== null) onClick(stage, selectResult)
        }

        const disabled = selectResult === null

        return (
          <SelectorGridItem
            key={title}
            onClick={handleClick}
            data-disabled={disabled}
          >
            <Title>{t(title)}</Title>
            <Description>{t(description)}</Description>
          </SelectorGridItem>
        )
      })}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 400px);
  grid-gap: 16px;
`

const Title = styled.span`
  color: var(--color-text);
  transition: color var(--transition-duration) var(--transition-function);
  ${text18Medium};
`

const Description = styled.span`
  color: var(--color-text);
  transition: color var(--transition-duration) var(--transition-function);
  opacity: 0.8;
  ${text16};
`

import React from "react"

import { Helmet } from "react-helmet-async"
import { Trans, useTranslation } from "react-i18next"
import styled from "styled-components"

import LoginContainer from "@/features/auth/LoginContainer"
import LinkTable from "@/features/main/LinkTable"
import MainHeader from "@/features/main/MainHeader"
import Socials from "@/features/main/Socials"
import { useAppSelector } from "@/store"
import dynamic from "@/utils/dynamic"
import { text20 } from "@/utils/fonts"
import { IPageConfig } from "@/utils/types"

const Animation = dynamic(() => import("@/components/HomepageSpline"), {
  ssr: false,
})

export const config: IPageConfig = {
  routes: { path: "/", Component: Home },

  server: {},
  client: {},

  layout: "normal",
}

function Home() {
  const token = useAppSelector(({ user }) => user.token)
  const name = useAppSelector(({ user }) => user.handle)

  const { t } = useTranslation()

  const i18n = {
    title: t("pages.home.title"),
    guest: {
      header: t("pages.home.guest.header"),
      description: "pages.home.guest.description",
    },
    authed: {
      header: t("pages.home.authed.header", { name }),
    },
  }

  const isLoggedIn = token !== null

  return (
    <>
      <Helmet>
        <title>{i18n.title}</title>
        <link rel="canonical" href="https://sort-me.org" />
      </Helmet>

      <Wrapper>
        {isLoggedIn ? (
          <MainHeader>{i18n.authed.header}</MainHeader>
        ) : (
          <>
            {/* <Animation /> */}

            <MainHeader>{i18n.guest.header}</MainHeader>
            <Trans i18nKey={i18n.guest.description} parent={Description} />
            <LoginContainer />
          </>
        )}

        <LinkTable />
        <Socials />
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  grid-template-rows: repeat(5, max-content);
  grid-template-areas:
    "header header ."
    "description . ."
    "login . ."
    "links links links"
    "socials socials socials ";
`

const Description = styled.p`
  grid-area: description;

  margin: 27px 0 0 0;
  padding: 0;

  max-width: 580px;

  ${text20};
`

import { useMemo } from "react"

import { Trans, useTranslation } from "react-i18next"
import styled from "styled-components"

import RankGoldOne from "~/assets/images/rank/GoldOne"
import RankGoldThree from "~/assets/images/rank/GoldThree"
import RankGoldTwo from "~/assets/images/rank/GoldTwo"
import RankObsolete from "~/assets/images/rank/Obsolete"
import RankPlatinumOne from "~/assets/images/rank/PlatinumOne"
import RankPlatinumThree from "~/assets/images/rank/PlatinumThree"
import RankPlatinumTwo from "~/assets/images/rank/PlatinumTwo"
import RankSilverOne from "~/assets/images/rank/SilverOne"
import RankSilverThree from "~/assets/images/rank/SilverThree"
import RankSilverTwo from "~/assets/images/rank/SilverTwo"
import RankTopOne from "~/assets/images/rank/TopOne"
import RankUnknown from "~/assets/images/rank/Unknown"

import { text13Medium, text18Medium } from "@/utils/fonts"
import mediaQueryFor from "@/utils/mediaQuery"

interface IRegalRankProps {
  rank: number
  updated: number
}

export default function RegalRank({ rank }: IRegalRankProps) {
  const { t } = useTranslation()

  const [Rank, title] = useMemo(
    () => [findRankIcon(rank), findRankText(rank)],
    [rank]
  )

  return (
    <Wrapper>
      <Rank />
      <Trans
        t={t}
        parent={Title}
        i18nKey={title}
        components={{ next: <br /> }}
      />
    </Wrapper>
  )
}

function findRankIcon(rank: number) {
  // TODO: maybe React.lazy?
  switch (rank) {
    case -1:
      return RankObsolete
    case 0:
      return RankUnknown

    case 1:
      return RankSilverOne
    case 2:
      return RankSilverTwo
    case 3:
      return RankSilverThree

    case 4:
      return RankGoldOne
    case 5:
      return RankGoldTwo
    case 6:
      return RankGoldThree

    case 7:
      return RankPlatinumOne
    case 8:
      return RankPlatinumTwo
    case 9:
      return RankPlatinumThree

    case 10:
      return RankTopOne

    default:
      return RankUnknown
  }
}

function findRankText(rank: number) {
  switch (rank) {
    case -1:
      return "pages.profile.regals.rank.obsolete"
    case 0:
      return "pages.profile.regals.rank.unknown"

    case 1:
      return "pages.profile.regals.rank.silver_one"
    case 2:
      return "pages.profile.regals.rank.silver_two"
    case 3:
      return "pages.profile.regals.rank.silver_three"

    case 4:
      return "pages.profile.regals.rank.gold_one"
    case 5:
      return "pages.profile.regals.rank.gold_two"
    case 6:
      return "pages.profile.regals.rank.gold_three"

    case 7:
      return "pages.profile.regals.rank.platinum_one"
    case 8:
      return "pages.profile.regals.rank.platinum_two"
    case 9:
      return "pages.profile.regals.rank.platinum_three"

    case 10:
      return "pages.profile.regals.rank.top_one"

    default:
      return "pages.profile.regals.rank.unknown"
  }
}

const Wrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;

  height: max-content;

  padding: 24px 20px;

  gap: 32px;

  transition: color var(--transition-duration) var(--transition-function);

  color: var(--color-text);

  ${mediaQueryFor.mobile} {
    gap: 20px;
    padding: 16px 15px;

    > svg {
      width: 108px;
      height: 33px;
    }
  }
`

const Title = styled.span`
  ${text18Medium};

  max-width: 200px;

  ${mediaQueryFor.mobile} {
    ${text13Medium};
  }
`

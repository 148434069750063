import { MouseEventHandler, useCallback } from "react"

import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"

import PlusIcon from "~/assets/icons/PlusIcon"

import useIsEditable from "@/features/task/hooks/useIsEditable"
import { text15Medium } from "@/utils/fonts"

export default function AddButton() {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleCreateTest = useCallback<MouseEventHandler<HTMLButtonElement>>(
    () => navigate("new", { state: { forced: true } }),
    [navigate],
  )

  const { isEditable } = useIsEditable()

  if (!isEditable) return null

  const i18n = {
    text: t("pages.task.edit.stages.tests.add.text"),
    loading: t("pages.task.edit.stages.tests.add.loading"),
  }

  return (
    <Wrapper onClick={handleCreateTest}>
      <PlusIcon />
      {i18n.text}
    </Wrapper>
  )
}

const Wrapper = styled.button`
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 0;

  ${text15Medium};
  color: var(--color-primary);
  transition: color var(--transition-duration) var(--transition-function);

  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 20px;

  > svg {
    path {
      fill: var(--color-primary);
      transition: fill var(--transition-duration) var(--transition-function);
    }
  }
`

import { useCallback, useEffect, useRef, useState } from "react"

import styled from "styled-components"

import { text22Medium } from "@/utils/fonts"

const DIGIT_REGEX = /^\d$/

interface CodeEntryProps {
  onCodeReady: (code: string) => void
  disabled?: boolean
}

export default function CodeEntry({
  onCodeReady,
  disabled = false,
}: CodeEntryProps) {
  const [code, setCode] = useState("______")

  const inputRef = useRef<HTMLInputElement>(null)
  const fireRef = useRef(false)

  const digits = code.split("")
  const active = digits.indexOf("_")

  const focus = useCallback(() => inputRef.current?.focus?.(), [])

  useEffect(() => {
    if (disabled) return

    const onKeyDown = ({ key }: KeyboardEvent) => {
      fireRef.current = true

      if (DIGIT_REGEX.test(key)) return setCode(code => appendToCode(code, key))

      if (key === "Backspace" || key === "Delete")
        return setCode(code => removeFromCode(code))
    }

    document.addEventListener("keydown", onKeyDown, { passive: true })

    return () => document.removeEventListener("keydown", onKeyDown)
  }, [disabled])

  useEffect(() => {
    if (disabled) return

    const onPaste = ({ clipboardData }: ClipboardEvent) => {
      if (!clipboardData) return

      const data = clipboardData
        .getData("text/plain")
        .replaceAll(/\D/g, "")
        .slice(0, 6)
        .split("")

      if (!data) return

      setCode(code => data.reduce((acc, key) => appendToCode(acc, key), code))

      fireRef.current = true
    }

    document.addEventListener("paste", onPaste, { passive: true })

    return () => document.removeEventListener("paste", onPaste)
  }, [disabled])

  useEffect(() => {
    if (code.includes("_") || !fireRef.current) return

    fireRef.current = false
    onCodeReady(code)
  }, [code, onCodeReady])

  useEffect(() => {
    if (!disabled) focus()
  }, [disabled, focus])

  return (
    <Code onClick={focus}>
      {digits.map((digit, idx) => (
        <CodeDigit data-active={idx === active} key={idx}>
          {digit === "_" ? null : digit}
        </CodeDigit>
      ))}

      <Input
        type="text"
        autoComplete="one-time-code"
        name="code"
        inputMode="numeric"
        maxLength={6}
        disabled={disabled}
        ref={inputRef}
      />
    </Code>
  )
}

const appendToCode = (code: string, digit: string): string =>
  code.replace("_", digit)

const removeFromCode = (code: string) => code.replace(/\d(_|$)/, "_$1") // Replace last digit with `_`

const Code = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 8px;
`

const CodeDigit = styled.span`
  ${text22Medium};

  width: 41px;
  height: 45px;
  line-height: 45px;

  text-align: center;

  color: var(--color-text);

  background: var(--color-bubble);
  border: 1px solid var(--color-card-stroke);
  border-radius: 5px;

  transition:
    color var(--transition-duration) var(--transition-function),
    background-color var(--transition-duration) var(--transition-function);

  &[data-active="true"] {
    border-bottom-color: var(--color-primary);
  }
`

const Input = styled.input`
  position: absolute;
  opacity: 0;

  z-index: -1;

  width: 0;
  height: 0;
  padding: 0;

  border: none;
`

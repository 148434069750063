import { ChangeEvent } from "react"

import styled from "styled-components"

import { text14Medium, text18Medium } from "@/utils/fonts"
import mediaQueryFor from "@/utils/mediaQuery"

interface ISearchBarProps {
  value: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  placeholder?: string
}

const SearchBar = styled.input<ISearchBarProps>`
  background: var(--color-background);
  border: 1px solid var(--color-input-stroke);
  border-radius: 30px;
  transition: color var(--transition-duration) var(--transition-function),
    background-color var(--transition-duration) var(--transition-function),
    border-color var(--transition-duration) var(--transition-function),
    box-shadow var(--transition-duration) var(--transition-function);

  height: 38px;
  padding: 8px 20px;
  box-sizing: border-box;

  ${text18Medium};
  color: var(--color-text);

  &::placeholder {
    opacity: 0.3;
  }

  &:focus {
    outline: none;
    box-shadow: 0px 0px 0 1px #c4c4c4;
    border: 1px solid #c4c4c4;
  }

  ${mediaQueryFor.mobile} {
    height: 32px;
    padding: 8px 16px 7px 16px;

    ${text14Medium};
  }
`

export default SearchBar

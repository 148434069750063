import { useTranslation } from "react-i18next"
import styled from "styled-components"

import DisappointedEmoji from "~/assets/icons/DisappointedEmoji"

import { header24, header30, text14Medium, text18Medium } from "@/utils/fonts"
import mediaQueryFor from "@/utils/mediaQuery"

export default function Empty() {
  const { t } = useTranslation()

  const i18n = {
    emptyHeader: t("pages.contests.history.empty_header"),
    emptyText: t("pages.contests.history.empty_text"),
  }

  return (
    <EmptyWrapper>
      <DisappointedEmoji />
      <EmptyHeader>{i18n.emptyHeader}</EmptyHeader>
      <EmptyText>{i18n.emptyText}</EmptyText>
    </EmptyWrapper>
  )
}

const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 20px;
  // TODO: fix here
  height: calc(var(--window-h) - var(--shift-y) - 212px);
  text-align: center;

  ${mediaQueryFor.mobile} {
    height: calc(var(--window-h) - var(--shift-y) - 145px);
  }
`

const EmptyHeader = styled.h2`
  margin: 10px 0 0 0;
  ${header30};

  ${mediaQueryFor.mobile} {
    max-width: 338px;
    ${header24};
  }
`

const EmptyText = styled.p`
  max-width: 595px;
  margin: 0;
  ${text18Medium};

  ${mediaQueryFor.mobile} {
    max-width: 338px;
    ${text14Medium};
  }
`

import styled from "styled-components"

import { text14Medium, text16Medium } from "@/utils/fonts"

const UserDetails = styled.div`
  display: grid;
  grid-template-areas:
    "avatar handle"
    "avatar description";
  align-items: center;
  justify-content: start;
  column-gap: 20px;

  > picture {
    grid-area: avatar;
  }

  > p {
    grid-area: handle;
    margin: 0;
    ${text16Medium};

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  > span {
    grid-area: description;
    ${text14Medium};
    opacity: 0.5;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &[data-disabled="true"]:hover {
    cursor: not-allowed;
  }
`

export default UserDetails

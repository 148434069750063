import { useCallback, useMemo } from "react"

import { getDraft } from "@/features/task/edit/legend/utils/helpers"

interface IUseRestoreProps {
  problemId: string
  translation: string
  restore: ({ title, legend }: { title: string; legend: string }) => void
}

export default function useRestore({
  problemId,
  translation,
  restore,
}: IUseRestoreProps) {
  const draft = useMemo(() => getDraft(problemId), [problemId])

  const handleRestore = useCallback(() => {
    restore({
      title: draft[translation].title,
      legend: draft[translation].legend,
    })

    delete draft[translation]
  }, [translation, draft, restore])

  return {
    draft,
    handleRestore,
  }
}

import { useTranslation } from "react-i18next"
import Lottie, { Options as LottieOptions } from "react-lottie"
import styled from "styled-components"

import { useGetTipQuery } from "@/store/api/misc"
import { ISubmissionDetails } from "@/store/api/submissions/types"

import bars from "~/assets/documents/bars.json"
import ReloadIcon from "~/assets/icons/ReloadIcon"

import Line from "@/components/Line"
import { text18Medium, text30Medium } from "@/utils/fonts"

import SubmissionResult from "./SubmissionResult"

const options: LottieOptions = {
  loop: true,
  autoplay: true,
  animationData: bars,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
    className: "lottie-loader",
  },
}

export default function SubmissionLoader({
  submission,
}: {
  submission: ISubmissionDetails
}) {
  const { t } = useTranslation()
  const { data: { tip = "" } = {}, refetch: getNewTip } = useGetTipQuery()

  const { results: { verdict = 0 } = {} } = submission ?? {}

  const i18n = {
    title: t("pages.task.submission_modal.checking"),
    getNewTip: t("pages.task.submission_modal.tip.get_new"),
    showTip: t("pages.task.submission_modal.tip.show", { tip }),
  }

  if (verdict === 0) {
    return (
      <>
        <Line />
        <Wrapper>
          <Lottie options={options} width={150} height={150} />
          <h3>{i18n.title}</h3>

          <p>{i18n.showTip}</p>
          <span onClick={getNewTip}>
            <ReloadIcon />
            {i18n.getNewTip}
          </span>
        </Wrapper>
      </>
    )
  }

  return <SubmissionResult submission={submission} />
}

const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  width: 100%;

  h3 {
    margin: 0;

    ${text30Medium};
  }

  p {
    display: inline-block;

    color: var(--color-text);
    opacity: 0.5;

    ${text18Medium};

    text-align: center;
  }

  span {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;

    gap: 15px;
    cursor: pointer;

    ${text18Medium};

    color: var(--color-primary);

    svg {
      path {
        fill: var(--color-primary);
      }
    }
  }
`

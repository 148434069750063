import { useTranslation } from "react-i18next"
import styled from "styled-components"

import { Integration } from "@/store/slices/auth/types"

import { IUserDetails } from "@/store/api/users/types"

import GoogleIcon from "~/assets/icons/GoogleIcon"
import TelegramIcon from "~/assets/icons/TelegramIcon"

import { text14Medium, text18Medium } from "@/utils/fonts"
import mediaQueryFor from "@/utils/mediaQuery"

import ConnectButton from "./ConnectButton"

interface IAccountButtonsProps {
  integrations: IUserDetails["integrations"]
}

export default function AccountButtons({ integrations }: IAccountButtonsProps) {
  const { t } = useTranslation()

  const i18n = {
    google: t("shared.google"),
    telegram: t("shared.telegram"),
  }

  return (
    <Wrapper>
      <ConnectionBox>
        <TelegramIcon fill="#69a8e5" />
        <span>{i18n.telegram}</span>

        <ConnectButton
          kind={Integration.telegram}
          current={integrations?.telegram?.name ?? null}
        />
      </ConnectionBox>
      <ConnectionBox>
        <GoogleIcon />
        <span>{i18n.google}</span>

        <ConnectButton
          kind={Integration.google}
          current={integrations?.google?.name ?? null}
        />
      </ConnectionBox>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  grid-area: connect;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  gap: 16px;

  ${mediaQueryFor.mobile} {
    display: grid;

    grid-template-columns: 1fr 1fr;
    grid-template-rows: max-content;

    grid-auto-flow: row;

    margin-top: 32px;
  }
`

const ConnectionBox = styled.div`
  display: grid;
  grid-template-areas:
    "icon name"
    "connect connect";
  grid-template-rows: 24px max-content;
  grid-template-columns: 24px 1fr;

  row-gap: 14px;
  column-gap: 16px;

  flex: 1 0 auto;

  background-color: var(--body-color);

  transition: background-color var(--transition-duration)
    var(--transition-function);

  padding: 13px 16px;
  border-radius: 8px;

  > svg {
    grid-area: icon;
    width: 24px;
    height: 24px;
  }

  > span {
    grid-area: name;
    ${text18Medium};
  }

  > a {
    color: var(--link-color);
    ${text14Medium};

    grid-area: connect;
  }

  ${mediaQueryFor.mobile} {
    grid-template-rows: 18px max-content;
    grid-template-columns: 18px 1fr;

    box-sizing: border-box;

    column-gap: 12px;
    row-gap: 8px;

    > svg {
      width: 18px;
      height: 18px;
    }

    > span {
      ${text14Medium};
      line-height: 18px;
    }

    width: 100%;
  }
`

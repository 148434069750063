import { MouseEventHandler, useCallback, useRef } from "react"

import { Transition } from "react-transition-group"
import styled from "styled-components"

interface IModalProps {
  isOpen: boolean
  close: () => void
  onExit?: () => void
  children?: React.ReactNode
  className?: string
}

export default function Modal({
  isOpen,
  close,
  onExit = undefined,
  children = null,
  className = "",
}: IModalProps) {
  const wrapper = useRef<HTMLDivElement>(null)

  const stopPropagation = useCallback<MouseEventHandler<HTMLDivElement>>(
    event => event.stopPropagation(),
    [],
  )

  return (
    <Transition
      in={isOpen}
      timeout={200}
      nodeRef={wrapper}
      appear
      onExited={onExit}
    >
      {state => (
        <Background
          onMouseDown={state === "entered" ? close : stopPropagation}
          onClick={stopPropagation}
          data-open={state}
          ref={wrapper}
        >
          <ModalWrapper
            data-open={state}
            onMouseDown={stopPropagation}
            onClick={stopPropagation}
            className={className}
          >
            {children}
          </ModalWrapper>
        </Background>
      )}
    </Transition>
  )
}

const Background = styled.div`
  background-color: #0000007f;

  width: 100vw;
  height: var(--window-h);

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;

  overflow-x: hidden;
  overflow-y: overlay;

  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0px;
  }

  &[data-open="entering"] {
    animation: appear 0.2s ease-out;
  }

  &[data-open="exiting"] {
    animation: appear 0.2s ease-out reverse;
  }

  &[data-open="exited"] {
    display: none;
  }

  @keyframes appear {
    from {
      background-color: transparent;
    }
    to {
      background-color: #0000007f;
    }
  }
`

const ModalWrapper = styled.div`
  background: var(--color-g-background);
  border: 1px solid var(--color-g-background-stroke);
  border-radius: 10px;

  position: relative;
  margin: 100px auto;

  &[data-open="entering"] {
    animation: popup 0.2s cubic-bezier(0, 1, 0, 1);
  }

  &[data-open="exiting"] {
    animation: popup 0.2s cubic-bezier(0, 1, 0, 1) reverse;
  }

  &[data-open="exited"] {
    display: none;
  }

  @keyframes popup {
    from {
      transform: translate(0, 200px);
    }

    to {
      transform: translate(0, 0);
    }
  }
`

import { useMemo } from "react"

import { useGetProblemByIdQuery } from "@/store/api/problems"

export default function useTranslatedProblem(id: number, lang: null | string) {
  const { data, isFetching } = useGetProblemByIdQuery({ id, lang })

  const selected = useMemo(() => {
    // No data yet
    if (!data) return null

    // We have requested a specific language. Only try and return what's been requested
    if (lang && lang in data.langs) return data.langs[lang]

    // No specific preference - load the first available language
    // That *will* match with what backend selected given our preferences
    for (const lang of Object.keys(data.langs)) return data.langs[lang]

    // No translations found, should never happen
    // Display nothing (probably an error?)
    return null
  }, [data, lang])

  return {
    available: data?.available ?? null,
    selected,
    isFetching,
  }
}

import styled from "styled-components"

import Divider from "@/components/Divider"
import { text15Medium } from "@/utils/fonts"
import mediaQueryFor from "@/utils/mediaQuery"

interface ISolvedAmountColumnProps {
  solvedAmount: number
}

export default function SolvedAmountColumn({
  solvedAmount,
}: ISolvedAmountColumnProps) {
  return (
    <Column>
      <Divider data-divider />
      {solvedAmount}
    </Column>
  )
}

const Column = styled.div`
  display: flex;
  align-items: center;

  ${text15Medium};
  color: var(--color-text);
  transition: color var(--transition-duration) var(--transition-function);

  ${Divider} {
    margin-right: 13px;
  }

  ${mediaQueryFor.mobile} {
    display: none;
  }
`

import { loadToken } from "@/store/slices/auth/token"

import baseApi from "@/store/api/base"

import { EmailAuthRequest, EmailAuthResponse, EmailAuthStatus } from "./types"

type ResponseWith<Status extends EmailAuthStatus> = Extract<
  EmailAuthResponse,
  { status: Status }
>

const withStatus = <const Status extends EmailAuthStatus>(
  data: Omit<EmailAuthResponse, "status">,
  status: Status,
) => ({ ...data, status }) as ResponseWith<Status>

const authApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    emailAuth: builder.mutation<EmailAuthResponse, EmailAuthRequest>({
      query: ({ token, ...body }) => ({
        method: "POST",
        url: "https://beta.sort-me.org/api/auth?provider=email",
        body,
        headers: { "x-htoken": token },
        /* always consider this request a success */
        validateStatus: () => true,
      }),
      transformResponse: (raw, meta) => {
        const data = raw as Omit<EmailAuthResponse, "status">

        switch (meta?.response?.status) {
          case 200: // OK
            return withStatus(data, EmailAuthStatus.Success)
          case 202: // Accepted
            return withStatus(data, EmailAuthStatus.Accepted)
          case 403: // Forbidden (token error)
            return withStatus(data, EmailAuthStatus.TokenFailure)
          default:
            return withStatus(
              { error: "unkown error", ...data },
              EmailAuthStatus.Error,
            )
        }
      },
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled

        if (data.status === EmailAuthStatus.Success)
          dispatch(loadToken(data.token))
      },
    }),
  }),
})

export default authApi

export const { useEmailAuthMutation } = authApi

import { Helmet } from "react-helmet-async"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

import Loader from "@/components/Loader"
import ContestContainer from "@/features/contest/ContestContainer"
import useManagedPagination from "@/features/contest/hooks/useManagedPagintation"
import { text18Medium, text14Medium } from "@/utils/fonts"
import mediaQueryFor from "@/utils/mediaQuery"

import CreateContestBar from "./CreateContestBar"
import Empty, { EmptyNoQuota } from "./ManageEmpty"

export default function ContestManage() {
  const { t } = useTranslation()

  const i18n = {
    title: t("pages.contests.manage.title"),
    mainText: t("pages.contests.manage.main_text"),
  }

  const {
    data: { contests, receivedAt, remainingQuota, hasQuota },

    isLoading,
    isFetchingMore,
    isEmpty,
    isExhausted,
    isRefetching,

    fetchMore,
    refetch,
  } = useManagedPagination()

  const isScrollable = !isEmpty && !isExhausted

  return (
    <>
      <Helmet>
        <title>{i18n.title}</title>
        <link rel="canonical" href="https://sort-me.org/contest/manage" />
      </Helmet>

      <Loader loading={isRefetching} size="large">
        {!isEmpty && (
          <>
            <ManagedText>{i18n.mainText}</ManagedText>
            <CreateContestBar remainingQuota={remainingQuota} />
            <ContestContainer
              contests={contests}
              receivedAt={receivedAt!}
              enableScrolling={isScrollable}
              isFetchingMore={isFetchingMore}
              fetchMore={fetchMore}
              refetch={refetch}
            />
          </>
        )}

        {isEmpty && !isLoading && (
          <>{hasQuota ? <Empty /> : <EmptyNoQuota />}</>
        )}
      </Loader>
    </>
  )
}

const ManagedText = styled.p`
  max-width: 679px;
  margin: 0 0 32px 0;
  ${text18Medium};

  ${mediaQueryFor.mobile} {
    ${text14Medium};
  }
`

import { InitOptions as I18nOptions } from "i18next"

export const LOCALE_COOKIE = "locale"
export const FALLBACK_LANGUAGE = "ru"

const i18nConfig: I18nOptions = {
  defaultNS: "common",
  supportedLngs: ["ru", "en"],
  fallbackLng: FALLBACK_LANGUAGE,

  interpolation: {
    escapeValue: false,
  },

  react: {
    useSuspense: false,
  },
}

export default i18nConfig

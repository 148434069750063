import styled from "styled-components"

import { ManagedProblemMarker } from "@/store/api/problems/create/types"

import Checkmark from "~/assets/icons/Checkmark"
import FileEditIcon from "~/assets/icons/FIleEditIcon"
import WarningIcon from "~/assets/icons/WarningIcon"

import NameColumnWrapper from "@/features/task/list/NameColumnWrapper"
import useProblemName from "@/utils/hooks/useProblemName"

const colors = {
  green: "var(--color-green)",
  black: "var(--color-text)",
  orange: "var(--color-orange)",
}

interface INameColumnProps {
  names: Record<string, string>
  marker: undefined | ManagedProblemMarker
}

export default function NameColumn({ names, marker }: INameColumnProps) {
  const name = useProblemName(names)

  switch (marker) {
    case ManagedProblemMarker.verified:
      return (
        <Column $color="green">
          <Checkmark />
          <span>{name}</span>
        </Column>
      )
    case ManagedProblemMarker.ready:
      return (
        <Column $color="black">
          <Checkmark />
          <span>{name}</span>
        </Column>
      )
    case ManagedProblemMarker.unfinished:
      return (
        <Column $color="orange">
          <WarningIcon />
          <span>{name}</span>
        </Column>
      )
    case ManagedProblemMarker.draft:
      return (
        <Column $color="black">
          <StyledFileEditIcon />
          <span>{name}</span>
        </Column>
      )
    default:
      return (
        <Column $color="black">
          <span>{name}</span>
        </Column>
      )
  }
}

const Column = styled(NameColumnWrapper)<{ $color: keyof typeof colors }>`
  color: var(--color-text);

  > span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  > svg {
    path {
      fill: ${({ $color }) => colors[$color]};
    }
  }
`

const StyledFileEditIcon = styled(FileEditIcon)`
  opacity: 0.5;
`

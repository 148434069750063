// Represents the amount of problems which are passed to Sidebar above and below the current problem in the list
// Total number of problems in sidebar will always be 2 * SEARCH_OFFSET + 1
const SEARCH_OFFSET = 8

export default function getSimilar<T>(problems: T[], index: number) {
  const end = Math.min(
    problems.length,
    Math.max(0, index - SEARCH_OFFSET) + SEARCH_OFFSET * 2 + 1
  )
  const start = Math.max(0, end - SEARCH_OFFSET * 2 - 1)

  return problems.slice(start, end)
}

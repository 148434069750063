import baseApi from "@/store/api/base"
import {
  IDropStatementParams,
  ISetStatementParams,
} from "@/store/api/problems/edit/legend/types"

const editProblemApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    setStatement: builder.mutation<null, ISetStatementParams>({
      query: params => ({
        url: "https://beta.sort-me.org/api/problems/setStatement",
        method: "POST",
        body: params,
      }),
    }),
    dropStatement: builder.mutation<null, IDropStatementParams>({
      query: params => ({
        url: "https://beta.sort-me.org/api/problems/dropStatement",
        method: "POST",
        body: params,
      }),
    }),
  }),
})

export default editProblemApi
export const { useSetStatementMutation, useDropStatementMutation } =
  editProblemApi

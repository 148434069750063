import styled from "styled-components"

import { header32, header48 } from "@/utils/fonts"
import mediaQueryFor from "@/utils/mediaQuery"

const MainHeader = styled.h1`
  margin: 0;
  padding: 0;

  max-width: 850px;

  grid-area: header;

  ${header48};

  ${mediaQueryFor.mobile} {
    ${header32};
  }
`

export default MainHeader

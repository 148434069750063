import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import styled from "styled-components"

import { SidebarHolder, SidebarItem } from "@/components/Sidebar"

export default function PolygonImportSidebar() {
  const { t } = useTranslation()

  const i18n = {
    back: t("pages.task.create.polygon_import.back"),
    title: t("pages.task.create.polygon_import.header"),
  }

  return (
    <StyledSidebar>
      <StyledLink as={Link} to="/problems/create">
        {i18n.back}
      </StyledLink>

      <SidebarItem>{i18n.title}</SidebarItem>
    </StyledSidebar>
  )
}

const StyledSidebar = styled(SidebarHolder)`
  gap: 33px;
`

const StyledLink = styled(SidebarItem)`
  opacity: 0.5;
  text-decoration: none;
`

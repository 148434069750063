import { useTranslation } from "react-i18next"

import NavbarCategory from "./NavbarCategory"

export default function NavbarLinks() {
  const { t } = useTranslation()

  const i18n = {
    categoryContests: t("navbar.categories.contests"),
    categoryTasks: t("navbar.categories.tasks"),
    categoryAlgorithms: t("navbar.categories.algorithms"),
    categoryAbout: t("navbar.categories.about"),
  }

  return (
    <>
      <NavbarCategory to="/contest">{i18n.categoryContests}</NavbarCategory>

      <NavbarCategory to="/problems">{i18n.categoryTasks}</NavbarCategory>
    </>
  )
}

import { useMemo } from "react"

import styled from "styled-components"

import { IUserDetails } from "@/store/api/users/types"

import RegalAwards from "@/features/profile/regal/RegalAwards"
import RegalRank from "@/features/profile/regal/RegalRank"
import RegalStats from "@/features/profile/regal/RegalStats"
import RegalUnique, {
  getUniqueType,
  UniqueRegalKind,
} from "@/features/profile/regal/RegalUniqie"
import mediaQueryFor from "@/utils/mediaQuery"

export default function AccountRegal({ user }: { user: IUserDetails }) {
  const kind = useMemo(() => getUniqueType(user), [user])

  const {
    regal: {
      rank_record: rankRecord = null,
      statistics = null,
      awards = [],
    } = {},
  } = user ?? {}

  const hasAwards = awards && awards.length > 0

  if (!user) return null

  if (kind !== UniqueRegalKind.regular) return <RegalUnique kind={kind} />

  return (
    <Wrapper>
      {rankRecord && (
        <RegalRank rank={rankRecord.rank} updated={rankRecord.updated} />
      )}
      {statistics && <RegalStats stats={statistics} />}
      {hasAwards && <RegalAwards awards={awards} />}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  grid-area: ranks;

  border: none;
  border-radius: 8px;

  display: flex;
  flex-flow: column nowrap;
  height: max-content;

  background-color: var(--color-background);

  transition: background-color var(--transition-duration)
    var(--transition-function);

  > div:not(:last-child) {
    border-bottom: 1px solid var(--color-g-background);

    transition: border-color var(--transition-duration)
      var(--transition-function);
  }

  ${mediaQueryFor.mobile} {
    margin-top: 32px;
  }
`

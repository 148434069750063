import { MouseEventHandler, useCallback, useEffect } from "react"

import { Helmet } from "react-helmet-async"
import { Trans, useTranslation } from "react-i18next"
import { Navigate, useNavigate, useParams } from "react-router-dom"
import styled from "styled-components"

import PlusIcon from "~/assets/icons/PlusIcon"

import useZipTestUploader from "@/features/task/edit/tests/hooks/useZipTestUploader"
import useIsEditable from "@/features/task/hooks/useIsEditable"
import { header30, text14Medium, text15Medium } from "@/utils/fonts"

import FileUploader from "./FileUploader"
import ImportLoader from "./ImportLoader"

export default function Upload() {
  const { problemId = "" } = useParams()
  const navigate = useNavigate()

  const { t } = useTranslation()

  const { fileUploader, uploadProgress, parsingProgress } = useZipTestUploader()

  useEffect(() => {
    if (
      ["defunct", "idle"].includes(fileUploader.state) &&
      parsingProgress === 100
    ) {
      const timeout = setTimeout(
        () => navigate("..", { relative: "path" }),
        300,
      ) // wait for animation
      return () => clearTimeout(timeout)
    }
  }, [fileUploader.state, parsingProgress, navigate])

  const handleAddManuallyClick = useCallback<
    MouseEventHandler<HTMLButtonElement>
  >(
    () => navigate("..", { relative: "path", state: { forced: true } }),
    [navigate],
  )

  const { isEditable, isFetching } = useIsEditable()
  if (!isFetching && !isEditable)
    return <Navigate to=".." replace relative="path" state={{ forced: true }} />

  const i18n = {
    title: t("pages.task.edit.stages.tests.upload.title"),
    addManually: t("pages.task.edit.stages.tests.upload.add_manually"),
  }

  const isUploading = fileUploader.state === "loading" || uploadProgress > 0

  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href={`https://sort-me.org/problems/${problemId}/edit/tests/upload`}
        />
      </Helmet>

      <Wrapper>
        {isUploading ? (
          <ImportLoader
            uploadProgress={uploadProgress}
            parsingProgress={parsingProgress}
          />
        ) : (
          <>
            <Title>{i18n.title}</Title>
            <FileUploader {...fileUploader} />
            <Trans
              t={t}
              parent={Description}
              i18nKey={"pages.task.edit.stages.tests.upload.description"}
              components={{ next: <br /> }}
            />
            <AddManually onClick={handleAddManuallyClick}>
              <PlusIcon />
              {i18n.addManually}
            </AddManually>
          </>
        )}
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div`
  grid-area: main;

  padding: 35px 36px;
  box-sizing: border-box;

  height: calc(var(--window-h) - var(--shift-y));
  max-width: calc(1200px - 2 * var(--shift-x));

  overflow: hidden overlay;
`

const Title = styled.h1`
  margin: 0 0 44px 0;
  ${header30}
`

const Description = styled.p`
  ${text14Medium};
  opacity: 0.75;
  margin: 33px 0 44px 0;
  width: 443px;
`

const AddManually = styled.button`
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 0;

  ${text15Medium};
  color: var(--color-primary);
  transition: color var(--transition-duration) var(--transition-function);

  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 20px;

  > svg {
    path {
      fill: var(--color-primary);
      transition: fill var(--transition-duration) var(--transition-function);
    }
  }
`

import { useRef } from "react"

import { Helmet } from "react-helmet-async"
import { useTranslation } from "react-i18next"
import { Outlet, useParams } from "react-router-dom"
import styled from "styled-components"

import { createQuerySelector } from "@/store/api/helpers"
import problemsApi from "@/store/api/problems"
import submissionsApi, { useGetSubmissionsQuery } from "@/store/api/submissions"

import Loader from "@/components/Loader"
import ProblemSidebar from "@/features/task/current/ProblemSidebar"
import Statement from "@/features/task/current/statement/Statement"
import SubmissionModal from "@/features/task/current/submission/SubmissionModal"
import SubmissionsList from "@/features/task/current/submission/SubmissionsList"
import useTranslatedProblem from "@/features/task/hooks/useTranslatedProblem"
import { useAppSelector } from "@/store"
import AlternateTranslationsLinks from "@/utils/AlternateTranslationsLinks"
import useLanguageSelector from "@/utils/hooks/useLanguageSelector"
import mediaQueryFor from "@/utils/mediaQuery"
import { IPageConfig } from "@/utils/types"

type ITaskProps = Record<"problemId", string>

export const config: IPageConfig<ITaskProps> = {
  routes: {
    path: "/problems/:problemId",
    Component: Task,
    children: [{ path: "s/:submissionId", Component: SubmissionModal }],
  },

  server: {
    async populate({ store, params: { problemId } }) {
      await store.dispatch(
        problemsApi.endpoints.getProblemById.initiate({
          id: Number(problemId),
          lang: "ru",
        }),
      )

      const {
        user: { uid },
      } = store.getState()

      if (uid) {
        // If user is authenticated, load submissions
        await store.dispatch(
          submissionsApi.endpoints.getSubmissions.initiate({
            user_id: uid.toString(),
            problem_id: problemId,
          }),
        )
      }

      await Promise.all([
        ...store.dispatch(problemsApi.util.getRunningQueriesThunk()),
        ...store.dispatch(submissionsApi.util.getRunningQueriesThunk()),
      ])
    },
  },

  client: {},

  layout: "own",
}

function Task() {
  const { t } = useTranslation()

  const { problemId } = useParams<ITaskProps>()
  const contentRef = useRef<HTMLDivElement>(null)

  const userId = useAppSelector(({ user }) => user.uid)
  const { language, forceLanguage } = useLanguageSelector()

  const { isFetching: isSubmissionsFetching } = useGetSubmissionsQuery(
    { user_id: userId?.toString(), problem_id: problemId },
    {
      selectFromResult: selectFromSubmissions,
      skip: !userId || !problemId,
    },
  )

  const {
    selected: problem,
    isFetching: isProblemFetching,
    available,
  } = useTranslatedProblem(Number(problemId), language)

  const i18n = {
    title: t("pages.task.title", {
      name: problem?.name ?? t("pages.task.default_title"),
    }),
  }

  if (!problemId) return null

  const isFetching = isProblemFetching || isSubmissionsFetching

  return (
    <>
      <Helmet>
        <title>{i18n.title}</title>
      </Helmet>

      <AlternateTranslationsLinks
        base={`https://sort-me.org/problems/${problemId}`}
        langs={available}
      />

      <Wrapper>
        <ProblemSidebar problemId={problemId} />

        <Loader loading={isFetching} size="large">
          {!isFetching && (
            <Content ref={contentRef}>
              <Statement
                scrollRef={contentRef}
                problem={problem}
                setLanguage={lang => forceLanguage(lang)}
              />

              <SubmissionsList taskId={problemId} />
            </Content>
          )}
        </Loader>
      </Wrapper>

      <Outlet />
    </>
  )
}

const selectFromSubmissions = createQuerySelector(
  useGetSubmissionsQuery,
  ({ isFetching }) => ({ isFetching }),
)

const Wrapper = styled.div`
  display: grid;
  grid-template-areas: "sidebar main";
  grid-template-rows: max-content;
  grid-template-columns: 238px 1fr;

  div[aria-label="animation"] {
    grid-area: main;
  }

  ${mediaQueryFor.mobile} {
    grid-template-areas: "main";
    grid-template-columns: 100%;
  }
`

const Content = styled.div`
  grid-area: main;

  display: grid;
  grid-template-areas: "statement submissions";
  grid-template-rows: max-content;
  grid-template-columns: 60% 40%;

  height: calc(var(--window-h) - var(--shift-y));
  max-width: calc(1200px - 2 * var(--shift-x));

  overflow-y: overlay;
  overflow-x: hidden;

  ${mediaQueryFor.mobile} {
    grid-template-areas:
      "statement"
      "submissions";
    grid-template-columns: 100%;
  }
`

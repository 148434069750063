import { QueryStatus } from "@reduxjs/toolkit/query"
import { match } from "ts-pattern"

import baseApi from "@/store/api/base"
import {
  ToQueryArray,
  ToQueryBoolean,
  getPreferredLanguages,
  withQueryParams,
} from "@/store/api/helpers"
import {
  IGetProblemsParams,
  ProblemDetails,
  INewGetProblemParams,
  INewProblemDetails,
  IProblemsList,
  GetProblemByIDArgs,
  TranslatedProblemDetails,
  IPublicProblemsList,
  ITagsList,
} from "@/store/api/problems/types"

const problemsApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getProblemById: builder.query<TranslatedProblemDetails, GetProblemByIDArgs>(
      {
        query: ({ id, lang }) =>
          withQueryParams({
            url: "https://beta.sort-me.org/api/problems/getByID",
            params: {
              id,
              translations: lang ? [lang] : getPreferredLanguages(),
            },
            schema: { translations: ToQueryArray },
          }),

        serializeQueryArgs: ({ queryArgs }) => queryArgs.id,

        // TODO: move to a helper?
        forceRefetch: ({ currentArg, previousArg, endpointState }) =>
          match(endpointState)
            .returnType<boolean>()
            .with(
              undefined, // First request OR rerender during first request
              { status: QueryStatus.uninitialized },
              { status: QueryStatus.rejected }, // Error when fetching happened, let's force refetch for now
              () => true,
            )
            .with(
              // Somehow switched languages when loading, force refetch IF now requesting a different language
              { status: QueryStatus.pending },
              () => currentArg?.lang !== previousArg?.lang,
            )
            .with({ status: QueryStatus.fulfilled }, ({ data }) => {
              const { langs } = data as TranslatedProblemDetails

              if (!currentArg?.lang) return false // If `null`, hook will pick language
              return !(currentArg.lang in langs) // fetch IF requested lang is IN langs
            })
            .exhaustive(),

        transformResponse: (data: ProblemDetails) => ({
          id: data.id,
          available: data.translations,
          langs: { [data.matched_translation]: data },
        }),

        merge: (current, next) => ({
          id: next.id,
          available: next.available,
          langs: { ...current.langs, ...next.langs },
        }),

        providesTags: (result, _, { id }) =>
          result ? [{ type: "Problem" as const, id }] : [],
      }
    ),

    newGetProblemById: builder.query<INewProblemDetails, INewGetProblemParams>({
      query: ({ id, translations }) =>
        withQueryParams({
          url: "https://beta.sort-me.org/api/problems/getByID",
          params: { id, translations },
        }),
      providesTags: result =>
        result ? [{ type: "Problem" as const, id: result.id }] : [],
    }),

    getProblems: builder.query<IProblemsList, IGetProblemsParams>({
      query: params =>
        withQueryParams({
          url: "/getTaskPreviews",
          params,
          schema: {
            hidesolved: ToQueryBoolean,
            reversed: ToQueryBoolean,
            categories: ToQueryArray,
            difficulties: ToQueryArray,
          },
        }),
      keepUnusedDataFor: 600,
    }),

    getTags: builder.query<ITagsList, void>({
      query: () => "https://beta.sort-me.org/api/problems/getTags",
    }),

    getPublic: builder.query<IPublicProblemsList, void>({
      query: () => "https://beta.sort-me.org/api/problems/getPublic",
      providesTags: [{ type: "Problem", id: "LIST" }],
    }),
  }),
})

export default problemsApi

export const {
  useGetProblemByIdQuery,
  useLazyGetProblemByIdQuery,
  useGetProblemsQuery,
  useNewGetProblemByIdQuery,
  useGetTagsQuery,
  useGetPublicQuery,
} = problemsApi

import styled from "styled-components"

import { text14Medium, text16Medium } from "@/utils/fonts"
import mediaQueryFor from "@/utils/mediaQuery"

const Column = styled.div`
  min-width: 0;
  display: flex;
  align-items: center;
  gap: 15px;

  transition: color var(--transition-duration) var(--transition-function);
  ${text16Medium};

  > span {
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    padding-right: 15px;

    margin-left: 35px;
  }

  > svg {
    flex-shrink: 0;
    width: 20px;
    height: 20px;

    path {
      transition: fill var(--transition-duration) var(--transition-function);
    }
  }

  > svg + span {
    margin-left: 0;
  }

  ${mediaQueryFor.mobile} {
    gap: 18px;

    ${text14Medium};

    > span {
      padding-right: 11px;

      margin-left: 34px;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    > svg {
      width: 16px;
      height: 16px;
    }
  }
`

export default Column

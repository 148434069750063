import DropdownTabGroup from "@/components/groups/DropdownTabGroup"
import TabGroup from "@/components/groups/TabGroup"
import { ITabGroupProps } from "@/components/groups/types"
import { useAppSelector } from "@/store"

const LinkComponent = {
  desktop: TabGroup,
  mobile: DropdownTabGroup,
}

export default function useHeaderTabGroup(): React.FC<ITabGroupProps> {
  const media = useAppSelector(({ common }) => common.media)

  return LinkComponent[media]
}

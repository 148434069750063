import { useMemo } from "react"

import { useTranslation } from "react-i18next"
import styled from "styled-components"

import { AwardKind, IUserAward } from "@/store/api/users/types"

import AwardRecord from "@/components/regal/AwardRecord"
import { useAppSelector } from "@/store"
import { text13Medium, text18Medium } from "@/utils/fonts"
import mediaQueryFor from "@/utils/mediaQuery"

interface IRegalAwardsProps {
  awards: IUserAward[]
}

export default function RegalAwards({ awards }: IRegalAwardsProps) {
  const { t } = useTranslation()

  const i18n = {
    title: t("pages.profile.awards_title"),
  }

  return (
    <Wrapper>
      <Title>{i18n.title}</Title>

      <ArrangeAwards awards={awards} kind={AwardKind.cup} />
      <ArrangeAwards awards={awards} kind={AwardKind.gold} />
      <ArrangeAwards awards={awards} kind={AwardKind.silver} />
    </Wrapper>
  )
}

interface IArrangeAwardsProps {
  awards: IUserAward[]
  kind: AwardKind
}

function ArrangeAwards({ awards, kind }: IArrangeAwardsProps) {
  const isMobile = useAppSelector(({ common }) => common.media === "mobile")

  const cardinality = isMobile ? 1 : kind === AwardKind.silver ? 4 : 3

  const selected = useMemo(
    () => awards.filter(({ type }) => type === kind),
    [kind, awards]
  )

  const blocks = useMemo(() => {
    return new Array(cardinality)
      .fill(null)
      .map((_, cid) => selected.filter((_, id) => id % cardinality === cid))
  }, [cardinality, selected])

  const hasEntries = selected?.length > 0

  if (!hasEntries) return null

  const columns = blocks.map(
    (block, i) =>
      block.length > 0 && (
        <Column key={i}>
          {block.map(award => (
            <AwardRecord key={award.title + award.date} award={award} />
          ))}
        </Column>
      )
  )

  return <Block cardinality={cardinality}>{columns}</Block>
}

const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-content: flex-start;

  height: max-content;

  padding: 24px 20px;

  gap: 16px;

  color: var(--color-text);

  ${mediaQueryFor.mobile} {
    gap: 12px;
    padding: 16px;
  }
`

const Title = styled.span`
  ${text18Medium};

  letter-spacing: 0.01em;

  ${mediaQueryFor.mobile} {
    ${text13Medium};
  }
`

const Block = styled.div<{ cardinality: number }>`
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: repeat(${({ cardinality }) => cardinality}, 1fr);
  grid-template-rows: max-content;

  column-gap: 12px;
`

const Column = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-content: flex-start;

  gap: 12px;
`

import styled from "styled-components"

import mediaQueryFor from "@/utils/mediaQuery"

const Grid = styled.div`
  display: grid;
  align-items: center;
  justify-content: start;
  grid-template-columns: repeat(auto-fill, minmax(368px, 1fr));
  grid-gap: 32px;

  ${mediaQueryFor.mobile} {
    gap: 16px;
  }

  @media (max-width: 408px) {
    grid-template-columns: 1fr;
  }
`

export default Grid

import { MouseEventHandler, useCallback, useEffect, useState } from "react"

import { useTranslation } from "react-i18next"
import styled from "styled-components"

import { useLazySearchQuery } from "@/store/api/users"

import { text15Medium } from "@/utils/fonts"
import useDebounce from "@/utils/hooks/useDebounce"

import UserList from "./UserList"

interface IAdminSearchProps {
  query: string
  setQuery: (query: string) => void
  existingAdminIds: Set<number>
  showModal: MouseEventHandler<HTMLDivElement>
  className?: string
}

export default function AdminSearchBar({
  query,
  setQuery,
  existingAdminIds,
  showModal,
  className = "",
}: IAdminSearchProps) {
  const { t } = useTranslation()

  const i18n = {
    placeholder: t("pages.task.edit.stages.access.search_placeholder"),
    add: t("pages.task.edit.stages.access.add"),
  }

  const debouncedQuery = useDebounce(query, 500)

  const [isListVisible, setIsListVisible] = useState(false)

  const [getUsers, { users, isFetching }] = useLazySearchQuery({
    selectFromResult: ({ data, isFetching }) => ({
      users: data?.users ?? [],
      isFetching,
    }),
  })

  useEffect(() => {
    if (debouncedQuery) {
      getUsers(debouncedQuery)
      setIsListVisible(true)
    } else {
      setIsListVisible(false)
    }
  }, [debouncedQuery, getUsers])

  const handleChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    event => {
      setQuery(event.target.value)
    },
    [setQuery]
  )

  return (
    <Wrapper className={className}>
      <Input
        placeholder={i18n.placeholder}
        value={query}
        onChange={handleChange}
      />

      <UserList
        users={users}
        existingAdminIds={existingAdminIds}
        isFetching={isFetching}
        isVisible={isListVisible}
        onClick={showModal}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`

const Input = styled.input`
  background-color: var(--color-bubble);
  border-radius: 10px;
  border: 1px solid transparent;
  transition: color var(--transition-duration) var(--transition-function),
    background-color var(--transition-duration) var(--transition-function),
    border var(--transition-duration) var(--transition-function);

  color: var(--color-text);
  ${text15Medium};

  width: 100%;
  box-sizing: border-box;
  padding: 24px 26px 22px 23px;
  z-index: 10;

  &::placeholder {
    opacity: 0.5;
  }

  &:focus {
    outline: none;
    border: 1px solid var(--color-g-background-stroke);
  }
`

import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import {
  IMoveTestParams,
  ISetDataParams,
  ITestsSlice,
} from "@/store/slices/tests/types"

import editTestsApi from "@/store/api/problems/edit/tests"

const initialState: ITestsSlice = {
  tests: null,
  subtasks: null,
}

/* eslint-disable no-param-reassign */
const testsSlice = createSlice({
  name: "tests",
  initialState,
  reducers: {
    moveTest({ tests, subtasks }, { payload }: PayloadAction<IMoveTestParams>) {
      const { source, destination } = payload

      if (tests) {
        const [removed] = tests.splice(source.index, 1)
        const destinationIndex = // if test is moved between subtasks, it doesn't always change its index, and we have to account for that
          destination.subtask > source.subtask
            ? destination.index - 1
            : destination.index
        tests.splice(destinationIndex, 0, {
          ...removed,
          subtask: destination.subtask + 1,
        })
      }

      if (subtasks) {
        subtasks[source.subtask].test_count -= 1
        subtasks[destination.subtask].test_count += 1
      }
    },

    setTestsData(state, { payload }: PayloadAction<ISetDataParams>) {
      state.tests = payload.tests
      state.subtasks = payload.subtasks
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      editTestsApi.endpoints.getTests.matchFulfilled,
      (state, { payload }) => {
        const { tests, subtasks } = payload

        state.tests = tests
        state.subtasks = subtasks
      }
    )

    builder.addMatcher(
      editTestsApi.endpoints.importTests.matchFulfilled,
      state => {
        state.tests = null
        state.subtasks = null
      }
    )

    builder.addMatcher(
      editTestsApi.endpoints.setTest.matchFulfilled,
      ({ tests, subtasks }, { payload, meta }) => {
        if (tests) {
          const testId = meta.arg.originalArgs.test_id

          if (testId) {
            const index = tests.findIndex(test => test.id === testId)
            tests[index] = {
              ...payload,
              subtask: tests[index].subtask,
              id: testId,
            }
          } else {
            tests.push({
              ...payload,
              id: tests.length + 1,
            })
            if (subtasks) subtasks.at(-1)!.test_count++
          }
        }
      }
    )

    builder.addMatcher(
      editTestsApi.endpoints.dropTest.matchFulfilled,
      ({ tests, subtasks }, { meta }) => {
        if (tests) {
          const testId = meta.arg.originalArgs.test_id

          const removedIndex = tests.findIndex(test => test.id === testId)
          if (subtasks)
            subtasks[tests[removedIndex].subtask - 1].test_count -= 1
          tests.splice(removedIndex, 1)

          tests.forEach((test, index) => {
            if (test.id > testId) {
              tests[index].id -= 1
            }
          })
        }
      }
    )

    builder.addMatcher(
      editTestsApi.endpoints.setTestComment.matchFulfilled,
      ({ tests }, { meta }) => {
        if (tests) {
          const testId = meta.arg.originalArgs.test_id
          const comment = meta.arg.originalArgs.comment

          const index = tests.findIndex(test => test.id === testId)
          tests[index].comment = comment
        }
      }
    )

    builder.addMatcher(
      editTestsApi.endpoints.updateTests.matchFulfilled,
      state => {
        if (state.tests)
          state.tests = state.tests.map((test, index) => ({
            ...test,
            id: index + 1,
          }))
      }
    )
  },
})

export const { moveTest, setTestsData } = testsSlice.actions

export default testsSlice.reducer

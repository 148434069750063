import { useCallback, useMemo, useState } from "react"

import { useSearchParams } from "react-router-dom"

import { useGetAvailableArchivesQuery } from "@/store/api/archives"

export default function useArchivePagination() {
  const [searchParams] = useSearchParams()

  const [offset, setOffset] = useState<number | undefined>(undefined)

  const query = useMemo(
    () => searchParams.get("query") ?? undefined,
    [searchParams]
  )

  const {
    data: { archives = [], count = 0 } = {},
    isFetching,
    isLoading,
  } = useGetAvailableArchivesQuery({ offset, query })

  const fetchMore = useCallback(() => {
    setOffset(archives.at(-1)?.id)
  }, [archives])

  return {
    isLoading,
    isFetching,
    isFetchingMore: isFetching && !isLoading,
    archives,
    isEmpty: count === 0,
    isExhausted: archives.length === count,

    fetchMore,
  }
}

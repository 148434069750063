import { DateTime } from "luxon"

import { IContestTimings } from "@/store/api/contests/types"

export function getContestDatetimes(
  timing: IContestTimings & { requestFulfilledAt: number }
) {
  const start = DateTime.fromSeconds(timing.start)
  const end = DateTime.fromSeconds(timing.end)
  const now = DateTime.fromMillis(
    timing.now * 1000 + Date.now() - timing.requestFulfilledAt
  )
  const registrationStart = DateTime.fromSeconds(timing.registration_start)
  const registrationEnd = DateTime.fromSeconds(timing.registration_end)

  return {
    start,
    end,
    now,
    registrationStart,
    registrationEnd,
  }
}

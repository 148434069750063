import { createAsyncThunk } from "@reduxjs/toolkit"
import Cookies from "js-cookie"

import { RootState } from "@/store/store"

import { ThemeT } from "./types"

const isTheme = (x): x is ThemeT => ["dark", "light"].includes(x)

export function getTheme(): ThemeT {
  if (typeof window === "undefined") {
    return "dark"
  }

  const saved = localStorage.getItem("theme") || Cookies.get("color-theme")

  return isTheme(saved) ? saved : "dark"
}

export const toggleTheme = createAsyncThunk<void, void, { state: RootState }>(
  "common/toggleTheme",
  (_, { dispatch, getState }) => {
    const {
      common: { theme },
    } = getState()

    dispatch(saveTheme(theme === "dark" ? "light" : "dark"))
  }
)

export const saveTheme = createAsyncThunk<void, ThemeT>(
  "common/saveTheme",
  theme => {
    if (typeof window !== "undefined") {
      localStorage.setItem("theme", theme)
      Cookies.set("color-theme", theme)
    }
  }
)

export enum ErrorMessage {
  unknown = "unknown",
}

export enum Integration {
  telegram = "telegram",
  google = "google",
}

export interface IAuthSlice {
  lastLoaded: Integration | null
  lastError: ErrorMessage | null
  loading: Integration | null
}

export type ILoginWithTelegramMode = "create"

export interface ILoginWithTelegramOptions {
  mode: ILoginWithTelegramMode
}

export interface ITelegramActivationOptions {
  id: string
  hash: string
  // eslint-disable-next-line camelcase
  auth_date: string
}

export type ILoginWithGoogleMode = "create"

export interface ILoginWithGoogleOptions {
  mode: ILoginWithGoogleMode
}

export interface IConnectAccountResponse {
  status: number
  data: { token?: string; error?: string }
}

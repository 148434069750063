import baseApi from "./base"
import miscApi from "./misc"
import problemsApi from "./problems"
import submissionsApi from "./submissions"
import usersApi from "./users"

const reducers = {
  [usersApi.reducerPath]: usersApi.reducer,
  [problemsApi.reducerPath]: problemsApi.reducer,
  [submissionsApi.reducerPath]: submissionsApi.reducer,
  [miscApi.reducerPath]: miscApi.reducer,
}

export const middlewares = [baseApi.middleware] as const

export default reducers

import { useMemo } from "react"

import { useTranslation } from "react-i18next"
import styled from "styled-components"

import { useGetTagsQuery } from "@/store/api/problems"

import { SupportedLanguagesT } from "~/translations/utils"

import Divider from "@/components/Divider"
import { text14Medium } from "@/utils/fonts"
import mediaQueryFor from "@/utils/mediaQuery"

const MAX_TAGS_COUNT = 3

interface ITagColumnProps {
  tags: number[]
}

export default function TagsColumn({ tags }: ITagColumnProps) {
  const {
    i18n: { resolvedLanguage },
  } = useTranslation()

  const { data: { tags: categoryData } = {} } = useGetTagsQuery()

  const tagString = useMemo(() => {
    if (!categoryData) return ""

    const result = []
    let extra = 0

    for (const tag of tags) {
      const category = categoryData.find(category => category.id === tag)
      if (result.length >= MAX_TAGS_COUNT) {
        extra++
        continue
      }
      if (category)
        result.push(category.names[resolvedLanguage as SupportedLanguagesT])
    }

    return result.join(", ") + (extra > 0 ? ` + ${extra}` : "")
  }, [categoryData, resolvedLanguage, tags])

  return (
    <Column>
      <Divider data-divider />
      <span>{tagString}</span>
    </Column>
  )
}

const Column = styled.div`
  display: flex;
  align-items: center;

  > span {
    ${text14Medium};
    color: var(--color-text);
    transition: color var(--transition-duration) var(--transition-function);

    opacity: 0.75;

    padding-right: 15px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  ${Divider} {
    margin-right: 16px;

    height: 20px;
    align-self: center;
  }

  ${mediaQueryFor.mobile} {
    display: none;
  }
`

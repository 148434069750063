import "~/assets/fonts.css"

const family = {
  header: "Gilroy, Tahoma, sans-serif",
  code: "JetBrains-Mono, monospace",
  light: "PT-Root-UI-Light, Tahoma, sans-serif",
  regular: "PT-Root-UI, Tahoma, sans-serif",
  medium: "PT-Root-UI-Medium, Tahoma, sans-serif",
  bold: "PT-Root-UI-Bold, Tahoma, sans-serif",
}

export const text10Medium = {
  fontFamily: family["medium"],
  fontSize: "10px",
  fontWeight: "normal",
  lineHeight: "12px",
  letterSpacing: "0.01em",
}

export const text10 = {
  fontFamily: family["regular"],
  fontSize: "10px",
  fontWeight: "normal",
  lineHeight: "13px",
  letterSpacing: "0.01em",
}

export const text11Medium = {
  fontFamily: family["medium"],
  fontSize: "11px",
  fontWeight: "normal",
  lineHeight: "13px",
  letterSpacing: "0.01em",
}

export const code12 = {
  fontFamily: family["code"],
  fontSize: "12px",
  fontWeight: "normal",
  lineHeight: "18px",
}

export const text12 = {
  fontFamily: family["regular"],
  fontSize: "12px",
  fontWeight: "normal",
  lineHeight: "15px",
  letterSpacing: "0.01em",
}

export const text12Medium = {
  fontFamily: family["medium"],
  fontSize: "12px",
  fontWeight: "normal",
  lineHeight: "15px",
  letterSpacing: "0.01em",
}

export const text13 = {
  fontFamily: family["regular"],
  fontSize: "13px",
  fontWeight: 400,
  lineHeight: "16px",
}

export const text13Medium = {
  fontFamily: family["medium"],
  fontSize: "13px",
  fontWeight: "normal",
  lineHeight: "16px",
  letterSpacing: "0.01em",
}

export const text14 = {
  fontFamily: family["regular"],
  fontSize: "14px",
  fontWeight: "normal",
  lineHeight: "17px",
  letterSpacing: "0.01em",
}

export const code14 = {
  fontFamily: family["code"],
  fontSize: "14px",
  fontWeight: "normal",
  lineHeight: "21px",
  letterSpacing: "0em",
}

export const text14Medium = {
  fontFamily: family["medium"],
  fontSize: "14px",
  fontWeight: "normal",
  lineHeight: "17px",
  letterSpacing: "0.01em",
}

export const text15 = {
  fontFamily: family["regular"],
  fontSize: "15px",
  fontWeight: "normal",
  lineHeight: "19px",
  letterSpacing: "0.01em",
}

export const text15Medium = {
  fontFamily: family["medium"],
  fontSize: "15px",
  fontWeight: "normal",
  lineHeight: "19px",
  letterSpacing: "0.01em",
}

export const text16 = {
  fontFamily: family["regular"],
  fontSize: "16px",
  fontWeight: "normal",
  lineHeight: "20px",
  letterSpacing: "0.01em",
}

export const code16 = {
  fontFamily: family["code"],
  fontSize: "16px",
  fontWeight: "normal",
  lineHeight: "24px",
  letterSpacing: "0em",
}

export const text16Medium = {
  fontFamily: family["medium"],
  fontSize: "16px",
  fontWeight: "500",
  lineHeight: "20px",
  letterSpacing: "0.01em",
}

export const text16Bold = {
  fontFamily: family["bold"],
  fontSize: "16px",
  fontWeight: "700",
  lineHeight: "20px",
  letterSpacing: "0.01em",
}

export const text17Medium = {
  fontFamily: family["medium"],
  fontSize: "17px",
  fontWeight: "normal",
  lineHeight: "21px",
}

export const header18 = {
  fontFamily: family["header"],
  fontSize: "18px",
  fontWeight: "normal",
  lineHeight: "22px",
  letterSpacing: "0.01em",
}

export const text18 = {
  fontFamily: family["regular"],
  fontSize: "18px",
  fontWeight: "normal",
  lineHeight: "23px",
  letterSpacing: "0.015em",
}

export const text18Medium = {
  fontFamily: family["medium"],
  fontSize: "18px",
  fontWeight: "normal",
  lineHeight: "23px",
}

export const text18Bold = {
  fontFamily: family["bold"],
  fontSize: "18px",
  fontWeight: "normal",
  lineHeight: "22px",
}

export const text20 = {
  fontFamily: family["regular"],
  fontSize: "20px",
  fontWeight: "normal",
  lineHeight: "25px",
}

export const text20Medium = {
  fontFamily: family["medium"],
  fontSize: "20px",
  fontWeight: "normal",
  lineHeight: "25px",
}

export const text22Medium = {
  fontFamily: family["medium"],
  fontSize: "22px",
  fontWeight: "500",
  lineHeight: "26.4px",
}

export const text24 = {
  fontFamily: family["regular"],
  fontSize: "24px",
  fontWeight: "normal",
  lineHeight: "30px",
}

export const text24Medium = {
  fontFamily: family["medium"],
  fontSize: "24px",
  fontWeight: "normal",
  lineHeight: "30px",
}

export const header24 = {
  fontFamily: family["header"],
  fontSize: "24px",
  fontWeight: "normal",
  lineHeight: "29px",
}

export const text30Medium = {
  fontFamily: family["medium"],
  fontSize: "30px",
  fontWeight: "normal",
  lineHeight: "38px",
}

export const header30 = {
  fontFamily: family["header"],
  fontSize: "30px",
  fontWeight: "normal",
  lineHeight: "37px",
}

export const header32 = {
  fontFamily: family["header"],
  fontSize: "32px",
  fontWeight: "normal",
  lineHeight: "39px",
}

export const text36Medium = {
  fontFamily: family["medium"],
  fontSize: "36px",
  fontWeight: "normal",
  lineHeight: "43px",
  letterSpacing: "0.01em",
}

export const header36 = {
  fontFamily: family["header"],
  fontSize: "36px",
  fontWeight: "normal",
  lineHeight: "44px",
}

export const header40 = {
  fontFamily: family["header"],
  fontSize: "40px",
  fontWeight: "normal",
  lineHeight: "49px",
}

export const header44 = {
  fontFamily: family["header"],
  fontSize: "44px",
  fontWeight: "normal",
  lineHeight: "48px",
}

export const header48 = {
  fontFamily: family["header"],
  fontSize: "48px",
  fontWeight: "normal",
  lineHeight: "59px",
}

export const header140 = {
  fontFamily: family["header"],
  fontSize: "140px",
  fontWeight: "normal",
  lineHeight: "144px",
}

import { useEffect, useMemo, useState } from "react"

import intersection from "lodash/intersection"

import { useGetManagedProblemsQuery } from "@/store/api/problems/create"
import {
  IManagedProblemDetails,
  ManagedProblemMarker,
} from "@/store/api/problems/create/types"

import {
  IManagedFilterProps,
  IManagedProblem,
} from "@/features/task/list/types"
import getProblemName from "@/features/task/list/utils/getProblemName"

export default function useProblemList(filterProps: IManagedFilterProps): {
  problems: IManagedProblem[]
  isLoading: boolean
} {
  const { data, isFetching } = useGetManagedProblemsQuery()

  const [isListLoading, setIsListLoading] = useState(isFetching)

  useEffect(() => {
    if (isFetching) setIsListLoading(true)
  }, [isFetching])

  const problems = useMemo(() => {
    if (!data) return []

    const filteredProblems = filterProblems({
      problems: data.problems,
      ...filterProps,
    })

    const problems = filteredProblems.map(
      ({ included_in_contests: includedInContests, ...rest }) => ({
        ...rest,
        includedInContests,
      })
    )
    setIsListLoading(false)

    return problems as IManagedProblem[]
  }, [data, filterProps])

  return {
    problems,
    isLoading: isListLoading || isFetching,
  }
}

interface IFilterProblemsProps {
  problems: IManagedProblemDetails[]
  search: string
  states: ManagedProblemMarker[]
  isUsedInContests: boolean
  creators: number[]
  categories: number[]
}

function filterProblems({
  problems,
  search,
  states,
  isUsedInContests,
  creators,
  categories,
}: IFilterProblemsProps) {
  return problems.filter(problem => {
    const isMatched = search
      ? getProblemName(problem.names)
          .toLowerCase()
          .includes(search.toLowerCase())
      : true

    if (isMatched) {
      if (
        states.length > 0 &&
        (!problem.marker || !states.includes(problem.marker))
      )
        return false
      if (isUsedInContests && problem.included_in_contests !== 0) return false
      if (creators.length > 0 && !creators.includes(problem.creator.id))
        return false
      if (
        categories.length > 0 &&
        intersection(problem.tags, categories).length === 0
      )
        return false
    }

    return isMatched
  })
}

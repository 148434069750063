import { createAsyncThunk } from "@reduxjs/toolkit"
import Cookies from "js-cookie"

import { RootState } from "@/store/store"
import parseToken from "@/utils/parseToken"

const tokenKey = "token"

const loadToken = createAsyncThunk("auth/loadToken", (token: string) => {
  const { exp, sub: uid }: { exp: number; sub: number } = parseToken(token)

  Cookies.set(tokenKey, token, {
    expires: new Date(exp * 1000),
    path: "/",
  })

  return uid
})

const removeToken = createAsyncThunk<void, void>("auth/removeToken", () => {
  Cookies.remove(tokenKey)
  // Force navigation & reload
  window.location.href = "/"
})

const rehydrateToken = createAsyncThunk<void, void, { state: RootState }>(
  "auth/rehydrateToken",
  (_, { getState, dispatch }) => {
    const {
      user: { token },
    } = getState()

    if (token) dispatch(loadToken(token))
  },
)

export { loadToken, rehydrateToken, removeToken }

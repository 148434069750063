import styled from "styled-components"

import { text14Medium } from "@/utils/fonts"
import mediaQueryFor from "@/utils/mediaQuery"

type ButtonType = "primary" | "secondary" | "danger" | "mini"

interface ICustomButtonProps {
  "data-type"?: ButtonType
}

const CustomButton = styled.button<ICustomButtonProps>`
  width: 214px;
  height: 40px;

  border: none;
  border-radius: 40px;

  cursor: pointer;

  transition:
    color var(--transition-duration) var(--transition-function),
    background-color var(--transition-duration) var(--transition-function),
    transform 0.3s cubic-bezier(0, 0.61, 0, 1);

  ${text14Medium};

  &[data-type="primary"] {
    color: #ffffff;
    background: var(--color-primary);

    &:disabled {
      cursor: not-allowed;
      color: #ffffffbf;
      background: var(--color-primary-inactive);
    }

    &:not(:disabled):hover {
      transform: scale(1.1);
      background-color: var(--color-primary-hover);
    }
  }

  &[data-type="secondary"] {
    background: #1771f11a;
    color: var(--color-primary);
  }

  &[data-type="danger"] {
    background: #d72a2a1a;
    color: #d72a2a;
  }

  &[data-type="mini"] {
    background: #1771f11a;
    color: var(--color-primary);

    width: 116px;
    height: 44px;
  }

  ${mediaQueryFor.mobile} {
    width: 100%;
  }
`

export default CustomButton

import { Trans, useTranslation } from "react-i18next"
import styled from "styled-components"

import { text16Medium } from "@/utils/fonts"

interface IStageDescriptionProps {
  stage: number
  text: string
  imgSrc?: string | null
}

export default function StageDescription({
  stage,
  text,
  imgSrc = null,
}: IStageDescriptionProps) {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <Stage>
        <span>{stage}</span>
      </Stage>
      <Trans
        t={t}
        parent={Description}
        i18nKey={text}
        components={{ next: <br /> }}
      />
      {imgSrc && <Image src={imgSrc} />}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: grid;
  grid-template-areas:
    "stage description"
    ". image";
  gap: 16px 25px;
  grid-template-columns: 34px 600px;
`

const Stage = styled.div`
  grid-area: stage;
  border-radius: 50%;
  background-color: var(--color-bubble);
  transition: background-color var(--transition-duration)
    var(--transition-function);

  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;

  > span {
    opacity: 0.5;
    color: var(--color-text);
    transition: color var(--transition-duration) var(--transition-function);
    ${text16Medium};
  }
`

const Description = styled.p`
  grid-area: description;
  margin: 0;
  ${text16Medium};
`

const Image = styled.img`
  grid-area: image;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 540px;
`

import { useCallback } from "react"

import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"

import { SidebarHolder, SidebarItem } from "@/components/Sidebar"
import { Stage } from "@/features/task/creation/variations/FromScratch/types"

interface IFromScratchSidebarProps {
  stage: Stage
  setStage: (stage: Stage) => void
}

export default function FromScratchSidebar({
  stage,
  setStage,
}: IFromScratchSidebarProps) {
  const navigate = useNavigate()

  const { t } = useTranslation()

  const i18n = {
    format: {
      back: t("pages.task.create.from_scratch.stages.format.back"),
      title: t("pages.task.create.from_scratch.stages.format.header"),
    },
    ratingSystem: {
      back: t("pages.task.create.from_scratch.stages.rating_system.back"),
      title: t("pages.task.create.from_scratch.stages.rating_system.header"),
    },
  }

  const handleBackClick = useCallback(() => {
    switch (stage) {
      case Stage.RatingSystem:
        setStage(Stage.Format)
        break
      default:
        navigate("/problems/create")
    }
  }, [navigate, setStage, stage])

  return (
    <StyledSidebar>
      <BackButton onClick={handleBackClick}>{i18n[stage].back}</BackButton>

      <SidebarItem>{i18n[stage].title}</SidebarItem>
    </StyledSidebar>
  )
}

const StyledSidebar = styled(SidebarHolder)`
  gap: 33px;
`

const BackButton = styled(SidebarItem)`
  opacity: 0.5;
`

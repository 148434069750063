import baseApi from "@/store/api/base"
import { withQueryParams } from "@/store/api/helpers"
import {
  IGetTestParams,
  IGetTestResponse,
  IGetTestsResponse,
  IImportTestsParams,
  IRawGetTestsResponse,
  ISetSamplesCountParams,
  ISetTestCommentParams,
  ISetTestParams,
  ISetTestResponse,
  IStatusResponse,
  IUpdateTestsParams,
} from "@/store/api/problems/edit/tests/types"

import { RootState } from "@/store/store"

/* eslint-disable no-param-reassign */
const editTestsApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getTests: builder.query<IGetTestsResponse, number>({
      query: id =>
        withQueryParams({
          url: "https://beta.sort-me.org/api/problems/getTests",
          params: { problem_id: id },
        }),

      transformResponse: (response: IRawGetTestsResponse) => {
        const { tests, subtasks } = response

        return {
          tests: (tests ?? []).map((test, index) => ({
            ...test,
            id: index + 1,
          })),
          subtasks: subtasks.map((subtask, index) => ({
            ...subtask,
            id: index + 1,
          })),
        }
      },

      providesTags: [{ type: "Test", id: "LIST" }],
      keepUnusedDataFor: 600,
    }),

    getTest: builder.query<IGetTestResponse, IGetTestParams>({
      query: ({ problem_id, test_id }) =>
        withQueryParams({
          url: "https://beta.sort-me.org/api/problems/getTest",
          params: { problem_id, test_id },
        }),

      providesTags: (result, _, { test_id: id }) =>
        result ? [{ type: "Test", id }] : [],
    }),

    setTest: builder.mutation<ISetTestResponse, ISetTestParams>({
      query: params => ({
        url: "https://beta.sort-me.org/api/problems/setTest",
        method: "POST",
        body: params,
      }),

      async onQueryStarted(
        { problem_id: problemId, test_id: testId },
        { dispatch, queryFulfilled }
      ) {
        try {
          const { data } = await queryFulfilled

          dispatch(
            editTestsApi.util.updateQueryData("getTests", problemId, draft => {
              if (testId)
                draft.tests[testId - 1] = {
                  ...draft.tests[testId - 1],
                  ...data,
                }
              else {
                draft.tests.push({
                  ...data,
                  id: draft.tests.length + 1,
                })
                draft.subtasks.at(-1)!.test_count++
              }
            })
          )

          if (testId)
            dispatch(
              editTestsApi.util.updateQueryData(
                "getTest",
                { problem_id: problemId, test_id: testId },
                () => data
              )
            )
        } catch {}
      },
    }),

    dropTest: builder.mutation<IStatusResponse, IGetTestParams>({
      query: ({ problem_id, test_id }) => ({
        url: "https://beta.sort-me.org/api/problems/dropTest",
        method: "POST",
        body: { problem_id, test_id },
      }),

      async onQueryStarted(
        { problem_id: problemId, test_id: testId },
        { dispatch, queryFulfilled }
      ) {
        try {
          const {
            data: { status },
          } = await queryFulfilled

          if (status === "ok") {
            dispatch(
              editTestsApi.util.updateQueryData(
                "getTests",
                problemId,
                draft => {
                  draft.tests.splice(testId - 1, 1)
                }
              )
            )
          }
        } catch {}
      },
    }),

    updateTests: builder.mutation<IStatusResponse, IUpdateTestsParams>({
      queryFn: async (
        {
          problem_id,
          subtasks,
          tests,
          orderChanged = true,
          subtaskChanged = true,
        },
        { dispatch },
        _extraOptions,
        baseQuery
      ) => {
        if (orderChanged) {
          const response = await baseQuery({
            url: "https://beta.sort-me.org/api/problems/reorderTests",
            method: "POST",
            body: {
              problem_id,
              new_order: tests.map(({ id }) => id),
            },
          })
          if (response.error) return { data: { status: "error" } }
        }

        if (subtaskChanged) {
          const response = await baseQuery({
            url: "https://beta.sort-me.org/api/problems/setSubtasks",
            method: "POST",
            body: {
              problem_id,
              subtasks,
            },
          })
          if (response.error) return { data: { status: "error" } }
        }

        dispatch(
          editTestsApi.util.updateQueryData("getTests", problem_id, () => {
            const newTests = tests.map((test, index) => ({
              ...test,
              id: index + 1,
            }))
            return {
              tests: newTests,
              subtasks,
            }
          })
        )

        return { data: { status: "ok" } }
      },

      invalidatesTags: (result, _, { problem_id: id }) =>
        result ? [{ type: "Problem", id }] : [],
    }),

    setSamplesCount: builder.mutation<IStatusResponse, ISetSamplesCountParams>({
      query: params => ({
        url: "https://beta.sort-me.org/api/problems/setSamplesCount",
        method: "POST",
        body: params,
      }),

      invalidatesTags: (result, _, { problem_id: id }) =>
        result ? [{ type: "Problem", id }] : [],
    }),

    setTestComment: builder.mutation<IStatusResponse, ISetTestCommentParams>({
      query: params => ({
        url: "https://beta.sort-me.org/api/problems/setTestComment",
        method: "POST",
        body: params,
      }),

      async onQueryStarted(
        { problem_id: problemId, test_id: testId, comment },
        { dispatch, queryFulfilled }
      ) {
        try {
          const {
            data: { status },
          } = await queryFulfilled

          if (status === "ok") {
            /* eslint-disable no-param-reassign */
            dispatch(
              editTestsApi.util.updateQueryData(
                "getTests",
                problemId,
                draft => {
                  draft.tests[testId - 1] = {
                    ...draft.tests[testId - 1],
                    comment,
                  }
                }
              )
            )
            /* eslint-enable no-param-reassign */
          }
        } catch {}
      },
    }),

    importTests: builder.mutation<IStatusResponse, IImportTestsParams>({
      queryFn: async ({ problem_id, file, callback }, api) => {
        const {
          user: { token },
          common: { lang },
        } = api.getState() as RootState

        const formData = new FormData()
        formData.append("document", file)

        const xhr = new XMLHttpRequest()
        await new Promise<void>(resolve => {
          xhr.upload.addEventListener("progress", event => {
            if (event.lengthComputable) {
              const percentCompleted = Math.round(
                (event.loaded * 100) / event.total
              )

              callback?.(percentCompleted)
            }
          })
          xhr.addEventListener("loadend", () => resolve())

          xhr.open(
            "POST",
            withQueryParams({
              url: "https://beta.sort-me.org/api/problems/importTests",
              params: { problem_id, erase_existing: true },
            }),
            true
          )

          xhr.setRequestHeader("X-Language", lang ?? "ru")
          if (token) xhr.setRequestHeader("Authorization", `Bearer ${token}`)

          xhr.send(formData)
        })

        const response = JSON.parse(xhr.response)
        if (response.error) return response

        return { data: response }
      },

      invalidatesTags: result => (result?.status === "ok" ? ["Test"] : []),
    }),
  }),
})

export default editTestsApi
export const {
  useGetTestsQuery,
  useGetTestQuery,
  useSetTestMutation,
  useDropTestMutation,
  useUpdateTestsMutation,
  useSetSamplesCountMutation,
  useSetTestCommentMutation,
  useImportTestsMutation,
} = editTestsApi

import { Request } from "express"
import { matchRoutes, RouteObject } from "react-router-dom"

import { AppStore } from "@/store/store"
import { commonPopulate, withPageProvider } from "@/utils/ssr"
import { IPageConfig } from "@/utils/types"

import { config as ContestConfig } from "./contests/contest"
import { config as ContestListConfig } from "./contests/list"
import { config as NotFoundConfig } from "./error/404"
import { config as HomeConfig } from "./home"
import { config as PrivacyPolicyConfig } from "./privacy-policy"
import { config as ProblemCreationConfig } from "./problems/creation"
import { config as ProblemEditConfig } from "./problems/edit"
import { config as TaskListConfig } from "./problems/list"
import { config as TaskConfig } from "./problems/problem"
import { config as EditProfileConfig } from "./profile/edit"
import { config as UserProfileConfig } from "./profile/user"
import { config as AuthorizationUtilityConfig } from "./utils/auth"

interface IPageManifest {
  [k: string]: IPageConfig<any>
}

interface IParsedArgs {
  page: string
  args: Record<string, string>
}

type PopulateOptionsT = {
  store: AppStore
  args: Record<string, string>
  req: Request
  page: string
}

const pages: IPageManifest = {
  "home": HomeConfig,
  "profile/user": UserProfileConfig,
  "profile/edit": EditProfileConfig,
  "problems/list": TaskListConfig,
  "problems/problem": TaskConfig,
  "problems/create": ProblemCreationConfig,
  "problems/edit": ProblemEditConfig,
  "privacy-policy": PrivacyPolicyConfig,
  "404": NotFoundConfig,
  "contest/list": ContestListConfig,
  "contest/contest": ContestConfig,
  "utils/auth": AuthorizationUtilityConfig,
}

export const routes = Object.entries(pages).flatMap<RouteObject>(
  ([id, config]) => withPageProvider(id, config)
)

export function parsePageArgs(req: Request): IParsedArgs {
  const matches = matchRoutes(routes, { pathname: req.path }) ?? []

  for (const match of matches) {
    const {
      route: { id: page },
      params,
    } = match

    if (!page) continue

    // We've got a page!
    return { page, args: params as Record<string, string> }
  }

  // return NotFound
  return {
    page: "404",
    args: {},
  }
}

export async function applyPagePopulate(
  options: PopulateOptionsT
): Promise<void> {
  const { page, store, args: params, req } = options
  const pageConfig = pages[page]

  await commonPopulate({ page, req, store, params, pageConfig })
  await pageConfig.server.populate?.({ page, req, store, params, pageConfig })
}

import { useState, useCallback, useMemo } from "react"

import { Helmet } from "react-helmet-async"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

import {
  Format,
  RatingSystem,
  Stage,
} from "@/features/task/creation/variations/FromScratch/types"
import { header30 } from "@/utils/fonts"

import FromScratchSidebar from "./FromScratchSidebar"
import SelectorGrid from "./SelectorGrid"

interface ITaskState {
  format: Format | null
  ratingSystem: RatingSystem | null
}

export default function FromScratch() {
  const [taskState, setTaskState] = useState<ITaskState>({
    format: null,
    ratingSystem: null,
  })

  const { t } = useTranslation()

  const i18n = {
    title: t("pages.task.create.from_scratch.title"),
    format: {
      header: t("pages.task.create.from_scratch.stages.format.header"),
    },
    ratingSystem: {
      header: t("pages.task.create.from_scratch.stages.rating_system.header"),
    },
  }

  const stage = useMemo(
    () => (taskState.format === null ? Stage.Format : Stage.RatingSystem),
    [taskState]
  )

  const setStage = useCallback((stage: Stage) => {
    setTaskState(prev => ({ ...prev, [stage]: null }))
  }, [])

  const handleSelect = useCallback(
    (stage: Stage, selectResult: Format | RatingSystem) => {
      setTaskState(prev => ({ ...prev, [stage]: selectResult }))
    },
    []
  )

  return (
    <>
      <Helmet>
        <title>{i18n.title}</title>
        <link
          rel="canonical"
          href={"https://sort-me.org/problems/create/new"}
        />
      </Helmet>

      <FromScratchSidebar stage={stage} setStage={setStage} />

      <Content>
        <Header>{i18n[stage].header}</Header>
        <SelectorGrid stage={stage} onClick={handleSelect} />
      </Content>
    </>
  )
}

const Content = styled.div`
  grid-area: main;
  padding: 35px 36px;
  height: calc(var(--window-h) - var(--shift-y));
  box-sizing: border-box;
  overflow: hidden overlay;
`

const Header = styled.h1`
  margin: 0 0 40px 0;
  ${header30};
`

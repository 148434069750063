import { useState, useEffect, useCallback, ChangeEventHandler } from "react"

import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import styled from "styled-components"

import { useSetTestCommentMutation } from "@/store/api/problems/edit/tests"

import { text16Medium } from "@/utils/fonts"
import useDebounce from "@/utils/hooks/useDebounce"

const COMMENT_TIMEOUT = 500

interface ICommentEditorProps {
  testId: number
  testComment: string
}

export default function CommentEditor({
  testId,
  testComment,
}: ICommentEditorProps) {
  const { problemId = "" } = useParams()

  const { t } = useTranslation()

  const [comment, setComment] = useState(testComment)

  const debouncedComment = useDebounce(comment, COMMENT_TIMEOUT)

  useEffect(() => {
    setComment(testComment)
  }, [testComment])

  const handleCommentChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    ({ target }) => setComment(target.value),
    [],
  )

  const [setTestComment] = useSetTestCommentMutation()

  useEffect(() => {
    if (debouncedComment !== testComment) {
      setTestComment({
        problem_id: Number(problemId),
        test_id: testId,
        comment: debouncedComment,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedComment, setTestComment])

  const i18n = {
    noComment: t("pages.task.edit.stages.tests.no_comment"),
  }

  return (
    <Input
      value={comment}
      onChange={handleCommentChange}
      placeholder={i18n.noComment}
    />
  )
}

const Input = styled.input`
  border: none;
  outline: none;
  opacity: 0.5;
  background-color: transparent;

  width: 100%;
  padding: 0;

  ${text16Medium};
  line-height: 150%;
  color: var(--color-text);
  transition: color var(--transition-duration) var(--transition-function);

  &::placeholder {
    opacity: 0.8;
    color: var(--color-text);
    transition: color var(--transition-duration) var(--transition-function);
  }
`

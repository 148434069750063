import { useRef } from "react"

import { CSSTransition } from "react-transition-group"
import styled from "styled-components"

import Hourglass from "~/assets/icons/Hourglass"
import LockIcon from "~/assets/icons/LockIcon"

import { TurnstileStatus } from "@/features/auth/LoginFlow/types"
import { text14Medium } from "@/utils/fonts"
import useScopedTranslation from "@/utils/hooks/useScopedTranslation"

interface TurnstileStatusProps {
  status: TurnstileStatus
}

export default function TurnstileStatusNotifier({
  status,
}: TurnstileStatusProps) {
  const t = useScopedTranslation("auth.widget.turnstile")

  const bubbleRef = useRef<HTMLDivElement>(null)

  const i18n: Partial<Record<TurnstileStatus, string>> = {
    [TurnstileStatus.Pending]: t("pending"),
    [TurnstileStatus.Error]: t("failed"),
  }

  const isDisplayed = displayOn.includes(status)

  const Icon = IconMap[status]!

  return (
    <CSSTransition
      nodeRef={bubbleRef}
      timeout={300}
      key="bubble-transition"
      in={isDisplayed}
      classNames="notifier"
      mountOnEnter
      unmountOnExit
    >
      <Bubble ref={bubbleRef} key={status} data-status={status}>
        {Icon ? <Icon /> : null}

        {i18n[status] ?? null}
      </Bubble>
    </CSSTransition>
  )
}

const displayOn = [TurnstileStatus.Pending, TurnstileStatus.Error]

const IconMap: Partial<Record<TurnstileStatus, React.FC>> = {
  [TurnstileStatus.Pending]: Hourglass,
  [TurnstileStatus.Error]: LockIcon,
}

const Bubble = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;

  padding: 12px 16px;
  border-radius: 8px;

  gap: 16px;
  width: 100%;
  box-sizing: border-box;

  ${text14Medium};
  color: var(--color-text);
  background-color: var(--color-bubble);

  svg {
    fill: var(--color-text);

    flex: 0 0 24px;
  }

  &[data-status="pending"] {
    svg {
      height: 20px;
    }
  }

  &[data-status="error"] {
    svg {
      fill: var(--color-red);
      width: 24px;
      height: 24px;
    }
  }

  &.notifier-enter {
    opacity: 0;
    max-height: 0px;
    padding: 0px 16px;
  }

  &.notifier-enter-active {
    opacity: 1;
    max-height: 100px;
    padding: 12px 16px;

    transition:
      opacity 300ms ease-in-out,
      max-height 300ms ease-in-out,
      padding 300ms ease-in-out;
  }

  &.notifier-exit {
    opacity: 1;
    max-height: 100px;

    padding: 12px 16px;
  }

  &.notifier-exit-active {
    opacity: 0;
    max-height: 0px;

    padding: 0px 16px;

    transition:
      opacity 300ms ease-in-out,
      max-height 300ms ease-in-out,
      padding 300ms ease-in-out;
  }
`

import styled from "styled-components"

import { text15Medium } from "@/utils/fonts"

const TabButton = styled.button`
  border: none;
  border-radius: 95px;
  background-color: transparent;
  cursor: pointer;

  transition: color var(--transition-duration) var(--transition-function),
    background-color var(--transition-duration) var(--transition-function);

  padding: 6px 14px;
  height: 32px;
  box-sizing: border-box;

  text-decoration: none;
  color: var(--color-text);
  font-weight: 500;
  ${text15Medium};

  &:hover {
    background-color: var(--navbar-accent);
  }

  &[data-active="true"] {
    background-color: var(--navbar-accent);
  }
`

export default TabButton

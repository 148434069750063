import { useMemo } from "react"

import styled from "styled-components"

import { text17Medium } from "@/utils/fonts"

const alphabet = "ABCDEFG -=`.;hijklm n012 34"

function* uniqueWord(initial: number): Generator<string, void, never> {
  let seed = initial

  const a = 1103515245
  const c = 12345
  const m = 1 << 31

  const size = 8 + ((a * seed + c * 17) % 12)

  for (let i = 0; i < size; i++) {
    seed = (a * seed + c) % m
    yield alphabet[seed % alphabet.length]
  }

  return
}

function generateUniqueName(seed: number) {
  return Array.from(uniqueWord(seed))
}

interface RandomContestProblemProps {
  letter: string
  contestId: number
}

export default function RandomContestProblem({
  letter,
  contestId,
}: RandomContestProblemProps) {
  const seed = 157 * contestId + 371 * letter.codePointAt(0)!

  const name = useMemo(() => generateUniqueName(seed), [seed])

  return (
    <SecretItem>
      <span>{letter}.</span>&nbsp;&nbsp;<span>{name}</span>
    </SecretItem>
  )
}

const SecretItem = styled.span`
  ${text17Medium};
  color: var(--color-text);
  cursor: default;

  user-select: none;

  span:first-child {
    opacity: 0.5;
  }

  span:last-child {
    filter: blur(7px);
  }
`

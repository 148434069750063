import { useTranslation } from "react-i18next"
import styled from "styled-components"

import ScrollableCodeCell from "@/components/ScrollableCodeCell"
import { text12Medium } from "@/utils/fonts"

interface ITestSamplesProps {
  samples: Array<{ stdin: string; stdout: string }>
}

export default function TestSamples({ samples }: ITestSamplesProps) {
  const { t } = useTranslation()

  const i18n = {
    stdin: t("pages.task.statement.stdin"),
    stdout: t("pages.task.statement.stdout"),
  }

  return (
    <Wrapper>
      <HeaderCell>{i18n.stdin}</HeaderCell>
      <HeaderCell>{i18n.stdout}</HeaderCell>

      {samples.map(({ stdin, stdout }) => (
        <SampleWrapper key={stdin + stdout}>
          <CodeCell>
            <pre>{stdin}</pre>
          </CodeCell>
          <CodeCell>
            <pre>{stdout}</pre>
          </CodeCell>
        </SampleWrapper>
      ))}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: max-content;
  grid-auto-flow: row;

  width: 100%;

  gap: 10px;
`

const SampleWrapper = styled.div`
  display: contents;
`

const HeaderCell = styled.div`
  ${text12Medium};
  text-transform: uppercase;
  color: var(--color-text);
  opacity: 0.3;

  transition: color var(--transition-duration) var(--transition-function);
`

const CodeCell = styled(ScrollableCodeCell)`
  padding: 8px 10px;

  &::after {
    right: 10px;
  }
`

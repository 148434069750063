import { cloneElement, useRef } from "react"

import { CSSTransition, SwitchTransition } from "react-transition-group"
import styled from "styled-components"

import BaseInput from "@/components/BaseInput"
import mediaQueryFor from "@/utils/mediaQuery"

import CreatePassword from "./content/CreatePassword"
import LoginEntry from "./content/LoginEntry"
import LoginPassword from "./content/LoginPassword"
import RegistrationCode from "./content/RegistrationCode"
import ResetCode from "./content/ResetCode"
import useEmailAuthorization from "./hooks/useEmailAuthorization"
import { LoginControls, LoginModalStep, WithForwardedRef } from "./types"

interface LoginFlowProps {
  className?: string
}

export default function LoginFlow({ className = undefined }: LoginFlowProps) {
  const controls = useEmailAuthorization()

  const Component = Steps[controls.step]

  return (
    <Root className={className}>
      <SwitchTransition>
        <LoginTransition key={controls.step}>
          <Component {...controls} />
        </LoginTransition>
      </SwitchTransition>
    </Root>
  )
}

function LoginTransition({
  children,
  ...props
}: {
  children: React.ReactElement<WithForwardedRef<any, any>>
}) {
  const forwardedRef = useRef<HTMLElement>(null)

  return (
    <CSSTransition
      classNames="switch"
      nodeRef={forwardedRef}
      timeout={300}
      {...props}
    >
      {cloneElement(children, { forwardedRef })}
    </CSSTransition>
  )
}

const Steps: Record<
  LoginModalStep,
  React.FC<WithForwardedRef<LoginControls, any>>
> = {
  [LoginModalStep.LoginEntry]: LoginEntry,
  [LoginModalStep.LoginPassword]: LoginPassword,

  [LoginModalStep.RegistrationCode]: RegistrationCode,
  [LoginModalStep.RegistrationPassword]: CreatePassword,

  [LoginModalStep.ResetCode]: ResetCode,
  [LoginModalStep.ResetPassword]: CreatePassword,
}

const Root = styled.div`
  padding: 20px 24px;

  box-sizing: border-box;

  ${BaseInput} {
    width: 294px;

    ${mediaQueryFor.mobile} {
      width: 100%;
    }
  }
`

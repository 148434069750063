import React from "react"

import { DateTime } from "luxon"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

import { text14 } from "@/utils/fonts"

function DateToTitle({ groupName }: { groupName: string }) {
  const { t, i18n } = useTranslation()

  switch (groupName) {
    case TODAY:
      return null
    case YESTERDAY:
      return (
        <DateSubtitle>{t("pages.task.submissions.yesterday")}</DateSubtitle>
      )
    default: {
      const date = DateTime.fromISO(groupName)
      const format = DateTime.now().hasSame(date, "year")
        ? { ...DateTime.DATE_FULL, year: undefined }
        : DateTime.DATE_FULL

      return (
        <DateSubtitle>
          {date.toLocaleString(format, { locale: i18n.resolvedLanguage })}
        </DateSubtitle>
      )
    }
  }
}

export const TODAY = "today"
export const YESTERDAY = "yesterday"

const DateSubtitle = styled.span`
  ${text14};

  opacity: 0.59;
  margin-bottom: -16px;

  color: var(--color-text);
  transition: color var(--transition-duration) var(--transition-function);
`

export default React.memo(DateToTitle)

import React, { Suspense } from "react"

import Lottie, { Options as LottieOptions } from "react-lottie"

import cubes from "~/assets/documents/cubes.json"

type SizeT = "small" | "large" | "none"

interface ILoaderProps {
  children?: React.ReactNode
  size: SizeT
  withText?: boolean | string
  loading?: null | boolean
  suspend?: null | boolean
}

const sizes: Record<SizeT, number> = {
  none: 0,
  small: 64,
  large: 224,
}

const options: LottieOptions = {
  loop: true,
  autoplay: true,
  animationData: cubes,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
    className: "lottie-loader",
  },
}

function Loader({
  children = null,
  size,
  withText = false,
  suspend = false,
  loading = null,
}: ILoaderProps) {
  const fallback = (
    <>
      {size !== "none" && (
        <Lottie options={options} width={sizes[size]} height={sizes[size]} />
      )}

      {Boolean(withText) ? (
        <p>{typeof withText == "string" ? withText : "Загрузка..."}</p>
      ) : null}
    </>
  )

  if (typeof loading === "boolean" && loading) {
    if (suspend) return <Suspense fallback={fallback}>{fallback}</Suspense>

    return fallback
  }

  if (suspend) return <Suspense fallback={fallback}>{children}</Suspense>

  return <>{children}</>
}

export default React.memo(Loader)

import { useMemo } from "react"

import { useTranslation } from "react-i18next"
import styled from "styled-components"

import { text13Medium, text18Medium } from "@/utils/fonts"
import mediaQueryFor from "@/utils/mediaQuery"

function getDateFormatter(locale: string) {
  return new Intl.DateTimeFormat(locale, {
    day: "numeric",
    month: "long",
    year: "numeric",
  })
}

export default function SignedUpDate({ at }: { at: number }) {
  const { t, i18n } = useTranslation()

  const formatter = useMemo(
    () => getDateFormatter(i18n.resolvedLanguage),
    [i18n.resolvedLanguage]
  )

  const translation = {
    signedUpAt: t("pages.profile.signed_up_at", {
      date: formatter.format(at * 1000),
    }),
  }

  return <Wrapper>{translation.signedUpAt}</Wrapper>
}

const Wrapper = styled.span`
  grid-area: date;

  color: #8b8b8b;
  ${text18Medium};

  margin-top: 24px;

  ${mediaQueryFor.mobile} {
    margin-top: 16px;

    ${text13Medium};
  }
`

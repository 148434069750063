import { useTranslation } from "react-i18next"
import styled from "styled-components"

import TimingText from "@/components/TimingText"

interface ISubscriptProps {
  kind: "registrationOpen" | "ended" | "waiting"
  children?: React.ReactNode
}

export default function Subscript({ kind, children = null }: ISubscriptProps) {
  const { t } = useTranslation()

  const translation = {
    registrationOpenText: t("pages.contests.details.registration_is_open"),
    ended: t("pages.contests.details.ended"),
  }

  switch (kind) {
    case "registrationOpen":
      return (
        <TimingText>
          {children}
          <RegistrationOpenText>
            ({translation.registrationOpenText})
          </RegistrationOpenText>
        </TimingText>
      )
    case "ended":
      return (
        <EndedText>
          {children}
          <EndedSpan>({translation.ended})</EndedSpan>
        </EndedText>
      )
    default:
      return <TimingText>{children}</TimingText>
  }
}

const RegistrationOpenText = styled.span`
  margin-left: 4px;
  color: var(--color-green);
`

const EndedText = styled(TimingText)`
  opacity: 0.5;
`

const EndedSpan = styled.span`
  margin-left: 4px;
`

import { useMemo } from "react"

import usePreferredLanguages from "@/utils/hooks/usePreferredLanguages"

export default function useProblemName(names: Record<string, string>) {
  const preferredLanguages = usePreferredLanguages()

  const name = useMemo(() => {
    for (const language of preferredLanguages) {
      if (names[language]) {
        return names[language]
      }
    }

    return Object.values(names)[0]
  }, [names, preferredLanguages])

  return name
}

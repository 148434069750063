import { useMemo } from "react"

import { Helmet } from "react-helmet-async"
import { useTranslation } from "react-i18next"
import { Navigate, Outlet, useParams } from "react-router-dom"
import styled from "styled-components"

import { useNewGetProblemByIdQuery } from "@/store/api/problems"
import { AdminLevel } from "@/store/api/problems/types"

import Loader from "@/components/Loader"
import Access from "@/features/task/edit/access/Access"
import Legend from "@/features/task/edit/legend/Legend"
import NotAllowed from "@/features/task/edit/NotAllowed"
import ProblemEditSidebar from "@/features/task/edit/ProblemEditSidebar"
import TestEditModal from "@/features/task/edit/tests/TestEditModal"
import Tests from "@/features/task/edit/tests/Tests"
import Upload from "@/features/task/edit/tests/upload/Upload"
import usePreferredLanguages from "@/utils/hooks/usePreferredLanguages"
import mediaQueryFor from "@/utils/mediaQuery"
import { IPageConfig } from "@/utils/types"

type ITaskProps = Record<"problemId", string>

export const config: IPageConfig<ITaskProps> = {
  routes: {
    path: "/problems/:problemId/edit/*",
    Component: ProblemEdit,
    children: [
      { index: true, element: <Navigate to="legend" replace /> },
      { path: "legend", Component: Legend },
      {
        path: "tests",
        Component: Tests,
        children: [
          {
            path: ":testId",
            Component: TestEditModal,
          },
        ],
      },
      { path: "tests/upload", Component: Upload },
      { path: "access", Component: Access },
    ],
  },

  server: {},
  client: {},

  layout: "own",
}

function ProblemEdit() {
  const { problemId = "" } = useParams<ITaskProps>()

  const { t } = useTranslation()

  const i18n = {
    title: t("pages.task.edit.title"),
  }

  const languagePreference = usePreferredLanguages()

  const { data, isLoading, error } = useNewGetProblemByIdQuery({
    id: problemId,
    translations: languagePreference,
  })

  const isAllowed = useMemo(
    () =>
      !error && (data?.admin_level ?? AdminLevel.None) >= AdminLevel.JustLook,
    [data, error]
  )

  return (
    <>
      <Helmet>
        <title>{i18n.title}</title>
        <link
          rel="canonical"
          href={`https://sort-me.org/problems/${problemId}/edit`}
        />
      </Helmet>

      <Wrapper>
        <ProblemEditSidebar taskTitle={data?.name} isAllowed={isAllowed} />

        <Loader size="large" loading={isLoading}>
          {isAllowed ? <Outlet /> : <NotAllowed />}
        </Loader>
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div`
  display: grid;
  grid-template-areas: "sidebar main";
  grid-template-rows: max-content;
  grid-template-columns: 238px 1fr;

  ${mediaQueryFor.mobile} {
    grid-template-areas: "main";
    grid-template-columns: 100%;
  }
`

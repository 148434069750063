import { useTranslation } from "react-i18next"
import styled from "styled-components"

import TelegramSmallIcon from "~/assets/icons/TelegramSmallIcon"

import { header40, text24 } from "@/utils/fonts"

const SortMeChatTelegramIcon = "/assets/images/sort-me-chat-telegram.jpg"
const SortMeTelegramIcon = "/assets/images/sort-me-telegram.jpg"

export default function Socials() {
  const { t } = useTranslation()

  const i18n = {
    header: t("pages.home.socials.header"),
  }

  return (
    <SocialsWrapper>
      <h2>{i18n.header}</h2>

      {LinkData.map(({ key, name, display, link, img }) => (
        <LinkWrapper href={t(link)} key={key} target="_blank" rel="noreferrer">
          <img src={img} width={52} height={52} alt={key} />
          <h3>{t(name)}</h3>

          <span className="display">
            <TelegramSmallIcon />
            {t(display)}
          </span>
        </LinkWrapper>
      ))}
    </SocialsWrapper>
  )
}

const LinkData = [
  {
    key: "channel",
    name: "pages.home.socials.channel.name",
    display: "pages.home.socials.channel.display",
    link: "pages.home.socials.channel.link",
    img: SortMeTelegramIcon,
  },
  {
    key: "chat",
    name: "pages.home.socials.chat.name",
    display: "pages.home.socials.chat.display",
    link: "pages.home.socials.chat.link",
    img: SortMeChatTelegramIcon,
  },
]

const SocialsWrapper = styled.div`
  grid-area: socials;
  display: grid;
  grid-template-columns: 1fr max-content max-content;
  grid-template-rows: max-content;
  grid-auto-flow: row;

  align-items: center;
  gap: 138px;

  margin-top: 124px;

  & > h2 {
    ${header40};
    margin: 0;
    padding: 0;

    max-width: 500px;
  }

  & h3 {
    margin: 0;
    padding: 0;

    ${text24};
  }
`

const LinkWrapper = styled.a`
  display: grid;
  grid-template-columns: max-content max-content;
  grid-template-rows: 30px 20px;
  grid-template-areas:
    "icon name"
    "icon display";

  text-decoration: none;

  column-gap: 19px;
  row-gap: 2px;

  height: 52px;

  width: max-content;
  height: max-content;

  & > h3 {
    grid-area: name;
    height: 30px;

    color: var(--color-text);
  }

  & > .display {
    grid-area: display;
    height: 20px;

    color: var(--telegram-link-color);

    svg {
      margin-right: 8px;
      position: relative;
      top: 2px;
    }
  }

  & span:not(.display) {
    grid-area: icon;
  }

  img {
    border: none;
    border-radius: 50%;
  }
`

import { useCallback } from "react"

import { useTranslation } from "react-i18next"
import { useNavigate, useSearchParams } from "react-router-dom"

import DefaultAvatar from "~/assets/icons/DefaultAvatar"

import { useAppSelector } from "@/store"

const userAvatarLink = "https://api.sort-me.org/getUserAvatar"

export default function ProfileBrief() {
  const { t } = useTranslation()

  const [_, setSearchParams] = useSearchParams()
  const navigate = useNavigate()

  const uid = useAppSelector(({ user }) => user.uid)
  const name = useAppSelector(({ user }) => user.handle)

  const i18n = {
    guest: t("navbar.guest"),
  }

  const handleProfileClick = useCallback(() => {
    if (uid) return navigate(`/profile/${uid}`)

    setSearchParams(params => {
      params.set("auth", "1")
      return params
    })
  }, [navigate, setSearchParams, uid])

  return (
    <>
      <span onClick={handleProfileClick}>{name || i18n.guest}</span>
      <picture onClick={handleProfileClick}>
        {uid === null ? (
          <DefaultAvatar />
        ) : (
          <img src={`${userAvatarLink}?id=${uid}`} alt="" />
        )}
      </picture>
    </>
  )
}

import styled from "styled-components"

import { text16Medium, text20Medium } from "@/utils/fonts"
import mediaQueryFor from "@/utils/mediaQuery"

const LoginTitle = styled.h3`
  padding: 0;
  margin: 0;

  ${text20Medium};

  color: var(--color-text);

  transition: color var(--transition-duration) var(--transition-function);

  ${mediaQueryFor.mobile} {
    ${text16Medium};
  }
`

export default LoginTitle

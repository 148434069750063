import baseApi from "@/store/api/base"
import { withQueryParams } from "@/store/api/helpers"
import { IUserDetails, IUserList } from "@/store/api/users/types"

const usersApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getUserById: builder.query<IUserDetails, number | string>({
      query: handle =>
        withQueryParams({
          url: "https://beta.sort-me.org/api/users/getByHandle",
          params: { handle },
        }),
    }),
    search: builder.query<IUserList, string>({
      query: query =>
        withQueryParams({
          url: "https://beta.sort-me.org/api/users/search",
          params: { query },
        }),
    }),
  }),
})

export default usersApi
export const { useGetUserByIdQuery, useLazySearchQuery } = usersApi

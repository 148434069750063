import { MouseEventHandler } from "react"

import { useTranslation } from "react-i18next"
import styled from "styled-components"

import { IAdminsList } from "@/store/api/problems/edit/admins/types"
import { AdminLevel } from "@/store/api/problems/types"

import Avatar from "@/components/Avatar"
import useIsEditable from "@/features/task/hooks/useIsEditable"
import { useAppSelector } from "@/store"

import levels from "./levels"
import UserDetails from "./UserDetails"

interface IAdminListProps extends IAdminsList {
  onItemClick: MouseEventHandler<HTMLDivElement>
}

export default function AdminList({ admins, onItemClick }: IAdminListProps) {
  const { t } = useTranslation()

  const { isEditable } = useIsEditable(AdminLevel.Admin)

  const i18n = {
    itsYou: t("pages.task.edit.stages.access.its_you"),
  }

  const selfId = useAppSelector(({ user }) => user.uid)

  return (
    <Wrapper>
      {admins.map(({ user: { id, handle, name, avatar }, level }) => (
        <AdminItem
          key={id}
          data-disabled={!isEditable || level === AdminLevel.Creator}
          data-id={id}
          data-handle={handle}
          data-name={name}
          data-avatar={avatar}
          data-level={level}
          onClick={onItemClick}
        >
          <Avatar src={avatar} width={40} height={40} />
          <p>{handle}</p>
          <span>
            {t(levels[level].name)}
            {id === selfId && ` (${i18n.itsYou})`}
          </span>
        </AdminItem>
      ))}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 12px;
  width: 400px;
`

const AdminItem = styled(UserDetails)`
  background-color: var(--color-bubble);
  transition: border var(--transition-duration) var(--transition-function),
    background-color var(--transition-duration) var(--transition-function),
    transform 0.15s ease-out,
    box-shadow var(--transition-duration) var(--transition-function);
  border-radius: 10px;
  border: 1px solid transparent;

  padding: 12px 26px 12px 16px;
  box-sizing: border-box;

  &[data-disabled="false"]:hover {
    cursor: pointer;
    transform: translate(0, -4px);
    border: 1px solid var(--color-card-stroke);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  }
`

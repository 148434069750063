/* eslint-disable camelcase */

import { Integration } from "@/store/slices/auth/types"

import { ArrayOfN } from "@/utils/types"

interface IUserIntegration {
  id: string
  name: string
}

export interface IUserRegalStatistics {
  difficulties: ArrayOfN<number, 5>
  total: number
  last_accepted: number
}

export enum AwardKind {
  silver = 4,
  gold = 3,
  cup = 2,
}

export interface IUserAward {
  title: string
  type: AwardKind
  date: number
}

export interface IUserDetails {
  uid: number
  handle: string
  name: string
  avatar: string
  bio: string
  registered_at: number
  integrations: Partial<Record<Integration, IUserIntegration>>
  regal: {
    rank_record: { rank: number; updated: number }
    statistics: IUserRegalStatistics
    awards: IUserAward[] | null
  }
  rated: boolean
  /**
   * Is user a developer - locked rankings
   */
  elite: boolean
  cheater: boolean
}

export interface IUserBrief {
  id: number
  handle: string
  name: string
  avatar: string
}

export interface IUserList {
  users: IUserBrief[]
}

import { useMemo } from "react"

import { Trans, useTranslation } from "react-i18next"
import styled from "styled-components"

import { IUserDetails } from "@/store/api/users/types"

import DetectiveIcon from "~/assets/icons/DetectiveIcon"
import NoEntryIcon from "~/assets/icons/NoEntryIcon"

import { text14Medium, text18Medium } from "@/utils/fonts"
import mediaQueryFor from "@/utils/mediaQuery"

export enum UniqueRegalKind {
  regular = "regular",
  cheater = "cheater",
  elite = "elite",
}

type RegalUniqueScreen = Exclude<UniqueRegalKind, UniqueRegalKind.regular>

interface IRegalUniqueProps {
  kind: RegalUniqueScreen
}

export default function RegalUnique({ kind }: IRegalUniqueProps) {
  const { t } = useTranslation()

  const [Icon, notice] = useMemo(
    () => [UniqueIcon[kind], UniqueNotice[kind]],
    [kind]
  )

  return (
    <Wrapper>
      <Icon />
      <Trans
        t={t}
        parent={Notice}
        i18nKey={notice}
        components={{ next: <br /> }}
      />
    </Wrapper>
  )
}

const UniqueIcon: Record<RegalUniqueScreen, React.ComponentType> = {
  [UniqueRegalKind.cheater]: NoEntryIcon,
  [UniqueRegalKind.elite]: DetectiveIcon,
}

const UniqueNotice: Record<RegalUniqueScreen, string> = {
  [UniqueRegalKind.cheater]: "pages.profile.regals.unique.cheater",
  [UniqueRegalKind.elite]: "pages.profile.regals.unique.elite",
}

export function getUniqueType(user: IUserDetails | null): UniqueRegalKind {
  if (user?.cheater) return UniqueRegalKind.cheater
  if (user?.elite) return UniqueRegalKind.elite

  return UniqueRegalKind.regular
}

const Wrapper = styled.div`
  grid-area: ranks;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  background: var(--color-background);
  border-radius: 8px;

  padding: 50px;
  width: 100%;

  gap: 24px;

  align-self: start;
  box-sizing: border-box;

  ${mediaQueryFor.mobile} {
    > svg {
      width: 44px;
      height: 44px;
    }

    margin-top: 32px;
  }
`

const Notice = styled.span`
  ${text18Medium};

  text-align: center;

  ${mediaQueryFor.mobile} {
    ${text14Medium};
  }
`

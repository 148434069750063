/* eslint-disable camelcase */
import { Paginated } from "@/store/api/types"

import { SupportedLanguagesT } from "~/translations/utils"

export interface IGetProblemsParams extends Paginated {
  categories: number[]
  difficulties: ProblemDifficulty[]
  hidesolved: boolean
  order: "id" | "difficulty" | "solvedby"
  reversed: boolean
}

export enum ProblemDifficulty {
  easy = 1,
  medium,
  difficult,
  hard,
  impossible,
}

type ProblemCategory = number

export interface IProblemPreview {
  id: number

  category: ProblemCategory
  difficulty: ProblemDifficulty

  name: string
  solved: boolean
  solved_by: number

  source: string
}

export interface IProblemsList {
  count: number
  task_previews: IProblemPreview[]
}

export interface GetProblemByIDArgs {
  id: number
  lang: string | null
}

export interface ProblemSubtask {
  points: number
  depends: number[] | null
  description: string
}

export interface ProblemEnvironment {
  id: number
  name: string
  production: boolean
  type: string
  cmh: string
}

export interface ProblemDetails {
  id: number

  translations: string[]
  matched_translation: string

  name: string

  legend: string

  limits: {
    time: number
    memory: number
  }

  samples: Array<{ stdin: string; stdout: string }>
  subtasks: ProblemSubtask[]

  difficulty?: number
  scope: number

  attribution?: string
  admin_level?: AdminLevel

  environments: ProblemEnvironment[]

  admin_level: AdminLevel | undefined
}

export interface TranslatedProblemDetails {
  id: number

  langs: Record<string, ProblemDetails>
  available: string[]
}

export interface INewGetProblemParams {
  id: string
  translations: string[]
}

export enum AdminLevel {
  None = 0,
  JustLook = 2,
  Editor = 3,
  Admin = 4,
  Creator = 5,
}

export interface INewProblemDetails {
  id: number
  translations: string[]
  matched_translation: string
  name: string
  legend: string

  limits: {
    time: number
    memory: number
  }

  samples: Array<{ stdin: string; stdout: string }>
  subtasks: ProblemSubtask[]

  scope: number
  environment: null
  admin_level: AdminLevel | undefined
}

interface IProblemTag {
  id: number
  names: Record<SupportedLanguagesT, string>
}

export interface ITagsList {
  tags: IProblemTag[]
}

export enum PublicProblemMarker {
  unsolved = "unsolved",
  partial = "partial",
  solved = "solved",
  bookmark = "bookmark",
}

export interface IPublicProblemDetails {
  id: number
  names: Record<string, string>
  marker?: PublicProblemMarker
  difficulty: number
  solved_by: number
  tags: number[]
}

export interface IPublicProblemsList {
  problems: IPublicProblemDetails[]
}

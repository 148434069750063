import React, { RefObject } from "react"

import { EmailAuthRequest, EmailAuthResponse } from "@/store/api/auth/types"

export enum TurnstileStatus {
  Idle = "idle",
  Pending = "pending",
  Success = "success",
  Error = "error",
}

export enum LoginModalStep {
  LoginEntry = "LoginEntry",
  LoginPassword = "LoginPassword",
  RegistrationCode = "RegistrationCode",
  RegistrationPassword = "RegistrationPassword",
  ResetCode = "ResetCode",
  ResetPassword = "ResetPassword",
}

export interface LoginControls {
  authorize: (update: EmailAuthRequest) => Promise<EmailAuthResponse>
  isLoading: boolean
  turnstile: React.ReactElement

  step: LoginModalStep
  setStep: (step: LoginModalStep) => void

  info: EmailAuthRequest
  updateInfo: (info: Partial<EmailAuthRequest>) => void
}

export type WithForwardedRef<T, E = HTMLElement> = T & {
  forwardedRef?: RefObject<E>
}

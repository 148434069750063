import { Trans, useTranslation } from "react-i18next"
import styled from "styled-components"

import ThinkingEmoji from "~/assets/icons/ThinkingEmoji"

import CustomButton from "@/components/CustomButton"
import { header24, header30, text14Medium, text18Medium } from "@/utils/fonts"
import mediaQueryFor from "@/utils/mediaQuery"

export default function Empty() {
  const { t } = useTranslation()

  const i18n = {
    emptyHeader: t("pages.contests.manage.empty_header"),
    emptyText: t("pages.contests.manage.empty_text"),
    createButtonText: t("pages.contests.manage.create_button_text"),
  }

  return (
    <EmptyWrapper>
      <ThinkingEmoji />
      <EmptyHeader>{i18n.emptyHeader}</EmptyHeader>
      <EmptyText>{i18n.emptyText}</EmptyText>
      <EmptyButton data-type="primary">{i18n.createButtonText}</EmptyButton>
    </EmptyWrapper>
  )
}

export function EmptyNoQuota() {
  const { t } = useTranslation()

  const i18n = {
    emptyHeader: t("pages.contests.manage.empty_header"),
  }

  return (
    <EmptyWrapper>
      <ThinkingEmoji />
      <EmptyHeader>{i18n.emptyHeader}</EmptyHeader>
      <Trans
        t={t}
        i18nKey="pages.contest.manage.empty_text_no_quota"
        parent={EmptyText}
        components={{
          mail: <a href="mailto: guys@sort-me.org" />,
        }}
      />
    </EmptyWrapper>
  )
}

const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 20px;
  // TODO: fix here
  height: calc(var(--window-h) - var(--shift-y) - 212px);
  text-align: center;

  ${mediaQueryFor.mobile} {
    height: calc(var(--window-h) - var(--shift-y) - 145px);
  }
`

const EmptyHeader = styled.h2`
  margin: 10px 0 0 0;
  ${header30};

  ${mediaQueryFor.mobile} {
    max-width: 338px;
    ${header24};
  }
`

const EmptyText = styled.p`
  width: 585px;
  margin: 0;
  ${text18Medium};
  text-align: center;

  > a {
    color: var(--color-primary);
    transition: color var(--transition-duration) var(--transition-function);
    text-decoration: underline;

    &:hover {
      color: #085fdb;
    }
  }

  ${mediaQueryFor.mobile} {
    max-width: 338px;
    ${text14Medium};
  }
`

const EmptyButton = styled(CustomButton)`
  min-height: 40px;
  margin-top: 10px;

  ${mediaQueryFor.mobile} {
    width: calc(100% - 50px);
    max-width: 338px;
  }
`

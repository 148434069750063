import { ChangeEvent, useCallback, useMemo } from "react"

import { omit } from "lodash"
import { Helmet } from "react-helmet-async"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import TextareaAutosize from "react-textarea-autosize"
import styled from "styled-components"

import {
  useDropStatementMutation,
  useSetStatementMutation,
} from "@/store/api/problems/edit/legend"
import { AdminLevel } from "@/store/api/problems/types"

import CustomButton from "@/components/CustomButton"
import MarkdownInput from "@/components/latex/MarkdownInput"
import Loader from "@/components/Loader"
import SubtasksTable from "@/components/SubtasksTable"
import { header30, text15Medium, text16, text16Medium } from "@/utils/fonts"

import RestoreDraft from "./RestoreDraft"
import TestSamples from "./TestSamples"
import Tip from "./Tip"
import TranslationSelector from "./TranslationSelector"
import { getDraft, saveDraft } from "./utils/helpers"
import useDraftSave from "./utils/hooks/useDraftSave"
import useLegendData from "./utils/hooks/useLegendData"

export default function Legend() {
  const { problemId = "" } = useParams()

  const { t } = useTranslation()

  const i18n = {
    titlePlaceholder: t("pages.task.edit.stages.legend.title_placeholder"),
    legendPlaceholder: t("pages.task.edit.stages.legend.legend_placeholder"),
    save: t("pages.task.edit.stages.legend.save"),
    error: t("pages.task.edit.stages.legend.error"),
    deleteTranslation: t("pages.task.edit.stages.legend.delete_translation"),
  }

  // TODO: think of some better way to write this
  const {
    translations,
    setTranslations,
    serverTranslations,
    currentTranslation,
    setCurrentTranslation,
    data,
    title,
    setTitle,
    legend,
    setLegend,
    isLoading,
    canDelete,
  } = useLegendData(problemId)

  useDraftSave({
    problemId,
    title,
    legend,
    translations,
    currentTranslation,
  })

  const handleTitleChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      setTitle(event.target.value)
      setTranslations(prev => ({
        ...prev,
        [currentTranslation!]: false,
      }))
    },
    [currentTranslation, setTitle, setTranslations]
  )

  const handleLegendChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      setLegend(event.target.value)
      setTranslations(prev => ({
        ...prev,
        [currentTranslation!]: false,
      }))
    },
    [currentTranslation, setLegend, setTranslations]
  )

  const [setStatement, { error }] = useSetStatementMutation()

  const handleSave = useCallback(async () => {
    const result = await setStatement({
      id: Number(problemId),
      translation: currentTranslation!,
      name: title,
      statement: legend,
    })

    if ("error" in result) return

    setTranslations(prev => ({
      ...prev,
      [currentTranslation!]: true,
    }))
    serverTranslations.add(currentTranslation!)

    const previousDraft = getDraft(problemId)
    const newDraft = omit(previousDraft, currentTranslation!)
    saveDraft(problemId, newDraft)
  }, [
    problemId,
    currentTranslation,
    serverTranslations,
    title,
    legend,
    setStatement,
    setTranslations,
  ])

  const [dropStatement] = useDropStatementMutation()

  const handleDelete = useCallback(() => {
    if (serverTranslations.has(currentTranslation!)) {
      dropStatement({
        id: Number(problemId),
        translation: currentTranslation!,
      })
    }

    setTranslations(prev => omit(prev, currentTranslation!))
    setCurrentTranslation(
      Object.keys(translations).find(t => t !== currentTranslation)
    )

    const previousDraft = getDraft(problemId)
    const newDraft = omit(previousDraft, currentTranslation!)
    saveDraft(problemId, newDraft)
  }, [
    serverTranslations,
    currentTranslation,
    setTranslations,
    translations,
    setCurrentTranslation,
    problemId,
    dropStatement,
  ])

  const handleRestore = useCallback(
    ({ title, legend }: { title: string; legend: string }) => {
      setTitle(title)
      setLegend(legend)

      setTranslations(prev => ({ ...prev, [currentTranslation!]: false }))
    },
    [currentTranslation, setLegend, setTitle, setTranslations]
  )

  const handleAddTranslation = useCallback(
    (translation: string) => {
      setTranslations(prev => ({ ...prev, [translation]: false }))
      setCurrentTranslation(translation)
    },
    [setCurrentTranslation, setTranslations]
  )

  // Compatibility layer
  const translationSelector = useMemo(
    () => ({
      all: Object.keys(translations),
      saved: Object.keys(translations).filter(code => translations[code]),
    }),
    [translations]
  )

  const isSaveDisabled = !(title && legend)

  const isEditable = (data?.admin_level ?? AdminLevel.None) >= AdminLevel.Editor

  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href={`https://sort-me.org/problems/${problemId}/edit/legend`}
        />
      </Helmet>

      <Wrapper>
        <Loader size="large" loading={isLoading}>
          <Edit>
            <TitleWrapper>
              {isEditable ? (
                <TaskEditableTitle
                  value={title}
                  onChange={handleTitleChange}
                  placeholder={i18n.titlePlaceholder}
                />
              ) : (
                <TaskTitle>{title}</TaskTitle>
              )}

              <TranslationSelector
                translations={translationSelector.all}
                isEditable={isEditable}
                saved={translationSelector.saved}
                selected={currentTranslation!}
                onTranslationSelected={setCurrentTranslation}
                onTranslationAdded={handleAddTranslation}
              />
            </TitleWrapper>

            {/* TODO: Reuse statement details component when it supports new api */}
            <LegendInput
              value={legend}
              onChange={handleLegendChange}
              isEditable={isEditable}
            >
              <LegendPlaceholder>{i18n.legendPlaceholder}</LegendPlaceholder>
            </LegendInput>

            {data?.subtasks && <SubtasksTable subtasks={data.subtasks} />}

            <TestSamples samples={data?.samples!} />

            {isEditable && (
              <SaveWrapper>
                <CustomButton
                  data-type="primary"
                  onClick={handleSave}
                  disabled={isSaveDisabled}
                >
                  {i18n.save}
                </CustomButton>
                {error && <p>{i18n.error}</p>}
              </SaveWrapper>
            )}

            {isEditable && canDelete && (
              <DeleteButton onClick={handleDelete}>
                {i18n.deleteTranslation}
              </DeleteButton>
            )}
          </Edit>
        </Loader>

        <TipWrapper>
          <Tip />
          {!isLoading && isEditable && (
            <RestoreDraft
              problemId={problemId}
              currentTranslation={currentTranslation!}
              serverTranslations={serverTranslations}
              title={data?.name!}
              legend={data?.legend!}
              restore={handleRestore}
            />
          )}
        </TipWrapper>
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div`
  grid-area: main;

  display: grid;
  grid-template-areas: "edit tip";
  grid-template-rows: max-content;
  grid-template-columns: 60% 40%;

  height: calc(var(--window-h) - var(--shift-y));
  max-width: calc(1200px - 2 * var(--shift-x));

  overflow: hidden overlay;
`

const Edit = styled.main`
  grid-area: edit;
  display: flex;
  flex-flow: column nowrap;
  justify-content: start;
  gap: 32px;
  padding: 35px 42px 52px 36px;
  height: max-content;

  ${text16};
  color: var(--color-text);

  p {
    margin: 0;
    padding: 0;
  }
`

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 12px;
`

const TaskEditableTitle = styled(TextareaAutosize)`
  border: none;
  outline: none;
  background-color: transparent;

  width: 100%;
  resize: none;
  padding: 0;

  ${header30};
  color: var(--color-text);
  transition: color var(--transition-duration) var(--transition-function);

  &::placeholder {
    opacity: 0.25;
  }
`

const TaskTitle = styled.h1`
  width: 100%;
  ${header30};
  color: var(--color-text);
  transition: color var(--transition-duration) var(--transition-function);
  margin: 0;
`

const LegendInput = styled(MarkdownInput)`
  width: 100%;
  transition: color var(--transition-duration) var(--transition-function);
`

const LegendPlaceholder = styled.span`
  ${text16};
  color: var(--color-text);
  opacity: 0.25;
  transition: color var(--transition-duration) var(--transition-function);
`

const DeleteButton = styled.button`
  cursor: pointer;
  background-color: transparent;
  border: none;

  width: fit-content;

  ${text15Medium};
  color: var(--color-red);
  transition: color var(--transition-duration) var(--transition-function);
`

const TipWrapper = styled.div`
  grid-area: tip;
  padding: 35px 36px 52px 42px;

  display: flex;
  flex-direction: column;
  gap: 16px;
`

const SaveWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 16px;

  > p {
    margin: 0;
    padding: 0;
    ${text16Medium};
    transition: color var(--transition-duration) var(--transition-function);
  }
`

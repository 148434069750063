import { TFunction } from "i18next"

const NATIVE_LANGUAGES: Record<string, string> = {
  en: "English",
  ru: "Русский",
  uk: "Українська",
  be: "Беларуская",
  kk: "Қазақша",
  de: "Deutsch",
  fr: "Français",
  es: "Español",
  it: "Italiano",
  pt: "Português",
  pl: "Polski",
  ch: "中文",
  ja: "日本語",
}

// eslint-disable-next-line import/prefer-default-export
export function getLanguageTranslations(t: TFunction, code: string) {
  return {
    name: code in NATIVE_LANGUAGES ? t(`shared.languages.${code}`) : code,
    native: NATIVE_LANGUAGES[code] ?? t("shared.languages.unknown"),
  }
}

export function getLanguagesList(t: TFunction) {
  return Object.keys(NATIVE_LANGUAGES).map(code => ({
    code,
    name: t(`shared.languages.${code}`),
    native: NATIVE_LANGUAGES[code],
  }))
}

import { IUserBrief } from "@/store/api/users/types"

export enum ManagedProblemMarker {
  verified = "verified",
  ready = "ready",
  unfinished = "unfinished",
  draft = "draft",
}

export interface IManagedProblemDetails {
  id: number
  names: Record<string, string>
  marker?: ManagedProblemMarker
  creator: IUserBrief
  tags: number[]
  included_in_contests: number
}

export interface IManagedProblemsList {
  problems: IManagedProblemDetails[]
  remaining_quota: number
}

export interface ICreateProblemResponse {
  id: number
  status: string
}

export interface IPolygonImportParams {
  file: File
  callback?: (progress: number) => void
}

import { createAsyncThunk } from "@reduxjs/toolkit"

import { RootState } from "@/store/store"

import { loadToken } from "./token"
import {
  IConnectAccountResponse,
  ILoginWithTelegramMode,
  ILoginWithTelegramOptions,
  ITelegramActivationOptions,
} from "./types"

const base = "https://beta.sort-me.org/api"

function invokeTelegramAuth(): Promise<any> {
  return new Promise(resolve => {
    window.Telegram.Login.auth(
      {
        bot_id: 1996931175, // TODO: production - 1756957630
        request_access: true,
      },
      resolve,
    )
  })
}

async function activateTelegramLogin(
  data: ITelegramActivationOptions,
  userToken: string | null,
  mode: ILoginWithTelegramMode,
): Promise<IConnectAccountResponse> {
  const url =
    mode === "create"
      ? "https://beta.sort-me.org/api/auth?provider=telegram"
      : "https://beta.sort-me.org/api/auth/connect?provider=telegram"

  const headers = new Headers()

  if (userToken) headers.append("Authorization", `Bearer ${userToken}`)

  const req = await fetch(`${base}${url}`, {
    method: "POST",
    headers,
    body: JSON.stringify(data),
  })

  return {
    status: req.status,
    data: await req.json(),
  }
}

const loginWithTelegram = createAsyncThunk<
  void,
  ILoginWithTelegramOptions,
  { state: RootState }
>(
  "user/loginWithTelegram",
  async ({ mode }, { rejectWithValue, getState, dispatch }) => {
    const data = await invokeTelegramAuth()
    if (!data) throw rejectWithValue(data)

    const {
      user: { token },
    } = getState()

    const { status, data: resp } = await activateTelegramLogin(
      {
        hash: data.hash,
        id: data.id,
        auth_date: data.auth_date,
      },
      token,
      mode,
    )

    if (status !== 200) throw rejectWithValue(resp?.error)
    if (resp?.token) dispatch(loadToken(resp?.token))
  },
  {
    condition(_, { getState }) {
      const {
        auth: { loading },
      } = getState()

      return loading === null // should make request?
    },
  },
)

export default loginWithTelegram

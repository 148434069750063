import { ChangeEvent, useCallback, useEffect, useState } from "react"

import { useTranslation } from "react-i18next"
import { useSearchParams } from "react-router-dom"
import styled from "styled-components"

import SearchBar from "@/components/SearchBar"
import useDebounce from "@/utils/hooks/useDebounce"

const MAX_QUERY_LENGTH = 54 // maximum length of a query that api can handle

interface IArchiveSearchBarProps {
  submit: (value: string) => void
}

export default function ArchiveSearchBar({ submit }: IArchiveSearchBarProps) {
  const [searchParams] = useSearchParams()

  const [query, setQuery] = useState(searchParams.get("query") ?? "")
  const debouncedQuery = useDebounce(query, 1000)

  const { t } = useTranslation()

  const i18n = {
    searchPlaceholder: t("pages.contests.public.search_placeholder"),
    archiveText: t("pages.contests.public.archive_text"),
  }

  useEffect(() => {
    submit(debouncedQuery)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedQuery])

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value)
  }, [])

  const handleSubmit = useCallback(
    (event: ChangeEvent<HTMLFormElement>) => {
      event.preventDefault()
      submit(query)
    },
    [query, submit]
  )

  return (
    <Form onSubmit={handleSubmit}>
      <SearchBar
        value={query}
        onChange={handleChange}
        placeholder={i18n.searchPlaceholder}
        maxLength={MAX_QUERY_LENGTH}
      />
    </Form>
  )
}

const Form = styled.form`
  max-width: 727px;

  margin-bottom: 32px;

  > input {
    width: 100%;
  }
`

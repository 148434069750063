import { Helmet } from "react-helmet-async"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

import Loader from "@/components/Loader"
import ContestContainer from "@/features/contest/ContestContainer"
import useParticipatedPagination from "@/features/contest/hooks/useParticipatedPagination"
import Empty from "@/features/contest/subpages/History/HistoryEmpty"
import { text14Medium, text18Medium } from "@/utils/fonts"
import mediaQueryFor from "@/utils/mediaQuery"

export default function ContestHistory() {
  const { t } = useTranslation()

  const i18n = {
    title: t("pages.contests.history.title"),
    mainText: t("pages.contests.history.main_text"),
  }

  const {
    data: { contests, receivedAt },

    isLoading,
    isFetchingMore,
    isEmpty,
    isExhausted,
    isRefetching,

    fetchMore,
  } = useParticipatedPagination()

  const isScrollable = !isEmpty && !isExhausted

  return (
    <>
      <Helmet>
        <title>{i18n.title}</title>
        <link rel="canonical" href="https://sort-me.org/contest/history" />
      </Helmet>

      <Loader loading={isRefetching} size="large">
        {isEmpty && !isLoading ? (
          <Empty />
        ) : (
          <>
            <HistoryText>{i18n.mainText}</HistoryText>
            <ContestContainer
              contests={contests}
              receivedAt={receivedAt!}
              enableScrolling={isScrollable}
              isFetchingMore={isFetchingMore}
              fetchMore={fetchMore}
              refetch={() => {}}
            />
          </>
        )}
      </Loader>
    </>
  )
}

const HistoryText = styled.p`
  max-width: 679px;
  margin: 0 0 32px 0;
  ${text18Medium};

  ${mediaQueryFor.mobile} {
    ${text14Medium};
  }
`

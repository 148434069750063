import { Helmet } from "react-helmet-async"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

import { useGetAvailableContestsQuery } from "@/store/api/contests"

import Loader from "@/components/Loader"
import useArchivePagination from "@/features/contest/hooks/useArchivePagination"
import { header24, header32 } from "@/utils/fonts"
import mediaQueryFor from "@/utils/mediaQuery"

import Archive from "./Archive"
import Upcoming from "./Upcoming"

export default function ContestPublic() {
  const { t } = useTranslation()

  const i18n = {
    title: t("pages.contests.public.title"),
    searchPlaceholder: t("pages.contests.public.search_placeholder"),
    archiveHeader: t("pages.contests.public.archive_header"),
  }

  const { isLoading: isArchiveLoading } = useArchivePagination()
  const { isLoading: isUpcomingLoading, data: upcomingContests } =
    useGetAvailableContestsQuery()

  const isLoading = isUpcomingLoading || isArchiveLoading

  const isUpcomingEmpty = upcomingContests?.contests.length === 0

  return (
    <>
      <Helmet>
        <title>{i18n.title}</title>
        <link rel="canonical" href="https://sort-me.org/contest" />
      </Helmet>

      <Loader loading={isLoading} size="large">
        <Wrapper>
          {!isUpcomingEmpty && (
            <UpcomingSection>
              <Upcoming />
            </UpcomingSection>
          )}
          <section>
            {!isUpcomingEmpty && (
              <ArchiveHeader>{i18n.archiveHeader}</ArchiveHeader>
            )}
            <Archive />
          </section>
        </Wrapper>
      </Loader>
    </>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 72px;

  > section {
    width: 100%;
  }

  ${mediaQueryFor.mobile} {
    gap: 32px;
  }
`

const UpcomingSection = styled.section`
  ${mediaQueryFor.mobile} {
    margin-top: 20px;
  }
`

const ArchiveHeader = styled.h2`
  margin: 0 0 24px 0;
  ${header32};

  ${mediaQueryFor.mobile} {
    margin-bottom: 12px;
    ${header24};
  }
`

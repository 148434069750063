import React, {
  MouseEventHandler,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react"

import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { Transition } from "react-transition-group"
import styled from "styled-components"
import { match } from "ts-pattern"

import { setTestsData } from "@/store/slices/tests"

import {
  useGetTestsQuery,
  useUpdateTestsMutation,
} from "@/store/api/problems/edit/tests"

import ButtonWithLoader from "@/components/ButtonWithLoader"
import useTestChanges from "@/features/task/edit/tests/hooks/useTestChanges"
import { ISubtaskDetails } from "@/features/task/edit/tests/types"
import { useAppDispatch, useAppSelector } from "@/store"
import { text14Medium, text16Medium } from "@/utils/fonts"
import useDebounce from "@/utils/hooks/useDebounce"

const DROP_ANIMATION_DELAY = 300
const APPEAR_ANIMATION_DURATION = 300

interface ISaveContainerProps {
  distribution?: ISubtaskDetails[]
}

export default function SaveContainer({
  distribution = [],
}: ISaveContainerProps) {
  const { problemId = "" } = useParams()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const wrapper = useRef<HTMLDivElement>(null)

  const { data } = useGetTestsQuery(Number(problemId))
  const { tests, subtasks } = useAppSelector(({ tests }) => tests)

  const [margin, setMargin] = useState<number>(0)
  const [isVisible, setIsVisible] = useState(false)

  const { orderChanged, subtaskChanged, highestMovedTestId } =
    useTestChanges(distribution)

  // keep previous values to correctly render disappear animation
  const prevOrderChanged = useDebounce(orderChanged, APPEAR_ANIMATION_DURATION)
  const prevSubtaskChanged = useDebounce(
    subtaskChanged,
    APPEAR_ANIMATION_DURATION,
  )

  useLayoutEffect(() => {
    const topElement = document.getElementById(`test-${highestMovedTestId}`)

    if (topElement) {
      const timeout = setTimeout(() => {
        setMargin(topElement.offsetTop)
        setIsVisible(true)
      }, DROP_ANIMATION_DELAY) // wait for drop animation to finish
      return () => clearTimeout(timeout)
    }
  })

  useEffect(() => {
    if (!highestMovedTestId) {
      setIsVisible(false)
    }
  }, [highestMovedTestId])

  const [updateTests] = useUpdateTestsMutation()

  const handleSaveClick = useCallback(async () => {
    try {
      if (tests && subtasks)
        await updateTests({
          problem_id: Number(problemId),
          tests,
          subtasks,
          orderChanged,
          subtaskChanged,
        }).unwrap()
    } catch {}
  }, [orderChanged, problemId, subtaskChanged, subtasks, tests, updateTests])

  const handleRollbackClick = useCallback<
    MouseEventHandler<HTMLButtonElement>
  >(() => {
    if (data) dispatch(setTestsData(data))
  }, [data, dispatch])

  const i18n = {
    orderChanged: t("pages.task.edit.stages.tests.order_change_description"),
    subtaskChanged: t(
      "pages.task.edit.stages.tests.subtask_change_description",
    ),
    bothChanged: t("pages.task.edit.stages.tests.both_change_description"),
    apply: t("pages.task.edit.stages.tests.apply"),
    rollback: t("pages.task.edit.stages.tests.rollback"),
  }

  const showOrderChanged = orderChanged || (!isVisible && prevOrderChanged)
  const showSubtaskChanged =
    subtaskChanged || (!isVisible && prevSubtaskChanged)
  const text = match({ showOrderChanged, showSubtaskChanged })
    .with(
      { showOrderChanged: true, showSubtaskChanged: false },
      () => i18n.orderChanged,
    )
    .with(
      { showOrderChanged: false, showSubtaskChanged: true },
      () => i18n.subtaskChanged,
    )
    .with(
      { showOrderChanged: true, showSubtaskChanged: true },
      () => i18n.bothChanged,
    )
    .otherwise(() => "")

  return (
    <Transition
      in={isVisible}
      timeout={APPEAR_ANIMATION_DURATION}
      nodeRef={wrapper}
    >
      {state => (
        <Wrapper $top={margin} ref={wrapper} data-visible={state}>
          {text}
          <SaveButton data-type={"primary"} onClick={handleSaveClick}>
            {i18n.apply}
          </SaveButton>
          <DeleteButton onClick={handleRollbackClick}>
            {i18n.rollback}
          </DeleteButton>
        </Wrapper>
      )}
    </Transition>
  )
}

const Wrapper = styled.div<{ $top: number }>`
  grid-column: save;

  background-color: var(--color-primary-transparent);
  border-radius: 8px;
  transition: margin 0.3s cubic-bezier(0, 1, 0, 1),
    background-color var(--transition-duration) var(--transition-function);

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  padding: 18px;
  height: fit-content;

  position: sticky;
  top: 0;
  margin-top: ${({ $top }) => $top}px;

  ${text16Medium};

  > p {
    margin: 0;
  }

  &[data-visible="entering"] {
    animation: slideIn ${APPEAR_ANIMATION_DURATION}ms cubic-bezier(0, 1, 0, 1);
  }

  &[data-visible="exiting"] {
    pointer-events: none;
    animation: slideIn ${APPEAR_ANIMATION_DURATION}ms cubic-bezier(0, 1, 0, 1)
      reverse;
  }

  &[data-visible="exited"] {
    display: none;
  }

  @keyframes slideIn {
    from {
      transform: translateX(400px);
      opacity: 0;
    }
    to {
      transform: translateX(0px);
      opacity: 1;
    }
  }
`

const SaveButton = styled(ButtonWithLoader)`
  width: 100%;
`

const DeleteButton = styled.button`
  cursor: pointer;
  border: none;
  background-color: transparent;

  ${text14Medium};
  color: var(--color-red);
  transition: color var(--transition-duration) var(--transition-function);
`

import { MouseEventHandler, useCallback } from "react"

import { Draggable } from "react-beautiful-dnd"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"

import DraggableIcon from "~/assets/icons/DraggableIcon"
import EditIcon from "~/assets/icons/EditIcon"

import { ITestDetails } from "@/features/task/edit/tests/types"
import useIsEditable from "@/features/task/hooks/useIsEditable"
import { text14Medium, text16Medium } from "@/utils/fonts"

import CommentEditor from "./CommentEditor"
import TestSample from "./TestSample"

interface ITestsListProps {
  test: ITestDetails
  index: number
  isMovable?: boolean
}

export default function TestItem({
  test,
  index,
  isMovable = true,
}: ITestsListProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleEditClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    () => navigate(`${test.id}`, { state: { popup: true } }),
    [navigate, test.id],
  )

  const { isEditable } = useIsEditable()

  const i18n = {
    header: t("pages.task.edit.stages.tests.test_header", {
      index: test.id,
    }),
    notification: t("pages.task.edit.stages.tests.order_change_notification", {
      index: test.newId,
    }),
    edit: t("pages.task.edit.stages.tests.edit"),
    noComment: t("pages.task.edit.stages.tests.no_comment"),
  }

  const orderChanged = test.id !== test.newId
  const wasMoved = test.originalSubtask !== test.subtask || orderChanged

  if (isMovable && isEditable)
    return (
      <Draggable draggableId={`${test.id}`} index={index} key={test.id}>
        {provided => (
          <Wrapper
            id={`test-${test.id}`}
            ref={provided.innerRef}
            {...provided.draggableProps}
            data-moved={wasMoved}
          >
            <div {...provided.dragHandleProps} data-drag>
              <DraggableIcon />
            </div>
            <Header>
              <p>
                {i18n.header}
                {orderChanged && ` (${i18n.notification})`}
              </p>
              <CommentEditor testId={test.id} testComment={test.comment} />
              <button onClick={handleEditClick}>
                <EditIcon width={12} height={12} />
                {i18n.edit}
              </button>
            </Header>
            <TestSample
              testId={test.id}
              stdinPreview={test.stdin_preview}
              stdinSize={test.stdin_size}
              stdoutPreview={test.stdout_preview}
              stdoutSize={test.stdout_size}
            />
          </Wrapper>
        )}
      </Draggable>
    )

  return (
    <Wrapper
      data-moved={wasMoved}
      data-editable={isEditable}
      id={`test-${test.id}`}
    >
      <Header>
        <p>
          {i18n.header}
          {orderChanged && ` (${i18n.notification})`}
        </p>
        <Comment data-empty={!test.comment}>
          {test.comment || i18n.noComment}
        </Comment>
        <button disabled={!isEditable}>
          <EditIcon width={12} height={12} />
          {i18n.edit}
        </button>
      </Header>
      <TestSample
        testId={test.id}
        stdinPreview={test.stdin_preview}
        stdinSize={test.stdin_size}
        stdoutPreview={test.stdout_preview}
        stdoutSize={test.stdout_size}
      />
    </Wrapper>
  )
}

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  > p {
    ${text16Medium};
    line-height: 150%;

    min-width: max-content;
    margin: 0;
  }

  > button {
    cursor: pointer;
    border: none;
    background-color: transparent;
    color: var(--color-primary);

    display: flex;
    align-items: center;
    gap: 8px;

    padding: 0;
    height: 21px;
    margin-left: auto;

    ${text14Medium};

    > svg {
      path {
        fill: var(--color-primary);
        transition: color var(--transition-duration) var(--transition-function);
      }
    }
  }
`

const Wrapper = styled.div`
  border: 1px solid var(--color-g-background-stroke);
  border-radius: 5px;
  background-color: var(--color-g-background);
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.02);
  transition: border-color var(--transition-duration) var(--transition-function),
    background-color var(--transition-duration) var(--transition-function);

  padding: 23px 29px;
  box-sizing: border-box;
  width: 100%;

  margin-top: 20px;
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 24px;

  > [data-drag] {
    cursor: grab;

    position: absolute;
    left: 5px;
    top: 21px;
    padding: 5px 10px;
    height: 14px;

    > svg {
      circle {
        fill: #d9d9d9;
      }
    }
  }

  &[data-moved="true"] {
    border-color: var(--color-primary);
  }

  &[data-editable="false"] {
    > ${Header} {
      > button {
        display: none;
      }
    }
  }
`

const Comment = styled.p`
  margin: 0;
  opacity: 0.5;
  ${text16Medium};
  line-height: 150%;

  &[data-empty="true"] {
    opacity: 0.4;
  }
`

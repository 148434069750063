import styled from "styled-components"

import RectangularLoader from "./RectangularLoader"

interface IContestLoaderProps {
  loading: boolean
  children?: React.ReactNode
}

export default function ContestLoader({
  loading,
  children = null,
}: IContestLoaderProps) {
  return (
    <Wrapper $loading={loading}>
      {children}
      <LoaderWrapper>
        <RectangularLoader loading={loading} size={166} />
      </LoaderWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div<{ $loading: boolean }>`
  position: relative;

  & > :not(:last-child) {
    visibility: ${({ $loading }) => ($loading ? "hidden" : "unset")};
  }
`

const LoaderWrapper = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
`

import { useCallback } from "react"

import { Trans, useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"

import { text18, text24 } from "@/utils/fonts"

const gobletIcon = "/assets/images/goblet.png"
const lampIcon = "/assets/images/lamp.png"
const magnifierIcon = "/assets/images/magnifier.png"

export default function LinkTable() {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const i18n = {
    contenst: {
      title: t("pages.home.links.contest.title"),
      subtitle: t("pages.home.links.contest.subtitle"),
    },
    tasks: {
      title: t("pages.home.links.tasks.title"),
      subtitle: t("pages.home.links.tasks.subtitle"),
    },
    algorithms: {
      title: t("pages.home.links.algorithms.title"),
      subtitle: "pages.home.links.algorithms.subtitle",
    },
  }

  const goToContest = useCallback(() => navigate("/contest"), [navigate])
  const goToTasks = useCallback(() => navigate("/problems"), [navigate])

  return (
    <Wrapper>
      <LinkBox data-kind="contest" onClick={goToContest}>
        <img src={gobletIcon} alt="goblet" width={80} height={80} />
        <LinkHeader>{i18n.contenst.title}</LinkHeader>
        <p>{i18n.contenst.subtitle}</p>
      </LinkBox>

      <LinkBox data-kind="tasks" onClick={goToTasks}>
        <img src={magnifierIcon} alt="magifier" width={80} height={80} />
        <LinkHeader>{i18n.tasks.title}</LinkHeader>
        <p>{i18n.tasks.subtitle}</p>
      </LinkBox>

      <LinkBox data-disabled="true" data-kind="algorithms">
        <img src={lampIcon} alt="lamp" width={80} height={80} />
        <LinkHeader>{i18n.algorithms.title}</LinkHeader>
        <Trans
          t={t}
          i18nKey={i18n.algorithms.subtitle}
          components={{
            lnk: <a target="_blank" href="https://t.me/sort_me" />,
          }}
          parent="p"
        />
      </LinkBox>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-top: 145px;

  grid-area: links;

  display: grid;

  grid-auto-flow: row;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: max-content;

  gap: 44px;
`

const LinkBox = styled.div`
  display: flex;
  position: relative;

  gap: 12px;

  cursor: pointer;

  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: space-between;

  padding: 54px 24px 24px 24px;

  box-shadow: 0px 4px 10px 0px var(--shadow);

  border: var(--links-border-width) solid var(--links-accent);
  border-radius: 10px;

  transition: border-color var(--transition-duration) var(--transition-function);

  &[data-disabled="true"] {
    filter: grayscale(1);
  }

  > img {
    position: absolute !important;

    top: -45px;
    left: 20px;
  }

  > p {
    ${text18};
    font-weight: 500;

    margin: 0;
    padding: 0;
  }

  a {
    color: #69a8e5;
  }
`

const LinkHeader = styled.h3`
  margin: 0;
  padding: 0;

  ${text24};

  [data-kind="contest"] > & {
    color: var(--links-contest);
  }

  [data-kind="tasks"] > & {
    color: var(--links-tasks);
  }

  [data-kind="algorithms"] > & {
    color: var(--links-algorithms);
  }
`

import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import styled from "styled-components"

import { SidebarHolder, SidebarItem } from "@/components/Sidebar"
import { text17Medium } from "@/utils/fonts"

export default function SelectorSidebar() {
  const { t } = useTranslation()

  const i18n = {
    back: t("pages.task.create.selector.back"),
    title: t("pages.task.create.selector.header"),
  }

  return (
    <StyledSidebar>
      <StyledLink as={Link} to="/problems">
        {i18n.back}
      </StyledLink>

      <Title>{i18n.title}</Title>
    </StyledSidebar>
  )
}

const StyledSidebar = styled(SidebarHolder)`
  gap: 33px;
`

const StyledLink = styled(SidebarItem)`
  opacity: 0.5;

  text-decoration: none;
  transition: color var(--transition-duration) var(--transition-function);
`

const Title = styled.span`
  ${text17Medium};
  transition: color var(--transition-duration) var(--transition-function);
`

import { useEffect, useRef } from "react"

import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"
import { ViewportList, ViewportListRef } from "react-viewport-list"
import styled from "styled-components"

import { createQuerySelector } from "@/store/api/helpers"
import { useGetManagedProblemsQuery } from "@/store/api/problems/create"

import Loader from "@/components/Loader"
import useProblemList from "@/features/task/list/Managed/hooks/useProblemList"
import CreateProblemButton from "@/features/task/list/Managed/ProblemList/CreateProblemButton"
import { IManagedFilterProps } from "@/features/task/list/types"
import getSimilar from "@/features/task/list/utils/getSimilar"
import useBriefProblems from "@/features/task/list/utils/hooks/useBriefProblems"
import { text13Medium, text16Medium } from "@/utils/fonts"
import mediaQueryFor from "@/utils/mediaQuery"

import ProblemListItem from "./ProblemListItem"

interface IProblemListProps {
  filterProps: IManagedFilterProps
}

export interface IProblemListState {
  listScrollPosition?: {
    index: number
    offset: number
  }
}

export default function ProblemList({ filterProps }: IProblemListProps) {
  const { state }: { state: IProblemListState } = useLocation()

  const listScrollPosition = state?.listScrollPosition

  const { t } = useTranslation()

  const i18n = {
    name: t("pages.task.list.tabs.managed.columns.name"),
    creator: t("pages.task.list.tabs.managed.columns.creator"),
    tags: t("pages.task.list.tabs.managed.columns.tags"),
    inContests: t("pages.task.list.tabs.managed.columns.in_contests"),
    empty: t("pages.task.list.empty"),
  }

  const listRef = useRef<ViewportListRef>(null)

  const { remainingQuota } = useGetManagedProblemsQuery(undefined, {
    selectFromResult: selectQuota,
  })

  const { problems, isLoading } = useProblemList(filterProps)
  const briefProblems = useBriefProblems(problems)

  const isEmpty = problems.length === 0

  useEffect(() => {
    if (listScrollPosition && listRef.current) {
      listRef.current.scrollToIndex({
        ...listScrollPosition,
        prerender: 50,
      })
    }
  }, [listScrollPosition])

  return (
    <Wrapper>
      <Header>
        <span>{i18n.name}</span>
        <span>{i18n.creator}</span>
        <span>{i18n.tags}</span>
        <span>{i18n.inContests}</span>
      </Header>
      <Loader size="large" loading={isLoading}>
        <CreateProblemButton remainingQuota={remainingQuota!} />
        {isEmpty ? (
          <Empty>{i18n.empty}</Empty>
        ) : (
          <ViewportList
            items={problems}
            itemMargin={8}
            overscan={50}
            ref={listRef}
          >
            {(item, index) => (
              <ProblemListItem
                {...item}
                key={item.id}
                linkState={{
                  filters: filterProps,
                  similar: getSimilar(briefProblems, index),
                  listScrollPosition: listRef.current?.getScrollPosition(),
                }}
              />
            )}
          </ViewportList>
        )}
      </Loader>
    </Wrapper>
  )
}

const selectQuota = createQuerySelector(
  useGetManagedProblemsQuery,
  ({ data: { remaining_quota: remainingQuota } = {} }) => ({ remainingQuota })
)

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Header = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 185px 1fr 90px;

  ${text13Medium};
  opacity: 0.3;

  ${mediaQueryFor.mobile} {
    display: none;
  }
`

const Empty = styled.span`
  margin-top: 16px;
  ${text16Medium};
  text-align: center;
`

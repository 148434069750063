import styled from "styled-components"

import Card from "@/components/Card"
import Timing from "@/features/contest/Timing"
import { text13Medium, text16Medium } from "@/utils/fonts"

import { IContestEntryProps } from "./types"

export default function UpcomingEntry({
  contest,
  onExpire,
}: IContestEntryProps) {
  return (
    <Card>
      <CardHeader>{contest.name}</CardHeader>
      <Timing
        timing={contest.timings}
        requestFulfilledAt={contest.requestFulfilledAt}
        onExpire={onExpire}
      />
      <CardOrganizer>{contest.organizer}</CardOrganizer>
    </Card>
  )
}

const CardHeader = styled.h4`
  height: 60px;
  overflow: hidden;
  width: 100%;

  margin: 0 0 19px 0;
  ${text16Medium};
`

const CardOrganizer = styled.span`
  opacity: 0.5;
  ${text13Medium};
`

import styled from "styled-components"

import OpenInNewIcon from "~/assets/icons/OpenInNewIcon"

interface OpenMailingAppProps {
  email: string
}

export default function OpenMailingApp({ email }: OpenMailingAppProps) {
  const domain = email.includes("@") ? email.split("@")[1] : null

  if (!domain) return null

  return (
    <OpenApp
      href={`https://${domain}`}
      target="_blank"
      referrerPolicy="no-referrer"
    >
      <OpenInNewIcon />
      Открыть {domain} в новой вкладке
    </OpenApp>
  )
}

const OpenApp = styled.a`
  display: flex;
  flex-flow: row nowrap;
  align-items: start;
  justify-content: start;
  gap: 8px;

  color: var(--color-primary);
  text-decoration: none;

  cursor: pointer;

  svg {
    width: 14px;
    height: 14px;

    path {
      fill: currentColor;
    }
  }
`

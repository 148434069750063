import { useCallback, MouseEventHandler, useState } from "react"

import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import styled from "styled-components"

import { withQueryParams } from "@/store/api/helpers"

import useIsEditable from "@/features/task/hooks/useIsEditable"
import { useAppSelector } from "@/store"
import { text14Medium, text16Medium } from "@/utils/fonts"

export default function ArchiveEdit() {
  const navigate = useNavigate()
  const { problemId = "" } = useParams()

  const { t } = useTranslation()

  const [isDownloading, setIsDownloading] = useState(false)

  const { isEditable } = useIsEditable()

  const tests = useAppSelector(({ tests }) => tests.tests)
  const hasTests = !!tests && tests.length > 0

  const token = useAppSelector(({ user }) => user.token)

  const toUpload = useCallback<MouseEventHandler<HTMLButtonElement>>(
    () => navigate("upload/"),
    [navigate]
  )

  const downloadTests = useCallback<
    MouseEventHandler<HTMLButtonElement>
  >(async () => {
    setIsDownloading(true)
    const result = await fetch(
      withQueryParams({
        url: "https://beta.sort-me.org/api/problems/exportTests",
        params: {
          problem_id: problemId,
        },
      }),
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    const blob = await result.blob()
    const url = URL.createObjectURL(blob)
    window.location.assign(url)
    URL.revokeObjectURL(url)
    setIsDownloading(false)
  }, [problemId, token])

  const i18n = {
    testsLoaded: t("pages.task.edit.stages.tests.archive_edit.tests_loaded", {
      count: tests?.length ?? 0,
    }),
    size: t("pages.task.edit.stages.tests.archive_edit.size", {
      count: 0,
    }),
    download: t("pages.task.edit.stages.tests.archive_edit.download"),
    loading: t("pages.task.edit.stages.tests.archive_edit.loading"),
    delete: t("pages.task.edit.stages.tests.archive_edit.delete"),
    upload: t("pages.task.edit.stages.tests.archive_edit.upload"),
  }

  if (hasTests)
    return (
      <Wrapper data-editable={isEditable}>
        <TestsLoaded>{i18n.testsLoaded}</TestsLoaded>
        <Size>{i18n.size}</Size>
        <Download onClick={downloadTests}>
          {isDownloading ? i18n.loading : i18n.download}
        </Download>
        <Delete onClick={toUpload}>{i18n.delete}</Delete>
      </Wrapper>
    )

  return (
    <NoTestsWrapper>
      <Upload onClick={toUpload}>{i18n.upload}</Upload>
    </NoTestsWrapper>
  )
}

const TestsLoaded = styled.span`
  grid-area: loaded;
`

const Size = styled.span`
  grid-area: size;

  opacity: 0.5;
`

const Download = styled.button`
  grid-area: download;

  color: var(--color-primary);
`

const Delete = styled.button`
  grid-area: delete;

  color: var(--color-red);
`

const Wrapper = styled.div`
  grid-column: main;

  background-color: var(--color-background);
  border-radius: 8px;
  transition: background-color var(--transition-duration)
    var(--transition-function);

  display: grid;
  grid-template-areas:
    "loaded download"
    "size delete";
  grid-template-columns: 1fr 1.5fr;
  grid-template-rows: 19px 19px;
  align-content: center;

  height: 75px;
  padding: 0 24px;
  box-sizing: border-box;
  margin-bottom: 20px;

  > span {
    ${text16Medium};
  }

  > button {
    cursor: pointer;
    border: none;
    background-color: transparent;

    width: fit-content;
    padding: 0;
    justify-self: end;

    ${text14Medium};
    text-align: right;
    transition: color var(--transition-duration) var(--transition-function);
  }

  &[data-editable="false"] {
    grid-template-areas:
      "loaded download"
      "size download";

    > ${Delete} {
      display: none;
    }
  }
`

const NoTestsWrapper = styled.div`
  grid-column: main;

  background-color: var(--color-background);
  border-radius: 8px;
  transition: background-color var(--transition-duration)
    var(--transition-function);

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 18px;
`

const Upload = styled.button`
  cursor: pointer;
  border: none;
  background-color: transparent;

  width: fit-content;
  padding: 0;

  ${text14Medium};
  text-align: right;
  color: var(--color-primary);
`

import styled from "styled-components"

const Divider = styled.div`
  width: 1px;
  align-self: stretch;

  opacity: 0.1;
  background-color: var(--color-text);
  transition: background-color var(--transition-duration)
    var(--transition-function);
`

export default Divider

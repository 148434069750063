import { ChangeEventHandler } from "react"

import styled from "styled-components"

import Checkmark from "~/assets/icons/Checkmark"

interface ICheckboxInputProps {
  checked: boolean
  onChange: ChangeEventHandler<HTMLInputElement>
  value?: string
  className?: string
  children?: React.ReactNode
}

export default function CheckboxInput({
  checked,
  onChange,
  value = "",
  className = "",
  children = null,
}: ICheckboxInputProps) {
  return (
    <Label className={className}>
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        value={value}
      />
      <Checkbox>
        <Checkmark />
      </Checkbox>
      {children}
    </Label>
  )
}

const Label = styled.label`
  > input {
    visibility: hidden;
    opacity: 0;
    position: absolute;
  }
`

const Checkbox = styled.span`
  cursor: pointer;
  background-color: var(--color-background);
  border: 1px solid var(--color-input-stroke);
  border-radius: 5px;
  transition: border var(--transition-duration) var(--transition-function),
    background-color var(--transition-duration) var(--transition-function);

  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  box-sizing: border-box;

  > svg path {
    fill: var(--color-background);
    transition: fill var(--transition-duration) var(--transition-function);
  }

  input:checked + & {
    background-color: var(--color-primary);
    border-color: var(--color-primary);

    > svg path {
      fill: #ffffff;
    }
  }
`

import styled from "styled-components"

import { ProblemDifficulty } from "@/store/api/problems/types"

import DifficultyIcon from "@/components/DifficultyIcon"
import { text10Medium, text16 } from "@/utils/fonts"
import mediaQueryFor from "@/utils/mediaQuery"

interface IDifficultyRecordProps {
  difficulty: ProblemDifficulty
  solved: number
}

export default function DifficultyRecord({
  difficulty,
  solved,
}: IDifficultyRecordProps) {
  return (
    <>
      <DifficultyIcon rank={difficulty} />
      <SolvedWrapper>{solved}</SolvedWrapper>
    </>
  )
}

const SolvedWrapper = styled.span`
  ${text16};
  letter-spacing: 0;

  ${mediaQueryFor.mobile} {
    ${text10Medium};
  }
`

import { ChangeEvent, useCallback, useState } from "react"

import Cookies from "js-cookie"

import { loadToken } from "@/store/slices/auth/token"
import { setToken as setStorageToken } from "@/store/slices/user"

import { useAppDispatch, useAppSelector } from "@/store"
import { IPageConfig } from "@/utils/types"

export const config: IPageConfig = {
  routes: { path: "/utils/auth", Component: AuthorizationUtility },

  server: {},
  client: {},

  layout: "normal",
}

function AuthorizationUtility() {
  const [token, setToken] = useState("")
  const dispatch = useAppDispatch()
  const me = useAppSelector(({ user }) => user.handle)

  const reset = useCallback(() => {
    dispatch(setStorageToken(null))
    Cookies.remove("token")
    window.location.reload()
  }, [dispatch])

  const confim = useCallback(() => {
    dispatch(loadToken(token))
      .unwrap()
      .catch(err => alert(err.message))
  }, [token, dispatch])

  const change = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
    setToken(ev.target.value)
  }, [])

  return (
    <div>
      <span>Very secure authorization utility</span>
      <br />
      Now: {me ?? "noone"}
      <br />
      <label htmlFor="token">Your token, please:</label>
      <input
        id="token"
        autoComplete="one-time-code"
        type="text"
        value={token}
        onChange={change}
      />
      <br />
      <button onClick={confim}>Confirm!</button>
      <br />
      <button onClick={reset}>Reset!</button>
    </div>
  )
}

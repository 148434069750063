import authReducer from "./auth"
import commonReducer from "./common"
import testsReducer from "./tests"
import userReducer from "./user"

const reducers = {
  auth: authReducer,
  common: commonReducer,
  user: userReducer,

  tests: testsReducer,
}

export default reducers

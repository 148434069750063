import React, { useEffect, useMemo } from "react"

import { IContestTimings } from "@/store/api/contests/types"

import useContestStatus, { ContestStatus } from "@/shared/time/useContestStatus"
import useMoscowTime from "@/shared/time/useMoscowTime"

import { getContestDatetimes } from "./helpers"
import Running from "./Running"
import Subscript from "./Subscript"

interface ITimingProps {
  timing: IContestTimings
  requestFulfilledAt: number
  onExpire: () => void
}

function Timing({ timing, requestFulfilledAt, onExpire }: ITimingProps) {
  const status = useContestStatus(timing)

  const contestDatetimes = useMemo(
    () =>
      getContestDatetimes({
        ...timing,
        requestFulfilledAt,
      }),
    [requestFulfilledAt, timing]
  )

  const { start, end, now, registrationStart, registrationEnd } =
    contestDatetimes

  const startText = useMoscowTime(timing.start)

  useEffect(() => {
    let destination: number

    switch (status) {
      case ContestStatus.WaitingToStart:
        destination = start.diff(now).toMillis()
        break
      case ContestStatus.RegistrationOpen:
        destination = registrationEnd.diff(now).toMillis()
        break
      case ContestStatus.WaitingToRegister:
        destination = registrationStart.diff(now).toMillis()
        break
      default:
        return
    }

    // 0x7fffffff is the largest delay that timeout can work with
    if (destination < 0x7fffffff) {
      const timeout = setTimeout(onExpire, destination)

      return () => clearTimeout(timeout)
    }
  })

  switch (status) {
    case ContestStatus.Running:
      return <Running end={end} now={now} onExpire={onExpire} />
    case ContestStatus.RegistrationOpen:
      return <Subscript kind="registrationOpen">{startText}</Subscript>
    case ContestStatus.Ended:
      return <Subscript kind="ended">{startText}</Subscript>
    default: // WaitingToStart, WaitingToRegister
      return <Subscript kind="waiting">{startText}</Subscript>
  }
}

export default React.memo(Timing)

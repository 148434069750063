import { useEffect, useMemo, useRef } from "react"

import diff from "fast-diff"
import { isEmpty } from "lodash"
import { DateTime } from "luxon"

import { getDraft, saveDraft } from "@/features/task/edit/legend/utils/helpers"
import { ISavedTranslations } from "@/features/task/edit/legend/utils/types"
import useDebounce from "@/utils/hooks/useDebounce"

const MIN_DIFF_LENGTH = 40

interface IUseDraftSaveProps {
  problemId: string
  title: string
  legend: string
  translations: ISavedTranslations
  currentTranslation?: string
}

export default function useDraftSave({
  problemId,
  title,
  legend,
  translations,
  currentTranslation,
}: IUseDraftSaveProps) {
  const initialTitle = useRef(title)
  const initialLegend = useRef(legend)

  const debouncedTitle = useDebounce(title, 500)
  const debouncedLegend = useDebounce(legend, 500)

  useEffect(() => {
    if (!initialTitle.current && title) initialTitle.current = title
  }, [title])

  useEffect(() => {
    if (!initialLegend.current && legend) initialLegend.current = legend
  }, [legend])

  const textDiff = useMemo(() => {
    const titleDiff = diff(initialTitle.current, debouncedTitle)
    const legendDiff = diff(initialLegend.current, debouncedLegend)

    return (
      titleDiff.reduce(
        (acc, [op, text]) => (op !== diff.EQUAL ? acc + text : acc),
        ""
      ) +
      legendDiff.reduce(
        (acc, [op, text]) => (op !== diff.EQUAL ? acc + text : acc),
        ""
      )
    )
  }, [debouncedTitle, debouncedLegend])

  useEffect(() => {
    // save draft only if translation is not saved on the server
    if (
      currentTranslation &&
      !isEmpty(translations) &&
      !translations[currentTranslation] &&
      textDiff.length > MIN_DIFF_LENGTH
    ) {
      const previousDraft = getDraft(problemId)

      const newDraft = {
        ...previousDraft,
        [currentTranslation]: {
          timestamp: DateTime.now().toMillis(),
          title: debouncedTitle,
          legend: debouncedLegend,
        },
      }
      saveDraft(problemId, newDraft)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedTitle, debouncedLegend, problemId])
}

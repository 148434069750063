import { useMemo } from "react"

import { useGetPublicQuery, useGetTagsQuery } from "@/store/api/problems"
import { ProblemDifficulty } from "@/store/api/problems/types"

import getProblemName from "@/features/task/list/utils/getProblemName"
import getDifficultyByPercent from "@/utils/getDifficultyByPercent"

export interface ICategoryCountFilterProps {
  search: string
  difficulties: ProblemDifficulty[]
}

export default function useCategoriesCount(
  filterProps: ICategoryCountFilterProps,
  selected: number[]
) {
  const { data: { problems: problemData } = {} } = useGetPublicQuery()
  const { data: { tags: categoryData } = {} } = useGetTagsQuery()

  const categories = useMemo(() => {
    if (!categoryData || !problemData) return []

    const { search, difficulties } = filterProps

    const count = Object.fromEntries(
      categoryData.map(category => [category.id, 0])
    )

    for (const problem of problemData) {
      const isMatched = search
        ? getProblemName(problem.names)
            .toLowerCase()
            .includes(search.toLowerCase())
        : true

      if (
        isMatched &&
        (difficulties.length === 0 ||
          difficulties.includes(getDifficultyByPercent(problem.difficulty)))
      )
        for (const tag of problem.tags) count[tag]++
    }

    const result = categoryData
      .map(category => ({
        ...category,
        problemAmount: count[category.id],
      }))
      .filter(
        category => category.problemAmount > 0 || selected.includes(category.id)
      )

    result.sort((a, b) => b.problemAmount - a.problemAmount)

    return result
  }, [categoryData, problemData, filterProps, selected])

  return categories
}

import { useTranslation } from "react-i18next"
import styled from "styled-components"

import { useGetContestByIdQuery } from "@/store/api/contest"

import UserIcon from "~/assets/icons/UserIcon"

import StatementRenderer from "@/components/latex/StatementRenderer"
import ParticipateButton from "@/features/contest/ParticipateButton"
import { header30, text16, text16Medium } from "@/utils/fonts"
import ContestTimingsBrief from "@/widgets/contest/ContestTimingsBrief"

interface ContestDescriptionProps {
  contestId: string
}

export default function ContestDescription({
  contestId,
}: ContestDescriptionProps) {
  const { t } = useTranslation()

  const { data: contest } = useGetContestByIdQuery({ id: contestId })

  if (!contest) return null

  const {
    name,
    timings,
    task_count: tasks,
    description,
    participants_count: participants,
    registered,
  } = contest

  const i18n = {
    participants: t("pages.contest.description.participants.registered", {
      count: participants,
    }),
  }

  return (
    <Wrapper>
      <Header>{name}</Header>

      <ContestTimingsBrief timings={timings} tasks={tasks} />

      <Description>{description}</Description>

      {!registered && (
        <Participants>
          <UserIcon />
          {i18n.participants}
        </Participants>
      )}

      <ParticipateButton timings={timings} registered={registered} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  grid-area: main;

  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;

  gap: 32px;

  padding: 36px;
`

const Header = styled.h1`
  margin: 0;
  padding: 0;

  ${header30};
`

const Description = styled(StatementRenderer)`
  ${text16};
`

const Participants = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: start;

  gap: 14px;

  ${text16Medium};

  > svg {
    height: 16px;
    width: 16px;
    fill: var(--color-text);
  }
`

import styled from "styled-components"

import { text14Medium } from "@/utils/fonts"

const TimingText = styled.p`
  margin: 0 0 6px 0;
  ${text14Medium};
`

export default TimingText

import styled from "styled-components"

import mediaQueryFor from "@/utils/mediaQuery"

const ProblemListItemWrapper = styled.div`
  border-radius: 10px;
  border: 1px solid transparent;
  background-color: var(--color-bubble);
  transition: border-color var(--transition-duration) var(--transition-function),
    background-color var(--transition-duration) var(--transition-function),
    transform 0.15s ease-out,
    box-shadow var(--transition-duration) var(--transition-function);

  display: grid;
  height: 50px;
  padding: 14px 11px 14px 14px;
  box-sizing: border-box;

  &:hover {
    border: 1px solid var(--color-card-stroke);
    transform: translate(0, -4px);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  }

  ${mediaQueryFor.mobile} {
    height: 44px;
    padding: 0 11px;
  }
`

export default ProblemListItemWrapper

import { Link } from "react-router-dom"
import styled from "styled-components"

import {
  ISearchSidebarState,
  ProblemSidebarSource,
} from "@/features/task/current/ProblemSidebar"
import ProblemListItemWrapper from "@/features/task/list/ProblemListItemWrapper"
import { IPublicProblem } from "@/features/task/list/types"
import mediaQueryFor from "@/utils/mediaQuery"

import DifficultyColumn from "./DifficultyColumn"
import NameColumn from "./NameColumn"
import SolvedAmountColumn from "./SolvedAmountColumn"

interface IProblemListItemProps extends IPublicProblem {
  linkState: Omit<ISearchSidebarState, "source">
}

export default function ProblemListItem({
  id,
  names,
  marker,
  difficulty,
  solvedAmount,
  linkState,
}: IProblemListItemProps) {
  return (
    <StyledLink
      to={`/problems/${id}`}
      state={{
        ...linkState,
        source: ProblemSidebarSource.search,
      }}
    >
      <Wrapper>
        <NameColumn names={names} marker={marker} />
        <SolvedAmountColumn solvedAmount={solvedAmount} />
        <DifficultyColumn difficulty={difficulty} />
      </Wrapper>
    </StyledLink>
  )
}

const StyledLink = styled(Link)`
  text-decoration: none;
`

const Wrapper = styled(ProblemListItemWrapper)`
  grid-template-columns: 1fr 106px 88px;

  ${mediaQueryFor.mobile} {
    grid-template-columns: 1fr 51px;
  }
`

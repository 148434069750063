import { Helmet } from "react-helmet-async"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

import useZipArchiveUploader from "@/features/task/creation/hooks/useZipArchiveUploader"
import ImportLoader from "@/features/task/creation/variations/PolygonImport/ImportLoader"
import { header30 } from "@/utils/fonts"

import FileUploader from "./FileUploader"
import PolygonImportSidebar from "./PolygonImportSidebar"
import StageDescription from "./StageDescription"

const stages = [
  {
    text: "pages.task.create.polygon_import.stages.open",
    imgSrc: "/assets/images/polygon-import-stage-1.png",
  },
  {
    text: "pages.task.create.polygon_import.stages.create",
    imgSrc: "/assets/images/polygon-import-stage-2.png",
  },
  {
    text: "pages.task.create.polygon_import.stages.wait",
    imgSrc: "/assets/images/polygon-import-stage-3.png",
  },
  {
    text: "pages.task.create.polygon_import.stages.upload",
    imgSrc: null,
  },
]

export default function PolygonImport() {
  const { t } = useTranslation()

  const i18n = {
    title: t("pages.task.create.polygon_import.title"),
    header: t("pages.task.create.polygon_import.header"),
  }

  const { fileUploader, uploadProgress, socketId } = useZipArchiveUploader()

  return (
    <>
      <Helmet>
        <title>{i18n.title}</title>
        <link rel="canonical" href={"https://sort-me.org/problems/create"} />
      </Helmet>

      <PolygonImportSidebar />

      <Content>
        {fileUploader.state === "loading" ? (
          <ImportLoader uploadProgress={uploadProgress} socketId={socketId} />
        ) : (
          <>
            <Header>{i18n.header}</Header>
            <Wrapper>
              <FileUploader {...fileUploader} />
              {stages.map((stage, index) => (
                <StageDescription
                  key={stage.text}
                  stage={index + 1}
                  text={stage.text}
                  imgSrc={stage.imgSrc}
                />
              ))}
            </Wrapper>
          </>
        )}
      </Content>
    </>
  )
}

const Content = styled.div`
  grid-area: main;
  padding: 35px 36px;
  height: calc(var(--window-h) - var(--shift-y));
  box-sizing: border-box;
  overflow: hidden overlay;
`

const Header = styled.h1`
  margin: 0 0 47px 0;
  ${header30};
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`

import {
  MouseEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"

import { Transition } from "react-transition-group"
import styled from "styled-components"

import FilterIcon from "~/assets/icons/FilterIcon"

import { useAppSelector } from "@/store"

interface IProblemFilterProps {
  children?: React.ReactNode
}

export default function ProblemFilter({
  children = null,
}: IProblemFilterProps) {
  const wrapper = useRef<HTMLDivElement>(null)

  const isMobile = useAppSelector(({ common }) => common.media === "mobile")

  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (isOpen) {
      const listener = () => setIsOpen(false)
      document.addEventListener("click", listener)

      return () => document.removeEventListener("click", listener)
    }
  }, [isOpen])

  const toggleOpen = useCallback<MouseEventHandler<HTMLDivElement>>(event => {
    event.stopPropagation()

    setIsOpen(open => !open)
  }, [])

  const stopPropagation = useCallback<MouseEventHandler<HTMLDivElement>>(
    event => event.stopPropagation(),
    []
  )

  if (isMobile)
    return (
      <Transition in={isOpen} timeout={300} nodeRef={wrapper}>
        {state => (
          <Wrapper onClick={toggleOpen} data-open={state} ref={wrapper}>
            <FilterIcon />

            <SelectorsWrapper data-open={state} onClick={stopPropagation}>
              {children}
            </SelectorsWrapper>
          </Wrapper>
        )}
      </Transition>
    )

  return children as JSX.Element
}

const Wrapper = styled.div`
  position: relative;

  > svg {
    opacity: 0.5;
    transition: opacity var(--transition-duration) var(--transition-function);

    path {
      fill: var(--color-text);
      transition: fill var(--transition-duration) var(--transition-function);
    }
  }

  &[data-open="entering"],
  &[data-open="entered"] {
    > svg {
      opacity: 1;

      path {
        fill: var(--color-primary);
      }
    }
  }
`

const SelectorsWrapper = styled.div`
  background: var(--color-g-background);
  border: 1px solid var(--color-g-background-stroke);
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.05);
  border-radius: 8px;

  box-sizing: border-box;
  width: calc(100vw - 40px);
  padding: 24px 20px;

  display: flex;
  flex-direction: column;
  gap: 24px;

  position: absolute;
  right: 0;
  top: 41px;
  z-index: 500;

  &[data-open="entering"] {
    animation: dropdownOpen 0.3s var(--transition-function);
  }

  &[data-open="entered"] {
    opacity: 1;
  }

  &[data-open="exiting"] {
    animation: dropdownOpen 0.3s var(--transition-function) reverse;
  }

  &[data-open="exited"] {
    display: none;
  }

  transition: background-color var(--transition-duration)
      var(--transition-function),
    border-color var(--transition-duration) var(--transition-function),
    opacity var(--transition-duration) var(--transition-function);

  @keyframes dropdownOpen {
    from {
      opacity: 0;
      pointer-events: none;
    }

    to {
      opacity: 1;
      pointer-events: all;
    }
  }
`

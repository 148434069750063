import { MouseEventHandler, useCallback } from "react"

import { useParams } from "react-router-dom"
import styled from "styled-components"

import editTestsApi from "@/store/api/problems/edit/tests"

import CopyIcon from "~/assets/icons/CopyIcon"
import DownloadIcon from "~/assets/icons/DownloadIcon"

import ScrollableCodeCell from "@/components/ScrollableCodeCell"

interface ITestSampleProps {
  testId: number
  stdinPreview: string
  stdinSize: number
  stdoutPreview: string
  stdoutSize: number
}

export default function TestSample({
  testId,
  stdinPreview,
  stdinSize,
  stdoutPreview,
  stdoutSize,
}: ITestSampleProps) {
  const { problemId = "" } = useParams()

  const handleCopyClick = useCallback<MouseEventHandler<SVGSVGElement>>(
    ({ currentTarget: icon }) => {
      const cell = icon.parentNode as HTMLDivElement

      if (cell.textContent) {
        window.navigator.clipboard.writeText(cell.textContent)
        cell.classList.add("copied")

        setTimeout(() => {
          if (cell?.isConnected) {
            cell.classList.remove("copied")
          }
        }, 300)
      }
    },
    [],
  )

  const [getTest] = editTestsApi.endpoints.getTest.useLazyQuery()

  const handleDownloadClick = useCallback<MouseEventHandler<SVGSVGElement>>(
    async ({ currentTarget: icon }) => {
      const load = icon.dataset.load as "stdin" | "stdout"
      const { data } = await getTest(
        {
          problem_id: Number(problemId),
          test_id: testId,
        },
        true,
      )
      if (data) {
        const link = document.createElement("a")
        const file = new Blob([data[load]], { type: "text/plain" })
        link.href = URL.createObjectURL(file)
        link.download = `${load === "stdin" ? "input" : "output"}${String(
          testId,
        ).padStart(3, "0")}.txt`

        link.style.display = "none"
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    },
    [getTest, problemId, testId],
  )

  const isStdinOverflown = stdinSize > stdinPreview.length
  const isStdoutOverflown = stdoutSize > stdoutPreview.length

  return (
    <Wrapper>
      <SampleWrapper>
        <CodeCell data-copyable={!isStdinOverflown}>
          <pre>
            {stdinPreview}
            {isStdinOverflown && "\n..."}
          </pre>
          {!isStdinOverflown && <CopyIcon onClick={handleCopyClick} />}
          <DownloadIcon onClick={handleDownloadClick} load="stdin" />
        </CodeCell>
        <CodeCell data-copyable={!isStdoutOverflown}>
          <pre>
            {stdoutPreview}
            {isStdoutOverflown && "\n..."}
          </pre>
          {!isStdoutOverflown && <CopyIcon onClick={handleCopyClick} />}
          <DownloadIcon onClick={handleDownloadClick} load="stdout" />
        </CodeCell>
      </SampleWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: max-content;
  grid-auto-flow: row;

  width: 100%;

  gap: 10px;
`

const SampleWrapper = styled.div`
  display: contents;
`

const CodeCell = styled(ScrollableCodeCell)`
  &[data-copyable="true"] {
    padding-right: 56px;

    &::after {
      right: 56px;
    }

    > svg:nth-child(1) {
      right: 10px;
    }

    > svg:nth-child(2) {
      right: 38px;
    }
  }
`

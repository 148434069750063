import { useTranslation } from "react-i18next"
import styled from "styled-components"

import loginWithGoogle from "@/store/slices/auth/google"
import { Integration, ILoginWithGoogleMode } from "@/store/slices/auth/types"

import GoogleIcon from "~/assets/icons/GoogleIcon"

import { useAppDispatch, useAppSelector } from "@/store"
import { useTheme } from "@/store/hooks"
import { text14, text16 } from "@/utils/fonts"
import mediaQueryFor from "@/utils/mediaQuery"

interface GoogleLoginButtonProps {
  type?: ILoginWithGoogleMode
  className?: string
}

export default function GoogleLoginButton({
  type = "create",
  className = undefined,
}: GoogleLoginButtonProps) {
  const dispatch = useAppDispatch()
  const login = () => dispatch(loginWithGoogle({ mode: type }))

  const { t } = useTranslation()

  const theme = useTheme()
  const loading = useAppSelector(state => state.auth.loading)
  const error = useAppSelector(state =>
    state.auth.lastLoaded === Integration.google ? state.auth.lastError : null
  )

  const buttonText =
    loading === Integration.google
      ? t("auth.button.logging_in")
      : error
      ? t("auth.button.error")
      : t("auth.button.login_with_provider", { provider: t("shared.google") })

  return (
    <Wrapper
      style={{ gridArea: "google" }}
      data-loading={loading !== null}
      data-theme={theme}
      onClick={login}
      className={className}
    >
      <GoogleIcon />
      {buttonText}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  grid-area: google;

  cursor: pointer;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  padding: 12px 24px;

  border-radius: 4px;

  ${text16};
  font-weight: 700;

  &[data-loading="true"] {
    pointer-events: none;
  }

  transition: color var(--transition-duration) var(--transition-function),
    background-color var(--transition-duration) var(--transition-function),
    border-color var(--transition-duration) var(--transition-function);

  color: var(--navbar-primary);

  > svg {
    margin-right: 16px;
  }

  &[data-theme="dark"] {
    background: #2d2d2d;
    border: 1px solid #3c3c3c;
  }

  &[data-theme="light"] {
    background-color: #ffffff;
    border: 1px solid #e7e8ec;
  }

  ${mediaQueryFor.mobile} {
    > svg {
      margin-right: 12px;

      width: 17px;
      height: 17px;
    }

    ${text14};
  }
`

import { useEffect, useMemo, useState } from "react"

import { intersection } from "lodash"

import { useGetPublicQuery } from "@/store/api/problems"
import { IPublicProblemDetails } from "@/store/api/problems/types"

import {
  IPublicProblem,
  IPublicFilterProps,
  SortOrder,
} from "@/features/task/list/types"
import getProblemName from "@/features/task/list/utils/getProblemName"
import getDifficultyByPercent from "@/utils/getDifficultyByPercent"

export default function useProblemList(filterProps: IPublicFilterProps): {
  problems: IPublicProblem[]
  isLoading: boolean
} {
  const { data, isFetching } = useGetPublicQuery()

  const [isListLoading, setIsListLoading] = useState(isFetching)

  useEffect(() => {
    if (isFetching) setIsListLoading(true)
  }, [isFetching])

  const problems = useMemo(() => {
    if (!data) return []

    const { search, sortOrder, isReversed, difficulties, categories } =
      filterProps

    const filteredProblems = filterProblems({
      problems: data.problems,
      search,
      difficulties,
      categories,
    })

    const reversedMultiplier = isReversed ? -1 : 1

    filteredProblems.sort((a, b) => {
      switch (sortOrder) {
        case SortOrder.ByInterest:
          return (a.id - b.id) * reversedMultiplier
        case SortOrder.ByDifficulty:
          return (a.difficulty - b.difficulty) * reversedMultiplier
        case SortOrder.BySolvedAmount:
          return (a.solved_by - b.solved_by) * reversedMultiplier
      }
    })

    const problems = filteredProblems.map(
      ({ id, names, marker, difficulty, solved_by: solvedAmount }) => ({
        id,
        names,
        difficulty,
        solvedAmount,
        marker,
      })
    )
    setIsListLoading(false)

    return problems
  }, [data, filterProps])

  return {
    problems,
    isLoading: isListLoading || isFetching,
  }
}

interface IFilterProblemsProps {
  problems: IPublicProblemDetails[]
  search: string
  difficulties: number[]
  categories: number[]
}

function filterProblems({
  problems,
  search,
  difficulties,
  categories,
}: IFilterProblemsProps) {
  return problems.filter(problem => {
    const isMatched = search
      ? getProblemName(problem.names)
          .toLowerCase()
          .includes(search.toLowerCase())
      : true

    if (isMatched) {
      if (
        difficulties.length > 0 &&
        !difficulties.includes(getDifficultyByPercent(problem.difficulty))
      )
        return false
      if (
        categories.length > 0 &&
        intersection(problem.tags, categories).length === 0
      )
        return false
    }

    return isMatched
  })
}

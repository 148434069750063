import React from "react"

import { useTranslation } from "react-i18next"
import styled from "styled-components"

import { IArchiveContest } from "@/store/api/archives/types"

import CardGrid from "@/components/CardGrid"
import InfiniteScroller from "@/components/InfiniteScroller"
import RectangularLoader from "@/features/contest/loaders/RectangularLoader"
import { text18Medium } from "@/utils/fonts"

import ArchiveEntry from "./Entry"

interface IArchiveContainerProps {
  archives: IArchiveContest[]
  fetchMore: () => void
  enableScrolling: boolean
  isFetchingMore: boolean
}

function ArchiveContainer({
  archives,
  fetchMore,
  enableScrolling,
  isFetchingMore,
}: IArchiveContainerProps) {
  const { t } = useTranslation()

  const i18n = {
    nothingFound: t("pages.contests.details.nothing_found"),
  }

  if (archives.length === 0 && !isFetchingMore)
    return <EmptyNotification>{i18n.nothingFound}</EmptyNotification>

  return (
    <>
      <CardGrid>
        <InfiniteScroller
          fetchMore={fetchMore}
          enabled={enableScrolling}
          seamless={1}
        >
          {archives.map(archive => (
            <ArchiveEntry key={archive.id} archive={archive} />
          ))}
        </InfiniteScroller>
      </CardGrid>
      <RectangularLoader loading={isFetchingMore} size={154} />
    </>
  )
}

const EmptyNotification = styled.p`
  margin: 0;

  text-align: center;
  ${text18Medium};
`

export default React.memo(ArchiveContainer)

import { useCallback } from "react"

import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"

import { removeToken } from "@/store/slices/auth/token"

import CustomButton from "@/components/CustomButton"
import { useAppDispatch } from "@/store"
import mediaQueryFor from "@/utils/mediaQuery"

export default function ProfileActions() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const { t } = useTranslation()

  const goToEdit = useCallback(() => {
    navigate("/profile/edit")
  }, [navigate])

  const logout = useCallback(() => dispatch(removeToken()), [dispatch])

  const i18n = {
    edit: t("pages.profile.edit"),
    logout: t("pages.profile.logout"),
  }

  return (
    <Wrapper>
      <CustomButton onClick={goToEdit} data-type="secondary">
        {i18n.edit}
      </CustomButton>
      <CustomButton onClick={logout} data-type="danger">
        {i18n.logout}
      </CustomButton>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  grid-area: buttons;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: start;

  margin-top: 40px;

  gap: 32px;

  ${mediaQueryFor.mobile} {
    flex-flow: column nowrap;

    margin-top: 32px;
    gap: 16px;
  }
`

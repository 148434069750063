import styled from "styled-components"

import { code14, code16 } from "@/utils/fonts"
import mediaQueryFor from "@/utils/mediaQuery"

const ScrollableCodeCell = styled.div`
  background-color: var(--color-background);
  transition: background-color var(--transition-duration)
    var(--transition-function);

  padding: 8px 28px 8px 10px;
  position: relative;
  min-height: 40px;
  box-sizing: border-box;

  overflow-x: hidden;

  &::after {
    content: "";
    background: linear-gradient(
      270deg,
      #f8f8f8 0%,
      rgba(255, 255, 255, 0) 100%
    );
    filter: var(--code-cell-invert);
    transition: filter var(--transition-duration) var(--transition-function);

    position: absolute;
    top: 0;
    right: 28px;
    width: 10px;
    height: 100%;
  }

  > pre {
    margin: 0;
    padding-right: 10px;

    ${code16};
    overflow-x: scroll;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &.copied {
    animation: codeCopy 0.3s ease-in;

    @keyframes codeCopy {
      0% {
        filter: brightness(100%);
      }

      50% {
        filter: brightness(var(--blink-target));
      }

      100% {
        filter: brightness(100%);
      }
    }
  }

  > svg {
    position: absolute;
    top: 8px;
    right: 10px;

    cursor: pointer;

    opacity: 0.1;
    transition: opacity var(--transition-duration) var(--transition-function);

    &:hover {
      opacity: 0.8;
    }

    path {
      fill: var(--color-text);
      transition: fill var(--transition-duration) var(--transition-function);
    }
  }

  ${mediaQueryFor.mobile} {
    ${code14};
  }
`

export default ScrollableCodeCell

import { useCallback, useState } from "react"

import { useLocation, useNavigate, useParams } from "react-router-dom"
import styled from "styled-components"

import Modal from "@/components/Modal"
import SubmissionDetails from "@/features/task/current/submission/SubmissionDetails"
import mediaQueryFor from "@/utils/mediaQuery"

export default function SubmissionModal() {
  const navigate = useNavigate()
  const { state } = useLocation()

  const { submissionId } = useParams<"submissionId">()

  const [isOpen, setIsOpen] = useState(!!submissionId)

  const navigateBack = useCallback(() => {
    if (state?.popup) navigate(-1) // modal, so pop history
    else navigate("../..", { relative: "path" }) // navigate directly
  }, [navigate, state?.popup])

  const handleCloseModal = useCallback(() => setIsOpen(false), [])

  return (
    <ModalWrapper
      isOpen={isOpen}
      close={handleCloseModal}
      onExit={navigateBack}
    >
      <SubmissionDetails id={submissionId!} />
    </ModalWrapper>
  )
}

const ModalWrapper = styled(Modal)`
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 48px 32px;

  max-width: 1000px;

  gap: 32px;

  ${mediaQueryFor.mobile} {
    width: calc(100% - 20px * 2);

    margin: 129px 20px 129px 20px;
    padding: 24px 16px;

    gap: 24px;
  }
`

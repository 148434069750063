import { getPreferredLanguages } from "@/store/api/helpers"

export default function getProblemName(names: Record<string, string>) {
  const preferredLanguages = getPreferredLanguages()

  for (const language of preferredLanguages)
    if (names[language]) return names[language]

  return names[Object.keys(names)[0]]
}

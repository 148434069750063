import { TFunction } from "i18next"
import { useTranslation } from "react-i18next"

export default function useScopedTranslation<const Scope extends string>(
  scope: Scope,
): TFunction<"translation", Scope> {
  const { t } = useTranslation(undefined, { keyPrefix: scope })

  return t
}

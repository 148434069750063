import { NavLink } from "react-router-dom"
import styled from "styled-components"

import { text16Medium } from "@/utils/fonts"

const NavbarCategory = styled(NavLink).attrs({ draggable: false })`
  padding: 6px 14px;
  background-color: transparent;

  cursor: pointer;
  user-select: none;

  &.active,
  &:hover {
    background-color: var(--navbar-accent);
  }

  ${text16Medium};

  text-decoration: none;

  color: var(--color-text);

  border: none;
  border-radius: 95px;

  transition: color var(--transition-duration) var(--transition-function),
    background-color var(--transition-duration) var(--transition-function);
`

export default NavbarCategory

import React from "react"

import { IContest } from "@/store/api/contests/types"

import CardGrid from "@/components/CardGrid"
import InfiniteScroller from "@/components/InfiniteScroller"
import BaseEntry from "@/features/contest/ContestContainer/BaseEntry"
import RectangularLoader from "@/features/contest/loaders/RectangularLoader"

interface IContestContainerProps {
  contests: IContest[]
  receivedAt: Record<number, number>

  fetchMore: () => void
  refetch: () => void

  enableScrolling: boolean
  isFetchingMore: boolean
}

function ContestContainer({
  contests,
  receivedAt,
  fetchMore,
  refetch,
  enableScrolling,
  isFetchingMore,
}: IContestContainerProps) {
  return (
    <>
      <CardGrid>
        <InfiniteScroller
          fetchMore={fetchMore}
          enabled={enableScrolling}
          seamless={1}
        >
          {contests.map(contest => (
            <BaseEntry
              key={contest.id}
              contest={contest}
              receivedAt={receivedAt[contest.id]}
              onExpire={refetch}
            />
          ))}
        </InfiniteScroller>
      </CardGrid>
      <RectangularLoader loading={isFetchingMore} size={154} />
    </>
  )
}

export default React.memo(ContestContainer)

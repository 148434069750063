import { useEffect, useMemo, useSyncExternalStore } from "react"

import i18n from "i18next"
import { I18nextProvider } from "react-i18next"

import i18nConfig from "~/app/utils/i18n"
import translations from "~/translations/resources"

import { useAppSelector } from "@/store"

interface ITranslationProviderProps {
  children: React.ReactNode
}

export default function TranslationProvider({
  children,
}: ITranslationProviderProps) {
  const lang = useAppSelector(
    ({ common: { lang } }) => lang, // Choose lang
    () => true // Never trigger re-render
  )

  const instance = useMemo(() => {
    const value = i18n.createInstance(
      {
        ...i18nConfig,
        lng: lang,
        resources: translations, // bundle statically
      },
      () => {}
    )

    return value

    // Never re-create the i18n instance, regardless of the language
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <I18nextProvider i18n={instance}>{children}</I18nextProvider>
}

import { useMemo } from "react"

import { IContestTimings } from "@/store/api/contests/types"

export enum ContestStatus {
  /**
   * Waiting for registration to start.
   */
  Waiting = "waiting",
  /**
   * Registration in process.
   */
  Registration = "registration",
  /**
   * Pending start after registration is finished.
   */
  Pending = "pending",
  /**
   * Contest is ongoing.
   */
  Running = "running",
  /**
   * Contest has ended.
   */
  Ended = "ended",
  /**
   * Any other case.
   */
  Unknown = "unknown",
}

export default function useContestStatus(
  timing: IContestTimings | undefined
): ContestStatus {
  return useMemo(
    () => (timing ? getContestStatus(timing) : ContestStatus.Unknown),
    [timing]
  )
}

function getContestStatus({
  start,
  now,
  end,
  registration_start: registrationStart,
  registration_end: registrationEnd,
}: IContestTimings): ContestStatus {
  switch (true) {
    case now < registrationStart:
      return ContestStatus.Waiting
    case start <= now && now < end: // Between start and end
      return ContestStatus.Running
    case registrationStart <= now && now < registrationEnd: // Between rstart & rend
      return ContestStatus.Registration
    case end <= now: // After end
      return ContestStatus.Ended
    case registrationEnd <= now && now < start: // Between rend & start
      return ContestStatus.Pending
    default:
      return ContestStatus.Unknown
  }
}

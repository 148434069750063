import { createSlice } from "@reduxjs/toolkit"

import loginWithGoogle from "./google"
import loginWithTelegram from "./telegram"
import { ErrorMessage, IAuthSlice, Integration } from "./types"

const initialState: IAuthSlice = {
  lastLoaded: null,
  lastError: null,
  loading: null,
}

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loginWithTelegram.pending, state => {
      state.lastLoaded = null
      state.lastError = null
      state.loading = Integration.telegram
    })

    builder.addCase(loginWithTelegram.fulfilled, state => {
      state.lastLoaded = Integration.telegram
      state.loading = null
    })

    builder.addCase(loginWithTelegram.rejected, state => {
      state.lastLoaded = Integration.telegram
      state.lastError = ErrorMessage.unknown
      state.loading = null
    })

    builder.addCase(loginWithGoogle.pending, state => {
      state.lastLoaded = null
      state.lastError = null
      state.loading = Integration.google
    })

    builder.addCase(loginWithGoogle.fulfilled, state => {
      state.lastLoaded = Integration.google
      state.loading = null
    })

    builder.addCase(loginWithGoogle.rejected, state => {
      state.lastLoaded = Integration.google
      state.lastError = ErrorMessage.unknown
      state.loading = null
    })
  },
})

export default authSlice.reducer

import { useCallback, useMemo, useRef, useState } from "react"

import { BasicSetupOptions, ReactCodeMirrorRef } from "@uiw/react-codemirror"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

import { ProblemDetails } from "@/store/api/problems/types"
import { useSubmitCodeMutation } from "@/store/api/submissions"

import ButtonWithLoader from "@/components/ButtonWithLoader"
import LanguageSelector from "@/features/task/current/editor/LanguageSelector"
import { text14 } from "@/utils/fonts"
import useFileUploader, { getExtension } from "@/utils/hooks/useFileUploader"

import FileUploader from "./FileUploader"
import StyledCodeMirror, { getLinter } from "./StyledCodeMirror"

export default function CodeEditor({
  onSubmissionAccepted,
  problem: { id: problemId, environments },
}: {
  onSubmissionAccepted: () => void
  problem: ProblemDetails
}) {
  const { t } = useTranslation()

  const [lang, setLang] = useState<number>(() => environments[0].id)
  const [hasCode, setHasCode] = useState(false)

  const code = useRef<string>("")
  const editor = useRef<ReactCodeMirrorRef>(null)

  const [submitCodeMutation, { isError }] = useSubmitCodeMutation({
    selectFromResult: ({ isError }) => ({ isError }),
  })

  const lint = useMemo(
    () => getLinter(environments.find(({ id }) => id === lang)?.cmh ?? null),
    [environments, lang],
  )

  const onCodeChange = useCallback((value: string) => {
    code.current = value

    setHasCode(value.length > 0)
  }, [])

  const changeCode = useCallback(
    (text: string, ext?: string) => {
      if (!editor.current?.view) return

      editor.current.view.dispatch(makeSetTransaction(editor.current, text))
      code.current = text

      if (!ext) return

      const uploaded = environments.find(({ cmh }) => cmh.includes(ext))
      if (uploaded) setLang(uploaded.id)
    },
    [environments],
  )

  const submitCode = useCallback(async () => {
    if (!code.current) return
    changeCode("")

    const result = await submitCodeMutation({
      task_id: problemId,
      lang: lang.toString(),
      code: code.current,
      // TODO: for new API
      // environment_id: lang,
      // files: [{ name: "solution", type: "text", content: code.current }],
    })

    if ("data" in result) onSubmissionAccepted()
  }, [lang, problemId, changeCode, submitCodeMutation, onSubmissionAccepted])

  const uploadFile = useCallback(
    async (file: File) => {
      const text = await file.text()

      changeCode(text, getExtension(file.name))
    },
    [changeCode],
  )

  const fileUploader = useFileUploader({ uploadFile })

  const i18n = {
    placeholder: t("pages.task.editor.placeholder"),
    submit: t("pages.task.editor.submit"),
    error: t("pages.task.editor.error"),
  }

  return (
    <Wrapper>
      <EditorWrapper {...fileUploader.dragHandlers}>
        <StyledCodeMirror
          ref={editor}
          extensions={lint ? [lint] : []}
          placeholder={i18n.placeholder}
          basicSetup={Settings}
          onChange={onCodeChange}
          data-has-value={hasCode}
        />

        {!hasCode && (
          <FileUploader
            state={fileUploader.state}
            {...fileUploader.clickHandlers}
          />
        )}

        <LanguageSelector
          items={environments}
          onSelect={setLang}
          value={lang}
        />
      </EditorWrapper>

      <ButtonWrapper>
        <ButtonWithLoader
          data-type="primary"
          disabled={!hasCode}
          onClick={submitCode}
        >
          {i18n.submit}
        </ButtonWithLoader>

        {isError && <Error>{i18n.error}</Error>}
      </ButtonWrapper>
    </Wrapper>
  )
}

const Settings: BasicSetupOptions = {
  foldGutter: false,
  highlightSpecialChars: false,
  dropCursor: false,
  foldKeymap: false,
  rectangularSelection: false,
  crosshairCursor: false,
  lintKeymap: false,
  autocompletion: false,
}

function makeSetTransaction(editor: ReactCodeMirrorRef, insert: string) {
  return {
    changes: {
      from: 0,
      to: editor.view!.state.doc.length,
      insert,
    },
    userEvent: "input.set",
  }
}

const Wrapper = styled.div`
  width: 100%;

  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;

  gap: 49px;
`

const EditorWrapper = styled.div`
  width: 100%;
  position: relative;
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;

  gap: 6px;

  width: 100%;
`

const Error = styled.span`
  ${text14};
  color: var(--color-red);
`

import { ManagedProblemMarker } from "@/store/api/problems/create/types"
import {
  ProblemDifficulty,
  PublicProblemMarker,
} from "@/store/api/problems/types"
import { IUserBrief } from "@/store/api/users/types"

import { SupportedLanguagesT } from "~/translations/utils"

export interface IPublicProblem {
  id: number
  names: Record<string, string>
  difficulty: number
  solvedAmount: number
  marker?: PublicProblemMarker
}

export interface IManagedProblem {
  id: number
  names: Record<string, string>
  creator: IUserBrief
  marker?: ManagedProblemMarker
  includedInContests: number
  tags: number[]
}

export interface IProblemBrief {
  id: number
  names: Record<string, string>
  marker?: PublicProblemMarker | ManagedProblemMarker
}

export interface ICategoryDetails {
  id: number
  names: Record<SupportedLanguagesT, string>
  problemAmount: number
}

export enum SortOrder {
  ByInterest = 1,
  ByDifficulty,
  BySolvedAmount,
}

export interface IPublicFilterProps {
  search: string
  sortOrder: SortOrder
  isReversed: boolean
  difficulties: ProblemDifficulty[]
  categories: number[]
}

export interface IManagedFilterProps {
  search: string
  states: ManagedProblemMarker[]
  isUsedInContests: boolean
  creators: number[]
  categories: number[]
}

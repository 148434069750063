import { useCallback } from "react"

import { useTranslation } from "react-i18next"
import { useSearchParams } from "react-router-dom"
import styled from "styled-components"

import Loader from "@/components/Loader"
import ArchiveContainer from "@/features/contest/ArchiveContainer"
import useArchivePagination from "@/features/contest/hooks/useArchivePagination"
import { text14Medium, text16Medium } from "@/utils/fonts"
import mediaQueryFor from "@/utils/mediaQuery"

import ArchiveSearchBar from "./ArchiveSearchBar"

export default function Archive() {
  const [searchParams, setSearchParams] = useSearchParams()

  const { t } = useTranslation()

  const i18n = {
    archiveText: t("pages.contests.public.archive_text"),
  }

  const {
    isFetching,
    isFetchingMore,
    archives,
    isEmpty,
    fetchMore,
    isExhausted,
  } = useArchivePagination()

  const submit = useCallback(
    (value: string) => {
      if ((searchParams.get("query") ?? "") !== value)
        setSearchParams(value ? { query: value } : {})
    },
    [searchParams, setSearchParams]
  )

  const isRefetching = isFetching && !isFetchingMore
  const isScrollable = !isEmpty && !isExhausted

  return (
    <>
      <StyledText>{i18n.archiveText}</StyledText>
      <ArchiveSearchBar submit={submit} />
      <Loader loading={isRefetching} size="large">
        <>
          <ArchiveContainer
            archives={archives}
            enableScrolling={isScrollable}
            fetchMore={fetchMore}
            isFetchingMore={isFetchingMore}
          />
        </>
      </Loader>
    </>
  )
}

const StyledText = styled.p`
  max-width: 679px;

  margin: 0 0 40px 0;

  ${text16Medium};

  ${mediaQueryFor.mobile} {
    margin-bottom: 24px;

    ${text14Medium};
  }
`

import { useCallback, useState } from "react"

import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom"
import styled from "styled-components"

import Modal from "@/components/Modal"
import useIsEditable from "@/features/task/hooks/useIsEditable"
import mediaQueryFor from "@/utils/mediaQuery"

import TestDetails from "./TestDetails"

export default function TestEditModal() {
  const navigate = useNavigate()
  const { state } = useLocation()

  const { testId } = useParams<"testId">()

  const [isOpen, setIsOpen] = useState(!!testId)

  const navigateBack = useCallback(() => {
    if (state?.popup) navigate(-1) // modal, so pop history
    else navigate("..", { state }) // navigate directly
  }, [navigate, state])

  const handleCloseModal = useCallback(() => setIsOpen(false), [])

  const { isEditable, isFetching } = useIsEditable()
  if (!isFetching && !isEditable) return <Navigate to=".." replace />

  return (
    <ModalWrapper
      isOpen={isOpen}
      close={handleCloseModal}
      onExit={navigateBack}
    >
      <TestDetails id={testId!} closeModal={handleCloseModal} />
    </ModalWrapper>
  )
}

const ModalWrapper = styled(Modal)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 48px 32px;

  gap: 32px;

  max-width: 1200px;
  width: calc(100% - 20px * 2);
  box-sizing: border-box;

  ${mediaQueryFor.mobile} {
    margin: 129px 20px 129px 20px;
    padding: 24px 16px;

    gap: 24px;
  }
`

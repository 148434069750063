import styled from "styled-components"

import { text12Medium, text14Medium } from "@/utils/fonts"
import mediaQueryFor from "@/utils/mediaQuery"

const LoginFormLabel = styled.label`
  ${text14Medium};

  opacity: 0.5;
  color: var(--color-text);

  transition: color var(--transition-duration) var(--transition-function);

  ${mediaQueryFor.mobile} {
    ${text12Medium};
  }
`

export default LoginFormLabel

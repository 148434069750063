import { ProblemDifficulty } from "@/store/api/problems/types"

export default function getDifficultyByPercent(
  percent: number
): ProblemDifficulty {
  if (percent < 30) return ProblemDifficulty.easy
  if (percent < 50) return ProblemDifficulty.medium
  if (percent < 70) return ProblemDifficulty.difficult
  if (percent < 90) return ProblemDifficulty.hard
  return ProblemDifficulty.impossible
}

import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import Cookies from "js-cookie"

import { loadToken } from "@/store/slices/auth/token"

import authApi from "@/store/api/auth"
import { EmailAuthStatus } from "@/store/api/auth/types"

import { IUserInfo, IUserSlice } from "./types"

const initialState: IUserSlice = {
  handle: null,
  token: Cookies.get("token") ?? null,
  uid: null,
}

const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    setUser(state, { payload }: PayloadAction<IUserInfo>) {
      state.handle = payload.handle
      state.uid = payload.uid
    },
    setToken(state, { payload }: PayloadAction<string>) {
      state.token = payload
    },
  },
  extraReducers: builder => {
    builder.addCase(loadToken.pending, (state, { meta }) => {
      state.token = meta.arg
    })

    builder.addCase(loadToken.fulfilled, (state, { payload }) => {
      state.uid = payload
    })

    builder.addMatcher(
      authApi.endpoints.emailAuth.matchFulfilled,
      (state, { payload }) => {
        if ("status" in payload && payload.status === EmailAuthStatus.Success) {
          state.token = payload.token
          state.handle = payload.user.handle
          state.uid = payload.user.id
        }
      },
    )
  },
})

export const { setUser, setToken } = userSlice.actions

export default userSlice.reducer

import { createAsyncThunk } from "@reduxjs/toolkit"

import { RootState } from "@/store/store"

import { loadToken } from "./token"
import {
  IConnectAccountResponse,
  ILoginWithGoogleMode,
  ILoginWithGoogleOptions,
} from "./types"

const base = "https://beta.sort-me.org/api"
const clientId =
  "867876921972-dn8tnsvq7d7ggh49rre89sjrso4oiqiu.apps.googleusercontent.com"

async function invokeGoogleAuth(): Promise<gapi.auth2.GoogleUser> {
  await new Promise<void>(res => window.gapi.load("auth2", () => res()))

  try {
    await window.gapi.auth2.init({
      client_id: clientId,
      scope: "profile",
      ux_mode: "popup",
    })
  } catch (err) {
    console.error(err)
  }

  const auth = window.gapi.auth2.getAuthInstance()

  try {
    await new Promise<void>((res, rej) => {
      auth.then(
        () => res(),
        err => rej(err),
      )
    })
  } catch (err) {
    console.error(err)
  }

  try {
    if (!auth.isSignedIn.get()) {
      await auth.signIn()
    }
  } catch (err) {
    console.error(err)
  }

  return auth.currentUser.get()
}

async function activateGoogleLogin(
  data: any,
  userToken: string | null,
  mode: ILoginWithGoogleMode,
): Promise<IConnectAccountResponse> {
  const url =
    mode === "create"
      ? `${base}/auth?provider=google`
      : `${base}/auth/connect?provider=google`
  // TODO: extract this to a helper with Telegram provider

  const headers = new Headers()
  if (userToken) headers.append("Authorization", `Bearer ${userToken}`)
  headers.append("Content-Type", "application/json")

  const req = await fetch(`${url}`, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(data),
  })

  return {
    status: req.status,
    data: await req.json(),
  }
}

const loginWithGoogle = createAsyncThunk<
  void,
  ILoginWithGoogleOptions,
  { state: RootState }
>(
  "user/loginWithGoogle",
  async ({ mode }, { rejectWithValue, getState, dispatch }) => {
    const data = await invokeGoogleAuth()
    if (!data) throw rejectWithValue(data)

    const {
      user: { token },
    } = getState()

    const { status, data: resp } = await activateGoogleLogin(
      { token: data.getAuthResponse().id_token },
      token,
      mode,
    )

    if (status !== 200) throw rejectWithValue(resp?.error)
    if (resp?.token) dispatch(loadToken(resp?.token))
  },
  {
    condition(_, { getState }) {
      const {
        auth: { loading },
      } = getState()

      return loading === null // should make request?
    },
  },
)

export default loginWithGoogle

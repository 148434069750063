import { useCallback, useState } from "react"

export default function useCategoryFilter(initialCategories: number[] = []) {
  const [selectedCategories, setSelectedCategories] =
    useState<number[]>(initialCategories)

  const toggleCategory = useCallback((categoryId: number) => {
    setSelectedCategories(prev => {
      if (prev.includes(categoryId)) return prev.filter(c => c !== categoryId)

      return [...prev, categoryId]
    })
  }, [])

  return { selectedCategories, toggleCategory }
}

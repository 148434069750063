import {
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"

import { useTranslation } from "react-i18next"
import { Transition } from "react-transition-group"
import styled from "styled-components"

import DropdownIcon from "~/assets/icons/DropdownIcon"

import { ITabGroupProps } from "@/components/groups/types"
import { text13Medium } from "@/utils/fonts"

import DropdownTabButton from "./DropdownTabButton"

export default function DropdownLinkGroup({
  tabs,
  activeTab,
  setActiveTab,
}: ITabGroupProps) {
  const { t } = useTranslation()

  const [isOpen, setIsOpen] = useState(false)

  const wrapper = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (isOpen) {
      const listener = () => setIsOpen(false)
      document.addEventListener("click", listener)

      return () => document.removeEventListener("click", listener)
    }
  }, [isOpen])

  const toggleOpen: MouseEventHandler<HTMLDivElement> = useCallback(event => {
    event.stopPropagation()

    setIsOpen(open => !open)
  }, [])

  const handleTabClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    ({ currentTarget }) => {
      const tab = Number(currentTarget.dataset.tab)
      setActiveTab(tab)
    },
    [setActiveTab]
  )

  const activeTabName = useMemo(
    () => tabs.find(({ tab }) => tab === activeTab)!.name,
    [activeTab, tabs]
  )

  return (
    <Wrapper>
      <TitleWrapper onClick={toggleOpen}>
        <span>{t(activeTabName)}</span>
        <DropdownIcon width={16} height={16} />
      </TitleWrapper>

      <Transition in={isOpen} timeout={300} nodeRef={wrapper}>
        {state => (
          <ListWrapper data-open={state} ref={wrapper}>
            {tabs.map(({ name, tab }) => (
              <DropdownTabButton
                key={tab}
                data-tab={tab}
                onClick={handleTabClick}
                data-active={tab === activeTab}
              >
                <DropdownIcon />
                {t(name)}
              </DropdownTabButton>
            ))}
          </ListWrapper>
        )}
      </Transition>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  width: fit-content;

  > span {
    color: var(--color-primary);

    ${text13Medium}
    transition: color var(--transition-duration) var(--transition-function);
  }

  & > svg path {
    fill: var(--color-primary);

    transition: fill var(--transition-duration) var(--transition-function);
  }

  &:hover {
    cursor: pointer;

    > span {
      color: #085fdb;
    }

    & > svg path {
      fill: #085fdb;
    }
  }
`

const ListWrapper = styled.div`
  background-color: var(--layout-color);
  border: 1px solid var(--color-g-background-stroke);
  border-radius: 4px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);

  box-sizing: border-box;
  width: 338px;
  max-width: 100%;
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  gap: 18px;

  position: absolute;
  top: -4px;
  z-index: 500;

  &[data-open="entering"] {
    animation: dropdownOpen 0.3s var(--transition-function);
  }

  &[data-open="entered"] {
    opacity: 1;
  }

  &[data-open="exiting"] {
    animation: dropdownOpen 0.3s var(--transition-function) reverse;
  }

  &[data-open="exited"] {
    display: none;
  }

  transition: background-color var(--transition-duration)
      var(--transition-function),
    border-color var(--transition-duration) var(--transition-function),
    color var(--transition-duration) var(--transition-function),
    opacity var(--transition-duration) var(--transition-function);

  @keyframes dropdownOpen {
    from {
      opacity: 0;
      pointer-events: none;
    }

    to {
      opacity: 1;
      pointer-events: all;
    }
  }
`

import React, { useCallback, useMemo, useRef, useState } from "react"

import {
  Turnstile,
  TurnstileInstance,
  TurnstileProps,
} from "@marsidev/react-turnstile"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

import { TurnstileStatus } from "@/features/auth/LoginFlow/types"
import { useTheme } from "@/store/hooks"

type LoginTurnstileProps = Omit<TurnstileProps, "siteKey" | "options">

export default function useLoginTurnstile() {
  const [status, setStatus] = useState(TurnstileStatus.Idle)
  const [interactive, setInteractive] = useState(false)

  const tokenRef = useRef<Defer<string>>()

  const controls = useRef<TurnstileInstance>(null)

  const reset = useCallback(() => {
    controls.current?.reset()

    setInteractive(false)
    setStatus(TurnstileStatus.Idle)
  }, [])

  const onSuccess = useCallback((token: string) => {
    setStatus(TurnstileStatus.Success)

    if (!tokenRef.current) return

    tokenRef.current.resolve(token)
    tokenRef.current = undefined
  }, [])

  const onError = useCallback(() => {
    setStatus(TurnstileStatus.Error)

    if (!tokenRef.current) return

    tokenRef.current.reject(null)
    tokenRef.current = undefined
  }, [])

  const onInteract = useCallback(() => {
    setStatus(TurnstileStatus.Pending)
    setInteractive(true)
  }, [])

  const request = useCallback(() => {
    if (tokenRef.current) return tokenRef.current.promise

    setStatus(TurnstileStatus.Pending)

    tokenRef.current = createDefer<string>()
    controls.current?.reset()

    setTimeout(() => {
      try {
        controls.current?.execute() /* hack to allow turnstile to render */
      } catch (err) {
        console.warn(err) /* failsafe IF turnstile throws */
      }
    }, 10)

    return tokenRef.current.promise
  }, [])

  const turnstile = (
    <LoginTurnstile
      key="login-turnstile"
      ref={controls}
      onSuccess={onSuccess}
      onError={onError}
      onBeforeInteractive={onInteract}
      onExpire={() => setStatus(TurnstileStatus.Idle)}
      data-status={status}
      data-interactive={interactive}
    />
  )

  return {
    status,
    request,
    reset,
    turnstile,
  }
}

function createDefer<T>() {
  let resolve!: (value: T) => void
  let reject!: (reason: any) => void

  const promise = new Promise<T>((res, rej) => {
    resolve = res
    reject = rej
  })

  return { resolve, reject, promise }
}

interface Defer<T> {
  resolve: (value: T) => void
  reject: (reason: any) => void
  promise: Promise<T>
}

const LoginTurnstile = React.forwardRef(function LoginTurnstile(
  props: LoginTurnstileProps,
  ref: React.ForwardedRef<TurnstileInstance>,
) {
  const { i18n } = useTranslation()
  const theme = useTheme()

  const options = useMemo<TurnstileProps["options"]>(
    () => ({
      ...TurnstileOptions,
      language: i18n.resolvedLanguage,
      theme,
    }),
    [i18n.resolvedLanguage, theme],
  )

  return (
    <StyledTurnstile
      key="login-turnstile"
      className="login-turnstile"
      siteKey="0x4AAAAAAAJ7Ti-zuJom7eHW"
      options={options}
      ref={ref}
      {...props}
    />
  )
})

/**
 * Keys for testing purposes:
 * siteKey="1x00000000000000000000AA" // PASS
 * siteKey="2x00000000000000000000AB" // FAIL
 * siteKey="3x00000000000000000000FF" // VERIFY
 */

const TurnstileOptions: TurnstileProps["options"] = {
  action: "email-login",
  responseField: false,
  refreshExpired: "auto",
  retry: "never",
  execution: "execute",
  appearance: "execute",
}

const StyledTurnstile = styled(Turnstile)`
  position: absolute;

  width: 0px !important;
  height: 0px !important;

  transition: height 0.3s ease-in-out;

  > iframe {
    visibility: hidden;
  }

  &[data-interactive="true"][data-status="pending"] {
    position: static;

    width: 300px !important;
    height: 65px !important;

    > iframe {
      width: 300px !important;
      height: 65px !important;
      visibility: visible;
    }
  }
`

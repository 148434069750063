import React, { Suspense } from "react"

interface IDynamicOptions {
  ssr?: boolean
  fallback?: React.ComponentType<unknown>
}

interface IComponentModule<T> {
  default: React.ComponentType<T>
}

export default function dynamic<
  T = {},
  Module extends IComponentModule<T> = IComponentModule<T>
>(importer: () => Promise<Module>, options: IDynamicOptions) {
  const { ssr = false } = options

  if (process.env.SSR && !ssr) {
    return function DynamicWrapperServer(props) {
      return (
        <div suppressHydrationWarning={true}>
          <span>{props?.children}</span>
        </div>
      )
    }
  }

  // TODO: Enable suspense

  const Component = React.lazy(importer)

  return function DynamicWrapperClient(props) {
    return (
      <div suppressHydrationWarning={!ssr}>
        <Component {...props} />
      </div>
    )
  }
}

export class ClientOnlyComponentError extends Error {}

interface IWithSuspenseOptions<
  T,
  Module extends IComponentModule<T> = IComponentModule<T>
> {
  fn: () => Promise<Module>
  fallback?: (props: T) => React.ReactNode
  serverThrows?: boolean
}

export function withSuspense<
  T,
  Module extends IComponentModule<T> = IComponentModule<T>
>({ fn, fallback, serverThrows }: IWithSuspenseOptions<T, Module>) {
  const Component = React.lazy(fn)

  return function WithSuspense(props) {
    return (
      <Suspense fallback={fallback?.(props) ?? null}>
        <Component {...props} />
        {Boolean(process.env.SSR) && serverThrows && <SuspenseTrigger />}
      </Suspense>
    )
  }
}

function SuspenseTrigger(): null {
  throw new ClientOnlyComponentError("OriginalStatementRenderer")
}

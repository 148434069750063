import { useMemo } from "react"

import { Trans, useTranslation } from "react-i18next"
import styled from "styled-components"

import { IProblemSubtask } from "@/store/api/problems/types"
import { ISubtaskDetails } from "@/store/api/submissions/types"

import PartyPopperIcon from "~/assets/icons/PartyPopperIcon"

import TaskTable, { WithTableDivider } from "@/components/table/TaskTable"
import {
  text10Medium,
  text12,
  text12Medium,
  text14,
  text14Medium,
  text15,
  text16,
  text16Medium,
  text20Medium,
  text24Medium,
} from "@/utils/fonts"
import mediaQueryFor from "@/utils/mediaQuery"

export default function SubtaskResult({
  index,
  submissionSubtask: {
    points: earnedPoints,
    worst_metrics: { time = 0 },
    skipped = false,
    skip_reason: blockedBySubtaskIndex = null,
    failed_tests: tests = null,
  },
  problemSubtask: { points: totalPoints },
}: {
  index: number
  submissionSubtask: ISubtaskDetails
  problemSubtask: IProblemSubtask
}) {
  const { t } = useTranslation()

  const i18n = {
    title: t("pages.task.submission_modal.subtask.title", { index }),

    result: "pages.task.submission_modal.subtask.points",

    description: t("pages.task.submission_modal.subtask.skipped", {
      index: blockedBySubtaskIndex,
    }),

    worstTime: t("pages.task.submission_modal.subtask.worst_time", {
      seconds: (time / 1000).toFixed(3),
    }),

    table: {
      id: t("pages.task.submission_modal.subtask.table.test"),
      verdict: t("pages.task.submission_modal.subtask.table.verdict"),
      time: t("pages.task.submission_modal.subtask.table.time"),
    },
  }

  const tableData = useMemo(
    () =>
      tests === null
        ? null
        : tests.map(({ n, verdict_text: verdict, metrics }) => ({
            id: n,
            verdict,
            time: metrics.time,
          })),
    [tests]
  )

  const showGlobalWorstTime = !skipped && !tests

  return (
    <ResultBlock>
      <h4>{i18n.title}</h4>

      {skipped && <ResultDescription>{i18n.description}</ResultDescription>}

      {!skipped && (
        <ResultPoints>
          {earnedPoints === totalPoints && <PartyPopperIcon />}
          <Trans
            t={t}
            i18nKey={i18n.result}
            values={{
              earned: earnedPoints,
              total: totalPoints,
            }}
            components={{
              accent: <b />,
            }}
          />
        </ResultPoints>
      )}

      {tableData && (
        <TaskTable
          data={tableData}
          header={i18n.table}
          mapper={{
            verdict: SubtaskVerdictCell,
            time: SubtaskTimeCell,
          }}
        />
      )}

      {showGlobalWorstTime && (
        <ResultSubscript>{i18n.worstTime}</ResultSubscript>
      )}
    </ResultBlock>
  )
}

function SubtaskVerdictCell(verdict: string) {
  return <WithTableDivider>{verdict}</WithTableDivider>
}

function SubtaskTimeCell(time: number) {
  return <WithTableDivider>{(time / 1000).toFixed(3)}</WithTableDivider>
}

const ResultBlock = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;

  gap: 17px;

  color: var(--color-text);

  h4 {
    ${text20Medium};

    margin: 0;
  }

  table {
    ${text15};

    th {
      ${text12Medium};
    }

    td {
      color: var(--color-red);
    }

    tr {
      > td:nth-child(1) > div {
        ${text16Medium};
        min-width: 30px;
      }

      > td:nth-child(2) > div {
        ${text14Medium};
        text-transform: uppercase;
      }

      > td:nth-child(3) > div {
        ${text16Medium};
        min-width: 30px;
      }
    }
  }

  ${mediaQueryFor.mobile} {
    width: 100%;

    h4 {
      ${text16Medium};
    }

    table {
      ${text12};

      th {
        ${text10Medium};
      }

      tr {
        > td:nth-child(1) > div {
          ${text14Medium};
        }

        > td:nth-child(2) > div {
          ${text12Medium};
          width: 100%;
        }

        > td:nth-child(3) > div {
          ${text14Medium};
        }
      }
    }
  }
`

const ResultPoints = styled.span`
  ${text16};

  line-height: 24px;

  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
  justify-content: center;

  svg {
    margin-right: 12px;
    align-self: center;
  }

  b {
    ${text24Medium};
  }

  ${mediaQueryFor.mobile} {
    ${text14};

    b {
      ${text20Medium};
    }
  }
`

const ResultDescription = styled.span`
  ${text16};

  ${mediaQueryFor.mobile} {
    ${text14};
  }
`

const ResultSubscript = styled.span`
  ${text12Medium};

  opacity: 0.5;
`

import { useCallback, useState } from "react"

export default function useCreatorsFilter(initialCreators: number[] = []) {
  const [selectedCreatorIds, setSelectedCreatorIds] = useState(initialCreators)

  const toggleCreator = useCallback((creatorId: number) => {
    setSelectedCreatorIds(prev => {
      if (prev.includes(creatorId)) return prev.filter(id => id !== creatorId)

      return [...prev, creatorId]
    })
  }, [])

  return { creators: selectedCreatorIds, toggleCreator }
}

import styled from "styled-components"

import { AdminLevel, ProblemDetails } from "@/store/api/problems/types"

import StatementRenderer from "@/components/latex/StatementRenderer"
import SubtasksTable from "@/components/SubtasksTable"
import EditorEntrypoint from "@/features/task/current/EditorEntrypoint"
import mediaQueryFor from "@/utils/mediaQuery"

import TestSamples from "./TestSamples"

interface StatementDetailsProps {
  problem: ProblemDetails
}

export default function StatementDetails({ problem }: StatementDetailsProps) {
  if (!problem) return null

  const {
    legend,
    subtasks,
    samples,
    admin_level: adminLevel = AdminLevel.None,
  } = problem

  const withSubtasks = subtasks.length > 1
  const withSamples = samples.length > 0
  const withEditPermissions = adminLevel > 0

  return (
    <StatementWrapper>
      {withEditPermissions && <EditorEntrypoint level={adminLevel} />}

      {legend && (
        <StatementPart>
          <div>
            <StatementRenderer>{legend}</StatementRenderer>
          </div>
        </StatementPart>
      )}

      {withSubtasks && <SubtasksTable subtasks={subtasks} />}

      {withSamples && <TestSamples samples={samples} />}
    </StatementWrapper>
  )
}

const StatementWrapper = styled.div`
  width: 100%;

  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-content: flex-start;

  gap: 32px;

  & img {
    max-width: 100%;
  }

  & p {
    margin: 0;
    padding: 0;
  }

  ${mediaQueryFor.mobile} {
    margin: 8px 0px 0px 0px;

    gap: 24px;
  }
`

const StatementPart = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-content: flex-start;

  gap: 12px;
  white-space: pre-wrap;
  text-align: justify;

  color: var(--color-text);
  transition: color var(--transition-duration) var(--transition-function);

  .katex {
    word-break: keep-all;
    white-space: nowrap;
  }
`

import { MutableRefObject, useCallback, useMemo } from "react"

import { Duration } from "luxon"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

import { ProblemDetails } from "@/store/api/problems/types"

import AlarmIcon from "~/assets/icons/AlarmIcon"
import ServerIcon from "~/assets/icons/ServerIcon"

import DifficultyIcon from "@/components/DifficultyIcon"
import LoginFlow from "@/features/auth/LoginFlow"
import CodeEditor from "@/features/task/current/editor/CodeEditor"
import StatementDetails from "@/features/task/current/statement/StatementDetails"
import TranslationSelector from "@/features/task/edit/legend/TranslationSelector"
import { useAppSelector } from "@/store"
import {
  header24,
  header30,
  text12Medium,
  text13Medium,
  text14,
  text14Medium,
  text15Medium,
  text16,
} from "@/utils/fonts"
import mediaQueryFor from "@/utils/mediaQuery"

interface IStatementProps {
  scrollRef: MutableRefObject<HTMLElement | null>
  problem: ProblemDetails | null
  setLanguage: (lang: string) => void
}

export default function Statement({
  scrollRef,
  problem,
  setLanguage,
}: IStatementProps) {
  const { t } = useTranslation()

  const userId = useAppSelector(({ user }) => user.uid)
  const isDesktop = useAppSelector(({ common }) => common.media === "desktop")

  const onSubmissionAccepted = useCallback(() => {
    if (isDesktop) scrollRef.current!.scrollTo({ top: 0, behavior: "smooth" })
  }, [scrollRef, isDesktop])

  const {
    name = "",
    difficulty,
    attribution,
    limits: { memory = 0, time: rawTime = 0 } = {},
  } = problem ?? {}

  const time = useMemo(
    () => Duration.fromMillis(rawTime || 0).toFormat("s,S"),
    [rawTime],
  )

  const i18n = {
    difficulty: t("pages.task.difficulty", { difficulty }),
    loginTitle: t("pages.task.login"),
    attribution: t("pages.task.attribution", { attribution }),
    limits: {
      time: t("pages.task.statement.limits.time", { time }),
      memory: t("pages.task.statement.limits.memory", { memory }),
    },
  }

  if (!problem) return null

  return (
    <Wrapper>
      <TaskHeader>
        <TaskName>{name}</TaskName>

        <ChooseLanguage
          translations={problem.translations}
          isEditable={false}
          selected={problem.matched_translation!}
          onTranslationSelected={setLanguage}
        />

        {difficulty && (
          <Block data-kind="large">
            <DifficultyIcon percent={difficulty} />
            <span>{i18n.difficulty}</span>
          </Block>
        )}

        <Block data-kind="small">
          <AlarmIcon />
          <span>{i18n.limits.time}</span>
        </Block>

        <Block data-kind="small">
          <ServerIcon />
          <span>{i18n.limits.memory}</span>
        </Block>
      </TaskHeader>

      <StatementDetails problem={problem} />

      {Boolean(userId) ? (
        <CodeEditor
          onSubmissionAccepted={onSubmissionAccepted}
          problem={problem}
        />
      ) : (
        <ProblemLogin />
      )}

      {attribution && <Attribution>{i18n.attribution}</Attribution>}
    </Wrapper>
  )
}

const Wrapper = styled.main`
  grid-area: statement;

  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: start;

  gap: 32px;

  ${text16};
  color: var(--color-text);

  padding: 36px;

  height: max-content;

  ${mediaQueryFor.mobile} {
    ${text14};

    padding: 26px 20px 20px 20px;
  }
`

const TaskHeader = styled.div`
  display: grid;

  grid-template-columns: 1fr max-content max-content;
  grid-template-rows: max-content max-content;

  position: relative;

  grid-auto-flow: row;

  row-gap: 18px;
  column-gap: 10px;

  width: 100%;
`

const TaskName = styled.h2`
  margin: 0;
  padding: 0;

  ${header30};

  color: var(--color-text);
  transition: color var(--transition-duration) var(--transition-function);

  ${mediaQueryFor.mobile} {
    ${header24};
  }
`

const ChooseLanguage = styled(TranslationSelector)`
  grid-row: 1 / 2;
  grid-column: 2 / 4;

  justify-self: right;
`

const Attribution = styled.span`
  ${text14};
  color: var(--color-text);
  opacity: 0.5;
`

const Block = styled.div`
  width: max-content;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;

  &[data-kind="large"] {
    > span {
      ${text15Medium};
      line-height: 18px;
      margin-left: 12px;
      height: 20px;
    }
  }

  &[data-kind="small"] {
    > span {
      ${text14Medium};
      line-height: 16px;
      margin-left: 10px;
      height: 16px;
    }
  }

  ${mediaQueryFor.mobile} {
    &[data-kind="large"] {
      > span {
        ${text12Medium};
        line-height: 16px;
        height: 16px;
      }

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  ${mediaQueryFor.mobile} {
    &[data-kind="small"] {
      > span {
        ${text13Medium};
        height: 14px;
      }

      svg {
        width: 12px;
        height: 12px;
      }
    }
  }

  span {
    color: #909090;
    // --color-text with opacity: 0.5, stable in themes
  }
`

const ProblemLogin = styled(LoginFlow)`
  width: 100%;

  border: 1px solid var(--color-card-stroke);
  border-radius: 10px;
`

import { useCallback, useRef } from "react"

import { useTranslation } from "react-i18next"
import styled from "styled-components"

import ArchiveIcon from "~/assets/icons/ArchiveIcon"

import { text14Medium, text18Medium } from "@/utils/fonts"
import useFileUploader from "@/utils/hooks/useFileUploader"

interface IFileUploaderProps extends ReturnType<typeof useFileUploader> {}

export default function FileUploader({
  dragHandlers,
  clickHandlers,
  state,
  description,
}: IFileUploaderProps) {
  const inputRef = useRef<HTMLInputElement>(null)

  const { t } = useTranslation()

  const triggerUploadModal = useCallback(
    () => inputRef.current?.click(),
    [inputRef]
  )

  const i18n = {
    description: t("pages.task.create.polygon_import.upload.description"),
    idle: t("pages.task.create.polygon_import.upload.idle"),
    defunct: t("pages.task.create.polygon_import.upload.defunct"),
    hover: t("pages.task.create.polygon_import.upload.hover"),
    loading: t("pages.task.create.polygon_import.upload.loading"),
    errored: t("pages.task.create.polygon_import.upload.errored", {
      error: description && t(description),
    }),
  }

  const hasError = state === "errored"

  return (
    <Wrapper onClick={triggerUploadModal} {...dragHandlers}>
      <input
        type="file"
        ref={inputRef}
        accept="application/zip"
        {...clickHandlers}
      />
      <ArchiveIcon />
      <Header>{i18n[hasError ? "idle" : state]}</Header>
      <Description>{i18n.description}</Description>
      {hasError && <ErrorText>{i18n.errored}</ErrorText>}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  cursor: pointer;
  background-color: var(--color-background);
  border: 1px solid var(--color-input-stroke);
  border-radius: 4px;
  transition: background-color var(--transition-duration)
      var(--transition-function),
    border-color var(--transition-duration) var(--transition-function);

  box-sizing: border-box;
  width: 100%;
  height: 272px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;

  color: #888888;

  input {
    display: none;
  }
`

const Header = styled.h3`
  ${text18Medium};
  margin: 18px 0 0 0;
`

const Description = styled.span`
  opacity: 0.5;
  ${text14Medium};
`

const ErrorText = styled.span`
  ${text14Medium};
  color: var(--color-red);
  text-align: center;
  margin-top: 17px;
`

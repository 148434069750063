import { useMemo } from "react"

import { useTranslation } from "react-i18next"
import { Link, useLocation, useParams } from "react-router-dom"
import styled from "styled-components"

import { SidebarHolder, SidebarItem } from "@/components/Sidebar"
import { EditStageT } from "@/features/task/edit/types"
import { text17Medium } from "@/utils/fonts"

interface IStageDescription {
  stage: EditStageT
  link: string
  title: string
}

const stages: IStageDescription[] = [
  {
    stage: "Legend",
    link: "legend",
    title: "pages.task.edit.stages.legend.title",
  },
  {
    stage: "Tests",
    link: "tests",
    title: "pages.task.edit.stages.tests.title",
  },
  {
    stage: "RatingSystem",
    link: "rating-system",
    title: "pages.task.edit.stages.rating_system.title",
  },
  {
    stage: "Access",
    link: "access",
    title: "pages.task.edit.stages.access.title",
  },
  {
    stage: "Logs",
    link: "logs",
    title: "pages.task.edit.stages.logs.title",
  },
]

interface IProblemEditSidebarProps {
  taskTitle: string | undefined
  isAllowed: boolean
}

export default function ProblemEditSidebar({
  taskTitle,
  isAllowed,
}: IProblemEditSidebarProps) {
  const { problemId } = useParams()
  const location = useLocation()

  const currentStage = useMemo(() => {
    const [, stage] = location.pathname.split("/edit/")
    return stages.filter(({ link }) => stage?.startsWith(link))[0]?.stage
  }, [location])

  const { t } = useTranslation()

  const i18n = {
    subtitle: t("pages.task.edit.sidebar.subtitle"),
    back: t("pages.task.edit.sidebar.back"),
    notSaved: t("pages.task.edit.sidebar.not_saved"),
  }

  return (
    <SidebarHolder>
      <BackButton as={Link} to={`/problems/${problemId}`}>
        {i18n.back}
      </BackButton>

      <Subtitle>
        {i18n.subtitle}
        {isAllowed && taskTitle && ` ${taskTitle}`}
      </Subtitle>

      {isAllowed &&
        stages.map(({ stage, link, title }) => (
          <Item
            key={stage}
            $highlighted={stage === currentStage}
            to={`/problems/${problemId}/edit/${link}`}
          >
            {t(title)}
            {/* {stage !== currentStage && !stageSaves[stage] && (
            <SaveIndicator>{i18n.notSaved}</SaveIndicator>
          )} */}
          </Item>
        ))}
    </SidebarHolder>
  )
}

const BackButton = styled(SidebarItem)`
  opacity: 0.5;
  text-decoration: none;
`

const Subtitle = styled.span`
  ${text17Medium};
  transition: color var(--transition-duration) var(--transition-function);

  margin-top: 23px;
  margin-bottom: 6px;
`

const Item = styled(Link)<{ $highlighted: boolean }>`
  display: inline;
  position: relative;

  ${text17Medium};
  text-decoration: none;

  --item-color: var(
    ${({ $highlighted }) =>
      $highlighted ? "--color-left-bar-selection" : "--color-text"}
  );

  color: var(--item-color);
  transition: color var(--transition-duration) var(--transition-function),
    opacity var(--transition-duration) var(--transition-function);

  opacity: ${({ $highlighted }) => ($highlighted ? 1 : 0.5)};

  cursor: ${({ $highlighted }) => ($highlighted ? "default" : "pointer")};
`

// unfinished
// const SaveIndicator = styled.span`
//   position: absolute;
//   top: 0;

//   ${text11Medium};
//   color: var(--color-text);
// `

import { ILegendDraft, ISavedTranslations } from "./types"

const MAX_QUEUE_SIZE = 10

export function mergeTranslations({
  draft,
  recievedTranslations,
}: {
  draft: ILegendDraft
  recievedTranslations: string[]
}): ISavedTranslations {
  const result = Object.fromEntries(recievedTranslations.map(t => [t, true]))

  for (const key of Object.keys(draft)) if (!result[key]) result[key] = false

  return result
}

export function getDraft(problemId: string): ILegendDraft {
  return localStorage.getItem(problemId)
    ? JSON.parse(localStorage.getItem(problemId)!)
    : {}
}

export function saveDraft(problemId: string, draft: ILegendDraft) {
  const queue: number[] = localStorage.getItem("editingQueue")
    ? JSON.parse(localStorage.getItem("editingQueue")!)
    : []
  const id = Number(problemId)

  if (queue.at(-1) !== id) {
    if (queue.includes(id)) queue.splice(queue.indexOf(id), 1)
    queue.push(id)
    while (queue.length > MAX_QUEUE_SIZE) {
      const deleted = queue.shift()
      localStorage.removeItem(deleted!.toString())
    }
    localStorage.setItem("editingQueue", JSON.stringify(queue))
  }
  localStorage.setItem(problemId, JSON.stringify(draft))
}

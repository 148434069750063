export enum EmailAuthStatus {
  Accepted = "accepted",
  Success = "ok",
  Error = "error",
  TokenFailure = "token",
}

export enum EmailAuthField {
  Password = "password",
  Code = "code",
}

export type EmailAuthResponse =
  | { status: EmailAuthStatus.Error; error: string }
  | { status: EmailAuthStatus.Accepted; excepted_fields: EmailAuthField[] }
  | {
      status: EmailAuthStatus.Success
      token: string
      user: { handle: string; id: number }
    }
  | { status: EmailAuthStatus.TokenFailure }

export interface EmailAuthRequest {
  email: string
  token?: string
  code?: string
  password?: string
  reset_password?: true
}

import React, { useEffect } from "react"

import { RouteObject } from "react-router-dom"

import { loadToken, rehydrateToken } from "@/store/slices/auth/token"
import { setLanguage, setLayout, setMedia } from "@/store/slices/common"
import { saveTheme } from "@/store/slices/common/theme"
import { ThemeT } from "@/store/slices/common/types"

import { isSupportedLanguage } from "~/translations/utils"

import { useAppDispatch } from "@/store"

import { FALLBACK_LANGUAGE, LOCALE_COOKIE } from "./i18n"
import { extractMediaFromUserAgent } from "./mediaQuery"
import {
  ConfigRoute,
  IPageConfig,
  PagePopulateFnT,
  PageRehydrateFnT,
  PageRehydrateOptions,
} from "./types"

export const commonPopulate: PagePopulateFnT<{}> = async ({
  req,
  store,
  pageConfig: { layout },
}) => {
  const media = extractMediaFromUserAgent(req.headers["user-agent"] ?? "")
  const token = req.cookies["token"]
  const theme: ThemeT = (req.cookies["color-theme"] as ThemeT) ?? "dark"

  const language = isSupportedLanguage(req.cookies[LOCALE_COOKIE])
    ? req.cookies[LOCALE_COOKIE]
    : FALLBACK_LANGUAGE

  if (token) await store.dispatch(loadToken(token))

  await store.dispatch(setMedia(media))
  await store.dispatch(saveTheme(theme))
  await store.dispatch(setLayout(layout))
  await store.dispatch(setLanguage(language))
}

export const commonRehydrate: PageRehydrateFnT = (dispatch, { layout }) => [
  dispatch(rehydrateToken()).unwrap(),
  dispatch(setLayout(layout)),
]

function Rehydrator({
  children,
  options,
  rehydrate = () => [],
}: {
  rehydrate?: PageRehydrateFnT
  children: React.ReactNode
  options: PageRehydrateOptions
}) {
  const dispatch = useAppDispatch()

  useEffect(() => {
    const work = async () => {
      await Promise.all([
        ...rehydrate(dispatch, options),
        ...commonRehydrate(dispatch, options),
      ])
    }

    work()
  }, [children, rehydrate, dispatch, options])

  return <>{children}</>
}

const getRouteId = (route: RouteObject, pageId: string): string =>
  route.id ? `${pageId}/${route.id}` : pageId

export function withPageProvider(
  pageId: string,
  config: IPageConfig
): RouteObject[] {
  const {
    routes: configRoutes,
    client: { rehydrate },
    layout,
  } = config

  const routes = Array.isArray(configRoutes) ? configRoutes : [configRoutes]

  if (process.env.SSR) {
    // No need to rehydrate, return Route
    // any, because mismatched index/non-index types
    return routes.map(
      (route: ConfigRoute): RouteObject => ({
        ...route,
        id: getRouteId(route, pageId),
      })
    )
  }

  // Client-only! Should add rehydrator
  // any, because mismatched index/non-index types
  return routes.map(
    ({ Component, ...route }: ConfigRoute): RouteObject => ({
      ...route,
      id: getRouteId(route, pageId),
      element: (
        <Rehydrator rehydrate={rehydrate} options={{ layout }}>
          {Component ? <Component /> : null}
        </Rehydrator>
      ),
    })
  )
}

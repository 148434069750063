import { useCallback, useEffect, useReducer, useRef, useState } from "react"

import { useEmailAuthMutation } from "@/store/api/auth"
import { EmailAuthRequest, EmailAuthStatus } from "@/store/api/auth/types"

import TurnstileStatusNotifier from "@/features/auth/LoginFlow/components/TurnstileStatusNotifier"
import {
  LoginControls,
  LoginModalStep,
  TurnstileStatus,
} from "@/features/auth/LoginFlow/types"

import useLoginTurnstile from "./useLoginTurnstile"

export default function useEmailAuthorization(): LoginControls {
  const [step, setStep] = useState(LoginModalStep.LoginEntry)

  const [info, updateInfo] = useReducer(merger<EmailAuthRequest>, { email: "" })
  const [tokenFailed, setTokenFailed] = useState(false)

  const [request, { isLoading: isAuthLoading }] = useEmailAuthMutation()

  const turnstile = useLoginTurnstile()
  const issuedForRef = useRef<string>("")

  const authorize = useCallback(
    async (data: EmailAuthRequest) => {
      let token = info.token
      setTokenFailed(false)

      if (!token || issuedForRef.current !== data.email) {
        token = await turnstile.request()
        updateInfo({ token })
        issuedForRef.current = data.email
      }

      const response = await request({ ...data, token }).unwrap()

      if (response.status === EmailAuthStatus.TokenFailure) {
        updateInfo({ token: undefined })
        setTokenFailed(true)
      }

      return response
    },
    [info.token, request, turnstile, updateInfo],
  )

  useEffect(() => {
    if (removeOnStatus.includes(turnstile.status))
      updateInfo({ token: undefined })
  }, [turnstile.status, updateInfo])

  useEffect(() => {
    turnstile.reset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [turnstile.reset, step])

  const isLoading = isAuthLoading || turnstile.status == TurnstileStatus.Pending

  const turnstileElement = (
    <>
      {turnstile.turnstile}

      <TurnstileStatusNotifier
        status={tokenFailed ? TurnstileStatus.Error : turnstile.status}
      />
    </>
  )

  return {
    authorize,
    isLoading,
    turnstile: turnstileElement,

    step,
    setStep,
    info,
    updateInfo,
  }
}

const removeOnStatus = [TurnstileStatus.Error]

const merger = <T, A extends Partial<T> = Partial<T>>(
  prev: T,
  action: A,
): T => ({ ...prev, ...action })

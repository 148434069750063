import { useCallback, useState } from "react"

import { useParams } from "react-router-dom"

import { useImportTestsMutation } from "@/store/api/problems/edit/tests"

import useFileUploader from "@/utils/hooks/useFileUploader"

const MAX_UPLOAD_SIZE = 100 // MB

const ZIP_MIME_TYPES = [
  "application/zip",
  "application/octet-stream",
  "application/x-zip-compressed",
  "multipart/x-zip",
]

export default function useTestZipUploader() {
  const { problemId = "" } = useParams()

  const [uploadProgress, setUploadProgress] = useState(0)
  const [parsingProgress, setParsingProgress] = useState(0)

  const [triggerUpload] = useImportTestsMutation()

  const uploadFile = useCallback(
    async (file: File) => {
      setUploadProgress(0)
      setParsingProgress(0)

      if (file.size / 1024 ** 2 > MAX_UPLOAD_SIZE)
        throw new Error(
          "pages.task.edit.stages.tests.upload.file_uploader.errors.too_large"
        )

      if (!ZIP_MIME_TYPES.includes(file.type))
        throw new Error(
          "pages.task.edit.stages.tests.upload.file_uploader.errors.invalid_type"
        )

      const result = await triggerUpload({
        problem_id: Number(problemId),
        file,
        callback: progress => setUploadProgress(progress),
      })

      if ("error" in result)
        throw new Error(
          "pages.task.edit.stages.tests.upload.file_uploader.errors.unexpected"
        )

      setParsingProgress(100)
    },
    [problemId, triggerUpload]
  )

  const fileUploader = useFileUploader({ uploadFile })

  return {
    fileUploader,
    uploadProgress,
    parsingProgress,
  }
}

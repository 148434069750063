import { useTranslation } from "react-i18next"
import styled from "styled-components"

import loginWithTelegram from "@/store/slices/auth/telegram"
import { Integration, ILoginWithTelegramMode } from "@/store/slices/auth/types"

import TelegramIcon from "~/assets/icons/TelegramIcon"

import { useAppDispatch, useAppSelector } from "@/store"
import { text14, text16 } from "@/utils/fonts"
import mediaQueryFor from "@/utils/mediaQuery"

interface TelegramLoginButtonProps {
  type?: ILoginWithTelegramMode
  className?: string
}

export default function TelegramLoginButton({
  type = "create",
  className = undefined,
}: TelegramLoginButtonProps) {
  const dispatch = useAppDispatch()
  const login = () => dispatch(loginWithTelegram({ mode: type }))

  const { t } = useTranslation()

  const loading = useAppSelector(state => state.auth.loading)
  const error = useAppSelector(state =>
    state.auth.lastLoaded === Integration.telegram ? state.auth.lastError : null
  )

  const buttonText =
    loading === Integration.telegram
      ? t("auth.button.logging_in")
      : error
      ? t("auth.button.error")
      : t("auth.button.login_with_provider", { provider: t("shared.telegram") })

  return (
    <Wrapper
      data-loading={loading !== null}
      onClick={login}
      className={className}
    >
      <TelegramIcon />
      <span>{buttonText}</span>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  grid-area: telegram;

  cursor: pointer;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  padding: 12px 24px;

  border-radius: 4px;
  background-color: #69a8e5;
  color: white;

  ${text16};
  font-weight: 700;

  > svg {
    margin-right: 16px;
  }

  &[data-loading="true"] {
    pointer-events: none;
  }

  ${mediaQueryFor.mobile} {
    > svg {
      margin-right: 12px;

      width: 17px;
      height: 17px;
    }

    ${text14};
  }
`

import styled from "styled-components"

import { text16Medium } from "@/utils/fonts"

const DropdownTabButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;

  display: flex;
  gap: 6px;

  ${text16Medium};
  color: var(--color-text);
  transition: color var(--transition-duration) var(--transition-function),
    opacity var(--transition-duration) var(--transition-function);

  &[data-active="true"] {
    color: var(--color-primary);
    cursor: default;

    > svg {
      opacity: 1;

      path {
        fill: var(--color-primary);
      }
    }
  }

  > svg {
    opacity: 0;
    transform: rotate(-90deg);
    transition: opacity var(--transition-duration) var(--transition-function);

    path {
      fill: var(--color-text);
      transition: fill var(--transition-duration) var(--transition-function);
    }
  }

  &[data-active="false"]:hover {
    > svg {
      opacity: 1;
    }
  }
`

export default DropdownTabButton

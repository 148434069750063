import React from "react"

import { Link } from "react-router-dom"
import styled from "styled-components"

interface ISelectorGridItemProps {
  to?: string | null
  children?: React.ReactNode
}

function SelectorGridItem({
  to = null,
  children = null,
}: ISelectorGridItemProps) {
  const disabled = to === null

  if (disabled) return <Content data-disabled={disabled}>{children}</Content>

  return (
    <StyledLink to={to}>
      <Content data-disabled={disabled}>{children}</Content>
    </StyledLink>
  )
}

const Content = styled.div`
  background-color: var(--color-bubble);
  border-radius: 10px;
  transition: transform 0.15s ease-out,
    box-shadow var(--transition-duration) var(--transition-function),
    background-color var(--transition-duration) var(--transition-function);

  width: 400px;
  height: 166px;
  padding: 27px;
  box-sizing: border-box;
  overflow: hidden;

  display: flex;
  flex-direction: column-reverse;
  position: relative;

  &[data-disabled="true"] > * {
    opacity: 0.3;
  }

  &[data-disabled="false"] {
    > svg,
    > img {
      opacity: 0.3;
      transition: opacity var(--transition-duration) var(--transition-function);
    }

    &:hover {
      box-shadow: inset 0 0 0 1px var(--color-card-stroke),
        0px 4px 20px var(--color-g-background-stroke);
      transform: translate(0, -4px);

      > svg,
      > img {
        opacity: 1;
      }
    }
  }
`

const StyledLink = styled(Link)`
  text-decoration: none;
`

export default React.memo(SelectorGridItem)

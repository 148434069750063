import {
  ChangeEventHandler,
  MouseEventHandler,
  useCallback,
  useEffect,
  useState,
} from "react"

import { omit } from "lodash"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import styled from "styled-components"

import { AdminLevel } from "@/store/api/problems/types"

import Avatar from "@/components/Avatar"
import ButtonWithLoader from "@/components/ButtonWithLoader"
import Modal from "@/components/Modal"
import RadioInput from "@/components/RadioInput"
import { text14Medium, text16Medium } from "@/utils/fonts"

import useAdminList from "./hooks/useAdminList"
import levels from "./levels"
import UserDetails from "./UserDetails"

interface IEditLevelModalProps {
  close: () => void
  user: {
    id: number
    handle: string
    name: string
    level: AdminLevel
    avatar: string
  } | null
  save: (level: AdminLevel) => Promise<void>
  delete: () => Promise<void>
}

export default function EditLevelModal({
  close,
  user,
  save,
  delete: deleteUser,
}: IEditLevelModalProps) {
  const { problemId = "" } = useParams()

  const { t } = useTranslation()

  const { adminIds } = useAdminList(problemId)

  const [currentLevel, setCurrentLevel] = useState(user?.level ?? null)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    setCurrentLevel(user?.level ?? null)
    if (user) setIsOpen(true)
  }, [user])

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    event => {
      const level = Number(event.currentTarget.value)
      setCurrentLevel(level)
    },
    []
  )

  const handleSave = useCallback(async () => {
    if (currentLevel) await save(currentLevel)
    setIsOpen(false)
  }, [currentLevel, save])

  const handleDelete = useCallback<
    MouseEventHandler<HTMLButtonElement>
  >(async () => {
    await deleteUser()
    setIsOpen(false)
  }, [deleteUser])

  const handleModalClose = useCallback(() => setIsOpen(false), [])

  const i18n = {
    save: t("pages.task.edit.stages.access.save"),
    delete: t("pages.task.edit.stages.access.delete"),
  }

  if (!user) return null

  const isDelitable = adminIds.has(user.id)

  return (
    <ModalWrapper isOpen={isOpen} close={handleModalClose} onExit={close}>
      <CustomUserDetails>
        <Avatar src={user.avatar} width={40} height={40} />
        <p>{user.handle}</p>
        <span>{user.name}</span>
      </CustomUserDetails>

      <Selector>
        {Object.entries(omit(levels, 5)).map(
          ([level, { name, description }]) => (
            <CustomRadioInput
              key={level}
              value={level}
              checked={
                currentLevel
                  ? Number(level) === currentLevel
                  : Number(level) === user?.level
              }
              onChange={handleChange}
            >
              <Label>
                <p>{t(name)}</p>
                <span>{t(description)}</span>
              </Label>
            </CustomRadioInput>
          )
        )}
      </Selector>
      <SaveButton onClick={handleSave} data-type="primary">
        {i18n.save}
      </SaveButton>
      {isDelitable && (
        <DeleteButton onClick={handleDelete}>{i18n.delete}</DeleteButton>
      )}
    </ModalWrapper>
  )
}

const ModalWrapper = styled(Modal)`
  box-sizing: border-box;
  padding: 48px 32px 32px 32px;
  width: 640px;

  display: grid;
  grid-template-areas:
    "details details"
    "selector selector"
    "save delete";
  row-gap: 32px;
`

const CustomUserDetails = styled(UserDetails)`
  grid-area: details;
`

const Selector = styled.div`
  grid-area: selector;
  display: flex;
  flex-flow: column;
  gap: 24px;
`

const CustomRadioInput = styled(RadioInput)`
  display: flex;
  align-items: start;
  justify-content: flex-start;
  gap: 12px;
`

const Label = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  > p {
    margin: 0;
    ${text16Medium};
  }

  > span {
    ${text14Medium};
    opacity: 0.5;
  }
`

const SaveButton = styled(ButtonWithLoader)`
  grid-area: save;
`

const DeleteButton = styled.button`
  grid-area: delete;
  justify-self: end;
  align-self: center;

  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  width: max-content;
  height: max-content;

  ${text14Medium};
  color: var(--color-red);
`

import { ChangeEventHandler, useCallback, useState } from "react"

import { Helmet } from "react-helmet-async"
import { useTranslation } from "react-i18next"
import { Navigate, Outlet, useLocation, useParams } from "react-router-dom"
import styled from "styled-components"

import { useGetTestsQuery } from "@/store/api/problems/edit/tests"

import Loader from "@/components/Loader"
import SearchBar from "@/components/SearchBar"
import { useAppSelector } from "@/store"
import { header30 } from "@/utils/fonts"
import useDebounce from "@/utils/hooks/useDebounce"

import ArchiveEdit from "./ArchiveEdit"
import ShownTestsInput from "./ShownTestsInput"
import TestsList from "./TestsList"

const SEARCH_TIMEOUT = 100

interface ITestEditState {
  forced?: boolean
}

export default function Tests() {
  const { problemId = "" } = useParams()
  const { state }: { state: ITestEditState } = useLocation()

  const { t } = useTranslation()

  const [search, setSearch] = useState("")

  const debouncedSearch = useDebounce(search, SEARCH_TIMEOUT)

  const handleSearchChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    event => setSearch(event.target.value),
    [],
  )

  const { isFetching } = useGetTestsQuery(Number(problemId))
  const tests = useAppSelector(({ tests }) => tests.tests)

  const i18n = {
    title: t("pages.task.edit.stages.tests.title"),
    searchPlaceholder: t("pages.task.edit.stages.tests.search_placeholder"),
  }

  const hasTests = tests && tests.length > 0

  if (!state?.forced && tests && !hasTests)
    return <Navigate to="upload/" replace />

  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href={`https://sort-me.org/problems/${problemId}/edit/tests`}
        />
      </Helmet>

      <Loader size="large" loading={isFetching}>
        <Wrapper>
          <Title>{i18n.title}</Title>
          <ShownTestsInput />
          <StyledSearchBar
            value={search}
            onChange={handleSearchChange}
            placeholder={i18n.searchPlaceholder}
          />
          <ArchiveEdit />

          <TestsList search={debouncedSearch} />
        </Wrapper>
      </Loader>

      <Outlet />
    </>
  )
}

const Wrapper = styled.div`
  grid-area: main;

  padding: 35px 36px;
  box-sizing: border-box;

  height: calc(var(--window-h) - var(--shift-y));
  max-width: calc(1200px - 2 * var(--shift-x));

  overflow: hidden overlay;

  display: grid;
  grid-template-columns: [main] 66fr [save] 40fr;
  grid-auto-rows: min-content;
  column-gap: 24px;
`

const Title = styled.h1`
  grid-column: main;
  margin: 0 0 40px 0;
  ${header30}
`

const StyledSearchBar = styled(SearchBar)`
  grid-column: main;
  width: 100%;
  margin-bottom: 29px;
`

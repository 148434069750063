import { useTranslation } from "react-i18next"
import styled from "styled-components"

import { text14, text18, text18Medium } from "@/utils/fonts"
import mediaQueryFor from "@/utils/mediaQuery"

export default function Bio({ bio }: { bio: string }) {
  const { t } = useTranslation()

  const i18n = {
    title: t("pages.profile.bio"),
  }

  if (!bio) return null

  return (
    <Wrapper>
      <span>{i18n.title}</span>
      <p>{bio}</p>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  grid-area: bio;

  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: start;

  gap: 12px;

  color: var(--color-text);

  > p {
    margin: 0;

    word-wrap: break-word;
    white-space: pre-wrap;

    max-width: 600px;
    width: 100%;

    ${text18};
  }

  > span {
    ${text18Medium};
    letter-spacing: 0.01em;
    font-weight: 800;
  }

  ${mediaQueryFor.mobile} {
    > span {
      display: none;
    }

    > p {
      text-align: justify;

      ${text14};
    }
  }
`

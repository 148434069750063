import { useCallback, useState } from "react"

import styled from "styled-components"

import { EmailAuthStatus } from "@/store/api/auth/types"

import CodeEntry from "@/features/auth/LoginFlow/components/CodeEntry"
import LoginFormLabel from "@/features/auth/LoginFlow/components/LoginFormLabel"
import LoginTitle from "@/features/auth/LoginFlow/components/LoginTitle"
import LoginWrapper from "@/features/auth/LoginFlow/components/LoginWrapper"
import OpenMailingApp from "@/features/auth/LoginFlow/components/OpenMailingApp"
import {
  LoginControls,
  LoginModalStep,
  WithForwardedRef,
} from "@/features/auth/LoginFlow/types"
import { text12Medium, text14Medium } from "@/utils/fonts"
import useScopedTranslation from "@/utils/hooks/useScopedTranslation"
import mediaQueryFor from "@/utils/mediaQuery"

export default function ResetCode({
  setStep,
  info,
  updateInfo,
  authorize,
  isLoading,
  turnstile,
  forwardedRef,
}: WithForwardedRef<LoginControls, HTMLDivElement>) {
  const t = useScopedTranslation("auth.widget.reset_code")

  const [hasError, setError] = useState<boolean>(false)

  const goBack = () => {
    updateInfo({ reset_password: undefined })
    setStep(LoginModalStep.LoginEntry)
  }

  const onReady = useCallback(
    async (code: string) => {
      const result = await authorize({ ...info, code })

      if (result.status === EmailAuthStatus.Error) return setError(true)
      if (result.status === EmailAuthStatus.TokenFailure) return setError(true)
      // TODO: add interactive element to force continue when token failed

      if (result.status === EmailAuthStatus.Accepted) {
        updateInfo({ code })
        setStep(LoginModalStep.ResetPassword)
      }
    },
    [authorize, info, setStep, updateInfo],
  )

  return (
    <Wrapper ref={forwardedRef}>
      <LoginTitle>{t("title", { email: info.email })}</LoginTitle>

      <CodeWrapper>
        <LoginFormLabel htmlFor="code">{t`code`}</LoginFormLabel>
        <CodeEntry onCodeReady={onReady} disabled={isLoading} />
      </CodeWrapper>

      {turnstile}

      {hasError && <ErrorMessage>{t`wrong_code`}</ErrorMessage>}

      <Controls>
        <BackLink data-disabled={isLoading} onClick={goBack}>
          {t`remembered`}
        </BackLink>
        <OpenMailingApp email={info.email} />
      </Controls>
    </Wrapper>
  )
}

const Wrapper = styled(LoginWrapper)`
  ${mediaQueryFor.mobile} {
    align-items: center;

    ${LoginTitle} {
      width: 100%;
    }
  }
`

const CodeWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: start;

  ${mediaQueryFor.mobile} {
    flex-flow: column nowrap;
    justify-content: center;
    gap: 12px;
  }

  gap: 24px;
`

const ErrorMessage = styled.span`
  ${text14Medium};

  color: var(--color-red);
`

const Controls = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: start;
  justify-content: start;

  gap: 32px;

  ${text12Medium};

  ${mediaQueryFor.mobile} {
    width: 100%;
    flex-flow: column-reverse nowrap;
    align-items: center;

    gap: 14px;
  }
`

const BackLink = styled.span`
  color: var(--color-text);
  opacity: 0.5;

  transition: color var(--transition-duration) var(--transition-function);

  cursor: pointer;

  ${mediaQueryFor.mobile} {
    align-self: start;
  }

  &[data-disabled="true"] {
    pointer-events: none;
  }
`

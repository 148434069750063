import {
  MouseEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"

import { Transition } from "react-transition-group"
import styled from "styled-components"

import { ProblemEnvironment } from "@/store/api/problems/types"

import DropdownIcon from "~/assets/icons/DropdownIcon"

import { text13Medium, text15Medium } from "@/utils/fonts"
import mediaQueryFor from "@/utils/mediaQuery"

interface ILanguageSelectorProps {
  items: ProblemEnvironment[]

  onSelect: (key: ProblemEnvironment["id"]) => void
  defaultValue?: ProblemEnvironment["id"] | null
  value: ProblemEnvironment["id"]

  className?: string
}

export default function LanguageSelector({
  className = "",
  items,
  defaultValue = null,
  value,
  onSelect: callback,
}: ILanguageSelectorProps) {
  const [selected, setSelected] = useState(defaultValue ?? items[0].id)
  const [isOpen, setIsOpen] = useState(false)

  const selector = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (isOpen) {
      if (selector.current) {
        selector.current.classList.add("open")
      }

      const listener = () => setIsOpen(false)
      document.addEventListener("click", listener)

      return () => document.removeEventListener("click", listener)
    }
  }, [isOpen])

  useEffect(() => {
    setSelected(value)
  }, [value])

  const onSelect: MouseEventHandler<HTMLSpanElement> = useCallback(
    ev => {
      ev.stopPropagation()

      const id = Number(ev.currentTarget.dataset["api"]!)

      setSelected(id)
      callback(id)
      setIsOpen(false)
    },
    [callback]
  )

  const onOpen: MouseEventHandler<HTMLDivElement> = useCallback(ev => {
    ev.stopPropagation()

    setIsOpen(true)
  }, [])

  return (
    <Wrapper onClick={onOpen} className={className}>
      <span>{items.find(({ id }) => selected === id)?.name}</span>

      <DropdownIcon />

      <Transition in={isOpen} nodeRef={selector} timeout={300}>
        {state => (
          <ListWrapper
            ref={selector}
            key="select-list-wrapper"
            data-open={state}
          >
            {items.map(({ id, name }) => (
              <span key={id} data-api={id} onClick={onSelect}>
                {name}
              </span>
            ))}
          </ListWrapper>
        )}
      </Transition>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: absolute;
  bottom: -20px;
  right: 17px;

  background-color: var(--color-background);
  border: 1px solid var(--color-input-stroke);
  border-radius: 5px;
  color: var(--color-text);

  padding: 10px 12px;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;

  width: 169px;

  cursor: pointer;
  user-select: none;

  ${text15Medium};

  & > svg {
    path {
      fill: var(--color-text);
      opacity: 0.5;

      transition: fill var(--transition-duration) var(--transition-function);
    }
  }

  transition: background-color var(--transition-duration)
      var(--transition-function),
    border-color var(--transition-duration) var(--transition-function),
    color var(--transition-duration) var(--transition-function);

  box-sizing: border-box;

  ${mediaQueryFor.mobile} {
    ${text13Medium};

    right: -2px;
    outline: none;
  }
`

const ListWrapper = styled.div`
  position: absolute;

  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-content: flex-start;

  box-sizing: border-box;
  width: 169px;

  bottom: -1px;
  left: -1px;

  background-color: var(--color-background);
  border: 1px solid var(--color-input-stroke);
  border-radius: 5px;
  color: var(--color-text);

  & > span {
    padding: 10px 12px;

    transition: background-color var(--transition-duration)
      var(--transition-function);

    &:hover {
      background-color: var(--color-g-background);
    }

    &:first-child {
      border-radius: 5px 5px 0px 0px;
    }

    &:last-child {
      border-radius: 0px 0px 5px 5px;
    }
  }

  &[data-open="entering"] {
    animation: selectorOpen 0.3s var(--transition-function);
  }

  &[data-open="entered"] {
    opacity: 1;
  }

  &[data-open="exiting"] {
    animation: selectorOpen 0.3s var(--transition-function) reverse;
  }

  &[data-open="exited"] {
    display: none;
  }

  transition: background-color var(--transition-duration)
      var(--transition-function),
    border-color var(--transition-duration) var(--transition-function),
    color var(--transition-duration) var(--transition-function),
    opacity var(--transition-duration) var(--transition-function);

  @keyframes selectorOpen {
    0% {
      opacity: 0;
      pointer-events: none;
    }

    100% {
      opacity: 1;
      pointer-events: all;
    }
  }
`
